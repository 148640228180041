/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'

class Checkout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      holdername: '',
      cardNumber: '',
      expiration_month: '',
      expiration_year: '',
      security_code: '',
      processOmise: false,
      globalSettings: []
    }
  }

  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://cdn.omise.co/omise.js'
    script.async = true
    document.body.appendChild(script)
    this.props.sateValChange('productlist', 'a')
  }

  componentWillReceiveProps(PropsDt) {
    if (
      this.state.processOmise !== PropsDt.checkoutState.processOmise &&
      PropsDt.processOmise !== false
    ) {
      if (PropsDt.checkoutState.processOmise === true) {
        var checkoutState = PropsDt.checkoutState
        this.setState(
          {
            holdername: checkoutState.holdername,
            cardNumber: checkoutState.cardNumber,
            expiration_month: checkoutState.expiration_month,
            expiration_year: checkoutState.expiration_year,
            security_code: checkoutState.security_code,
            globalSettings: PropsDt.checkoutState.settings
          },
          function() {
            this.getOmiseToken()
          }
        )
      }
      console.log(PropsDt, 'PropsDt')
    }
  }

  getOmiseToken() {
    try {
      Omise.setPublicKey(this.state.globalSettings.omise_public_key)
      var current = this
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false
      }
      Omise.createToken('card', card, function(statusCode, response) {
        current.props.sateValChange('processOmise', false)
        //hideLoader('omise-pay-btn', 'class')
        if (statusCode === 200) {
          if (
            response.object == 'error' ||
            !response.card.security_code_check
          ) {
            var msgArr = Array()
            msgArr['message'] =
              response.object == 'error'
                ? response.message
                : 'Invalid card details.'
            current.props.sateValChange('omiseTokenError', msgArr)
          } else {
            current.props.sateValChange('omiseTokensucess', response)
            /*current.onProcessOmiseToken(response);*/
          }
        } else {
          var msgArr = Array()
          msgArr['message'] =
            response.message !== ''
              ? response.message
              : 'Sorry!. Error from omise.'
          current.props.sateValChange('omiseTokenError', msgArr)
        }
      })
    } catch (e) {}
  }

  render() {
    return <div style={{ display: 'none' }} />
  }
}

const mapStateTopProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(Checkout)
