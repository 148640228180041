import React, { Component } from 'react'
import $ from 'jquery'
import axios from 'axios'
import cookie from 'react-cookies'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getUserData } from '../../stores/user'
import { getCartItems } from '../../stores/cart'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import { customerId, showInfo } from '../../config/supporters'
import { history } from '../../store'
import moment from 'moment'
import MaskedInput from 'react-text-mask'
import { APP_ID, API_URL } from '../../config/constans'
import 'react-datepicker/dist/react-datepicker.css'
import gldMbrImg from '../../assets/img/gold-rippon.png'

var _ = require('lodash')

class Accountdetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {},
      errors: {}
    }

    this.onChangeGender = this.onChangeGender.bind(this)
    this.handleChange = this.handleChange.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.destroyUserSession = this.destroyUserSession.bind(this)
  }

  componentDidMount() {
    this.props.getCartItems()
  }

  handleInputChange(event) {
    const target = event.target

    var btnValue = target.attributes['aria-pressed'].value
    var finalValue = btnValue === 'true' ? 1 : 0
    var qs = require('qs')
    var postObject = {
      app_id: APP_ID,
      customer_id: customerId(),
      notify_email: finalValue
    }
    axios
      .post(
        API_URL + 'api/customer/update_notification_status',
        qs.stringify(postObject)
      )
      .then(response => {
        if (response.data.status === 'ok') {
          return true
        } else {
        }
      })
  }

  componentWillMount() {
    this.props.getUserData()
  }
  destroyUserSession() {
    cookie.remove('UserId')
    cookie.remove('UserFname')
    cookie.remove('UserLname')
    cookie.remove('UserMobile')
    cookie.remove('UserEmail')
    cookie.remove('userAccountType')
    cookie.remove('UserDefaultAddress')
    cookie.remove('UserDefaultPostalCode')
    cookie.remove('UserDefaultUnitOne')
    cookie.remove('UserDefaultUnitTwo')

    history.push('/')
  }

  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true
    var userPhoneNumber = fields['phonenumber']

    //First Name
    if (!fields['firstname']) {
      formIsValid = false
      errors['firstname'] = 'This field is required.'
    }

    if (typeof fields['firstname'] !== 'undefined') {
      if (!fields['firstname'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false
        errors['firstname'] = 'First Name must contain only letters.'
      }
    }
    //Last Name
    if (!fields['lastname']) {
      formIsValid = false
      errors['lastname'] = 'This field is required.'
    }

    if (typeof fields['lastname'] !== 'undefined') {
      if (!fields['lastname'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false
        errors['lastname'] = 'Last Name must contain only letters.'
      }
    }
    //Phone Number
    if (!userPhoneNumber) {
      formIsValid = false
      errors['phonenumber'] = 'This field is required.'
    }

    if (typeof userPhoneNumber !== 'undefined') {
      if (!userPhoneNumber.match(/^[0-9]+$/)) {
        formIsValid = false
        errors['phonenumber'] = 'Invalid Mobile number'
      } else if (userPhoneNumber.length < 8) {
        formIsValid = false
        errors['phonenumber'] =
          'Mobile number must be of minimum 8 characters length.'
      }
    }
    //Email
    if (!fields['email']) {
      formIsValid = false
      errors['email'] = 'This field is required.'
    }

    if (typeof fields['email'] !== 'undefined') {
      let lastAtPos = fields['email'].lastIndexOf('@')
      let lastDotPos = fields['email'].lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors['email'] = 'Email is not valid.'
      }
    }

    this.setState({ errors: errors })
    return formIsValid
  }
  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value

    this.setState({ fields, errors: {} })
    if (field === 'phonenumber') {
      var mobileNo = e.target.value
      if (mobileNo !== '') {
        this.phoneNumberExist(mobileNo)
      }
    }

    if (field === 'email') {
      var email = e.target.value
      if (email !== '' && this.emailIsValid(email) === true) {
        this.emailExist(email)
      }
    }
  }
  onChangeGender(fieldName, field) {
    let fields = this.state.fields
    fields[fieldName] = field.value
    this.setState({ fields, errors: {} })
    return field
  }

  phoneNumberExist(mobileNo) {
    let mobileNumber = mobileNo

    var qs = require('qs')
    var postObject = {
      app_id: APP_ID,
      type: 'web',
      customer_phone: mobileNumber
    }
    axios
      .post(API_URL + 'api/customer/phoneno_exist', qs.stringify(postObject))
      .then(response => {
        if (response.data.status === 'ok') {
          return true
        } else {
          let fields = this.state.fields
          fields['phonenumber'] = ''
          this.setState({
            fields,
            errors: { phonenumber: 'Phone no already exists' }
          })
        }
      })
  }

  emailExist(email) {
    var qs = require('qs')
    var postObject = {
      app_id: APP_ID,
      type: 'web',
      customer_email: email
    }
    axios
      .post(API_URL + 'api/customer/email_exist', qs.stringify(postObject))
      .then(response => {
        if (response.data.status === 'ok') {
          return true
        } else {
          let fields = this.state.fields
          fields['email'] = ''
          this.setState({
            fields,
            errors: { email: 'Email address already exists' }
          })
        }
      })
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  getUserDataByField(defaultValue, field) {
    return typeof this.state.fields[field] !== 'undefined'
      ? this.state.fields[field]
      : defaultValue
  }
  componentWillReceiveProps(nextProps) {
    let fields = this.state.fields

    if (
      nextProps.userData.customer_birthdate !== null &&
      nextProps.userData.customer_birthdate !== '0000-00-00' &&
      nextProps.userData.customer_birthdate !== ''
    ) {
      var birthdate = moment(nextProps.userData.customer_birthdate).format(
        'DD-MM-YYYY'
      )
    } else {
      birthdate = ''
    }
    fields['firstname'] =
      nextProps.userData.customer_first_name !== null
        ? nextProps.userData.customer_first_name
        : ''
    fields['lastname'] =
      nextProps.userData.customer_last_name !== null
        ? nextProps.userData.customer_last_name
        : ''
    fields['phonenumber'] =
      nextProps.userData.customer_phone !== null
        ? nextProps.userData.customer_phone
        : ''
    fields['email'] =
      nextProps.userData.customer_email !== null
        ? nextProps.userData.customer_email
        : ''
    fields['nickname'] =
      nextProps.userData.customer_nick_name !== null
        ? nextProps.userData.customer_nick_name
        : ''
    fields['email'] =
      nextProps.userData.customer_email !== null
        ? nextProps.userData.customer_email
        : ''
    fields['mobile'] =
      nextProps.userData.customer_phone !== null
        ? nextProps.userData.customer_phone
        : ''
    fields['photo'] =
      nextProps.userData.customer_photo !== null &&
      nextProps.userData.customer_photo !== ''
        ? 'https://ccpl.ninjaos.com/media/dev_team/profile-pictures/customers/' +
          nextProps.userData.customer_photo
        : ''
    fields['postal'] =
      nextProps.userData.customer_postal_code !== null
        ? nextProps.userData.customer_postal_code
        : ''
    fields['unitnumber2'] =
      nextProps.userData.customer_address_line2 !== null
        ? nextProps.userData.customer_address_line2
        : ''
    fields['unitnumber1'] =
      nextProps.userData.customer_address_line1 !== null
        ? nextProps.userData.customer_address_line1
        : ''
    fields['address'] =
      nextProps.userData.customer_address_name !== null
        ? nextProps.userData.customer_address_name
        : ''
    fields['address1'] =
      nextProps.userData.customer_address_name2 !== null
        ? nextProps.userData.customer_address_name2
        : ''
    fields['company'] =
      nextProps.userData.customer_company_name !== null
        ? nextProps.userData.customer_company_name
        : ''
    fields['fbid'] = nextProps.userData.customer_fb_id
    fields['birthdate'] = birthdate
    fields['image_source'] =
      'https://ccpl.ninjaos.com/media/dev_team/profile-pictures/customers/'
    fields['juicedid'] =
      nextProps.userData.customer_unique_id !== null
        ? nextProps.userData.customer_unique_id
        : ''
    fields['joinedOn'] =
      nextProps.userData.customer_created_on !== null
        ? nextProps.userData.customer_created_on
        : ''
    fields['gender'] =
      nextProps.userData.customer_gender !== null
        ? nextProps.userData.customer_gender
        : 'U'
    this.setState({ fields })
  }
  updateProfileSubmit(e) {
    e.preventDefault()

    if (this.handleValidation()) {
      $('#c_loading').show()

      const formPayload = this.state.fields

      var qs = require('qs')
      $('#c_loading').show()
      var postObject = {
        app_id: APP_ID,
        type: 'web',
        customer_first_name: formPayload.firstname,
        customer_last_name: formPayload.lastname,
        customer_nick_name: formPayload.nickname,
        customer_phone: formPayload.phonenumber,
        customer_email: formPayload.email,
        customer_birthdate: formPayload.birthdate,
        customer_postal_code: formPayload.postal,
        customer_address_name: formPayload.address,
        customer_address_name2: formPayload.address1,
        customer_company_name: formPayload.company,
        customer_gender:
          formPayload.gender !== '' && typeof formPayload.gender !== 'undefined'
            ? formPayload.gender
            : 'U',
        customer_address_line1: formPayload.unitnumber1,
        customer_address_line2: formPayload.unitnumber2,
        customer_id: customerId()
      }

      axios
        .post(API_URL + 'api/customer/updateprofile', qs.stringify(postObject))
        .then(response => {
          $('#c_loading').hide()

          if (response.data.status === 'ok') {
            //this.setStateLoading('ok');
            cookie.save(
              'UserFname',
              response.data.result_set.customer_first_name
            )
            cookie.save(
              'UserLname',
              response.data.result_set.customer_last_name
            )
            cookie.save('UserMobile', response.data.result_set.customer_phone)

            cookie.save(
              'UserDefaultAddress',
              response.data.result_set.customer_address_name
            )
            cookie.save(
              'UserDefaultUnitOne',
              response.data.result_set.customer_address_line1
            )
            cookie.save(
              'UserDefaultUnitTwo',
              response.data.result_set.customer_address_line2
            )
            cookie.save(
              'UserDefaultPostalCode',
              response.data.result_set.customer_postal_code
            )

            showInfo(response.data.message, 'Success')
            const cartItems = this.props.cartData
            /* Redirect to checkout page .. */
            if (
              cookie.load('isChecoutRedirect') === 'Yes' &&
              _.isEmpty(cartItems) === false &&
              _.isEmpty(cartItems.cart_details) === false &&
              _.isEmpty(cartItems.cart_items) === false
            ) {
              cookie.save('isChecoutRedirect', 'No')
              history.push('/checkout')
              //hashHistory.push("/checkout");
            }
          } else {
            if (response.data.form_error) {
              $('.myaccount-error').html(
                '<div class="alert alert_danger" style="display:block">' +
                  response.data.form_error +
                  '</div>'
              )
            } else {
              $('.guest-chk-error').html(
                '<div class="alert alert_danger" style="display:block">' +
                  response.data.message +
                  '</div>'
              )
            }
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }
  render() {
    if (this.props.userStatus === 'error') {
      history.push('/')
    }

    /* { value: 'Unspecified', label: 'Unspecified' } */
    const userData = this.props.userData
    const settingBtnClass =
      parseInt(userData.customer_email_notify) === 1
        ? 'btn btn-sm btn-secondary btn-toggle active'
        : 'btn btn-sm btn-secondary btn-toggle'
    const disabledEmail =
      this.state.fields['fbid'] !== '' && this.state.fields['email'] === ''
        ? 'hhhh'
        : 'disabled'
    if (_.isEmpty(userData) === true && this.props.userStatus === 'error') {
      history.push('/')
    }

    return (
      <React.Fragment>
        <div className="account_sec">
          <div className="accmenu_sec">
            <div className="accprofile text-center">
              <div className="accprofile_img">
                <img src="img/no-image.jpg" alt="..." />
              </div>
              {userData.customer_membership_type !== '' &&
                userData.customer_membership_type === 'Gold' && (
                  <div>
                    <div className="membership_status">
                      <img src={gldMbrImg} alt="Gold Member" />
                      <span wfd-id="313">Gold Member</span>
                    </div>
                    <div className="member-ship-main">
                      <h3>Member Benefits</h3>
                      <div className="member-ship-gold">
                        <h4>
                          <span>Gold Member</span>
                        </h4>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <div className="accprofile_info">
                {userData.customer_membership_type === 'Normal' && (
                  <p className="text-uppercase">Cashback Membership</p>
                )}
                <p className="text-uppercase">Date Joined</p>
                <p>
                  {' '}
                  {moment(userData.customer_created_on).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
            <div className="setting_menu_list">
              <ul>
                {userData.customer_fb_id == '' && (
                  <li>
                    <Link
                      data-toggle="modal"
                      data-target="#changePasswordModalCenter"
                      to="#changePasswordModalCenter">
                      <i className="setting_menu_list_icon password_icon" />
                      Change Password
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="#">
                    <i className="setting_menu_list_icon setting_icon" />
                    Settings
                  </Link>
                  <ul className="myacc_dropmenu">
                    <li>
                      {' '}
                      <Link to="#">Option For Promotions </Link>
                      <ul className="tg-list">
                        <li className="tg-list-item">
                          <button
                            type="button"
                            className={settingBtnClass}
                            data-toggle="button"
                            onClick={this.handleInputChange}
                            aria-pressed="true"
                            autoComplete="off">
                            <div className="handle" />
                          </button>
                          {/* <input className="tgl tgl-light cb1" data-id="push" id="cb1" onChange={this.handleInputChange} type="checkbox" defaultValue={userData.customer_email_notify} defaultChecked={(parseInt(userData.customer_email_notify) === 1 ? true : false)} />
                                                    <label className="tgl-btn notification_settings" data-section="web" data-id="push" htmlFor="cb1" />
                                                
                                               */}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#" onClick={this.destroyUserSession.bind()}>
                    <i className="setting_menu_list_icon logout_icon" />
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="accsec_right">
            <h1>
              Hello, {this.state.fields['firstname']}{' '}
              {this.state.fields['lastname']}
            </h1>
            <p>
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </p>
            <div className="ttsplitter ttsplitter_md text-center myaccount-form-sec-block">
              <div className="ttsplitter_in">
                <h1 className="font-gothamlight">Your Account Information</h1>
              </div>
            </div>
            <span className="myaccount-error" />
            <div className="acc-inform">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <div className="focus-out focused">
                      <label>First Name</label>
                      <input
                        type="input"
                        name="firstname"
                        id="firstname"
                        className="form-control input-focus"
                        onChange={this.handleChange.bind(this, 'firstname')}
                        value={this.state.fields['firstname'] || ''}
                      />
                    </div>
                    <span
                      className={
                        this.state.errors['firstname']
                          ? 'error show'
                          : 'error hide'
                      }>
                      {this.state.errors['firstname']}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <div className="focus-out focused">
                      <label>Last Name</label>
                      <input
                        type="input"
                        className="form-control input-focus"
                        name="lastname"
                        id="lastname"
                        onChange={this.handleChange.bind(this, 'lastname')}
                        value={this.state.fields['lastname'] || ''}
                      />
                    </div>
                    <span
                      className={
                        this.state.errors['lastname']
                          ? 'error show'
                          : 'error hide'
                      }>
                      {this.state.errors['lastname']}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <div className="focus-out focused">
                      <label>Email Address</label>
                      <input
                        type="text"
                        ref="user_email"
                        disabled={disabledEmail}
                        id="user_email"
                        name="user_email"
                        className="form-control input-focus"
                        value={this.state.fields['email'] || ''}
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="focus-out focused">
                      <label>Mobile Number</label>
                      <input
                        type="tel"
                        maxLength={8}
                        name="phonenumber"
                        id="phonenumber"
                        className="form-control input-focus"
                        pattern="\d*"
                        placeholder="Phone Number"
                        onChange={this.handleChange.bind(this, 'phonenumber')}
                        value={this.state.fields['phonenumber'] || ''}
                      />
                      <span
                        className={
                          this.state.errors['phonenumber']
                            ? 'error show'
                            : 'error hide'
                        }>
                        {this.state.errors['phonenumber']}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  {/* <div className="col-md-6">
                                        <div className="focus-out focused">
                                            <label>Email Address</label>
                                            <input type="email" name="customer_email" className="form-control input-focus" defaultValue="devtest@mailinator.com" />
                                        </div>
								</div> */}
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="focus-out focused">
                          <label>Birthday</label>
                          <div className="react_datepicker">
                            <div className="react-datepicker-wrapper">
                              <div className="react-datepicker__input-container">
                                <MaskedInput
                                  mask={[
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  className="form-control"
                                  placeholder="DD/MM/YYYY"
                                  guide={false}
                                  value={this.state.birthDate}
                                  id="my-input-id"
                                  onChange={this.handleChange.bind(
                                    this,
                                    'birthdate'
                                  )}
                                  value={this.state.fields['birthdate'] || ''}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label>Gender </label>
                        <div className="custom-select-control">
                          <select
                            className="form-control"
                            id="gender"
                            value={this.state.fields['gender']}
                            onChange={this.handleChange.bind(this, 'gender')}>
                            <option value="">Select...</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="U">Unspecified</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*    <div className="form-group">
                                <div className="row" /></div>
                            <h4 className="form_grouptt">Address</h4>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="focus-out">
                                            <label>Postal Code</label>
                                            <input type="input" maxLength={6} id="postal-code" className="form-control input-focus customer_postal_code" defaultValue />
                                            <div id="spn-postalcode-error" /></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="focus-out">
                                            <label>Address Line </label>
                                            <input type="input" className="form-control input-focus" readOnly defaultValue />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="focus-out">
                                                    <label>Unit Number 1</label>
                                                    <input type="input" className="form-control input-focus" defaultValue />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="focus-out">
                                                    <label>Unit Number 2</label>
                                                    <input type="input" className="form-control input-focus" defaultValue />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
	</div> */}
              <div className="btn_sec">
                <button
                  type="button"
                  className="btn btn-primary btn_minwid myaccount_update"
                  onClick={this.updateProfileSubmit.bind(this)}>
                  Update
                </button>
              </div>
            </div>
            {/*
                        <div className="other-add myaccount-form-sec-block">
                            <div className="ttsplitter ttsplitter_md text-center">
                                <div className="ttsplitter_in"><h1 className="font-gothamlight">Other Address</h1></div>
                            </div>
                            <div className="other-add-body mCustomScrollbar">
                                <div className="addressBook">
                                    <div>
                                        <div className="m-0 row">
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                            <div className="p-0 col-12 col-sm-6 col-md-4">
                                                <div className="addressBlocks">
                                                    <p>Block 37, #11 - 427 Jalan Rumah Tinggi, Singapore - 150037</p> <span>x</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <ul className="other-add-row">
                                        <li>No address found</li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                        */}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ user, cart }) => ({
  userData: user.returnData,
  cartData: cart.returnData,
  userStatus: user.isStatus,
  userCommonData: user.commoData
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUserData, getCartItems }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accountdetails)
