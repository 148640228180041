import axios from "axios";
import { API_URL, APP_ID } from "../../config/constans";
export const GET_CURRENT_ORDERS = "settings/GET_CURRENT_ORDERS";

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ORDERS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      };

    default:
      return state;
  }
}
export function getcurrentOrders(search_key , search_value , a_from_date , a_to_date , order_source) {
  let appId =APP_ID;
  let API_URLL = API_URL+ "api/reports/order_history";
  var searchStirng1 = "";
    var searchStirng2 = "";
	  var searchStirng3 = "";
  if( (search_key !== "" && typeof search_key !== "undefined" ) && (search_value !== "" && typeof search_value !== "undefined" )) {
	   searchStirng1=  "&"+search_key + "=" + search_value;
  }
  
    if( ( a_from_date !== ""  && typeof a_from_date !== "undefined" )  && (a_to_date !== "" && typeof a_to_date !== "undefined" )) {
	  searchStirng2=  "&from_date=" + a_from_date + "&to_date="+a_to_date;
  }
 console.log(order_source);
   if( typeof order_source !== "undefined" &&  order_source !== "") {
	 searchStirng3=  "&order_source=" + order_source;
  }


  return function(dispatch) {
    axios
      .get(
        `${API_URLL}/?app_id=` +
          appId + "&order_type=Currentday&except_availability=yes"+searchStirng1 + searchStirng2 + searchStirng3
      )
      .then(response => {
        dispatch({
          type: GET_CURRENT_ORDERS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
