import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getblocks, getOutlets } from '../../stores/cms'
import axios from 'axios'
import Header from '../layout/header'
import Footer from '../layout/footer'
import {
  API_URL,
  APP_ID,
  DINEIN_ID,
  DELIVERY_ID,
  deliveryText
} from '../../config/constans'
import cookie from 'react-cookies'
import { history } from '../../store'
import moment from 'moment'
import noImage from '../../assets/img/product-no-image.jpg'
import { addressFormat } from '../../config/supporters'
var _ = require('lodash')
var parse = require('html-react-parser')
class Orderconfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cartData: [],
      cartItems: [],
      status: 'Loading',
      availabilityId: '',
      windowHeightNormal: 0,
      windowHeight: 0
    }
  }

  componentDidMount() {
    cookie.remove('promotionApplied')
    cookie.remove('promotionAmount')
    cookie.remove('promocode')
    cookie.remove('promotionCategory')
    cookie.remove('promotionId')
    this.props.getblocks()
    this.props.getOutlets()

    const orderId =
      typeof this.props.match.params.orderId !== 'undefined' &&
      this.props.match.params.orderId !== ''
        ? this.props.match.params.orderId
        : ''
    axios
      .get(
        API_URL +
          'apiV3/reports/order_history?app_id=' +
          APP_ID +
          '&local_order_no=' +
          orderId +
          '&customer_id=' +
          cookie.load('UserId')
      )
      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({ status: res.data.status })
          /*	this.setState({availabilityId: res.data.result_set[0]['order_availability_id']});
			 this.setState({subTotal: res.data.result_set[0]['order_sub_total']});
		 this.setState({grandTotal: res.data.result_set[0]['order_total_amount']}); */
          this.setState({ cartItems: res.data.result_set[0]['items'] })
          this.setState({ cartData: res.data.result_set[0] })
        } else {
          history.push('/')
        }
      })
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['order_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name']
        var modPrice =
          modifiers[i]['modifiers_values'][0]['order_modifier_price']
        var modPricedisplay = modPrice > 0 ? ' ( +$' + modPrice + ' )' : ''
        html +=
          "<li className='sub-item'>" +
          modName +
          ' : ' +
          modval +
          modPricedisplay +
          '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (_.isEmpty(comboLen) === true && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]['menu_product_name']
        var comboQty = combos[r]['menu_product_qty']
        var comboPrice = combos[r]['menu_product_price']
        var newPrice = ''
        var newPrice = comboPrice > 0 ? ' ( +$' + comboPrice + ' )' : ''
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['order_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['order_modifier_price']
        var newModValPrice = ''
        var newModValPrice = modValPrice > 0 ? ' (+' + modValPrice + ')' : ''

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }
  render() {
    return (
      <React.Fragment>
        <Header allProps={this.props} />
        <main>
          <div className="main-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="page-header-block bg-transparent">
                    <img src="../img/checked-mark.png" alt="" />
                    <h1 className="font-gothamlight  text-center text-uppercase">
                      {' '}
                      Thank You
                    </h1>
                    <h2 className="font-azakie text-center">
                      Check your order details and select your payment option
                    </h2>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-7 m-auto">
                  <div className="order-details-item-block order-info-block">
                    <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                      Your Order Details
                    </h4>
                    <div className="order-info text-center">
                      <h4 className="font-gothamlight  text-center text-uppercase">
                        Order No - {this.state.cartData.order_local_no}
                      </h4>
                      <p>
                        Order placed at :{' '}
                        <moment format="DD/MM/YYYY">
                          {this.state.cartData.order_created_on}
                        </moment>
                      </p>
                      <p>Pay by : {this.state.cartData.order_method_name}</p>
                    </div>
                    <div className="delivery-info">
                      <div className="slfcol-form-block">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="media">
                              <img
                                className="mr-3"
                                src="../img/take-away.png"
                                alt="Generic placeholder "
                              />
                              {this.state.cartData.order_availability_id ===
                              DELIVERY_ID ? (
                                <div className="media-body">
                                  <h6 className="mt-0">
                                    {deliveryText} LOCATION{' '}
                                  </h6>
                                  <p className="outlet-info">
                                    {addressFormat(
                                      this.state.cartData
                                        .order_customer_unit_no1,
                                      this.state.cartData
                                        .order_customer_unit_no2,
                                      this.state.cartData
                                        .order_customer_address_line1,
                                      this.state.cartData
                                        .order_customer_address_line2,
                                      this.state.cartData
                                        .order_customer_postal_code
                                    )}
                                  </p>
                                </div>
                              ) : (
                                <div className="media-body">
                                  <h6 className="mt-0">
                                    {this.state.cartData
                                      .order_availability_id === DINEIN_ID
                                      ? 'DIne in'
                                      : 'SELF COLLECTION'}{' '}
                                    LOCATION{' '}
                                  </h6>
                                  <p className="outlet-info">
                                    {this.state.cartData.outlet_name} -{' '}
                                    {addressFormat(
                                      this.state.cartData.outlet_unit_number1,
                                      this.state.cartData.outlet_unit_number2,
                                      this.state.cartData.outlet_address_line1,
                                      this.state.cartData.outlet_address_line2,
                                      this.state.cartData.outlet_postal_code
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="media">
                              <div className="media-body">
                                <h6 className="mt-0">
                                  {this.state.last_counter !== '' &&
                                  this.state.last_counter !== null
                                    ? deliveryText
                                    : this.state.cartData
                                        .order_availability_id === DINEIN_ID
                                    ? 'DIne in'
                                    : 'SELF COLLECTION'}{' '}
                                  Date & Time
                                </h6>
                                <p className="outlet-info">
                                  <moment format="DD/MM/YYYY">
                                    {this.state.cartData.order_date}
                                  </moment>
                                </p>
                              </div>
                              <img
                                className="mr-3"
                                src="../img/alarm-clock.png"
                                alt="Generic placeholder image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="cart-items-block">
                      {this.state.cartItems.map((item, index) => (
                        <div className="cart-row" key={index}>
                          <div className="row">
                            <div className="col-7">
                              <div className="cart-left">
                                <div className="cart-img">
                                  <img
                                    src={
                                      item.item_image !== ''
                                        ? item.item_image
                                        : noImage
                                    }
                                    width={110}
                                    height={67}
                                    alt=""
                                  />
                                </div>
                                <div className="cart-info">
                                  <h6>
                                    {item.item_qty} X {item.item_name}
                                  </h6>

                                  {this.loadModifierItems(
                                    item.cart_item_type,
                                    item.modifiers,
                                    item.set_menu_component
                                  )}
                                  {/*  <div className="cart-addon-info">
                                <span className="d-inline-block">Size:</span><span className="d-inline-block font-italic"> Large</span>

                              </div>
                              <div className="cart-addon-info">
                                <span className="d-inline-block">Add on:</span><span className="d-inline-block font-italic"> Name</span>

                              </div> */}
                                </div>
                              </div>
                              <p className="cart-special-text">
                                {item.item_specification}
                              </p>
                            </div>
                            <div className="col-5 text-right">
                              <div className="cart-right ">
                                <div className="cart-price">
                                  <p>${item.item_total_amount}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="calctrcart-footer">
                        <div className="calctrcart-row">
                          <div className="row">
                            <div className="col-8">
                              <p className="text-uppercase">SUBTOTAL</p>
                            </div>
                            <div className="col-4 text-right">
                              <span>
                                ${this.state.cartData.order_sub_total}
                              </span>
                            </div>
                          </div>
                        </div>

                        {this.state.cartData.order_availability_id ===
                          DINEIN_ID &&
                          this.state.cartData.order_service_charge > 0 &&
                          this.state.cartData.order_service_charge_amount && (
                            <div className="calctrcart-row ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    Service Charge (
                                    {this.state.cartData.order_service_charge}%)
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    $
                                    {
                                      this.state.cartData
                                        .order_service_charge_amount
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.cartData.order_delivery_charge > 0 &&
                          this.state.cartData.order_delivery_charge && (
                            <div className="calctrcart-row ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    Delivery Charge
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    ${this.state.cartData.order_delivery_charge}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.cartData.order_additional_delivery > 0 &&
                          this.state.cartData.order_additional_delivery && (
                            <div className="calctrcart-row ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    Additional Delivery Charge
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    $
                                    {
                                      this.state.cartData
                                        .order_additional_delivery
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.cartData.order_tax_charge > 0 &&
                          this.state.cartData.order_tax_calculate_amount && (
                            <div className="calctrcart-row ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    GST ({this.state.cartData.order_tax_charge}
                                    %)
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    $
                                    {
                                      this.state.cartData
                                        .order_tax_calculate_amount
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.cartData
                          .order_discount_apply_grand_total !== '1' &&
                          this.state.cartData.order_promocode_name !== '' &&
                          this.state.cartData.order_discount_applied ===
                            'Yes' && (
                            <div className="calctrcart-row ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    DISCOUNT (
                                    {this.state.cartData.order_promocode_name})
                                  </p>
                                </div>
                                {this.state.cartData.order_discount_amount >
                                  0 && (
                                  <div className="col-4 text-right">
                                    <span>
                                      - $
                                      {
                                        this.state.cartData
                                          .order_discount_amount
                                      }
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                        <div className="calctrcart-row calctrcart-footer-totrow">
                          <div className="row">
                            <div className="col-8">
                              <p className="text-uppercase">Total</p>
                            </div>
                            <div className="col-4 text-right">
                              <span>
                                ${this.state.cartData.order_total_amount}
                              </span>
                            </div>
                          </div>
                        </div>
                        {this.state.cartData
                          .order_discount_apply_grand_total === '1' &&
                          this.state.cartData.order_discount_amount > 0 &&
                          this.state.cartData.order_discount_applied ===
                            'Yes' && (
                            <div className="calctrcart-row ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    DISCOUNT (
                                    {this.state.cartData.order_promocode_name})
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    - $
                                    {this.state.cartData.order_discount_amount}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="check-order-status-btn-block order-con-status">
                      <Link
                        to="/myorders"
                        className="btn btn-secondary btn-block">
                        Check My Order Status
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer allProps={this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cms }) => ({
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  outlets: cms.outlets
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getblocks, getOutlets }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orderconfirmation)
