/* eslint-disable */
 
import React, { Component } from "react";
import { history } from "../../store";
import cookie from "react-cookies";


 
	class Resetpassword extends Component {
  /* Account activation */
  componentDidMount() {
    var qs = require("qs");
    var resetId = this.props.match.params.resetId;

    /* for account activation - start */
    if (typeof resetId !== "undefined" && resetId !== "") {
        cookie.save("resetId", resetId,{ path: '/' });
        cookie.save("resetStatus", "Yes", { path: '/' });
	    history.push("/");
    } else {
      history.push("/");
    }
    /* for account activation - end  */
  }

  render() {
    return <div />;
  }
}
export default Resetpassword;
