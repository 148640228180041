import React, { Component } from 'react'
import $ from 'jquery'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { getStaticBlock } from '../../config/supporters'
import loadingImage from '../../assets/img/spinner.gif'
import { APP_ID, API_URL, MENU_PDF, MEMBER_PDF } from '../../config/constans'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      birthDate: new Date(),
      fields: {},
      errors: {}
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }
  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields, errors: {} })
  }
  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true
    var userPhoneNumber = fields['feedback_phonenumber']

    //feedback_name
    if (!fields['feedback_name']) {
      formIsValid = false
      errors['feedback_name'] = 'This field is required.'
    }

    if (typeof fields['feedback_name'] !== 'undefined') {
      if (!fields['feedback_name'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false
        errors['feedback_name'] = 'Name must contain only letters.'
      }
    }

    //Phone Number
    if (!userPhoneNumber) {
      formIsValid = false
      errors['feedback_phonenumber'] = 'This field is required.'
    }

    if (typeof userPhoneNumber !== 'undefined') {
      if (!userPhoneNumber.match(/^[0-9]+$/)) {
        formIsValid = false
        errors['feedback_phonenumber'] = 'Invalid Mobile number'
      } else if (userPhoneNumber.length < 8) {
        formIsValid = false
        errors['feedback_phonenumber'] =
          'Mobile number must be of minimum 8 characters length.'
      }
    }

    if (!fields['feedback_email']) {
      formIsValid = false
      errors['feedback_email'] = 'Email address field is required.'
    }

    if (typeof fields['feedback_email'] !== 'undefined') {
      let lastAtPos = fields['feedback_email'].lastIndexOf('@')
      let lastDotPos = fields['feedback_email'].lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['feedback_email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['feedback_email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors['feedback_email'] = 'Email address is not valid.'
      }
    }

    //feedback_name
    if (!fields['feedback_message']) {
      formIsValid = false
      errors['feedback_message'] = 'This field is required.'
    }
    this.setState({ errors: errors })
    return formIsValid
  }
  feedbackFormSubmit(e) {
    e.preventDefault()

    if (this.handleValidation()) {
      var qs = require('qs')
      var postObject = {
        app_id: APP_ID,
        customer_first_name: this.state.fields['feedback_name'],
        customer_phonenumber: this.state.fields['feedback_phonenumber'],
        customer_emailaddress: this.state.fields['feedback_email'],
        customer_message: this.state.fields['feedback_message']
      }
      // console.log(postObject); return false;
      $('#f_loading').show()
      axios
        .post(API_URL + 'api/contactus/feedback_us', qs.stringify(postObject))
        .then(response => {
          $('#f_loading').hide()
          if (response.data.status === 'ok') {
            this.setState({
              fields: {
                feedback_name: '',
                feedback_phonenumber: '',
                feedback_message: ''
              }
            })

            $('.feedback-form-success').show()
            $('.feedback-form-success .success-msg').html(
              'your feedback has been successfully submitted'
            )
          } else {
            if (typeof response.data.form_error !== 'undefined') {
              $('.feedback-form-error').show()
              $('.feedback-form-error .error-msg').html(
                response.data.form_error
              )
            } else {
              $('.feedback-form-error').show()
              $('.feedback-form-error .error-msg').html(response.data.message)
            }
          }
          return false
        })
        .catch(function(error) {})
    }
  }
  render() {
    return (
      <React.Fragment>
        <footer>
          <div className="footer-quicklinks-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <span className="nav-header">Send Us Your Feedback </span>
                  {/* getStaticBlock(this.props.allProps.staticblocks,"footer-about-text") */}
                  <div className="feedback-form">
                    <div
                      className="alert-success feedback-form-success"
                      style={{ display: 'none' }}>
                      <p className="success-msg" />
                    </div>

                    <div
                      className="alert-error feedback-form-error"
                      style={{ display: 'none' }}>
                      <p className="error-msg" />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="focus-out position-relative">
                            <input
                              type="input"
                              name="feedback_name"
                              id="feedback_name"
                              className="form-control input-focus"
                              placeholder="Your Name"
                              onChange={this.handleChange.bind(
                                this,
                                'feedback_name'
                              )}
                              value={this.state.fields['feedback_name'] || ''}
                            />
                            <span
                              className={
                                this.state.errors['feedback_name']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['feedback_name']}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="focus-out position-relative">
                            <input
                              type="input"
                              name="feedback_email"
                              id="feedback_email"
                              className="form-control input-focus"
                              placeholder="Your Email Address"
                              onChange={this.handleChange.bind(
                                this,
                                'feedback_email'
                              )}
                              value={this.state.fields['feedback_email'] || ''}
                            />
                            <span
                              className={
                                this.state.errors['feedback_email']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['feedback_email']}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="focus-out position-relative">
                            <input
                              type="tel"
                              maxLength={8}
                              name="feedback_phonenumber"
                              id="feedback_phonenumber"
                              className="form-control input-focus"
                              pattern="\d*"
                              placeholder="Your Phone Number"
                              onChange={this.handleChange.bind(
                                this,
                                'feedback_phonenumber'
                              )}
                              value={
                                this.state.fields['feedback_phonenumber'] || ''
                              }
                            />
                            <span
                              className={
                                this.state.errors['feedback_phonenumber']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['feedback_phonenumber']}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="focus-out position-relative">
                            <textarea
                              name="feedback_message"
                              id="feedback_message"
                              rows={3}
                              className="form-control input-focus"
                              pattern="\d*"
                              placeholder="Your Message"
                              onChange={this.handleChange.bind(
                                this,
                                'feedback_message'
                              )}
                              value={
                                this.state.fields['feedback_message'] || ''
                              }
                            />
                            <span
                              className={
                                this.state.errors['feedback_message']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['feedback_message']}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="feedbackform_submit">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={this.feedbackFormSubmit.bind(this)}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-5">
                  {getStaticBlock(
                    this.props.allProps.staticblocks,
                    'footer-operation-hours-text'
                  )}
                </div>

                <div className="col-lg-3 col-md-6 col-sm-7">
                  {getStaticBlock(
                    this.props.allProps.staticblocks,
                    'footer-contact-us-text'
                  )}
                </div>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 p-0">
                    <nav className="navbar navbar-expand-lg navbar-dark navbar-footer">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarText"
                        aria-controls="navbarText"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                      </button>
                      <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav m-auto footer-nav">
                          <li className="nav-item">
                            <Link className="nav-link" to="/pages/about-us">
                              {' '}
                              About Us
                            </Link>
                          </li>
                          <li className="nav-item ecoupons-nav-item">
                            <a
                              className="nav-link"
                              target="_blank"
                              href="https://springcourt.mezzofy.com/coupons/">
                              {' '}
                              eCoupons
                            </a>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="/pages/media-gallery">
                              {' '}
                              Media Gallery
                            </Link>
                          </li>

                          <li className="nav-item">
                            <a className="nav-link" href="https://inline.app/booking/-MklD4VkXHlVkrAKwYQX:inline-live-2/-MklD4cwSe_cVCaKiEGc" target="_blank">
                              Reservation{' '}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              target="_blank"
                              className="nav-link"
                              href={MEMBER_PDF}>
                              {' '}
                              Membership{' '}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="f_loading"
            style={{
              display: 'none',
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              margin: 'auto',
              zIndex: 9999,
              background: 'rgba(255, 255, 255, 0.8)'
            }}>
            <img
              src={loadingImage}
              alt="Loading"
              style={{
                bottom: 0,
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                margin: 'auto'
              }}
            />
          </div>
          {getStaticBlock(
            this.props.allProps.staticblocks,
            'footer-social-block'
          )}
          {getStaticBlock(
            this.props.allProps.staticblocks,
            'footer-copy-block'
          )}
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer
