import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getblocks, getOutlets } from '../../stores/cms'
import { history } from '../../store'
import Header from '../layout/header'
import Footer from '../layout/footer'
import Promotions from '../myaccount/promotions'
import Accountdetails from '../myaccount/accountdetails'
import Myorders from '../myaccount/myorders'
import Myrewards from '../myaccount/myrewards'
import cookie from 'react-cookies'
import { type } from 'jquery'
class Myaccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
      currentPage: this.props.location.pathname
    }
    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount() {
    if (
      cookie.load('UserId') === '' ||
      typeof cookie.load('UserId') === undefined ||
      typeof cookie.load('UserId') === 'undefined'
    ) {
      history.push('/')
    }
    this.props.getblocks()
    this.props.getOutlets()
  }
  handleChange(date) {
    this.setState({
      startDate: date
    })
  }
  render() {
    return (
      <React.Fragment>
        <Header allProps={this.props} />
        <div className="main-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-header-block bg-transparent">
                  <h1 className="font-gothamlight  text-center text-uppercase">
                    {' '}
                    My Account
                  </h1>
                  <h2 className="font-azakie text-center">
                    You can update your account details and check order status
                    here
                  </h2>
                  <nav
                    aria-label="breadcrumb"
                    className="d-flex justify-content-center">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page">
                        My Account
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-12">
                <div className="myaccount-dashboard-block">
                  <div className="myaccount-order-item-list">
                    <div className="myaccount-navbar">
                      <ul
                        className="nav nav-tabs nav-justified"
                        id="myTab"
                        role="tablist">
                        <li className="nav-item">
                          <a
                            className={
                              this.state.currentPage === '/myaccount'
                                ? 'nav-link active'
                                : 'nav-link'
                            }
                            id="home-tab"
                            data-toggle="tab"
                            href="#acccountdetails"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true">
                            Account Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.currentPage === '/myorders'
                                ? 'nav-link active'
                                : 'nav-link'
                            }
                            id="profile-tab"
                            data-toggle="tab"
                            href="#orders"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false">
                            Orders
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="contact-tab"
                            data-toggle="tab"
                            href="#rewards"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false">
                            Cashback
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.currentPage === '/promotions'
                                ? 'nav-link active'
                                : 'nav-link'
                            }
                            id="contact-tab"
                            data-toggle="tab"
                            href="#promotions"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false">
                            Promotions
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={
                          this.state.currentPage === '/myaccount'
                            ? 'tab-pane fade show active'
                            : 'tab-pane fade'
                        }
                        id="acccountdetails"
                        role="tabpanel"
                        aria-labelledby="acccountdetails">
                        <Accountdetails />
                      </div>
                      <div
                        className={
                          this.state.currentPage === '/myorders'
                            ? 'tab-pane fade show active'
                            : 'tab-pane fade'
                        }
                        id="orders"
                        role="tabpanel"
                        aria-labelledby="profile-tab">
                        <Myorders />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="rewards"
                        role="tabpanel"
                        aria-labelledby="contact-tab">
                        <Myrewards />
                      </div>
                      <div
                        className={
                          this.state.currentPage === '/promotions'
                            ? 'tab-pane fade show active'
                            : 'tab-pane fade'
                        }
                        id="promotions"
                        role="tabpanel"
                        aria-labelledby="contact-tab">
                        <Promotions />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer allProps={this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cms }) => ({
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  outlets: cms.outlets
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getblocks, getOutlets }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Myaccount)
