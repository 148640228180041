import React from 'react'
import * as Sentry from '@sentry/browser'
import { Route } from 'react-router-dom'
import Home from '../home'
import Menu from '../menu'
import Delivery from '../delivery'
import Checkout from '../checkout'
import Orderconfirmation from '../order-confirmation'
import paymentRequest from '../payment-request'
import Myaccount from '../myaccount'
import Activation from '../account/Activation'
import Resetpassword from '../account/Resetpassword'
import Pages from '../pages'

Sentry.init({
  dsn: 'https://89b819c20c1e4c9fb68d1b56e6d33ef1@sentry.io/1540698'
})

const App = () => (
  <div id="outer-container">
    <Route exact path="/" component={Home} />
    <Route exact path="/sign-in" component={Home} />
    <Route exact path="/online-order" component={Home} />
    <Route exact path="/menu/:proCateSlug/:proslug" component={Menu} />
    <Route exact path="/menu/:proCateSlug" component={Menu} />
    <Route exact path="/menu" component={Menu} />
    <Route exact path="/delivery/:proCateSlug/:proslug" component={Delivery} />
    <Route exact path="/delivery/:proCateSlug" component={Delivery} />
    <Route exact path="/delivery" component={Delivery} />
    <Route path="/checkout" component={Checkout} />
    <Route path="/orderconfirmation/:orderId" component={Orderconfirmation} />
    <Route path="/myaccount" component={Myaccount} />
    <Route path="/myorders/" component={Myaccount} />
    <Route path="/promotions/" component={Myaccount} />
    <Route path="/pages/:pageSlug" component={Pages} />
    <Route path="/account/activation/:activationId" component={Activation} />
    <Route path="/account/resetpassword/:resetId" component={Resetpassword} />
    <Route
      path="/payment-request/:orderId/:paymentId"
      component={paymentRequest}
    />
  </div>
)

export default App
