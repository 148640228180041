import cookie from 'react-cookies'
import { ADMIN_ID, HOST_URL, APP_ID } from '../config/constans'
var strp = require('strp')
var _ = require('lodash')
var parse = require('html-react-parser')
const $ = window.$
export function checkAuth() {
  if (
    typeof cookie.load(APP_ID) !== 'undefined' &&
    cookie.load(APP_ID) !== null &&
    (typeof cookie.load(HOST_URL) !== 'undefined' &&
      cookie.load(HOST_URL) !== null) &&
    (typeof cookie.load(ADMIN_ID) !== 'undefined' &&
      cookie.load(ADMIN_ID) !== null)
  ) {
    return true
  } else {
    return false
  }
}

export function getStaticBlock(object, value) {
  if (_.isEmpty(object) === false && value !== '') {
    var res = _.filter(object, ['staticblocks_slug', value])
    if (_.isEmpty(res) === false) {
      if (
        typeof res[0].staticblocks_description !== 'undefined' &&
        res[0].staticblocks_description !== ''
      ) {
        return parse(res[0].staticblocks_description)
      }
    }
  }
}

export function returnValue(dataval) {
  return typeof dataval !== 'undefined' && dataval !== null ? dataval : ''
}

export const addressFormat = function(
  unitOne,
  unitTwo,
  addressOne,
  addressTwo,
  postCode
) {
  var unit =
    unitTwo !== '' && unitOne !== ''
      ? '#' + unitOne + ' - ' + unitTwo + ','
      : unitOne !== ''
      ? '#' + unitOne + ', '
      : ''
      
 var postCode_new = "";

  if(postCode !=="" && postCode !==undefined ){

    postCode_new = postCode.length >= 6 ? postCode : "0"+postCode;
  }

  unit = addressOne !== '' ? unit + addressOne + ',' : unit
  unit = addressTwo !== '' ? unit + addressTwo + ',' : unit
  unit = postCode_new !== '' ? unit + ' Singapore ' + postCode_new : unit
  return unit !== '' ? unit.replace(/,\s*$/, '') : 'N/A'
}

export function stripslashes(value) {
  return typeof value !== 'undefined' && value !== null
    ? strp.stripslashes(value)
    : ''
}

export const getAliasName = function(alias, productName) {
  return alias !== '' ? alias : productName
}

export const randomId = function() {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

export const getReferenceID = function() {
  if (typeof cookie.load('referenceId') === 'undefined') {
    var randomKey = randomId()
    cookie.save('referenceId', randomKey, {})
    return randomKey
  } else {
    return cookie.load('referenceId')
  }
}

export const customerId = function() {
  if (
    typeof cookie.load('UserId') !== 'undefined' &&
    cookie.load('UserId') !== ''
  ) {
    return cookie.load('UserId')
  } else {
    return ''
  }
}

export const getCookies = function(cookieName) {
  if (
    typeof cookie.load(cookieName) !== 'undefined' &&
    cookie.load(cookieName) !== ''
  ) {
    return cookie.load(cookieName)
  } else {
    return ''
  }
}

export const deleteOrderCookie = function(deleteOrderCookie) {
  cookie.remove('orderAddressOne')
  cookie.remove('orderAddressTwo')
  cookie.remove('orderOutletId')
  cookie.remove('orderOutletName')
  cookie.remove('orderPostalCode')
  cookie.remove('orderUnitOne')
  cookie.remove('orderUnitTwo')
  cookie.remove('orderZoneID')
  cookie.remove('orderDeliveryDate')
  cookie.remove('orderDeliveryTime')
  cookie.remove('orderAvailability')
  //cookie.remove("customerId");
  cookie.remove('orderCustomerfName')
  cookie.remove('orderCustomerlName')
  cookie.remove('orderCustomerEmail')
  cookie.remove('orderCustomerMobile')
  cookie.remove('orderDeliveryTimeRequest')
  cookie.remove('productLeadTime')
  cookie.remove('minDay')
  cookie.remove('checkoutMinDay')
  cookie.remove('orderDateTime')
  cookie.remove('deliveryTime')
  cookie.remove('pickupTAT')
}

export const getGstValue = function(enabled, gst, subtotal, format) {
  if (parseInt(enabled) === 1) {
    gst = parseFloat(gst)
    subtotal = parseFloat(subtotal)
    var gstResult = 0

    if (gst > 0) {
      gstResult = (gst / 100) * subtotal
    }

    if (format === 'format') {
      return gstResult.toFixed(2)
    } else {
      return gstResult
    }
  } else {
    return 0
  }
}

export const showInfo = function(alrtmessage, alertheaders) {
  $('.alrt_overlay').remove()
  $('.custom_alert').remove()

  var alertheader =
    typeof alertheaders == 'undefined' ? 'Information' : alertheaders

  $('body').append(
    '<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
      alertheader +
      '</div><div class="alert_body"> ' +
      alrtmessage +
      ' <div class="alt_btns"><a href="javascript:;" class="btn btn-info reject" id="alt0">Close</a></div></div></div></div></div>'
  )

  var actheight = $('.alert_height').height()
  $('.custom_alert').height(actheight + 10)

  $('.alt_btns a').click(function(e) {
    $('.alrt_overlay, .custom_alert').remove()
  })
}

export const showInfoNew = function(alrtmessage, alertheaders) {
  $('.alrt_overlay').remove()
  $('.custom_alert').remove()

  var alertheader =
    typeof alertheaders == 'undefined' ? 'Information' : alertheaders

  $('body').append(
    '<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
      alertheader +
      '</div><div class="alert_body"> ' +
      alrtmessage +
      ' <div class="alt_btns" style="display:none;"><a href="javascript:;" class="btn btn-info reject promo_pop_close" id="alt0">Close</a></div></div></div></div></div>'
  )

  var actheight = $('.alert_height').height()
  $('.custom_alert').height(actheight + 10)

  $('.alt_btns a').click(function(e) {
    $('.alrt_overlay, .custom_alert').remove()
  })
}

export const confirmAlert = function(alrtmessage, alertheaders) {
  $('.alrt_overlay').remove()
  $('.custom_alert').remove()

  var alertheader =
    typeof alertheaders == 'undefined' ? 'Confirmation' : alertheaders

  $('body').append(
    '<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
      alertheader +
      '</div><div class="alert_body"> ' +
      alrtmessage +
      ' <div class="alt_btns"><a href="javascript:;" class="btn btn-primary confirm" id="alt1">OK</a><a href="javascript:;" class="btn btn-info reject" id="alt0">Cancel</a></div></div></div></div></div>'
  )

  var actheight = $('.alert_height').height()
  $('.custom_alert').height(actheight + 10)

  $('.alt_btns a').click(function(e) {
    $('.alrt_overlay, .custom_alert').remove()
  })
}

export const getDineInInitialAmount = function(amount, per, format) {
  const percentage = per
  const total = parseFloat(amount)
  let result = 0

  if (percentage > 0) {
    result = (percentage / 100) * total
  }

  if (format === 'format') {
    return result.toFixed(2)
  } else {
    return result
  }
}

export const roundoffTotal = function(amount) {
  if (amount > 0) {
    var str = amount.toString()
    var numarray = str.split('.')
    if (typeof numarray[1] !== 'undefined' && numarray[1] > 0) {
      var roundVal = Math.ceil(numarray[1] / 10) * 10
      if (roundVal === 100) {
        return parseFloat(parseInt(numarray[0]) + parseInt(1)).toFixed(2)
      } else {
        return parseFloat(numarray[0] + '.' + roundVal).toFixed(2)
      }
    }

    return amount
  }
}

export const roundednearest = function(value) {
  var finalvalue = ''
  if (value !== '' && value > 0 && typeof value !== undefined) {
    var decimelvalue = '00'
    var value = value.toString()

    var splitvalue = value.split('.')
    if (splitvalue[1] != '') {
      var first = splitvalue[1].charAt(0)
      var second = splitvalue[1].charAt(1)
      if (second > 0) {
        var totalDecimal = parseInt(first) + 1
      } else {
        var totalDecimal = parseInt(first)
      }

      if (totalDecimal == 10) {
        decimelvalue = '00'
      } else {
        decimelvalue = totalDecimal + '0'
      }
      if (decimelvalue == '00') {
        finalvalue = parseInt(splitvalue['0']) + 1 + '.00'
      } else {
        finalvalue = splitvalue['0'] + '.' + decimelvalue
      }
    }
  }
  return finalvalue
}
