import axios from "axios";
import { API_URL, APP_ID } from "../config/constans";
import { customerId,getReferenceID} from "../config/supporters";
import cookie from "react-cookies";
export const GET_CARTITEMS = "settings/GET_CARTITEMS";

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CARTITEMS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      };

    default:
      return state;
  }
}
export function getCartItems(pageslug = null) {
	
  let FI_API_URL = API_URL + "apiv2/cart/contents";
  var reference_id = "";
  let customer_Id =customerId();
  
  if(typeof customer_Id === "undefined" || customer_Id === "") {
	  reference_id =getReferenceID();
  }

  return function(dispatch) {
    axios
      .get(
        `${FI_API_URL}/?app_id=` +
          APP_ID +
          "&reference_id=" +
          reference_id +
          "&customer_id=" +
          customer_Id
      )
      .then(response => {
		 
	 	if( typeof response.data.result_set !== "undefined"  && typeof response.data.result_set.product_lead_time !== "undefined" && response.data.result_set.product_lead_time !== "" && response.data.result_set.product_lead_time > 0) {
	     const time =( (response.data.result_set.product_lead_time / 24) / 60);
 
		   cookie.save("productLeadTime", time);
		}else {
           cookie.save("productLeadTime",0);
		}	 
 
        dispatch({
          type: GET_CARTITEMS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        });
		
	
		
      })
      .catch(error => {
        console.log(error);
      });
  };
}
