/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCartItems } from '../../stores/cart'
import { getSetings } from '../../stores/settings'
import { getUserData } from '../../stores/user'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import DatePicker from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import { slide as Menu } from 'react-burger-menu'
import { history } from '../../store'
import logo from '../../assets/img/springcourt-logo.svg'
import noImage from '../../assets/img/product-no-image.jpg'
import ico_mouse from '../../assets/img/mouse.png'
import ico_delivery_lg from '../../assets/img/ico-delivery-lg.png'
import ico_like from '../../assets/img/ico_like.png'
import { showLoader, hideLoader, numariconly } from '../../config/SettingHelper'
import {
  getStaticBlock,
  addressFormat,
  getCookies,
  returnValue,
  getGstValue,
  stripslashes,
  customerId,
  getReferenceID,
  deleteOrderCookie,
  showInfo,
  getAliasName,
  roundoffTotal,
  roundednearest
} from '../../config/supporters'
import {
  DINEIN_ID,
  PICKUP_ID,
  DELIVERY_ID,
  API_URL,
  APP_ID,
  dineInText,
  pickUpText,
  deliveryText,
  MENU_PDF,
  ALACARTE_PDF,
  LUNCH_MENU_PDF,
  BEVERAGES_PDF,
  MEMBER_PDF,
  MEMBER_LIST,
  WEDDING_PDF,
  SETMENU_PDF,
  SERVICE_CHARGE,
  OUTLET_ID
} from '../../config/constans'
import { addDays, setHours, setMinutes } from 'date-fns'
import Account from '../account/'
import Changepassword from '../myaccount/changepassword'
import cookie from 'react-cookies'
import moment from 'moment'
import axios from 'axios'
import OrderdatetimeSlot from './OrderdatetimeSlot'
var _ = require('lodash')
var qs = require('qs')
var parse = require('html-react-parser')
class Header extends Component {
  constructor(props) {
    super(props)

    /*  To change the date after 2 PM */
    var CurrentDate = this.calcTime('Singapore', '+8')
    var currentHrs = CurrentDate.getHours()
    var currentMins = CurrentDate.getMinutes()

    this.state = {
      iscartHovered: false,
      isMobileMenu: true,
      orderType: '',
      alreadyExist: 1,
      startDate:
        currentHrs >= 14 ? addDays(new Date(), 3) : addDays(new Date(), 2),
      startTime: setHours(setMinutes(new Date(), 0), 11),
      postal_code_information:
        getCookies('postal_code_information') !== '' &&
        typeof getCookies('postal_code_information') !== undefined
          ? getCookies('postal_code_information')
          : '',
      outletZoneInfo:
        getCookies('outletZoneInfo') !== '' &&
        typeof getCookies('outletZoneInfo') !== undefined
          ? getCookies('outletZoneInfo')
          : '',
      error_postalcode: '',
      postalcode: '',
      postalcodeFianl: false,
      getDateTimeFlg: '',
      topMessage: '',
      selectedAvilablity:
        cookie.load('orderAvailability') !== '' &&
        typeof cookie.load('orderAvailability') != undefined &&
        typeof cookie.load('orderAvailability') !== 'undefined'
          ? cookie.load('orderAvailability')
          : '',
      menuList: '',
      timeErroTxt: '',
      cartItem: []
    }
    this.handleCartHover = this.handleCartHover.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeTime = this.handleChangeTime.bind(this)
    this.setDineInData = this.setDineInData.bind(this)
    this.setDeliveryPostal = this.setDeliveryPostal.bind(this)
    this.setDeliveryDate = this.setDeliveryDate.bind(this)
    this.changeDateValue = this.changeDateValue.bind(this)
    this.handleProductSelectChange = this.handleProductSelectChange.bind(this)
    this.handleNotifySettingsChange = this.handleNotifySettingsChange.bind(this)
    this.UpdateDelveiryDateModal = this.UpdateDelveiryDateModal.bind(this)
    this.UpdateDelveiryBackModal = this.UpdateDelveiryBackModal.bind(this)
    this.loadMenuList()
    this.loadOutletInfo()
    this.getStaticBlock('top-info-banner')
    if (currentHrs >= 14) {
      cookie.save('minDay', 3, {})
    } else {
      cookie.save('minDay', 2, {})
    }
  }

  loadOutletInfo() {
    axios
      .get(
        API_URL +
          'api/outlets/pickup_outlets/?app_id=' +
          APP_ID +
          '&outlet_id=' +
          OUTLET_ID
      )
      .then(response => {
        if (response.data.status === 'ok') {
          var pickTAT = response.data.result_set[0].outlet_pickup_tat
          this.setState({ pickupTAT: pickTAT })
          cookie.save('pickupTAT', pickTAT)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  loadMenuList() {
    axios
      .get(
        API_URL +
          'api/menu/menuv1/?app_id=' +
          APP_ID +
          '&menu_slug=spring-header-menu'
      )
      .then(response => {
        if (response.data.status === 'ok') {
          this.displayMenu(response.data.result_set)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  displayMenu(menu) {
    var menu_List = ''
    if (menu !== '' && menu.length > 0) {
      menu_List = menu.map(function(item, index) {
        var linkPath = ''

        var slug = item.nav_pages
        if (item.nav_type === '0') {
          linkPath = '/pages'
          slug = '/' + item.nav_title_slug
        }
        if (item.nav_pages.indexOf('http') >= 0) {
          linkPath = ''
        }
        if (item.sub_menu.length > 0) {
          return (
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id={item.nav_id + 'navbarDropdownMenuLink'}
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {item.nav_title}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby={item.nav_id + 'navbarDropdownMenuLink'}>
                {item.sub_menu.map(function(item1, index1) {
                  var linksubPath = ''
                  if (item.nav_type === '0') {
                    linksubPath = '/pages'
                  }

                  return (
                    <a
                      className="dropdown-item"
                      href={linksubPath + item1.nav_pages}
                      target={'_' + item1.nav_link_type}
                      key={index1}>
                      {stripslashes(item1.nav_title)}
                    </a>
                  )
                })}
              </div>
            </li>
          )
        } else {
          return (
            <li className="nav-item" key={index}>
              <a
                className="nav-link"
                href={linkPath + slug}
                target={'_' + item.nav_link_type}>
                {stripslashes(item.nav_title)}
              </a>
            </li>
          )
        }
      })
    }
    this.setState({ menuList: menu_List })
  }

  handleChangeDate(datevalue) {
    this.setState({ startDate: datevalue })
  }

  changeDateValue(datevalue) {
    const orderdate = this.state.startDate
    const orderTime = this.state.startTime

    if (orderdate !== '' && orderTime !== null) {
      var dateval = new Date(orderdate)
      var timeval = orderTime
      dateval = moment(dateval).format('DD/MM/YYYY')

      var timeval1 = moment(timeval).format('HH:mm')
      var timeval2 = moment(timeval).format('hh:mm A')

      cookie.save('orderDeliveryDate', dateval)
      cookie.save('orderDeliveryTime', timeval1)
      cookie.save('orderDeliveryTimeShow', timeval2)

      $('#changeDateModal').modal('hide')
      // console.log(this.props.allProps.location.pathname);
    }
  }

  handleChangeTime(timevalue) {
    var timeval = new Date(timevalue)
    this.setState({
      startTime: timevalue
    })
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target
    this.setState({ [name]: value, ['error_' + name]: '' })
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date()
    var utc = d.getTime() + d.getTimezoneOffset() * 60000
    var nd = new Date(utc + 3600000 * offset)
    return nd
  }

  setdateTimeFlg = (field, value) => {
    /* if (
      this.state.orderType === deliveryText ||
      getCookies('orderAvailability') === DELIVERY_ID
    ) { */
    if (field == 'tmflg') {
      this.setState({ getDateTimeFlg: value })
    } else if (field == 'ordDate') {
      var ordTime = ''
      $('.ordrdatetime_error').html('')
      var startTime = new Date(this.state.startTime)
      var startDate = new Date(value)
      var orderHrs = startTime.getHours()
      var orderMin = startTime.getMinutes()
      var OrderTime = startDate.setHours(orderHrs)
      OrderTime = startDate.setMinutes(orderMin)
      /* .setMinutes(orderMin) */
      //cookie.save('orderDateTime', new Date(OrderTime))

      var ChangeTime = new Date(OrderTime)

      if (ChangeTime.toString() !== 'Invalid Date') {
        cookie.save('orderDateTime', ChangeTime)
        var dateval = moment(value).format('DD/MM/YYYY')
        cookie.save('orderDeliveryDate', dateval)
      }

      this.setState({ startDate: value, startTime: ordTime, timeErroTxt: '' })
    } else if (field == 'ordTime') {
      $('.ordrdatetime_error').html('')
      var startTime = new Date(value)
      var startDate = new Date(this.state.startDate)
      var orderHrs = startTime.getHours()
      var orderMin = startTime.getMinutes()
      var OrderTime = startDate.setHours(orderHrs)
      OrderTime = startDate.setMinutes(orderMin)

      //cookie.save('deliveryTime', new Date(value))
      var ChangeTime = new Date(OrderTime)
      if (ChangeTime.toString() !== 'Invalid Date') {
        cookie.save('deliveryTime', ChangeTime.toString())
        cookie.save('orderDeliveryTime', orderHrs + ':' + orderMin)

        //cookie.save('orderDeliveryTime', new Date(value))
      }

      this.setState({ startTime: value, timeErroTxt: '' })
    } else if (field == 'triggerErrorPopup') {
    }
    /* } */
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.cartAction !== this.props.cartAction &&
      nextProps.cartAction === true
    ) {
      this.props.getCartItems()
    }
    if (nextProps.cartData !== this.state.cartItem) {
      this.setState({ cartItem: nextProps.cartData }, function() {
        if (typeof this.props.sateValChange == 'function') {
          var cartDatas = []
          if (
            nextProps.cartData !== '' &&
            typeof nextProps.cartData !== undefined &&
            typeof nextProps.cartData !== 'undefined'
          ) {
            cartDatas = nextProps.cartData.cart_items
          }
          this.props.sateValChange('cartItem', cartDatas)
        }
      })
    }
  }
  getStaticBlock(slug) {
    return axios
      .get(API_URL + 'api/cms/staticblocks?app_id=' + APP_ID + '&slug=' + slug)
      .then(response => {
        if (response.data.status === 'ok') {
          var resultData = response.data.result_set
          this.setState({ topMessage: resultData[0].staticblocks_description })
        } else {
          //this.setState({ error_postalcode: response.data.message })
        }
      })
  }

  setDeliveryPostal() {
    if (this.state.postalcode !== '') {
      if (this.state.postalcode.length === 6) {
        this.setState({ timeErroTxt: '' })
        this.setState({ error_postalcode: '' })
        showLoader('delivery_submit_cls', 'class')
        /* find_outlet */
        return axios
          .get(
            API_URL +
              'apiv2/outlets/findOutletZone?app_id=' +
              APP_ID +
              '&skip_timing=Yes&availability_id=' +
              DELIVERY_ID +
              '&postal_code=' +
              this.state.postalcode +
              '&outlet_id=' +
              OUTLET_ID
          )
          .then(response => {
            hideLoader('delivery_submit_cls', 'class')
            if (response.data.status === 'ok') {
              var resultData = response.data.result_set
              cookie.save('orderPostalCode', this.state.postalcode)

              console.log(
                resultData.postal_code_information,
                'postal_code_information'
              )

              cookie.save(
                'postal_code_information',
                resultData.postal_code_information
              )
              cookie.save('outletZoneInfo', resultData)
              this.setState({
                outletZoneInfo: resultData,
                postal_code_information: resultData.postal_code_information,
                postalcodeFianl: true,
                getDateTimeFlg: 'yes'
              })
              $('#delviryPostalCodeModalCenter').modal('hide')
              $('#delviryDateTimeModalPopup').modal('show')
            } else {
              this.setState({ error_postalcode: response.data.message })
            }
          })
      } else {
        this.setState({ error_postalcode: 'Please enter valid postal code.' })
      }
    } else {
      this.setState({ error_postalcode: 'Please enter postal code.' })
    }
  }

  setPickupPostal() {
    this.setState({
      postalcodeFianl: true,
      getDateTimeFlg: 'yes'
    })
  }
  UpdateDelveiryDateModal() {
    this.setState(
      {
        getDateTimeFlg: 'yes'
      },
      function() {
        $('#cartDateUpdate').val('1')
        $('#delviryDateTimeModalPopup').modal('show')
      }
    )
  }

  UpdateDelveiryBackModal() {
    $('#delviryPostalCodeModalCenter').modal('hide')
    $('#SelectAvailabilityModalCenter').modal('show')
  }

  setDineInData(outlets) {
    const orderdate = this.state.startDate
    const orderTime = this.state.startTime
    const orderType = this.state.orderType
    if (orderType === '' && orderType) {
      showInfo('please choose order type')
    }

    if (
      orderdate !== '' &&
      orderTime !== null &&
      _.isEmpty(outlets) === false
    ) {
      /* date and time values */
      var dateval = new Date(orderdate)
      var timeval = orderTime
      dateval = moment(dateval).format('DD/MM/YYYY')

      var timeval1 = moment(timeval).format('HH:mm')
      var timeval2 = moment(timeval).format('hh:mm A')

      cookie.save('orderDeliveryDate', dateval)
      cookie.save('orderDeliveryTime', timeval1)
      cookie.save('orderDeliveryTimeShow', timeval2)

      /* dine in data */
      if (orderType == 'Delivery') {
        cookie.save('orderAvailability', DELIVERY_ID)
      } else {
        cookie.save(
          'orderAvailability',
          orderType === dineInText ? DINEIN_ID : PICKUP_ID
        )
      }

      cookie.save('orderOutletId', outlets[0].outlet_id)
      cookie.save('orderOutletName', outlets[0].outlet_name)
      cookie.save('orderPostalCode', outlets[0].outlet_postal_code)
      cookie.save('orderUnitOne', returnValue(outlets[0].outlet_unit_number1))
      cookie.save('orderUnitTwo', returnValue(outlets[0].outlet_unit_number2))
      cookie.save(
        'orderAddressOne',
        returnValue(outlets[0].outlet_address_line1)
      )
      cookie.save(
        'orderAddressTwo',
        returnValue(outlets[0].outlet_address_line2)
      )

      $('#delviryDateTimeModalCenter').modal('hide')
      if (orderType == 'Delivery') {
        if (this.props.allProps.location.pathname === '/delivery') {
          window.location.reload()
        } else {
          history.push('/delivery')
        }
      } else {
        if (this.props.allProps.location.pathname === '/menu') {
          window.location.reload()
        } else {
          history.push('/menu')
        }
      }
    } else {
      showInfo('Please choose order date and time')
      return false
    }
  }

  setDeliveryDate(outlets) {
    const orderdate = this.state.startDate
    const orderTime = this.state.startTime
    const orderType = this.state.orderType
    if (orderType === '' && orderType) {
      showInfo('please choose order type')
    }

    if (
      orderdate !== '' &&
      orderTime !== null &&
      _.isEmpty(outlets) === false
    ) {
      /* date and time values */
      var dateval = new Date(orderdate)
      var timeval = orderTime
      dateval = moment(dateval).format('DD/MM/YYYY')

      var timeval1 = moment(timeval).format('HH:mm')
      var timeval2 = moment(timeval).format('hh:mm A')
      var hourSplit = timeval1.split(':')
      if (dateval === '11/02/2021' && parseInt(hourSplit[0]) >= 14) {
        if (parseInt(hourSplit[0]) === 14) {
          if (parseInt(hourSplit[1]) > 30) {
            this.setState({
              timeErroTxt:
                'LAST ORDER FOR DELIVERY & PICKUP BY 2:30PM ON 11 FEB 2021. CLOSED ON 12 FEB 2021.'
            })
            return false
          } else {
            this.setState({ timeErroTxt: '' })
          }
        } else {
          this.setState({
            timeErroTxt:
              'LAST ORDER FOR DELIVERY & PICKUP BY 2:30PM ON 11 FEB 2021. CLOSED ON 12 FEB 2021.'
          })
          return false
        }
      } else {
        this.setState({ timeErroTxt: '' })
      }
      cookie.save('orderDeliveryDate', dateval)
      cookie.save('orderDeliveryTime', timeval1)
      cookie.save('orderDeliveryTimeShow', timeval2)

      //cookie.save('orderAvailability', DELIVERY_ID)

      cookie.save('orderOutletId', outlets[0].outlet_id)
      cookie.save('orderOutletName', outlets[0].outlet_name)
      cookie.save('orderPostalCode', outlets[0].outlet_postal_code)
      cookie.save('orderUnitOne', returnValue(outlets[0].outlet_unit_number1))
      cookie.save('orderUnitTwo', returnValue(outlets[0].outlet_unit_number2))
      cookie.save(
        'orderAddressOne',
        returnValue(outlets[0].outlet_address_line1)
      )
      cookie.save(
        'orderAddressTwo',
        returnValue(outlets[0].outlet_address_line2)
      )

      $('#delviryDateTimeModalPopup').modal('hide')
      /* if (this.props.allProps.location.pathname === '/delivery') {
        window.location.reload()
      } else { */
      if ($('#cartDateUpdate').val() === '1') {
        $('#cartDateUpdate').val('')
        window.location.reload()
      } else {
        if (cookie.load('orderAvailability') == DELIVERY_ID) {
          history.push('/delivery')
        } else {
          history.push('/menu')
        }
      }
      /* } */
    } else {
      showInfo('Please choose order date and time')
      return false
    }
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()
    var headerNav = setInterval(function() {
      if ($('.mobile-category-dropdown .dropdown-item').length > 0) {
        let headerHeight = $('header').height()
        let mcategoryHeight
        if ($('.mobile-category-dropdown').length) {
          mcategoryHeight = $('.mobile-category-dropdown').height()
        } else {
          mcategoryHeight = 0
        }
        $('.main-wrapper').css(
          'padding-top',
          headerHeight + mcategoryHeight + 'px'
        )
        $('.mobile-category-dropdown').css('top', $('header').height() + 'px')
        clearInterval(headerNav)
      }
    }, 1000)

    setTimeout(function() {
      let headerHeight = $('header').height()
      let mcategoryHeight
      if ($('.mobile-category-dropdown').length) {
        mcategoryHeight = $('.mobile-category-dropdown').height()
      } else {
        mcategoryHeight = 0
      }
      $('.main-wrapper').css(
        'padding-top',
        headerHeight + mcategoryHeight + 'px'
      )
      $('.mobile-category-dropdown').css('top', $('header').height() + 'px')
    }, 3000)

    $('#mobilenav-toggle').click(function() {
      $(this).after('<div class="mbnav-overlay"></div>')
      $('#navbarResponsive').addClass('mobile-nav-open')
      $(this).addClass('active')
      $('#outer-container')
        .addClass('mbnav-open')
        .css('height', '100%')
    })
    $(document).on('click', '#navbarResponsive, .mbnav-overlay', function() {
      $('#mobilenav-toggle').removeClass('active')
      $('#navbarResponsive').removeClass('mobile-nav-open')
      $('.mbnav-overlay').remove()
    })

    if ($('.search-toggle').length > 0) {
      $('.search-toggle').click(function(e) {
        $(this).addClass('active')
        $('.searchbox').toggleClass('open')
        $('.searchbox input').focus()
      })
      $(document).click(function(e) {
        if (
          !$(e.target).is(
            ".search-toggle, .searchbox, .searchbox * ,  div[class^='searchfilter-Selector']"
          )
        ) {
          if ($('.searchbox').hasClass('open')) {
            $('.searchbox').removeClass('open')
            $('.search-toggle').removeClass('active')
            $('.searchbox input').blur()
          }
        }
      })
    }
    /* Mobile account */

    if ($('.mobile_mainacc_menutrigger').length > 0) {
      $('.mobile_mainacc_menutrigger').click(function(e) {
        e.preventDefault()
        if ($('.mobile_mainacc_menulist').is(':visible')) {
          $('.mobile_mainacc_menulist').hide()
        } else {
          $('.mobile_mainacc_menulist').show()
        }
      })
      $(document).click(function(e) {
        if (!$(e.target).is('.mobile_account_item, .mobile_account_item * ')) {
          if ($('.mobile_mainacc_menulist').is(':visible')) {
            $('.mobile_mainacc_menulist').hide()
          }
        }
      })
      /* Hide cart out side click */
      $(document).click(function(e) {
        if (!$(e.target).is('.header-cart-dropdown, .header-cart-dropdown *')) {
          if ($('.header-cart-dropdown').hasClass('show-cart')) {
            $('.header-cart-dropdown').removeClass('show-cart')
            $('.header-cart-dropdown').addClass('hide-cart')
          }
        }
      })
    }
    /*NEW MENU SECTION*/
    if ($('.mobile_account_menu_click').length > 0) {
      $('.mobile_account_menu_click').click(function(e) {
        e.preventDefault()
        $('.mobile_account_menu').slideToggle()
      })
      $(document).click(function(e) {
        if (
          !$(e.target).is(
            '.arrow_myaccount, .mobile_account_menu_col, .mobile_account_menu_col *, .mobile_account_menu_click '
          )
        ) {
          if ($('.mobile_account_menu').is(':visible')) {
            $('.mobile_account_menu').hide()
          }
        }
      })
    }

    window.scrollTo(0, 0)
    this.props.getCartItems()
    this.props.getSetings()

    const currentpath = window.location.pathname
    if (
      getCookies('enable_order_popup') === 'Yes' &&
      currentpath.includes('menu') === false
    ) {
      // const sts = this.state.alreadyExist + 1;
      //this.setState({ alreadyExist : this.state.alreadyExist + 1});
      //alert(this.state.alreadyExist);
      $('#orderNowOptionModalCenter').modal('hide')
      $('#orderNowOptionModalCenter').modal('show')
      cookie.remove('enable_order_popup')
    }
  }
  handleOrderTypeModal = e => {
    return false
    $('#SelectAvailabilityModalCenter').modal('show')
  }
  handleClickCloseModal = (e, data, avail = null, avilablity = null) => {
    if (avilablity !== null) {
      cookie.save('orderAvailability', avilablity)
      this.setState({ selectedAvilablity: avilablity })
    }

    if (avail !== null) {
      this.setState({
        orderType: avail
      })
    }

    this.setState({ error_postalcode: '', postalcode: '' })
    $(data).modal('hide')
    $('#SelectAvailabilityModalCenter').modal('hide')
    $(e.target.dataset.target).modal('hide')
    $(e.target.dataset.target).modal('show')
    $(e.target.dataset.target).on('shown.bs.modal', function(e) {
      $('body').addClass('modal-open')
    })

    this.setPickupPostal()
  }
  handleCartHover() {
    this.setState(prevState => ({
      iscartHovered: !prevState.iscartHovered
    }))
  }

  /* Cart action */

  incQty(cartItemId, qty, productId, type, itemArr) {
    if (type === 'add') {
      var cartQty = Number(qty) + 1
    } else {
      if (Number(qty) === 1) {
        return false
      } else {
        cartQty = Number(qty) - 1
      }
    }
    showLoader('item_' + cartItemId, 'id')

    if (type === 'add') {
      var checkCartQty = this.checkCartQty(productId, cartItemId)
      var maxQty = parseInt(itemArr.product_maximum_quantity)
      var totalQtys = parseInt(checkCartQty) + parseInt(cartQty)
      if (maxQty > 0) {
        if (maxQty < totalQtys) {
          hideLoader('item_' + cartItemId, 'id')
          return false
        }
      }
    }
    const customId = customerId()
    const refId = getReferenceID()
    const host = API_URL
    const app = APP_ID
    var postObject = {}
    postObject = {
      app_id: app,
      cart_item_id: cartItemId,
      product_id: productId,
      product_qty: cartQty
    }
    if (typeof customId === 'undefined' || customId === '') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }

    $('#cartLoading').show()
    axios
      .post(host + '/api/cart/update', qs.stringify(postObject))
      .then(res => {
        hideLoader('item_' + cartItemId, 'id')
        $('#cartLoading').hide()
        if (res.data.status === 'ok') {
          this.props.getCartItems()
        } else if (res.data.status === 'error') {
          showInfo(res.data.message)
        }
      })
  }

  checkCartQty(productID, cartItemId) {
    var totalQty = 0
    var cartItem = this.state.cartItem
    if (cartItem.length > 0) {
      cartItem.map(function(item) {
        if (
          parseInt(item.cart_item_qty) > 0 &&
          productID === item.cart_item_product_id &&
          cartItemId !== item.cart_item_id
        ) {
          totalQty = parseInt(totalQty) + parseInt(item.cart_item_qty)
        }
      })
    }
    return totalQty
  }

  deleteCartItem(cartItemId) {
    const customId = customerId()
    const refId = getReferenceID()
    const host = API_URL
    const app = APP_ID
    var postObject = {}
    postObject = { app_id: app, cart_item_id: cartItemId }

    if (typeof customId === 'undefined' || customId === '') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }
    axios.post(host + 'api/cart/delete', qs.stringify(postObject)).then(res => {
      if (res.data.status === 'ok') {
        if (res.data.contents) {
          this.props.getCartItems()
        } else {
          deleteOrderCookie()
          history.push('/')
        }
      } else if (res.data.status === 'error') {
        showInfo(res.data.message)
      }
    })
  }

  changeAvailability = (e, data, avail = null) => {
    $(data).modal('hide')

    /*$(e.target.dataset.target).modal("hide");
    $(e.target.dataset.target).modal("show");
    $(e.target.dataset.target).on("shown.bs.modal", function (e) {
      $("body").addClass("modal-open");
    });*/
    this.destroyCart('Yes', 'avail')
    if (avail !== null) {
      this.setState({ orderType: avail })
    }
  }
  destroyCart(deletevar, type) {
    const customId = customerId()
    const refId = getReferenceID()
    const host = API_URL
    const app = APP_ID
    var postObject = {}
    postObject = { app_id: app }

    if (typeof customId === 'undefined' || customId === '') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }

    axios
      .post(host + 'api/cart/destroy', qs.stringify(postObject))
      .then(res => {
        //cookie.remove('promotion_code');
        if (res.data.status === 'ok') {
          if (type == 'avail') {
            deleteOrderCookie()
          }

          this.props.getCartItems()
          this.setState({ modal: false })
          if (deletevar === 'Yes') {
            //history.push('/')
          }
        } else if (res.data.status === 'error') {
          showInfo(res.data.message)
        }
      })
  }

  destroyUserSession() {
    cookie.remove('UserId')
    cookie.remove('UserFname')
    cookie.remove('UserLname')
    cookie.remove('UserMobile')
    cookie.remove('UserEmail')
    cookie.remove('userAccountType')
    cookie.remove('UserDefaultAddress')
    cookie.remove('UserDefaultPostalCode')
    cookie.remove('UserDefaultUnitOne')
    cookie.remove('UserDefaultUnitTwo')

    history.push('/')
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (itemType === 'Modifier' && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        var modPrice =
          modifiers[i]['modifiers_values'][0]['cart_modifier_price'] > 0
            ? ' (+' +
              modifiers[i]['modifiers_values'][0]['cart_modifier_price'] +
              ')'
            : ''

        html +=
          "<li className='sub-item'>" +
          modName +
          ' : ' +
          modval +
          modPrice +
          '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (itemType === 'Component' && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]['cart_menu_component_product_name']
        var comboQty = combos[r]['cart_menu_component_product_qty']
        var comboPrice = combos[r]['cart_menu_component_product_price']
        var newPrice = comboPrice > 0 ? ' (+' + comboPrice + ')' : ''
        //var newPrice = ''
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['cart_modifier_price']
        var newModValPrice = ''
        var newModValPrice = modValPrice > 0 ? ' (+' + modValPrice + ')' : ''

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }
  /* Search Result functions */
  getOptions(query, callback) {
    var orderOutletIdtext = cookie.load('orderOutletId')
    var avilablity = cookie.load('orderAvailability')

    var addquery_txt = ''
    var addquery_txt_two = ''

    if (typeof orderOutletIdtext !== 'undefined' && orderOutletIdtext !== '') {
      addquery_txt = '&outlet=' + orderOutletIdtext
    }
    if (typeof avilablity !== 'undefined' && avilablity !== '') {
      addquery_txt_two = '&availability=' + avilablity
    }
    var searchResult = []
    return axios
      .get(
        API_URL +
          'api/products/products_list?app_id=' +
          APP_ID +
          '&status=A&search_key=product_name' +
          addquery_txt +
          addquery_txt_two +
          '&search_value=' +
          query
      )
      .then(response => {
        if (response.data.status === 'ok') {
          var resultData = response.data.result_set

          let options = resultData.map(function(loadData) {
            return {
              value: loadData.cat_slug + '/' + loadData.product_slug,
              label: getAliasName(
                stripslashes(loadData.product_alias),
                stripslashes(loadData.product_name)
              )
            }
          })
          callback(options)
        }
      })
  }
  noOptionsMessage() {
    return 'Type to search products...'
  }
  handleProductSelectChange(option) {
    var productSlug = option.value
    var rlink = ''
    var orderAvailability = cookie.load('orderAvailability')
    if (orderAvailability !== '' && typeof orderAvailability !== undefined) {
      if (orderAvailability === DELIVERY_ID) {
        rlink = 'delivery'
      } else {
        rlink = 'menu'
      }
    } else {
      rlink = 'delivery'
    }
    history.push('/' + rlink + '/' + productSlug)
  }
  handleNotifySettingsChange(event) {
    const target = event.target

    var btnValue = target.attributes['aria-pressed'].value
    var finalValue = btnValue === 'true' ? 1 : 0
    var qs = require('qs')
    var postObject = {
      app_id: APP_ID,
      customer_id: customerId(),
      notify_email: finalValue
    }
    axios
      .post(
        API_URL + 'api/customer/update_notification_status',
        qs.stringify(postObject)
      )
      .then(response => {
        if (response.data.status === 'ok') {
          return true
        } else {
        }
      })
  }

  render() {
    const cartDropDownClass = this.state.iscartHovered
      ? 'show-cart'
      : 'hide-cart'
    const excludeTime = [
      setHours(setMinutes(new Date(), 0), 11),
      setHours(setMinutes(new Date(), 30), 11),
      setHours(setMinutes(new Date(), 0), 12),
      setHours(setMinutes(new Date(), 30), 12),
      setHours(setMinutes(new Date(), 0), 13),
      setHours(setMinutes(new Date(), 30), 13),
      setHours(setMinutes(new Date(), 0), 14),

      setHours(setMinutes(new Date(), 30), 17),
      setHours(setMinutes(new Date(), 0), 18),
      setHours(setMinutes(new Date(), 30), 18),
      setHours(setMinutes(new Date(), 0), 19),
      setHours(setMinutes(new Date(), 30), 19),
      setHours(setMinutes(new Date(), 0), 20),
      setHours(setMinutes(new Date(), 30), 20),
      setHours(setMinutes(new Date(), 0), 21),
      setHours(setMinutes(new Date(), 30), 21)
    ]

    const cartItems = this.props.cartData
    const cartStatus = this.props.cartStatus
    const settings = this.props.setStatus === 'ok' ? this.props.settings : []
    var finalTotal = 0
    var subTotal = 0
    var gstPercentage = 0
    var gstDisplay = 0

    var serviceCharge = 0
    var serviceChargeCalc = 0
    var cartCount = 0
    let deliveryFee = 0
    let additionaldelFee = 0
    if (
      _.isEmpty(settings) === false &&
      _.isEmpty(cartItems) === false &&
      _.isEmpty(cartItems.cart_details) === false &&
      _.isEmpty(cartItems.cart_items) === false
    ) {
      cartCount = cartItems.cart_details.cart_total_items
      /* get All values */

      let tempDelivery = 0

      let freeDelivery = 0
      let minCart = 0
      let balanceAmount = 0
      let percentage = 0
      subTotal = parseFloat(cartItems.cart_details.cart_sub_total)

      /* promo concept */
      let promotionAmount = 0

      if (
        this.state.promotionApplied === 'Yes' &&
        (this.state.promotionAmount > 0 ||
          this.state.promoIsDelivery === 'Yes') &&
        this.state.promotionType === 'PROMO'
      ) {
        promotionAmount =
          this.state.promotionAmount > 0 ? this.state.promotionAmount : 0
      }

      subTotal = subTotal - promotionAmount

      /* promo concept */

      /* SERVICE_CHARGE */
      if (getCookies('orderAvailability') === DINEIN_ID) {
        serviceChargeCalc = getGstValue(1, SERVICE_CHARGE, subTotal, '')

        serviceCharge = serviceChargeCalc.toFixed(2)
      }

      /*const gst = getGstValue(
        settings.client_tax_enable,
        settings.client_tax_surcharge,
        subTotal + parseFloat(serviceCharge),
        ''
      )
      gstDisplay = gst.toFixed(2)*/
      gstPercentage =
        typeof settings.client_tax_surcharge !== 'undefined' &&
        settings.client_tax_surcharge !== ''
          ? settings.client_tax_surcharge
          : 0
      deliveryFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_delivery_charge
          : 0

      var zone_free_delivery =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_free_delivery > 0
          ? this.state.outletZoneInfo.zone_free_delivery
          : 0
      if (
        parseFloat(subTotal) > parseFloat(zone_free_delivery) &&
        zone_free_delivery > 0
      ) {
        deliveryFee = 0
      }

      additionaldelFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_additional_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_additional_delivery_charge
          : 0
      var grandTotal = 0
      if (getCookies('orderAvailability') === DELIVERY_ID) {
        grandTotal =
          parseFloat(subTotal) +
          parseFloat(deliveryFee) +
          parseFloat(additionaldelFee) +
          parseFloat(serviceChargeCalc)
      } else {
        grandTotal = parseFloat(subTotal) + parseFloat(serviceChargeCalc)
      }

      var gst = 0
      if (getCookies('orderAvailability') === DELIVERY_ID) {
        gst = getGstValue(
          settings.client_tax_enable,
          settings.client_tax_surcharge,
          grandTotal,
          ''
        )
        gstDisplay = gst.toFixed(2)
      } else {
        gst = getGstValue(
          settings.client_tax_enable,
          settings.client_tax_surcharge,
          subTotal + parseFloat(serviceCharge),
          ''
        )
        gstDisplay = gst.toFixed(2)
      }

      finalTotal = (parseFloat(gst) + parseFloat(grandTotal)).toFixed(2)
    }

    const customerID = customerId()
    const userData = this.props.userData
    /* const settingBtnClass =
      (typeof userData.customer_email_notify !== undefined &&
        typeof userData.customer_email_notify !== 'undefined' &&
        parseInt(userData.customer_email_notify)) === 1
        ? 'btn btn-sm btn-secondary btn-toggle active'
        : 'btn btn-sm btn-secondary btn-toggle' */
    const settingBtnClass = 'btn btn-sm btn-secondary btn-toggle active'
    const headerNavigation = (
      <ul className="navbar-nav top-menu-nav">{this.state.menuList}</ul>
    )
    let orderNow = 'Order Now'
    if (
      getCookies('orderAvailability') !== '' &&
      typeof getCookies('orderAvailability') !== undefined
    ) {
      if (getCookies('orderAvailability') === DELIVERY_ID) {
        orderNow = deliveryText
      } else if (getCookies('orderAvailability') === DINEIN_ID) {
        orderNow = dineInText
      } else if (getCookies('orderAvailability') === PICKUP_ID) {
        orderNow = pickUpText
      }
    }
    var deliveryTAT = 0
    if (
      this.state.selectedAvilablity !== '' &&
      this.state.selectedAvilablity !== undefined
    ) {
      if (this.state.selectedAvilablity === DELIVERY_ID) {
        if (
          getCookies('outletZoneInfo') !== '' &&
          typeof getCookies('outletZoneInfo') !== undefined
        ) {
          deliveryTAT = getCookies('outletZoneInfo').outlet_delivery_tat
        }
      } else if (this.state.selectedAvilablity === DINEIN_ID) {
      } else if (this.state.selectedAvilablity === PICKUP_ID) {
        deliveryTAT = this.state.pickupTAT
      }
    }

    return (
      <React.Fragment>
        <header className="main-header fixed-top">
          <div className="quandoo-widget-builder">
            <a
              // href="https://reserve.oddle.me/springcourt"
              href="https://inline.app/booking/-MklD4VkXHlVkrAKwYQX:inline-live-2/-MklD4cwSe_cVCaKiEGc"
              target="_blank">
              <span className="quandoo-button quandoo-button-text quandoo-fixed quandoo-s quandoo- quandoo-round-border quandoo-md button-29329">
                Book a table
              </span>
            </a>
          </div>
          {/* Navigation */}
          <div id="mainNav">
            <div className="top-info-menu">
              <div className="container">
                <div className="info-text">
                  {this.state.topMessage !== '' && parse(this.state.topMessage)}
                </div>
                <div className="top-header-account d-none d-lg-inline-block">
                  <div className="inner-header-account">
                    {customerID == '' && (
                      <Link
                        data-toggle="modal"
                        data-target="#signInModalCenter"
                        to="#signInModalCenter">
                        {' '}
                        <img src="/img/user-icon.png" /> Login
                      </Link>
                    )}

                    {customerID !== '' && (
                      <Link to="/myaccount">
                        {' '}
                        <img src="/img/user-icon.png" /> My Account
                      </Link>
                    )}
                  </div>
                  <div className="inner-header-account">
                    {customerID == '' && (
                      <Link
                        data-toggle="modal"
                        data-target="#signUpModalCenter"
                        to="#signUpModalCenter">
                        Signup
                      </Link>
                    )}

                    {customerID !== '' && (
                      <a onClick={this.destroyUserSession.bind()}>Logout</a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="menu-section row d-flex">
                <div className="col-4 pr-0 mobilenav_wrapper">
                  <div id="mobilenav-toggle" className="mobile-menu">
                    <button>
                      <i className="fas fa-bars" />
                    </button>
                  </div>
                </div>
                <div className="col-4 col-lg-4 col-xl-2 sprcrt-logo-colm p-0">
                  <Link
                    className="navbar-brand brand-logo js-scroll-trigger"
                    to="/"
                    title="Spring Court">
                    <img src={logo} />
                  </Link>
                </div>
                <div className="col-4 col-lg-10  col-xl-10 main-menu-block">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="navbar-collapse">{headerNavigation}</div>
                  </nav>
                  <div className="mainmenu-right">
                    <div className="hordernow_btn d-none d-lg-inline-block">
                      <Link
                        to="#"
                        data-toggle="modal"
                        className="dinein-link"
                        onClick={e => this.handleOrderTypeModal(e)}>
                        <img src={ico_mouse} alt="" />
                        {orderNow}
                      </Link>
                    </div>
                    <div className="d-lg-inline-block cart-search-box">
                      <div className="outer-search-box d-inline-block">
                        <div className="search-toggle" />
                        <div className="searchbox">
                          <AsyncSelect
                            cacheOptions={true}
                            loadOptions={(query, callback) =>
                              this.getOptions(query, callback)
                            }
                            placeholder="Go ahead search for a product..."
                            noResultsText="No Products Found"
                            noOptionsMessage={this.noOptionsMessage}
                            onChange={this.handleProductSelectChange.bind(this)}
                            classNamePrefix="searchfilter-Selector"
                          />
                        </div>
                      </div>
                      {this.props.hideCart !== true && (
                        <div className="cart-box d-inline-block">
                          <Link to="#" onClick={this.handleCartHover}>
                            <span className="cart-icon-bag">
                              <img src="/img/shopping-cart.png" />
                              <span className="number">{cartCount}</span>
                            </span>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mobile-header-navigation">
                  <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="navbar-collapse" id="navbarResponsive">
                      <div className="mobile-menu-close">
                        <div>
                          <i className="fas fa-times" />
                        </div>
                      </div>
                      {headerNavigation}
                    </div>
                  </nav>
                </div>
                <div className="mobile_accountdel_body">
                  <div className="mobile_accountdel_row">
                    <div className="mobile_accountdel_col">
                      <div className="mobile_account_item">
                        {customerID == '' && (
                          <div className="mobile_account_item_login">
                            <Link
                              data-toggle="modal"
                              data-target="#signInModalCenter"
                              to="#signInModalCenter">
                              {' '}
                              <i /> Login
                            </Link>

                            <span className="myaccount_separate">/</span>

                            <Link
                              className="signup_btn"
                              data-toggle="modal"
                              data-target="#signUpModalCenter"
                              to="#signUpModalCenter">
                              <i />
                              Signup
                            </Link>
                          </div>
                        )}
                        {customerID !== '' && (
                          <div className="mobile_account_item_loggedin">
                            <Link className="my-account-link" to="myaccount">
                              <i />
                              My Account{' '}
                              <span className="mobile_mainacc_menutrigger" />
                            </Link>
                            <div
                              className="mobile_mainacc_menulist"
                              style={{ display: 'none' }}>
                              <ul>
                                <li>
                                  <Link
                                    data-toggle="modal"
                                    data-target="#changePasswordModalCenter"
                                    to="#changePasswordModalCenter">
                                    <i className="setting_menu_list_icon password_icon open-popup-link" />
                                    Change Password
                                  </Link>
                                </li>
                                <li className="mobile_setting_menu_list">
                                  {' '}
                                  <Link to="#">
                                    <i className="setting_menu_list_icon setting_icon" />
                                    Settings
                                  </Link>
                                  <ul className="myacc_dropmenu">
                                    <li>
                                      {' '}
                                      <Link to="#">Option For Promotions </Link>
                                      <ul className="tg-list">
                                        <li className="tg-list-item">
                                          <button
                                            type="button"
                                            className={settingBtnClass}
                                            data-toggle="button"
                                            onClick={
                                              this.handleNotifySettingsChange
                                            }
                                            aria-pressed="true"
                                            autoComplete="off">
                                            <div className="handle" />
                                          </button>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    style={{ color: '#fff', cursor: 'pointer' }}
                                    onClick={this.destroyUserSession.bind()}>
                                    <i className="setting_menu_list_icon logout_icon" />
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/**/}
                    <div className="mobile_accountdel_col">
                      <a
                        className="mobile_account_menu_click"
                        href="javascript:void(0);">
                        {orderNow}
                        <i className="mouse-myaccount" />
                        <i className="arrow_myaccount" />
                      </a>
                      <div
                        className="mobile_account_menu"
                        style={{ display: 'none' }}>
                        <ul className="mobile_account_delivery_item">
                          {/*<li
                            className={
                              getCookies('orderAvailability') === DELIVERY_ID
                                ? 'delivery_mob_menu active'
                                : 'delivery_mob_menu'
                            }>
                            {' '}
                            <Link
                              to="#"
                              data-toggle="modal"
                              className={
                                getCookies('orderAvailability') === DELIVERY_ID
                                  ? 'dinein_mob'
                                  : 'dinein-link'
                              }
                              data-target={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== DELIVERY_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      DELIVERY_ID
                                  ? ''
                                  : '#delviryPostalCodeModalCenter'
                              }
                              to={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== DELIVERY_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      DELIVERY_ID
                                  ? ''
                                  : '#delviryPostalCodeModalCenter'
                              }
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#delviryPostalCodeModalCenter',
                                  deliveryText,
                                  DELIVERY_ID
                                )
                              }>
                              <i />
                              {deliveryText}
                            </Link>
                          </li>*/}

                          <li
                            className={
                              getCookies('orderAvailability') === PICKUP_ID
                                ? 'takeaway_mob active'
                                : 'takeaway_mob'
                            }>
                            <Link
                              className={
                                getCookies('orderAvailability') === PICKUP_ID
                                  ? 'pickup_mob'
                                  : 'pickup_link'
                              }
                              data-toggle="modal"
                              data-target={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== PICKUP_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      PICKUP_ID
                                  ? ''
                                  : '#delviryDateTimeModalPopup'
                              }
                              to={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== PICKUP_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      PICKUP_ID
                                  ? ''
                                  : '#delviryDateTimeModalPopup'
                              }
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#delviryDateTimeModalPopup',
                                  pickUpText,
                                  PICKUP_ID
                                )
                              }>
                              <i />
                              {pickUpText}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {_.isEmpty(cartItems) === false &&
                  _.isEmpty(cartItems.cart_details) === false &&
                  _.isEmpty(cartItems.cart_items) === false && (
                    <div
                      className={`header-cart-dropdown ${cartDropDownClass}`}>
                      <div className="cart-row-header">
                        <div className="row">
                          <div className="col-6">
                            <h6 className="text-uppercase">Added Items</h6>
                          </div>
                          <div className="col-6 text-right">
                            <h6
                              className="text-uppercase font-weight-light"
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'none'
                              }}
                              onClick={this.destroyCart.bind(
                                this,
                                'Yes',
                                'cart'
                              )}>
                              Clear Cart
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="header-cart-scrollarea">
                        <div className="cart-table">
                          <div className="cart-body">
                            {_.isEmpty(cartItems.cart_items) === false &&
                              cartItems.cart_items.map((cart, cartIndex) => (
                                <div key={cartIndex} className="cart-row">
                                  <div className="row">
                                    <div className="col-9 col-xl-9">
                                      <div className="cart-left">
                                        <div className="cart-img">
                                          <img
                                            src={
                                              cart.cart_item_product_image !==
                                              ''
                                                ? cart.cart_item_product_image
                                                : noImage
                                            }
                                            width={110}
                                            height={67}
                                            alt="true"
                                          />
                                        </div>

                                        <div className="cart-info">
                                          <h6>
                                            {stripslashes(
                                              cart.cart_item_product_name
                                            )}
                                          </h6>

                                          {this.loadModifierItems(
                                            cart.cart_item_type,
                                            cart.modifiers,
                                            cart.set_menu_component
                                          )}

                                          {/*   <div className="cart-addon-info">
                                <span className="d-inline-block">Size:</span><span className="d-inline-block font-italic"> Large</span>

                              </div>
                              <div className="cart-addon-info">
                                <span className="d-inline-block">Add on:</span><span className="d-inline-block font-italic"> Name</span>

										  </div> */}
                                        </div>
                                      </div>
                                      {cart.cart_item_special_notes !== '' && (
                                        <p className="cart-special-text">
                                          {stripslashes(
                                            cart.cart_item_special_notes
                                          )}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col-3 col-xl-3 text-right">
                                      <div className="cart-right ">
                                        <div className="cart-price">
                                          <p>${cart.cart_item_total_price}</p>
                                        </div>
                                        <div
                                          className="qty-bx"
                                          id={'item_' + cart.cart_item_id}>
                                          <span
                                            className="qty-minus"
                                            onClick={this.incQty.bind(
                                              this,
                                              cart.cart_item_id,
                                              cart.cart_item_qty,
                                              cart.cart_item_product_id,
                                              'remove',
                                              cart
                                            )}>
                                            -
                                          </span>
                                          <input
                                            type="text"
                                            value={cart.cart_item_qty}
                                            readOnly
                                          />
                                          <span
                                            className="qty-plus"
                                            onClick={this.incQty.bind(
                                              this,
                                              cart.cart_item_id,
                                              cart.cart_item_qty,
                                              cart.cart_item_product_id,
                                              'add',
                                              cart
                                            )}>
                                            +
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <a
                                    href="javascript:;"
                                    className="cart-remove"
                                    onClick={this.deleteCartItem.bind(
                                      this,
                                      cart.cart_item_id
                                    )}
                                  />
                                </div>
                              ))}
                            <div className="calctrcart-footer">
                              <div className="calctrcart-row">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">SUBTOTAL</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <span>${subTotal.toFixed(2)}</span>
                                  </div>
                                </div>
                              </div>
                              {/*  <div className="calctrcart-row ">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">Delivery Charges</p>
                          </div>
                          <div className="col-4 text-right"><span>$0</span></div>
                        </div>
                      </div>
                      <div className="calctrcart-row ">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">Additional Delivery Charges</p>
                          </div>
                          <div className="col-4 text-right"><span>$0</span></div>
                        </div>
					</div> */}

                              {getCookies('orderAvailability') === DINEIN_ID &&
                                serviceCharge > 0 && (
                                  <div className="calctrcart-row ">
                                    <div className="row">
                                      <div className="col-8">
                                        <p className="text-uppercase">
                                          Service Charge ({SERVICE_CHARGE}%)
                                        </p>
                                      </div>
                                      <div className="col-4 text-right">
                                        <span>${serviceCharge}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {getCookies('orderAvailability') ===
                                DELIVERY_ID && (
                                <div>
                                  {deliveryFee > 0 && (
                                    <div className="calctrcart-row ">
                                      <div className="row">
                                        <div className="col-8">
                                          <p className="text-uppercase">
                                            Delivery Charges
                                          </p>
                                        </div>
                                        <div className="col-4 text-right">
                                          <span>${deliveryFee}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {additionaldelFee > 0 && (
                                    <div className="calctrcart-row ">
                                      <div className="row">
                                        <div className="col-8">
                                          <p className="text-uppercase">
                                            Additional Delivery Charges
                                          </p>
                                        </div>
                                        <div className="col-4 text-right">
                                          <span>${additionaldelFee}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                              {gstPercentage > 0 && (
                                <div className="calctrcart-row ">
                                  <div className="row">
                                    <div className="col-8">
                                      <p className="text-uppercase">
                                        GST ({gstPercentage}%)
                                      </p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <span>${gstDisplay}</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="calctrcart-row calctrcart-footer-totrow">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">Total</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <span>${roundednearest(finalTotal)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="delivery-info">
                          <div className="slfcol-form-block">
                            <div className="media">
                              <img
                                className="mr-3"
                                src="/img/take-away.png"
                                alt="Generic placeholder image"
                              />
                              {getCookies('orderAvailability') ===
                              DELIVERY_ID ? (
                                <div className="media-body">
                                  <h6 className="mt-0">
                                    {deliveryText}
                                    LOCATION
                                  </h6>
                                  <p className="outlet-info">
                                    {addressFormat(
                                      this.state.postal_code_information
                                        .zip_buno,
                                      '',
                                      this.state.postal_code_information
                                        .zip_sname,
                                      '',
                                      this.state.postal_code_information
                                        .zip_code
                                    )}
                                  </p>
                                  {/*  <a href="/checkout">Change Pickup Location</a> */}
                                </div>
                              ) : (
                                <div className="media-body">
                                  <h6 className="mt-0">
                                    {' '}
                                    {getCookies('orderAvailability') ===
                                    DINEIN_ID
                                      ? dineInText
                                      : pickUpText}{' '}
                                    LOCATION{' '}
                                  </h6>
                                  <p className="outlet-info">
                                    {stripslashes(
                                      getCookies('orderOutletName')
                                    )}{' '}
                                    -{' '}
                                    {addressFormat(
                                      getCookies('orderUnitOne'),
                                      getCookies('orderUnitTwo'),
                                      getCookies('orderAddressOne'),
                                      getCookies('orderAddressTwo'),
                                      getCookies('orderPostalCode')
                                    )}
                                  </p>
                                  {/*  <a href="/checkout">Change Pickup Location</a> */}
                                </div>
                              )}
                            </div>
                            <div className="media">
                              <img
                                className="mr-3"
                                src="/img/alarm-clock.png"
                                alt="Generic placeholder image"
                              />
                              <div className="media-body">
                                <h6 className="mt-0">
                                  {getCookies('orderAvailability') ===
                                  DELIVERY_ID
                                    ? deliveryText
                                    : getCookies('orderAvailability') ===
                                      DINEIN_ID
                                    ? dineInText
                                    : pickUpText}{' '}
                                  Date{' '}
                                </h6>
                                <p className="outlet-info">
                                  {' '}
                                  {getCookies('orderDeliveryDate')}{' '}
                                  {getCookies('orderDeliveryTime')}
                                </p>
                                {this.state.last_counter !== '' &&
                                this.state.last_counter !== null ? (
                                  <Link
                                    to="#"
                                    onClick={e =>
                                      this.UpdateDelveiryDateModal(e)
                                    }>
                                    Change Date?
                                  </Link>
                                ) : (
                                  <Link
                                    to="#"
                                    data-toggle="modal"
                                    data-target="#changeDateModal">
                                    Change Date?
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cart-action">
                        {/*  <div className="quto-download-block">
                              <h6>
                                <img src="../img/download-icon.png" alt="..." />
                                Download Quotation
                              </h6>
						  </div>  */}

                        <div className="cartaction-bottom">
                          {customerID == '' && (
                            <Link
                              data-toggle="modal"
                              data-target="#signInModalCenter"
                              to="#signInModalCenter"
                              className="btn btn-primary cartaction-checkout"
                              title="Checkout Now">
                              Checkout Now
                            </Link>
                          )}

                          {customerID != '' && (
                            <Link
                              className="btn btn-primary cartaction-checkout"
                              title="Checkout Now"
                              to="/checkout">
                              Checkout Now
                            </Link>
                          )}
                          <span className="btn btn-secondary btn-black cartaction-price">
                            <sup>$</sup>
                            {finalTotal}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </header>
        {/* subscribe Now Modal */}
        <div
          className="modal fade"
          id="subscribeModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document">
            <div className="modal-content bg-Camelot subscribe-now-modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="container-fluid form-block">
                  <div className="row">
                    <div className="col-md-8 m-auto">
                      <form>
                        <h1>Subscribe Now</h1>
                        <div className="input-group">
                          <input
                            placeholder="Email Address"
                            id="promocode"
                            type="text"
                            className="form-control"
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn btn-secondary">
                              Subscribe
                            </button>
                          </div>
                        </div>
                        <p className="help-text">
                          Subscribe FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                          INSIDER-ONLY DISCOUNTS
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer" />
            </div>
          </div>
        </div>
        {/* Special Promo subscribe Now Modal */}
        <div
          className="modal fade"
          id="speciallPromSubscribeModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot subscribe-now-modal special-promo-modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-md-5 pl-md-0">
                      <div className="promo-banner">
                        <img src="./img/promo-banner.jpg" alt="..." />
                      </div>
                    </div>
                    <div className="col-md-7 form-block">
                      <form>
                        <h1>
                          5% Off <br />
                          For December
                        </h1>
                        <h4 className="text-uppercase">SPecial Promotion</h4>
                        <p className="help-text">
                          Enter your email address to receive your promo code
                        </p>
                        <div className="input-group">
                          <input
                            placeholder="Email Address"
                            id="promocode2"
                            type="text"
                            className="form-control"
                          />
                          <div className="input-group-append">
                            <button type="button" className="btn btn-secondary">
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Oder Now Option Modal */}
        <div
          className="modal fade"
          id="orderNowOptionModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot order-now-option-modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-lg-7 m-auto">
                      <div className="order-option-block">
                        <h1 className="text-uppercase">Order Now</h1>
                        {/*    <h1 className="font-azakie">Select your order type</h1> */}
                        <ul className="order-option-list">
                          <li className="order-option-tem dine-in">
                            <Link
                              to="#"
                              data-toggle="modal"
                              className={
                                getCookies('orderAvailability') === DINEIN_ID
                                  ? 'btn btn-light active'
                                  : 'btn btn-light'
                              }
                              data-target="#delviryDateTimeModalCenter"
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#orderNowOptionModalCenter',
                                  dineInText,
                                  DINEIN_ID
                                )
                              }>
                              <span className="order-icon" />
                              {dineInText}
                            </Link>
                          </li>
                          <li className="order-option-tem self-collection">
                            <span>
                              <Link
                                to="#"
                                className={
                                  getCookies('orderAvailability') === PICKUP_ID
                                    ? 'btn btn-light active'
                                    : 'btn btn-light'
                                }
                                data-toggle="modal"
                                data-target="#delviryDateTimeModalCenter"
                                onClick={e =>
                                  this.handleClickCloseModal(
                                    e,
                                    '#orderNowOptionModalCenter',
                                    pickUpText,
                                    PICKUP_ID
                                  )
                                }>
                                <span className="order-icon" />
                                {pickUpText}
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Select Dinin Date & Time Modal */}
        <div
          className="modal fade"
          id="delviryDateTimeModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot delivery-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 popup_left">
                      <div className="delivery-address-block">
                        <img src="/img/like-thumb.png" alt="..." />
                        <h1 className="text-uppercase mt-2 mb-0">Awesome</h1>
                        {_.isEmpty(this.props.allProps.outlets) === false && (
                          <React.Fragment>
                            <p className="label-text font-opnsns font-weight-light mb-0">
                              {this.props.allProps.outlets[0].outlet_name}
                            </p>

                            <p className="label-text font-opnsns font-weight-bold">
                              {addressFormat(
                                this.props.allProps.outlets[0]
                                  .outlet_unit_number1,
                                this.props.allProps.outlets[0]
                                  .outlet_unit_number2,
                                this.props.allProps.outlets[0]
                                  .outlet_address_line1,
                                this.props.allProps.outlets[0]
                                  .outlet_address_line2,
                                this.props.allProps.outlets[0]
                                  .outlet_postal_code
                              )}
                            </p>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-7 popup_right">
                      <div className="innervmid_in delivery-datetime-form-block">
                        <h6 className="font-aller">
                          Select Your {this.state.orderType} Date & Time
                        </h6>

                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          minDate={addDays(new Date(), getCookies('minDay'))}
                          selected={this.state.startDate}
                          onChange={this.handleChangeDate}
                        />
                        <DatePicker
                          className="form-control collect-time-picker"
                          selected={this.state.startTime}
                          onChange={this.handleChangeTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          dateFormat="p"
                          /* dateFormat="h:mm aa" */
                          timeCaption="Time"
                          includeTimes={excludeTime}
                        />
                        {/* <p className="know_more_delivery 1">Please call us at 64495030 if you are unable to find your preferred Time Slot.</p> */}

                        <button
                          type="button"
                          onClick={this.setDineInData.bind(
                            this,
                            this.props.allProps.outlets
                          )}
                          className="btn btn-primary btn-block mt-2">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Select Delivery Date & Time Modal */}

        <div
          className="modal fade"
          id="delviryDateTimeModalPopup"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot delivery-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 popup_left">
                      <div className="delivery-address-block ordertype_address">
                        <img src={ico_like} alt="..." />
                        <h1 className="text-uppercase mt-2">Awesome</h1>

                        {this.state.orderType !== '' &&
                          typeof this.state.orderType !== undefined &&
                          this.state.orderType === deliveryText && (
                            <div>
                              <p className="label-text mb-0">
                                We can Deliver !
                              </p>
                              <p className="font-opnsns font-weight-light mb-0">
                                Your Delivery Address :
                              </p>
                              <p className="font-opnsns font-weight-bold mb-0">
                                {addressFormat(
                                  this.state.postal_code_information.zip_buno,
                                  '',
                                  this.state.postal_code_information.zip_sname,
                                  '',
                                  this.state.postal_code_information.zip_code
                                )}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-lg-7 popup_right">
                      <div className="innervmid_in">
                        <div className="delivery-datetime-form-block">
                          <h6 className="font-aller">
                            Select Your {this.state.orderType} Date & Time
                          </h6>
                          <OrderdatetimeSlot
                            {...this.props}
                            hdrState={[
                              {
                                getDateTimeFlg: this.state.getDateTimeFlg,
                                seletedAvilablityId:
                                  this.state.selectedAvilablity !== '' &&
                                  this.state.selectedAvilablity != undefined &&
                                  this.state.selectedAvilablity !== 'undefined'
                                    ? this.state.selectedAvilablity
                                    : DELIVERY_ID,
                                seletedOutletId: OUTLET_ID,
                                order_tat_time: deliveryTAT
                              }
                            ]}
                            setdateTimeFlg={this.setdateTimeFlg}
                          />
                          <input type="hidden" id="cartDateUpdate" />
                          {this.state.timeErroTxt !== '' && (
                            <span className="error">
                              {this.state.timeErroTxt}
                            </span>
                          )}
                          <p className="know_more_delivery">
                            Last Pickup for 21 Jan 2023 (CNY Reunion Day) is at
                            2pm. <br />
                            <br />
                            Please call us at 64495030 if you are unable to find
                            your preferred time slot.
                          </p>
                          <button
                            type="button"
                            onClick={this.setDeliveryDate.bind(
                              this,
                              this.props.allProps.outlets
                            )}
                            className="btn btn_normal btn_pink btn-block mt-2">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Self Collection Date & Time Modal */}
        <div
          className="modal fade"
          id="selfCollectionDateTimeModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot delivery-datetime-modal self-collection-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="delivery-address-block">
                        <img src="/img/like-thumb.png" alt="..." />
                        <h1 className="text-uppercase mt-2 mb-0">Awesome</h1>
                        <h1 className="font-azakie mb-0">
                          You Can Collect Your Food
                        </h1>
                        <p className="label-text font-opnsns font-weight-light mb-0">
                          Spring Court Outlet Address line will be here
                        </p>
                        <p className="label-text font-opnsns font-weight-bold d-none">
                          37 Jalan Rumah Tinggi Singapore, 150037
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-7 form-block justify-content-center">
                      <div className="delivery-datetime-form-block">
                        <h6 className="font-aller">
                          Select Your {pickUpText} Date & Time (din in)
                        </h6>
                        <DatePicker
                          className="form-control"
                          /* dateFormat="yyyy/MM/dd" */
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                        />
                        <DatePicker
                          className="form-control collect-time-picker"
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          /* dateFormat="h:mm aa" */
                          timeCaption="Time"
                        />
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#outletListOptionModalCenter"
                          onClick={e =>
                            this.handleClickCloseModal(
                              e,
                              '#selfCollectionDateTimeModalCenter'
                            )
                          }
                          to="#outletListOptionModalCenter"
                          className="btn btn-primary btn-block mt-2">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Change date */}

        <div
          className="modal fade"
          id="changeDateModal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot delivery-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="delivery-address-block">
                        <img src="/img/like-thumb.png" alt="..." />
                        <h1 className="text-uppercase mt-2 mb-0">Awesome</h1>
                        {/* <h1 className="font-azakie mb-0">We can Deliver !</h1>                       */}
                        {_.isEmpty(this.props.allProps.outlets) === false && (
                          <React.Fragment>
                            <p className="label-text font-opnsns font-weight-light mb-0">
                              {this.props.allProps.outlets[0].outlet_name}
                            </p>

                            <p className="label-text font-opnsns font-weight-bold">
                              {addressFormat(
                                this.props.allProps.outlets[0]
                                  .outlet_unit_number1,
                                this.props.allProps.outlets[0]
                                  .outlet_unit_number2,
                                this.props.allProps.outlets[0]
                                  .outlet_address_line1,
                                this.props.allProps.outlets[0]
                                  .outlet_address_line2,
                                this.props.allProps.outlets[0]
                                  .outlet_postal_code
                              )}
                            </p>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-7 form-block justify-content-center">
                      <div className="delivery-datetime-form-block">
                        <h6 className="font-aller">
                          Select Your{' '}
                          {getCookies('orderAvailability') === DINEIN_ID
                            ? dineInText
                            : pickUpText}{' '}
                          Date & Time
                        </h6>
                        <DatePicker
                          className="form-control"
                          /* dateFormat="dd/MM/YYYY" */
                          minDate={addDays(new Date(), getCookies('minDay'))}
                          selected={this.state.startDate}
                          onChange={this.handleChangeDate}
                        />
                        <DatePicker
                          className="form-control collect-time-picker"
                          selected={this.state.startTime}
                          onChange={this.handleChangeTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          /* dateFormat="h:mm aa" */
                          timeCaption="Time"
                          includeTimes={excludeTime}
                        />
                        <button
                          type="button"
                          onClick={this.changeDateValue.bind(
                            this,
                            this.props.allProps.outlets
                          )}
                          className="btn btn-primary btn-block mt-2">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Select Availability  Modal */}
        <div
          className="modal fade"
          id="SelectAvailabilityModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot delivery-datetime-modal self-collection-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="delivery-address-block ordertype_address">
                        <h1 className="text-uppercase">Order Now</h1>
                        <p className="label-text">Select Your order type</p>
                        <ul className="order-option-list ordertype_list">
                          {/*<li className="order-option-tem delivery">
                            <Link
                              data-target={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== DELIVERY_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      DELIVERY_ID
                                  ? ''
                                  : '#delviryPostalCodeModalCenter'
                              }
                              to={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== DELIVERY_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      DELIVERY_ID
                                  ? ''
                                  : '#delviryPostalCodeModalCenter'
                              }
                              className={
                                getCookies('orderAvailability') === DELIVERY_ID
                                  ? 'btn btn-light active'
                                  : 'btn btn-light '
                              }
                              data-toggle="modal"
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#delviryPostalCodeModalCenter',
                                  deliveryText,
                                  DELIVERY_ID
                                )
                              }>
                              <span className="order-icon" />
                              {deliveryText}
                            </Link>
                          </li>*/}
                          {/* <li className="order-option-tem dine-in">
                            <Link
                              data-toggle="modal"
                              className={
                                getCookies('orderAvailability') === DINEIN_ID
                                  ? 'btn btn-light active'
                                  : 'btn btn-light '
                              }
                              data-target={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== DINEIN_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      DINEIN_ID
                                  ? ''
                                  : '#delviryDateTimeModalCenter'
                              }
                              to={
                                _.isEmpty(cartItems) === false &&
                                _.isEmpty(cartItems.cart_details) === false &&
                                getCookies('orderAvailability') !== DINEIN_ID
                                  ? '#switchError'
                                  : _.isEmpty(cartItems) === false &&
                                    _.isEmpty(cartItems.cart_details) ===
                                      false &&
                                    getCookies('orderAvailability') ===
                                      DINEIN_ID
                                  ? ''
                                  : '#delviryDateTimeModalCenter'
                              }
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#orderNowOptionModalCenter',
                                  dineInText
                                )
                              }>
                              <span className="order-icon" />
                              {dineInText}
                            </Link>
                          </li> */}
                          <li className="order-option-tem self-collection">
                            <span>
                              <Link
                                data-target={
                                  _.isEmpty(cartItems) === false &&
                                  _.isEmpty(cartItems.cart_details) === false &&
                                  getCookies('orderAvailability') !== PICKUP_ID
                                    ? '#switchError'
                                    : _.isEmpty(cartItems) === false &&
                                      _.isEmpty(cartItems.cart_details) ===
                                        false &&
                                      getCookies('orderAvailability') ===
                                        PICKUP_ID
                                    ? ''
                                    : '#delviryDateTimeModalPopup'
                                }
                                to={
                                  _.isEmpty(cartItems) === false &&
                                  _.isEmpty(cartItems.cart_details) === false &&
                                  getCookies('orderAvailability') !== PICKUP_ID
                                    ? '#switchError'
                                    : _.isEmpty(cartItems) === false &&
                                      _.isEmpty(cartItems.cart_details) ===
                                        false &&
                                      getCookies('orderAvailability') ===
                                        PICKUP_ID
                                    ? ''
                                    : '#delviryDateTimeModalPopup'
                                }
                                className={
                                  getCookies('orderAvailability') === PICKUP_ID
                                    ? 'btn btn-light active'
                                    : 'btn btn-light '
                                }
                                data-toggle="modal"
                                onClick={e =>
                                  this.handleClickCloseModal(
                                    e,
                                    '#delviryDateTimeModalPopup',
                                    pickUpText,
                                    PICKUP_ID
                                  )
                                }>
                                <span className="order-icon" />
                                {pickUpText}
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Select Delivery Postal Code */}
        <div
          className="modal fade"
          id="delviryPostalCodeModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-Camelot delivery-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 col-md-5 popup_left">
                      <div className="delivery-address-block ordertype_address">
                        <img src={ico_delivery_lg} alt="..." />
                        <h1 className="text-uppercase mt-2 mb-0">
                          Let us know
                        </h1>
                        <p className="label-text">Your Delivery Location</p>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7 form-block popup_right">
                      <div className="innervmid_in">
                        <div className="form-group">
                          <input
                            type="text"
                            name="postalcode"
                            pattern="[0-9]{3}"
                            maxLength="6"
                            value={this.state.postalcode}
                            className="form-control input-focus"
                            placeholder="Enter your postal code"
                            onChange={this.handleChangeTxt.bind(this)}
                            onKeyPress={e => numariconly(e)}
                          />
                          {this.state.error_postalcode !== '' && (
                            <span className="error">
                              {this.state.error_postalcode}
                            </span>
                          )}
                        </div>

                        <div className="btn_sec">
                          <div className=" row">
                            <div className="col-6">
                              <button
                                type="button"
                                className="btn btn_normal btn_black btn-block"
                                onClick={e => this.UpdateDelveiryBackModal(e)}>
                                Go Back
                              </button>
                            </div>
                            <div className="col-6 delivery_submit_cls">
                              <button
                                type="button"
                                onClick={this.setDeliveryPostal.bind(
                                  this,
                                  this.props.allProps.outlets
                                )}
                                className="btn btn_normal btn_pink btn-block">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Outlet List Option Modal
        <div
          className="modal fade"
          id="outletListOptionModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content bg-Camelot outlet-list-option-modal">
              <div className="modal-header">
                <Link className="btn-link go-back-link" to="/">
                  GO Back
                </Link>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="outlet-modal-title col-12 col-xs-8 col-sm-8 col-md-7 col-lg-6 m-auto">
                  <img src="./img/take-away-light.png" alt="..." />
                  <h1 className="text-uppercase">Let us know</h1>
                  <h1 className="font-azakie">Your Self Collection Outlet</h1>
                </div>
              </div>
              <div className="modal-body">
                <div className="outlet-option-list-block">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 outlet-list-block-left">
                        <div className="location-btn-block">
                          <p className="help-text">
                            Let us know your location to find a Cedele nearby.
                          </p>
                          <Link
                            to="#"
                            className="btn btn-secondary location-find-btn"
                          >
                            Use My Location
                          </Link>
                          <div className="outlet-or-separtor-block">
                            <div className="or-seperator">
                              {" "}
                              <span>Or</span>
                            </div>
                          </div>
                        </div>
                        <div className="outlet-location-map">
                          <img src="./img/location-map.png" alt="" />
                        </div>
                      </div>
                      <div className="col-md-6 outlet-list-block-right">
                        <h6 className="font-opnsns font-weight-bold text-center">
                          Enter your location to find a cedele nearby.
                        </h6>
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="text"
                              className="form-control input-focus"
                              placeholder="Type Your address"
                            />
                          </div>
                        </div>
                        <h3>Nearby outlets</h3>
                        <ul className="pickup-location-list list-group">
                          <li className="list-group-item">
                            <div className="input-group">
                              <input
                                id="radio_233"
                                name="outletsZone"
                                type="radio"
                                className="form-check-input"
                                defaultValue={233}
                              />
                              <label htmlFor="radio_233" className>
                                <span className="outlet-name">
                                  1 Vista Exchange Green,
                                </span>
                                <span className="outlet-address">
                                  01-19,The Star Vista, Singapore 138617
                                </span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="input-group">
                              <input
                                id="radio_234"
                                name="outletsZone"
                                type="radio"
                                className="form-check-input"
                                defaultValue={233}
                              />
                              <label htmlFor="radio_234" className>
                                <span className="outlet-name">
                                  1 Vista Exchange Green,
                                </span>
                                <span className="outlet-address">
                                  01-19,The Star Vista, Singapore 138617
                                </span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="input-group">
                              <input
                                id="radio_235"
                                name="outletsZone"
                                type="radio"
                                className="form-check-input"
                                defaultValue={233}
                              />
                              <label htmlFor="radio_235" className>
                                <span className="outlet-name">
                                  1 Vista Exchange Green,
                                </span>
                                <span className="outlet-address">
                                  01-19,The Star Vista, Singapore 138617
                                </span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="input-group">
                              <input
                                id="radio_236"
                                name="outletsZone"
                                type="radio"
                                className="form-check-input"
                                defaultValue={233}
                              />
                              <label htmlFor="radio_236" className>
                                <span className="outlet-name">
                                  1 Vista Exchange Green,
                                </span>
                                <span className="outlet-address">
                                  01-19,The Star Vista, Singapore 138617
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                        <button
                          type="button"
                          className="btn btn-secondary btn-block"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  */}
        <div
          className="modal fade"
          id="switchError"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document">
            <div className="modal-content bg-Black delivery-datetime-modal">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 popup_left">
                      <div className="delivery-address-block">
                        <img src="/img/warning-icon.png" alt="..." />
                        <h1 className="text-uppercase mt-2 mb-0">Warning !</h1>
                        {/*    <h1 className="font-azakie mb-0">We can Deliver !</h1> */}
                      </div>
                    </div>
                    <div className="col-lg-7 popup_right">
                      <div className="innervmid_in delivery-datetime-form-block text-center">
                        <p>By switching you are about to clear your cart.</p>
                        <p>Do you wish to proceed ?</p>
                        <div className="alt_btns">
                          <a
                            href="javascript:;"
                            data-dismiss="modal"
                            className="btn   btn-sm btn_black popup-modal-dismiss">
                            No
                          </a>
                          <a
                            href="javascript:;"
                            onClick={e =>
                              this.changeAvailability(e, '#switchError')
                            }
                            to="#"
                            className="btn btn-sm btn_yellow popup-modal-dismiss">
                            Yes
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  <div
          className="modal fade"
          id="switchError"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-content bg-Camelot subscribe-now-modal "  style={{ color : "#000"}}>
            <div className="modal-body">
              <div className="container-fluid form-block">
                <div className="row">
                  <div className="col-lg-12 form-block justify-content-center">
                    <div>
                      <div>
                        <div className="custom_alert">
                          <div className="custom_alertin">
                            <div className="alert_height">
                              <div className="alert_header">Warning</div>
                              <div className="alert_body">
                                <p>
                                  By switching you are about to clear your cart.
                                </p>
                                <p>Do you wish to proceed ?</p>
                                <div className="alt_btns">
                                  <a
                                    href="javascript:;"
                                    className="btn btn-sm btn_black popup-modal-dismiss"
                                  >
                                    No
                                  </a>
                                  <a
                                    href="javascript:;"  onClick={this.changeAvailability.bind(this)} 
                                    className="btn btn-sm btn_yellow popup-modal-dismiss"
                                  >
                                    Yes
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      */}

        {customerID == '' && <Account allProps={this.props} />}
        <Changepassword />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cart, settings, user }) => ({
  cartData: cart.returnData,
  cartStatus: cart.isStatus,
  settings: settings.returnData,
  setStatus: settings.isStatus,
  userData: user.returnData
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCartItems, getSetings }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
