import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import axios from 'axios'
import cookie from 'react-cookies'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from '../../store'
import { getReferenceID, getCookies, showInfo } from '../../config/supporters'
import { updateCustomerCurrentData, getFbLoginData } from '../../stores/user'
import FacebookLogin from 'react-facebook-login'
import loadingImage from '../../assets/img/spinner.gif'
import { APP_ID, API_URL, SITEURL, FB_APPID } from '../../config/constans'
import Signup from './Signup'
var dateFormat = require('dateformat')
const siteURL = SITEURL

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      loading: false,
      loginField: {},
      loginError: {},
      forgotField: {},
      forgotError: {},
      resetField: {},
      resetError: {},
      resetKey: '',
      guestFields: {},
      guestErrors: {}
    }

    this.submitForgotPassword = this.submitForgotPassword.bind(this)
    this.onChangeforgotPasswordField = this.onChangeforgotPasswordField.bind(
      this
    )
    this.handleChangeLogin = this.handleChangeLogin.bind(this)
    this.applyLoginValidation = this.applyLoginValidation.bind(this)

    this.submitResetPassword = this.submitResetPassword.bind(this)
    this.onChangeResetPassword = this.onChangeResetPassword.bind(this)
    //this.guestFormChange.bind = this.guestFormChange.bind();
  }
  handleClickCloseModal = (e, data) => {
    $(data).modal('hide')
    $(e.target.dataset.target).modal('hide')
    $(e.target.dataset.target).modal('show')
    $(e.target.dataset.target).on('shown.bs.modal', function(e) {
      $('body').addClass('modal-open')
    })
  }

  componentDidMount() {
    if (getCookies('resetId') !== '' && getCookies('resetStatus') === 'Yes') {
      $('#resetPasswordModalCenter').modal('show')
      this.setState({ resetKey: getCookies('resetId') })
      cookie.remove('resetId', { path: '/' })
      cookie.remove('resetStatus', { path: '/' })
    }
  }
  resetModalClose() {
    cookie.remove('resetId', { path: '/' })
    cookie.remove('resetStatus', { path: '/' })
    this.setState({ resetKey: '' })
  }

  applyLoginValidation() {
    if (this.handleLoginValidation()) {
      var qs = require('qs')
      var postObject = {
        app_id: APP_ID,
        type: 'web',
        login_username: this.state.loginField['login_user_name'],
        login_password: this.state.loginField['login_password']
      }
      $('#c_loading').show()
      axios
        .post(API_URL + 'api/customer/login', qs.stringify(postObject))
        .then(response => {
          $('.login_error').hide()
          if (response.data.status === 'ok') {
            this.setState({
              loginField: { login_username: '', login_password: '' }
            })

            var mobileno = ''
            if (
              response.data.result_set.customer_phone !== '' ||
              response.data.result_set.customer_phone !== null ||
              typeof response.data.result_set.customer_phone !== 'undefined'
            ) {
              mobileno = response.data.result_set.customer_phone
            }

            /* set User cookie values - Start */
            cookie.save('UserId', response.data.result_set.customer_id)
            cookie.save(
              'UserFname',
              response.data.result_set.customer_first_name !== ''
                ? response.data.result_set.customer_first_name
                : ''
            )
            cookie.save(
              'UserLname',
              response.data.result_set.customer_last_name !== ''
                ? response.data.result_set.customer_last_name
                : ''
            )
            cookie.save('UserMobile', mobileno)
            cookie.save('UserEmail', response.data.result_set.customer_email)
            cookie.save(
              'UserDefaultAddress',
              response.data.result_set.customer_address_name
            )
            cookie.save(
              'UserDefaultUnitOne',
              response.data.result_set.customer_address_line1
            )
            cookie.save(
              'UserDefaultUnitTwo',
              response.data.result_set.customer_address_line2
            )
            cookie.save(
              'UserDefaultPostalCode',
              response.data.result_set.customer_postal_code
            )
            /* set User cookie values - End */

            /* set redirection */

            /* upadte customer info */

            var qs = require('qs')
            var postObject = {
              app_id: APP_ID,
              reference_id: getReferenceID(),
              customer_id: response.data.result_set.customer_id,
              availability_id: getCookies('orderAvailability')
            }

            axios
              .post(
                API_URL + 'api/cart/update_customer_info',
                qs.stringify(postObject)
              )
              .then(res => {
                if (
                  res.data.status === 'ok' &&
                  cookie.load('reserVationClick') !== 'fromhome'
                ) {
                  /* check FB login function */
                  $('#c_loading').hide()
                  $('#signInModalCenter').modal('hide')
                  cookie.save('cartValue', 'Yes')
                  history.push('/checkout')
                } else {
                  $('#c_loading').hide()
                  $('#signInModalCenter').modal('hide')
                  history.push('/myaccount')
                  cookie.save('cartValue', 'No')
                }
              })
          } else {
            $('#c_loading').hide()
            if (typeof response.data.form_error !== 'undefined') {
              $('.login_error').show()
              $('.login_error').html(response.data.form_error)
              $('.login_error p').addClass('error-msg')
            } else {
              $('.login_error').show()
              $('.login_error').html(response.data.message)
            }
          }
          return false
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }

  submitForgotPassword() {
    if (this.validateForgotForm()) {
      var qs = require('qs')
      var postObject = {
        app_id: APP_ID,
        type: 'web',
        email_address: this.state.forgotField.forgot_email,
        site_url: siteURL
      }
      $('#c_loading').show()
      $('.forgot_error_div').hide()
      $('.forgot_error_p').html('')
      axios
        .post(
          API_URL + 'api/customer/forgot_password',
          qs.stringify(postObject)
        )
        .then(response => {
          $('#c_loading').hide()
          $('.forgot_error_div').show()
          this.setState({ forgotField: {} })
          if (response.data.status === 'ok') {
            $('.forgot_error_p').html(response.data.message)
          } else {
            $('.forgot_error_p').html(response.data.message)
          }
        })
    } else {
    }
  }

  /* submitResetPassword */
  submitResetPassword() {
    if (this.validateResetForm()) {
      var qs = require('qs')
      var postObject = {
        app_id: APP_ID,
        type: 'web',
        password: this.state.resetField.reset_password,
        confirmpassword: this.state.resetField.reset_c_password,
        key: this.state.resetKey
      }
      $('#c_loading').show()
      $('.reset_error_div').hide()
      $('.reset_error_p').html('')
      axios
        .post(API_URL + 'api/customer/resetpassword', qs.stringify(postObject))
        .then(response => {
          $('#c_loading').hide()
          $('.reset_error_div').show()
          this.setState({ forgotField: {} })
          if (response.data.status === 'ok') {
            $('.reset_error_p').html(response.data.message)
          } else {
            $('.reset_error_p').html(response.data.message)
          }
        })
    } else {
    }
  }

  validateForgotForm() {
    let fields = this.state.forgotField
    let errors = {}
    let formIsValid = true

    if (!fields['forgot_email']) {
      formIsValid = false
      errors['forgot_email'] = 'Email address field is required.'
    }

    if (typeof fields['forgot_email'] !== 'undefined') {
      let lastAtPos = fields['forgot_email'].lastIndexOf('@')
      let lastDotPos = fields['forgot_email'].lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['forgot_email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['forgot_email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors['forgot_email'] = 'Email address is not valid.'
      }
    }

    this.setState({ forgotError: errors })
    return formIsValid
  }

  /*validateResetForm */

  validateResetForm() {
    let fields = this.state.resetField
    let errors = {}
    let formIsValid = true

    if (!fields['reset_password']) {
      formIsValid = false
      errors['reset_password'] = 'Password field is required.'
    }
    if (typeof fields['reset_password'] !== 'undefined') {
      if (fields['reset_password'].length < 6) {
        formIsValid = false
        errors['reset_password'] =
          'Password must be of minimum 6 characters length.'
      }
    }

    if (!fields['reset_c_password']) {
      formIsValid = false
      errors['reset_c_password'] = 'Confirm Password field is required.'
    }

    if (typeof fields['reset_c_password'] !== 'undefined') {
      if (fields['reset_c_password'].length < 6) {
        formIsValid = false
        errors['reset_c_password'] =
          'Confirm Password must be of minimum 6 characters length'
      } else if (fields['reset_password'] !== fields['reset_c_password']) {
        formIsValid = false
        errors['reset_c_password'] =
          'Your password and confirmation password do not match.'
      }
    }
    console.log(errors)

    this.setState({ resetError: errors })
    return formIsValid
  }

  onChangeforgotPasswordField(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    let forgotField = this.state.forgotField
    forgotField[name] = value
    this.setState({ forgotField, forgotError: {} })
  }

  /* onChangeResetPassword */
  onChangeResetPassword(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    let resetField = this.state.resetField
    resetField[name] = value
    this.setState({ resetField, resetError: {} })
  }

  handleChangeLogin(field, e) {
    let loginField = this.state.loginField
    loginField[field] = e.target.value

    this.setState({ loginField, loginError: {} })
  }

  handleLoginValidation() {
    let fields = this.state.loginField
    let errors = {}
    let formIsValid = true

    if (!fields['login_user_name']) {
      formIsValid = false
      errors['login_user_name'] = 'User name field is required.'
    }

    if (typeof fields['login_user_name'] !== 'undefined') {
      let lastAtPos = fields['login_user_name'].lastIndexOf('@')
      let lastDotPos = fields['login_user_name'].lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['login_user_name'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['login_user_name'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors['login_user_name'] = 'User name is not valid.'
      }
    }

    if (!fields['login_password']) {
      formIsValid = false
      errors['login_password'] = 'Password field is required.'
    }
    if (typeof fields['login_password'] !== 'undefined') {
      if (fields['login_password'].length < 6) {
        formIsValid = false
        errors['login_password'] =
          'Password must be of minimum 6 characters length.'
      }
    }

    this.setState({ loginError: errors })
    return formIsValid
  }
  doLogin(fbloginData) {
    if (fbloginData.status === 'ok') {
      $('#signUpModalCenter').modal('hide')
      $('#signInModalCenter').modal('hide')
      var mobileno = ''
      if (
        typeof fbloginData.result_set.customer_phone === 'undefined' ||
        fbloginData.result_set.customer_phone === 'null' ||
        fbloginData.result_set.customer_phone === ''
      ) {
        mobileno = ''
      } else {
        mobileno = fbloginData.result_set.customer_phone
      }

      cookie.save('UserId', fbloginData.result_set.customer_id)
      cookie.save('UserEmail', fbloginData.result_set.customer_email)
      cookie.save(
        'UserFname',
        fbloginData.result_set.customer_first_name !== ''
          ? fbloginData.result_set.customer_first_name
          : ''
      )
      cookie.save(
        'UserLname',
        fbloginData.result_set.customer_last_name !== ''
          ? fbloginData.result_set.customer_last_name
          : ''
      )
      cookie.save('UserMobile', mobileno)

      cookie.save(
        'UserDefaultAddress',
        fbloginData.result_set.customer_address_name
      )
      cookie.save(
        'UserDefaultUnitOne',
        fbloginData.result_set.customer_address_line1
      )
      cookie.save(
        'UserDefaultUnitTwo',
        fbloginData.result_set.customer_address_line2
      )
      cookie.save(
        'UserDefaultPostalCode',
        fbloginData.result_set.customer_postal_code
      )

      const { history } = this.props

      var qs = require('qs')
      var postObject = {
        app_id: APP_ID,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id: getCookies('orderAvailability')
      }

      this.props.updateCustomerCurrentData(qs.stringify(postObject))
    } else {
      $('#signInModalCenter').modal('show')
      $('.login_error').show()
      $('.login_error').html('Invalid Login Credentials')
      $('.login_error p').addClass('error-msg')
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.fbloginData !== this.props.fbloginData) {
      this.doLogin(nextProps.fbloginData)
    }

    if (nextProps.customerUpdatedData !== this.props.customerUpdatedData) {
      if (nextProps.customerUpdatedData.status === 'ok') {
        cookie.save('cartValue', 'Yes')
        history.push('/checkout')
      } else {
        cookie.save('cartValue', 'No')
        history.push('/myaccount')
      }
    }
  }
  responseFacebook = response => {
    if (
      response.name !== '' &&
      response.email !== '' &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(' ')
      var firstname = vSplitName[0]
      var lastname = vSplitName[1]
      var birthday = response.birthday
      var qs = require('qs')
      var photo = response.picture.data.url
      /* update gender field */
      var postGender = ''
      if (
        typeof response.gender !== 'undefined' &&
        response.gender === 'male'
      ) {
        postGender = 'M'
      } else if (
        typeof response.gender !== 'undefined' &&
        response.gender === 'female'
      ) {
        postGender = 'F'
      }
      /* update DOB */
      var dob = ''
      if (typeof birthday !== 'undefined' && birthday !== '') {
        dob = dateFormat(response.birthday, 'yyyy-mm-dd')
      }

      var postObject = {
        app_id: APP_ID,
        type: 'web',
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob
      }

      this.props.getFbLoginData(qs.stringify(postObject))
    }
  }

  //gusest

  guestFormChange(field, e) {
    let guestFields = this.state.guestFields
    guestFields[field] = e.target.value
    this.setState({ guestFields, guestErrors: {} })
    if (field === 'guest-mobile') {
      var mobileNo = e.target.value
      if (mobileNo.length >= 7) {
        this.validateCustomer(mobileNo)
      }
    }
  }

  validateCustomer(mobileNo) {
    let mobileNumber = mobileNo

    axios
      .get(
        API_URL +
          'api/settings/get_customerdetail_phone/?app_id=' +
          APP_ID +
          '&status=A&phone_no=' +
          mobileNumber
      )
      .then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data.result_set)
          var firstName = res.data.result_set.customer_first_name
          var email = res.data.result_set.customer_email
          var mobileNumber = res.data.result_set.customer_phone
          $('.is-exist').removeClass('is-empty')
          $('.is-exist').addClass('is-focused')
          this.setState({
            guestFields: {
              'guest-name': firstName,
              'guest-email': email,
              'guest-mobile': this.state.guestFields['guest-mobile'],
              'guest-password': this.state.guestFields['guest-password'],
              'guest-retype-password': this.state.guestFields[
                'guest-retype-password'
              ]
            }
          })
        } else {
          /* $(".is-exist").addClass("is-empty");	
                     $(".is-exist").removeClass("is-focused");				 
                      this.setState({ guestFields : { "guest-mobile" : this.state.guestFields['guest-mobile'] , "guest-password" : this.state.guestFields['guest-password'], "guest-retype-password" : this.state.guestFields['guest-retype-password']}   });*/
        }
      })
  }

  handleValidation() {
    let fields = this.state.guestFields
    let errors = {}
    let formIsValid = true
    console.log(fields['guest-mobile'])
    console.log(this.state.guestFields)
    var guestMobile = fields['guest-mobile']
    if (!guestMobile) {
      formIsValid = false
      errors['guest-mobile'] = 'Mobile number field is required.'
    }

    if (typeof guestMobile !== 'undefined') {
      if (!guestMobile.match(/^[0-9]+$/)) {
        formIsValid = false
        errors['guest-mobile'] = 'Invalid Mobile number'
      } else if (guestMobile.length < 8) {
        formIsValid = false
        errors['guest-mobile'] =
          'Mobile number must be of minimum 8 characters length.'
      }
    }

    if (!fields['guest-name']) {
      formIsValid = false
      errors['guest-name'] = 'Name field is required.'
    }

    if (typeof fields['guest-name'] !== 'undefined') {
      if (!fields['guest-name'].match(/^[A-Za-z ]{3,20}$/)) {
        formIsValid = false
        errors['guest-name'] = 'Name must contain only letters.'
      }
    }

    if (!fields['guest-email']) {
      formIsValid = false
      errors['guest-email'] = 'Email field is required.'
    }

    if (typeof fields['guest-email'] !== 'undefined') {
      let lastAtPos = fields['guest-email'].lastIndexOf('@')
      let lastDotPos = fields['guest-email'].lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['guest-email'].indexOf('@@') == -1 &&
          lastDotPos > 2 &&
          fields['guest-email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors['guest-email'] = 'Email is not valid.'
      }
    }

    if (parseInt(this.state.guest_type) === 1) {
      if (!fields['guest-password']) {
        formIsValid = false
        errors['guest-password'] = 'Password field is required.'
      }
      if (typeof fields['guest-password'] !== 'undefined') {
        if (fields['guest-password'].length < 6) {
          formIsValid = false
          errors['guest-password'] =
            'Password must be of minimum 6 characters length.'
        }
      }

      if (!fields['guest-retype-password']) {
        formIsValid = false
        errors['guest-retype-password'] = 'Confirm Password field is required.'
      }

      if (typeof fields['guest-retype-password'] !== 'undefined') {
        if (fields['guest-retype-password'].length < 6) {
          formIsValid = false
          errors['guest-retype-password'] =
            'Confirm Password must be of minimum 6 characters length'
        } else if (
          fields['guest-password'] != fields['guest-retype-password']
        ) {
          formIsValid = false
          errors['guest-retype-password'] =
            'Your password and confirmation password do not match.'
        }
      }
    }

    this.setState({ guestErrors: errors })
    return formIsValid
  }

  applyValidation() {
    if (this.handleValidation()) {
      $('#loadIcon').show()

      var qs = require('qs')
      var postObject = {
        app_id: APP_ID,
        type: 'web',
        customer_first_name: this.state.guestFields['guest-name'],
        customer_last_name: '',
        customer_email: this.state.guestFields['guest-email'],
        customer_password: this.state.guestFields['guest-password'],
        customer_phone: this.state.guestFields['guest-mobile'],
        site_url: this.state.client_site_url + '#/'
      }
      axios
        .post(API_URL + 'api/gusestAccount/create', qs.stringify(postObject))
        .then(response => {
          $('#loadIcon').hide()
          $('.gue-comm-error').hide()
          if (response.data.status === 'ok') {
            if (parseInt(response.data.gusetStatus) === 1) {
              this.setGuestData(response)
            } else if (parseInt(response.data.gusetStatus) === 2) {
              this.setGuestData(response)
            }
            this.closeGuestToggle()
          } else {
            if (typeof response.data.form_error !== 'undefined') {
              $('.guest-form-error').show()
              $('.guest-form-error').html(response.data.form_error)
              $('.guest-form-error p').addClass('error-msg')
            } else {
              $('.guest-form-error').show()
              $('.guest-form-error-p').html(response.data.message)
            }
          }
          return false
        })
        .catch(function(error) {
          console.log(error)
        })
    } else {
      console.log('Form has errors.')
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Sign Up Modal */}
        <Signup {...this.props} />

        {/* Sign In Modal */}
        <div
          className="modal fade"
          id="signInModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-transparent sign-in-modal">
              <div className="modal-body">
                <div className="pop-whole d-flex flex-row-reverse">
                  <div className="pop-whole-lhs">
                    <div className="pop-whole-lhs-inner">
                      <h2 className="font-gothamlight text-uppercase">
                        Have an account ?
                      </h2>
                      <h2 className="font-azakie">
                        Login with your details to manage
                      </h2>
                    </div>
                  </div>
                  <form>
                    <div className="inside-popup-rhs">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="popup-header text-center">
                        <FacebookLogin
                          appId={FB_APPID}
                          fields="name,email,picture,first_name,last_name,birthday,gender"
                          callback={this.responseFacebook}
                          scope="public_profile,email"
                          cssClass="btn fb-button"
                          redirectUri={siteURL}
                          icon="fab fa-facebook-f"
                          textButton="&nbsp; Signup with Facebook "
                        />
                        <div className="or-seperator">
                          {' '}
                          <span>Or</span>
                        </div>
                        <h4 className="font-opnsns font-weight-light">
                          Login with <br />
                          <img src="/img/spring-court-text-logo.png" />
                        </h4>
                      </div>
                      <div className="alert-error guest-form-error gue-comm-error login_error">
                        <p className="error-msg guest-form-error-p login_error_p" />
                      </div>
                      <div className="popup-body">
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="text"
                              id="login_user_name"
                              onChange={this.handleChangeLogin.bind(
                                this,
                                'login_user_name'
                              )}
                              value={this.state.loginField['login_user_name']}
                              name="login_user_name"
                              className="form-control input-focus"
                              placeholder="Email Address"
                            />

                            <span
                              className={
                                this.state.loginError['login_user_name']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.loginError['login_user_name']}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="password"
                              name="login_password"
                              onChange={this.handleChangeLogin.bind(
                                this,
                                'login_password'
                              )}
                              value={this.state.loginField['login_password']}
                              className="form-control input-focus"
                              placeholder="Password"
                            />
                            <span
                              className={
                                this.state.loginError['login_password']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.loginError['login_password']}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="login_pop_sub">
                            <button
                              type="button"
                              onClick={this.applyLoginValidation.bind(this)}
                              className="btn btn-primary btn-block">
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="controls two-links">
                            <Link
                              data-target="#signUpModalCenter"
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#signInModalCenter'
                                )
                              }
                              to="#signUpModalCenter"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link">
                              Create an account{' '}
                            </Link>
                            <span className="spilter"> </span>
                            <Link
                              data-target="#forgetPasswordModalCenter"
                              onClick={e =>
                                this.handleClickCloseModal(
                                  e,
                                  '#signInModalCenter'
                                )
                              }
                              to="#forgetPasswordModalCenter"
                              data-effect="mfp-zoom-in"
                              className="open-popup-link">
                              Forgot Password{' '}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/*     <div className="guest-checkout-link">
                      <Link className="nav-link" data-toggle="modal" data-target="#guestCheckoutModalCenter" onClick={((e) => this.handleClickCloseModal(e, '#signInModalCenter'))} to="#guestCheckoutModalCenter" className="btn btn-secondary btn-block">No I want  to Continue As Guest Checkout</Link>
					  </div>   */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Forget Password  Modal */}
        <div
          className="modal fade"
          id="forgetPasswordModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-transparent sign-in-modal">
              <div className="modal-body">
                <div className="pop-whole d-flex flex-row-reverse">
                  <div className="pop-whole-lhs">
                    <div className="pop-whole-lhs-inner">
                      <h2 className="font-gothamlight text-uppercase">
                        Forgot your Password ?
                      </h2>
                      <h2 className="font-azakie">
                        Change your password using your email
                      </h2>
                    </div>
                  </div>

                  <div className="inside-popup-rhs">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="popup-header text-center">
                      {/* <h4 className="font-opnsns font-weight-light pt-md-5">
                        <img src="/img/spring-court-text-logo.png" alt="" />
                      </h4> */}
                    </div>
                    {/*guest-form-error gue-comm-error */}
                    <div className="alert-error forgot_error_div">
                      <p className="error-msg guest-form-error-p forgot_error_p" />
                    </div>
                    <div className="popup-body">
                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="text"
                            id="forgot_email"
                            onChange={this.onChangeforgotPasswordField}
                            value={this.state.forgotField['forgot_email']}
                            name="forgot_email"
                            className="form-control input-focus"
                            placeholder="Email Address"
                          />

                          <span
                            className={
                              this.state.forgotError['forgot_email']
                                ? 'error show'
                                : 'error hide'
                            }>
                            {this.state.forgotError['forgot_email']}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="login_pop_sub">
                          <button
                            type="button"
                            onClick={this.submitForgotPassword}
                            className="btn btn-primary btn-block">
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="controls two-links">
                          <Link
                            data-target="#signUpModalCenter"
                            onClick={e =>
                              this.handleClickCloseModal(
                                e,
                                '#forgetPasswordModalCenter'
                              )
                            }
                            to="#signUpModalCenter"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link">
                            Create an account{' '}
                          </Link>
                          <span className="spilter"> </span>
                          <Link
                            data-target="#signInModalCenter"
                            onClick={e =>
                              this.handleClickCloseModal(
                                e,
                                '#forgetPasswordModalCenter'
                              )
                            }
                            to="#signInModalCenter"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link">
                            LogIn{' '}
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/*    <div className="guest-checkout-link">
                      <Link className="nav-link" data-toggle="modal" data-target="#guestCheckoutModalCenter" onClick={((e) => this.handleClickCloseModal(e, '#signInModalCenter'))} to="#guestCheckoutModalCenter" className="btn btn-secondary btn-block">No I want  to Continue As Guest Checkout</Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* reset password */}
        <div
          className="modal fade"
          id="resetPasswordModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-transparent sign-in-modal">
              <div className="modal-body">
                <div className="pop-whole d-flex flex-row-reverse">
                  <div className="pop-whole-lhs">
                    <div className="pop-whole-lhs-inner">
                      <h2 className="font-gothamlight text-uppercase">
                        Reset your Password
                      </h2>
                      <h2 className="font-azakie">Choose a new password</h2>
                    </div>
                  </div>

                  <div className="inside-popup-rhs">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.resetModalClose.bind(this)}
                    />
                    <div className="popup-header text-center">
                      <h4 className="font-opnsns font-weight-light pt-md-5">
                        <img src="/img/spring-court-text-logo.png" alt="" />
                      </h4>
                    </div>
                    {/*guest-form-error gue-comm-error */}
                    <div className="alert-error reset_error_div">
                      <p className="error-msg guest-form-error-p reset_error_p" />
                    </div>
                    <div className="popup-body">
                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="password"
                            id="reset_password"
                            onChange={this.onChangeResetPassword}
                            value={this.state.resetField['reset_password']}
                            name="reset_password"
                            className="form-control input-focus"
                            placeholder="Enter new password"
                          />

                          <span
                            className={
                              this.state.resetError['reset_password']
                                ? 'error show'
                                : 'error hide'
                            }>
                            {this.state.resetError['reset_password']}
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="password"
                            id="reset_c_password"
                            onChange={this.onChangeResetPassword}
                            value={this.state.resetField['reset_c_password']}
                            name="reset_c_password"
                            className="form-control input-focus"
                            placeholder="Enter confirm new password"
                          />

                          <span
                            className={
                              this.state.resetError['reset_c_password']
                                ? 'error show'
                                : 'error hide'
                            }>
                            {this.state.resetError['reset_c_password']}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="login_pop_sub">
                          <button
                            type="button"
                            onClick={this.submitResetPassword}
                            className="btn btn-primary btn-block">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*    <div className="guest-checkout-link">
                      <Link className="nav-link" data-toggle="modal" data-target="#guestCheckoutModalCenter" onClick={((e) => this.handleClickCloseModal(e, '#signInModalCenter'))} to="#guestCheckoutModalCenter" className="btn btn-secondary btn-block">No I want  to Continue As Guest Checkout</Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Guest Checkout Modal */}
        <div
          className="modal fade"
          id="guestCheckoutModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-transparent sign-up-modal">
              <div className="modal-body">
                <div className="pop-whole d-flex flex-row-reverse">
                  <div className="pop-whole-lhs">
                    <div className="pop-whole-lhs-inner">
                      <h2 className="font-gothamlight text-uppercase">
                        New to <br />
                        Spring Court ?
                      </h2>
                      <h2 className="font-azakie">
                        Create your Spring Court account.
                      </h2>
                    </div>
                  </div>
                  <div className="inside-popup-rhs">
                    <Link to="#" className="btn-link go-back-link">
                      <i className="fas fa-chevron-left" /> Back
                    </Link>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    />

                    <div className="popup-body mt-5">
                      <h4>Guest Chekcout Details</h4>

                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="tel"
                            maxLength={8}
                            className="form-control input-focus"
                            pattern="\d*"
                            placeholder="Mobile Numer"
                            name="guest-mobile"
                            onChange={this.guestFormChange.bind(
                              this,
                              'guest-mobile'
                            )}
                            value={this.state.guestFields['guest-mobile']}
                          />
                        </div>

                        <span
                          className={
                            this.state.guestErrors['guest-mobile']
                              ? 'error show'
                              : 'error hide'
                          }>
                          {this.state.guestErrors['guest-mobile']}
                        </span>
                      </div>

                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="text"
                            className="form-control input-focus"
                            placeholder="First Name"
                            name="guest-fname"
                            onChange={this.guestFormChange.bind(
                              this,
                              'guest-fname'
                            )}
                            value={this.state.guestFields['guest-fname']}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="text"
                            className="form-control input-focus"
                            placeholder="Last Name"
                            name="guest-lname"
                            onChange={this.guestFormChange.bind(
                              this,
                              'guest-lname'
                            )}
                            value={this.state.guestFields['guest-lname']}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            name="guest-email"
                            onChange={this.guestFormChange.bind(
                              this,
                              'guest-email'
                            )}
                            value={this.state.guestFields['guest-email']}
                          />
                        </div>
                      </div>

                      {/*<div className="form-group">
                        <div className="focus-out">
                          <input
                            type="text"
                            className="form-control input-focus"
                            placeholder="Postal Code"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <input
                            type="text"
                            className="form-control input-focus"
                            placeholder="Address Line"
                          />
                        </div>
					 </div> */}

                      <div className="form-group">
                        <div className="custom_checkbox custom_checkbox_content">
                          <input type="checkbox" id="terms" /> <span />
                          <div className="sign_reg">
                            <p>
                              I consent to receive coupons and promotions news
                              from Spring Court, its franchisees and affiliates,
                              and understand and agree that my information will
                              be used as described here and in Spring Court
                              Privacy Policy and Terms of Service.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="login_pop_sub">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={this.applyValidation.bind(this)}>
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  customerUpdatedData: user.customerUpdatedData,
  fbloginData: user.fbloginData
})
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        updateCustomerCurrentData,
        getFbLoginData
      },
      dispatch
    )
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)
