import axios from 'axios'
import { API_URL, APP_ID, OUTLET_ID, PICKUP_ID } from '../config/constans'
import { getCookies } from '../config/supporters'
export const INCREMENT_REQUESTED = 'counter/INCREMENT_REQUESTED'
export const INCREMENT = 'counter/INCREMENT'
export const GETOUTLETS = 'counter/GETOUTLETS'
export const GETBANNERS = 'counter/GETBANNERS'
export const GETFEATUREPRODUCTS = 'counter/GETFEATUREPRODUCTS'
export const GETADDONPRODUCTS = 'counter/GETADDONPRODUCTS'
export const GETPAGE = 'GETPAGE'
var _ = require('lodash')
const initialState = {
  staticblocks: [],
  outlets: [],
  banners: [],
  cmsFetch: false,
  pageStatus: null,
  pages: []
}

export default (state = initialState, action) => {
  //console.log(action);
  switch (action.type) {
    case INCREMENT_REQUESTED:
      return {
        ...state,
        cmsFetch: true
      }
    case INCREMENT:
      return {
        ...state,
        staticblocks: action.staticblocks,
        cmsFetch: !state.cmsFetch
      }
    case GETOUTLETS:
      return {
        ...state,
        outlets: action.outlets
      }
    case GETBANNERS:
      return {
        ...state,
        banners: action.banners
      }
    case GETFEATUREPRODUCTS:
      return {
        ...state,
        prCommon: action.prCommon,
        products: action.products
      }
    case GETADDONPRODUCTS:
      return {
        ...state,
        adCommon: action.adCommon,
        adproducts: action.adproducts
      }
    case GETPAGE:
      return {
        ...state,
        pageStatus: action.pageStatus,
        pages: action.pages
      }
    default:
      return state
  }
}

export const getPage = (page = null) => {
  let API = API_URL + 'api/cms/page'
  return function(dispatch) {
    axios
      .get(`${API}/?app_id=` + APP_ID + '&page_slug=' + page)
      .then(response => {
        let sts = 'error'
        let data = []
        if (response.data.status === 'ok') {
          sts = response.data.status
          data = response.data.result_set[0]
        }

        dispatch({
          type: GETPAGE,
          pageStatus: sts,
          pages: data
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getblocks = () => {
  let API = API_URL + 'apiv2/cms/staticblocks'
  return function(dispatch) {
    dispatch({
      type: INCREMENT_REQUESTED
    })
    axios
      .get(`${API}/?app_id=` + APP_ID)
      .then(response => {
        dispatch({
          type: INCREMENT,
          staticblocks: response.data.result_set
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getOutlets = () => {
  let API = API_URL + 'api/outlets/pickup_outlets'
  return function(dispatch) {
    axios
      .get(`${API}/?app_id=` + APP_ID + '&outlet_id=' + OUTLET_ID)
      .then(response => {
        dispatch({
          type: GETOUTLETS,
          outlets: response.data.result_set
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getBanners = () => {
  let API = API_URL + 'api/cms/banner'
  return function(dispatch) {
    axios
      .get(`${API}/?app_id=` + APP_ID)
      .then(response => {
        dispatch({
          type: GETBANNERS,
          banners: response.data.result_set
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getFeatureProducts = () => {
  let API = API_URL + 'apiV3/products/highlight_products_list'
  let avId =
    getCookies('orderAvailability') !== ''
      ? getCookies('orderAvailability')
      : PICKUP_ID
  return function(dispatch) {
    axios
      .get(`${API}/?app_id=` + APP_ID + '&availability=' + avId)
      .then(response => {
        dispatch({
          type: GETFEATUREPRODUCTS,
          prCommon: response.data.common,
          products: response.data.result_set
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getAdonProducts = () => {
  let API = API_URL + 'apiv2/products/getAllProducts'
  let avId =
    getCookies('orderAvailability') !== ''
      ? getCookies('orderAvailability')
      : PICKUP_ID
  return function(dispatch) {
    axios
      .get(
        `${API}/?app_id=` +
          APP_ID +
          '&availability=' +
          avId +
          '&apply_addon=Yes'
      )
      .then(response => {
        var subcateDetails = []
        if (_.isEmpty(response.data.result_set[0]) === false) {
          let subcate = response.data.result_set[0]
          subcateDetails = subcate.subcategorie
        }

        dispatch({
          type: GETADDONPRODUCTS,
          adCommon: response.data.common,
          adproducts: subcateDetails
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}
