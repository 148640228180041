import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/app.css'
import './assets/css/custom.css'
import './assets/css/app-responsive.css'
import './assets/css/custom-responsive.css'

const target = document.querySelector('#root')
var baseWindowHeight = Math.max(window.innerHeight)
var classAdded = false
var documentBody = document.body
document.addEventListener('scroll', function(e) {
  var newWindowHeight = Math.max(window.innerHeight)
  if (newWindowHeight - 50 > baseWindowHeight) {
    if (!document.body.classList.contains('ios-toolbar-gone')) {
      document.body.classList.add('ios-toolbar-gone')
    }
  } else {
    if (document.body.classList.contains('ios-toolbar-gone')) {
      document.body.classList.remove('ios-toolbar-gone')
    }
  }
})
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)
