import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import noImage from '../../assets/img/product-no-image.jpg'
import {
  API_URL,
  APP_ID,
  PICKUP_ID,
  DINEIN_ID,
  DELIVERY_ID,
  deliveryText
} from '../../config/constans'
import { addressFormat } from '../../config/supporters'
import { customerId } from '../../config/supporters'
var _ = require('lodash')
var parse = require('html-react-parser')
const filterCurrentOrder = 'currentorder'
const filterPastOrder = 'pastorder'
class Myorders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultAvilablity: DELIVERY_ID,
      orders: [],
      status: 'Loading',
      pastorders: [],
      orderscount: 0,
      pastorderscount: 0,
      windowHeightNormal: 0,
      windowHeight: 0,
      currentOrders: [],
      currentorderlimit: 25,
      pastorderslimit: 25,
      current_order_message: '',
      past_order_message: ''
    }
    this.updateOrderDetails = this.updateOrderDetails.bind(this)
    this.handleChangeOdersLimit = this.handleChangeOdersLimit.bind(this)
    this.changeAv = this.changeAv.bind(this)
  }

  changeAv(avId) {
    if (avId !== this.state.defaultAvilablity) {
      this.setState({ defaultAvilablity: avId })
      this.showAllOrders(avId)
    }
  }
  updateOrderDetails(orders) {
    this.setState({ currentOrders: orders })
  }

  componentWillMount() {
    this.showAllOrders(DELIVERY_ID)
  }
  handleChangeOdersLimit(field, e) {
    if (field === filterPastOrder) {
      this.setState({ pastorderslimit: e.target.value })
    }
    if (field === filterCurrentOrder) {
      this.setState({ currentorderlimit: e.target.value })
    }
    this.showAllOrders(this.state.defaultAvilablity, e.target.value, field)
  }
  /* show all orders */
  showAllOrders(avIdValue, limit, field) {
    const avId =
      avIdValue !== '' &&
      typeof avIdValue !== undefined &&
      typeof avIdValue !== 'undefined'
        ? avIdValue
        : this.state.defaultAvilablity
    const orderLimit =
      limit !== '' && field === filterCurrentOrder
        ? limit
        : this.state.currentorderlimit
    axios
      .get(
        API_URL +
          'apiV3/reports/order_history?except_availability=yes&order_status=C&app_id=' +
          APP_ID +
          '&customer_id=' +
          customerId() +
          '&availabilityIDs=' +
          avId +
          '&limit=' +
          orderLimit
      )

      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({
            orders: res.data.result_set,
            orderscount: 1,
            current_order_message: ''
          })
          this.setState({ status: 'ok' })
        } else {
          this.setState({
            orderscount: 0,
            orders: '',
            current_order_message: res.data.message
          })
          this.setState({ status: 'ok' })
        }
      })
    const pastOrderLimit =
      limit !== '' && field === filterPastOrder
        ? limit
        : this.state.currentorderlimit
    axios
      .get(
        API_URL +
          'apiV3/reports/order_history?except_availability=yes&order_status=P&app_id=' +
          APP_ID +
          '&customer_id=' +
          customerId() +
          '&availabilityIDs=' +
          avId +
          '&limit=' +
          pastOrderLimit
      )
      .then(res => {
        /* setTimeout(
          function() {
            this.showAllOrders()
          }.bind(this),
          10000
        ) */
        if (res.data.status === 'ok') {
          this.setState({
            pastorders: res.data.result_set,
            pastorderscount: 1,
            past_order_message: ''
          })
        } else {
          this.setState({
            pastorderscount: 1,
            past_order_message: res.data.message
          })
        }
      })
  }

  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['order_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name']
        var modPrice =
          modifiers[i]['modifiers_values'][0]['order_modifier_price']
        var modPricedisplay = modPrice > 0 ? ' ( +$' + modPrice + ' )' : ''
        html +=
          "<li className='sub-item'>" +
          modName +
          ' : ' +
          modval +
          modPricedisplay +
          '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (_.isEmpty(comboLen) === true && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        //console.log(combos[0]['modifiers']);
        var comboPro = combos[r]['menu_product_name']
        var comboQty = combos[r]['menu_product_qty']
        var comboPrice = combos[r]['menu_product_price']
        var newPrice = comboPrice > 0 ? ' ( +$' + comboPrice + ' )' : ''
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['order_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['order_modifier_price']
        var newModValPrice = modValPrice > 0 ? ' (+' + modValPrice + ')' : ''

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }

  render() {
    const cOrders = this.state.orders
    const pastorders = this.state.pastorders
    const current_orders = this.state.currentOrders
    const cartItems =
      typeof current_orders.items !== 'undefined' ? current_orders.items : []

    return (
      <React.Fragment>
        <div className="myaccount-orders-options">
          <ul className="order-option-list">
            <li className="order-option-tem delivery">
              <a
                data-toggle="modal"
                className={
                  this.state.defaultAvilablity === DELIVERY_ID
                    ? 'btn btn-light active'
                    : 'btn btn-light '
                }
                onClick={this.changeAv.bind(this, DELIVERY_ID)}>
                <span className="order-icon" />
                {deliveryText}
              </a>
            </li>
            {/* <li className="order-option-tem dine-in">
              <a
                data-toggle="modal"
                className={
                  this.state.defaultAvilablity === DINEIN_ID
                    ? 'btn btn-light active'
                    : 'btn btn-light '
                }
                onClick={this.changeAv.bind(this, DINEIN_ID)}>
                <span className="order-icon" />
                Dine In
              </a>
            </li> */}
            <li className="order-option-tem self-collection">
              <span>
                <a
                  onClick={this.changeAv.bind(this, PICKUP_ID)}
                  className={
                    this.state.defaultAvilablity === PICKUP_ID
                      ? 'btn btn-light active'
                      : 'btn btn-light '
                  }>
                  <span className="order-icon" />
                  Self Collection
                </a>
              </span>
            </li>
          </ul>
        </div>

        <div className="myaccount-orders-block">
          <ul
            className="nav nav-tabs nav-justified"
            id="myAccountOrderTab"
            role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="dineinCurrentOrdersList-tab"
                data-toggle="tab"
                href="#dineinCurrentOrdersList"
                role="tab"
                aria-controls="dineinCurrentOrdersList"
                aria-selected="true">
                <span>Current Orders</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="dineinPastOrdersList-tab"
                data-toggle="tab"
                href="#dineinPastOrdersList"
                role="tab"
                aria-controls="dineinPastOrdersList"
                aria-selected="false">
                <span>Past Orders</span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myAccountOrderTabContent">
            <div
              className="tab-pane fade show active"
              id="dineinCurrentOrdersList"
              role="tabpanel"
              aria-labelledby="dineinCurrentOrdersList">
              <div className="row order-item-list">
                {_.isEmpty(cOrders) === false && (
                  <div className="col-12 order-item-filter-block">
                    <div className="order-filter-option">
                      <span className="filter-text-lbl">Show</span>
                      <div className="custom-select-control orders-item-filter-dropdown">
                        <select
                          className="form-control"
                          id="filtercurrentordercount"
                          onChange={this.handleChangeOdersLimit.bind(
                            this,
                            filterCurrentOrder
                          )}>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span className="filter-text-lbl">Per Page</span>
                    </div>
                  </div>
                )}
                {_.isEmpty(cOrders) === false &&
                  cOrders.map((corders, cindex) => (
                    <div
                      className="col-12 col-sm-12 col-md-12 col-lg-6 order-item-colm"
                      key={cindex}>
                      <div className="order-details-item-block order-info-block">
                        <div className="checkout-block-header">
                          <h4 className="font-opnsns text-left text-uppercase">
                            Order No - {corders.order_local_no}
                          </h4>
                          <span className="order-status d-block text-uppercase float-right">
                            {corders.status_name}
                          </span>
                        </div>
                        <div className="order-info text-center">
                          <p>
                            Order placed at :
                            {moment(corders.order_created_on).format(
                              'DD/MM/YYYY hh:mm A'
                            )}{' '}
                          </p>
                          <p>Pay by : {corders.order_method_name}</p>
                        </div>
                        <div className="delivery-info">
                          <div className="slfcol-form-block">
                            <div className="delivery-scotter-location">
                              <span className="white-line" />
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6 pl-0">
                                <div className="media">
                                  <div className="media-body">
                                    <h6 className="mt-0">Order handled By</h6>
                                    <p className="outlet-info">
                                      {' '}
                                      {corders.outlet_name} -{' '}
                                      {addressFormat(
                                        corders.outlet_unit_number1,
                                        corders.outlet_unit_number2,
                                        corders.outlet_address_line1,
                                        corders.outlet_address_line2,
                                        corders.outlet_postal_code
                                      )}{' '}
                                    </p>
                                    <div className="event-info-row mt-4">
                                      <h6 className="mt-0 font-opnsns font-weight-bold">
                                        {corders.order_availability_name} Date &
                                        Time
                                      </h6>
                                      <h5 className="mt-0 font-opnsns font-weight-bold">
                                        {moment(corders.order_date).format(
                                          'DD/MM/YYYY hh:mm A'
                                        )}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 pr-0">
                                <div className="media">
                                  <div className="media-body text-right">
                                    <h6 className="mt-0">
                                      {corders.order_availability_name} Location
                                    </h6>
                                    {corders.order_availability_name ===
                                    deliveryText ? (
                                      addressFormat(
                                        corders.order_customer_unit_no1,
                                        corders.order_customer_unit_no2,
                                        corders.order_customer_address_line1,
                                        corders.order_customer_address_line2,
                                        corders.order_customer_postal_code
                                      )
                                    ) : (
                                      <p className="outlet-info">
                                        {' '}
                                        {corders.outlet_name} -{' '}
                                        {addressFormat(
                                          corders.outlet_unit_number1,
                                          corders.outlet_unit_number2,
                                          corders.outlet_address_line1,
                                          corders.outlet_address_line2,
                                          corders.outlet_postal_code
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="order-amount-row row">
                          <div className="col-6 pr-0">
                            <p className="float-left">Order Amount</p>
                          </div>
                          <div className="col-6 pl-0">
                            <p className="float-right">
                              $ {corders.order_total_amount}
                            </p>
                          </div>
                        </div>

                        <div className="checkout-btn-block row">
                          <div className="col-sm-6 pr-sm-0">
                            <button
                              type="button"
                              className="btn-back btn btn-primary">
                              {/*Print Invoice|*/}
                            </button>
                          </div>
                          <div className="col-sm-6 pl-sm-0">
                            <Link
                              data-toggle="modal"
                              data-target="#orderDetailsModalCenter"
                              to="#orderDetailsModalCenter"
                              className="btn-checkout btn btn-primary"
                              onClick={this.updateOrderDetails.bind(
                                this,
                                corders
                              )}>
                              {' '}
                              Recipt
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {this.state.current_order_message !== '' && (
                  <div>{this.state.current_order_message}</div>
                )}
              </div>
            </div>
            <div
              className="tab-pane fade show"
              id="dineinPastOrdersList"
              role="tabpanel"
              aria-labelledby="dineinPastOrdersList">
              <div className="row order-item-list">
                {_.isEmpty(pastorders) === false && (
                  <div className="col-12 order-item-filter-block">
                    <div className="order-filter-option">
                      <span className="filter-text-lbl">Show</span>
                      <div className="custom-select-control orders-item-filter-dropdown">
                        <select
                          className="form-control"
                          id="filterpastordercount"
                          onChange={this.handleChangeOdersLimit.bind(
                            this,
                            filterPastOrder
                          )}>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span className="filter-text-lbl">Per Page</span>
                    </div>
                  </div>
                )}
                {_.isEmpty(pastorders) === false &&
                  pastorders.map((corders, cindex) => (
                    <div
                      className="col-12 col-sm-12 col-md-12 col-lg-6 order-item-colm"
                      key={cindex}>
                      <div className="order-details-item-block order-info-block">
                        <div className="checkout-block-header">
                          <h4 className="font-opnsns text-left text-uppercase">
                            Order No - {corders.order_local_no}
                          </h4>
                          <span className="order-status d-block text-uppercase float-right">
                            {corders.status_name}
                          </span>
                        </div>
                        <div className="order-info text-center">
                          <p>
                            Order placed at :
                            {moment(corders.order_created_on).format(
                              'DD/MM/YYYY hh:mm A'
                            )}{' '}
                          </p>
                          <p>Pay by : {corders.order_method_name}</p>
                        </div>
                        <div className="delivery-info">
                          <div className="slfcol-form-block">
                            <div className="delivery-scotter-location">
                              <span className="white-line" />
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6 pl-0">
                                <div className="media">
                                  <div className="media-body">
                                    <h6 className="mt-0">Order handled By</h6>
                                    <p className="outlet-info">
                                      {' '}
                                      {corders.outlet_name} -{' '}
                                      {addressFormat(
                                        corders.outlet_unit_number1,
                                        corders.outlet_unit_number2,
                                        corders.outlet_address_line1,
                                        corders.outlet_address_line2,
                                        corders.outlet_postal_code
                                      )}{' '}
                                    </p>
                                    <div className="event-info-row mt-4">
                                      <h6 className="mt-0 font-opnsns font-weight-bold">
                                        {' '}
                                        {corders.order_availability_name} Date &
                                        Time
                                      </h6>
                                      <h5 className="mt-0 font-opnsns font-weight-bold">
                                        {moment(corders.order_date).format(
                                          'DD/MM/YYYY hh:mm A'
                                        )}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 pr-0">
                                <div className="media">
                                  <div className="media-body text-right">
                                    <h6 className="mt-0">
                                      {corders.order_availability_name} Location
                                    </h6>
                                    <p className="outlet-info">
                                      {' '}
                                      {corders.outlet_name} -{' '}
                                      {addressFormat(
                                        corders.outlet_unit_number1,
                                        corders.outlet_unit_number2,
                                        corders.outlet_address_line1,
                                        corders.outlet_address_line2,
                                        corders.outlet_postal_code
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="order-amount-row row">
                          <div className="col-6 pr-0">
                            <p className="float-left">Order Amount</p>
                          </div>
                          <div className="col-6 pl-0">
                            <p className="float-right">
                              $ {corders.order_total_amount}
                            </p>
                          </div>
                        </div>

                        <div className="checkout-btn-block row">
                          <div className="col-sm-6 pr-sm-0">
                            <button
                              type="button"
                              className="btn-back btn btn-primary">
                              {/*Print Invoice|*/}
                            </button>
                          </div>
                          <div className="col-sm-6 pl-sm-0">
                            <Link
                              data-toggle="modal"
                              data-target="#orderDetailsModalCenter"
                              to="#orderDetailsModalCenter"
                              className="btn-checkout btn btn-primary"
                              onClick={this.updateOrderDetails.bind(
                                this,
                                corders
                              )}>
                              {' '}
                              View Recipt
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {this.state.past_order_message !== '' && (
                  <div>{this.state.past_order_message}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* View Orders Info Modal */}
        <div
          className="modal fade"
          id="orderDetailsModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document">
            <div className="modal-content bg-Camelot">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="order-details-item-block order-info-block">
                  <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                    Order Details
                  </h4>
                  <div className="order-info text-center">
                    <h4 className="font-gothamlight  text-center text-uppercase">
                      Order No - {current_orders.order_local_no}
                    </h4>
                    <p>
                      Order placed at :{' '}
                      {moment(current_orders.order_created_on).format(
                        'DD/MM/YYYY hh:mm A'
                      )}
                    </p>
                    <p>Pay by : {current_orders.order_method_name}</p>
                  </div>
                  <div className="delivery-info">
                    <div className="slfcol-form-block">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="media">
                            <img
                              className="mr-3"
                              src="./img/take-away.png"
                              alt="Generic placeholder "
                            />
                            {current_orders.order_availability_id ===
                            DELIVERY_ID ? (
                              <div className="media-body">
                                <h6 className="mt-0">
                                  {deliveryText}
                                  LOCATION{' '}
                                </h6>
                                <p className="outlet-info">
                                  {addressFormat(
                                    current_orders.order_customer_unit_no1,
                                    current_orders.order_customer_unit_no2,
                                    current_orders.order_customer_address_line1,
                                    current_orders.order_customer_address_line2,
                                    current_orders.order_customer_postal_code
                                  )}
                                </p>
                              </div>
                            ) : (
                              <div className="media-body">
                                <h6 className="mt-0">
                                  {' '}
                                  {current_orders.order_availability_name}{' '}
                                  LOCATION{' '}
                                </h6>
                                <p className="outlet-info">
                                  {' '}
                                  {current_orders.outlet_name} -{' '}
                                  {addressFormat(
                                    current_orders.outlet_unit_number1,
                                    current_orders.outlet_unit_number2,
                                    current_orders.outlet_address_line1,
                                    current_orders.outlet_address_line2,
                                    current_orders.outlet_postal_code
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="media">
                            <div className="media-body">
                              <h6 className="mt-0">
                                {current_orders.order_availability_name} Date &
                                Time
                              </h6>
                              <p className="outlet-info">
                                {moment(current_orders.order_date).format(
                                  'DD/MM/YYYY hh:mm A'
                                )}
                              </p>
                            </div>
                            <img
                              className="mr-3"
                              src="./img/alarm-clock.png"
                              alt="Generic placeholder "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cart-items-block">
                    {cartItems.map((item, index) => (
                      <div className="cart-row">
                        <div className="row">
                          <div className="col-7">
                            <div className="cart-left">
                              <div className="cart-img">
                                <img
                                  src={
                                    item.item_image !== ''
                                      ? item.item_image
                                      : noImage
                                  }
                                  width={110}
                                  height={67}
                                  alt
                                />
                              </div>
                              <div className="cart-info">
                                <h6>
                                  {item.item_qty} X {item.item_name}
                                </h6>
                                {this.loadModifierItems(
                                  item.cart_item_type,
                                  item.modifiers,
                                  item.set_menu_component
                                )}
                                {/*  <div className="cart-addon-info">
                                <span className="d-inline-block">Size:</span><span className="d-inline-block font-italic"> Large</span>

                              </div>
                              <div className="cart-addon-info">
                                <span className="d-inline-block">Add on:</span><span className="d-inline-block font-italic"> Name</span>

                              </div> */}
                              </div>
                            </div>
                            <p className="cart-special-text">
                              {item.item_specification}
                            </p>
                          </div>
                          <div className="col-5 text-right">
                            <div className="cart-right ">
                              <div className="cart-price">
                                <p>${item.item_total_amount}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="calctrcart-footer">
                      <div className="calctrcart-row">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">SUBTOTAL</p>
                          </div>
                          <div className="col-4 text-right">
                            <span>${current_orders.order_sub_total}</span>
                          </div>
                        </div>
                      </div>

                      {current_orders.order_service_charge > 0 &&
                        current_orders.order_service_charge_amount && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  Service Charge (
                                  {current_orders.order_service_charge}%)
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${current_orders.order_service_charge_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      {current_orders.order_delivery_charge > 0 &&
                        current_orders.order_delivery_charge && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  Delivery Charge
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${current_orders.order_delivery_charge}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      {current_orders.order_additional_delivery > 0 &&
                        current_orders.order_additional_delivery && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  Additional Delivery Charge
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${current_orders.order_additional_delivery}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      {current_orders.order_tax_charge > 0 &&
                        current_orders.order_tax_calculate_amount && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  GST ({current_orders.order_tax_charge}%)
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${current_orders.order_tax_calculate_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      {current_orders.order_discount_apply_grand_total !==
                        '1' &&
                        current_orders.order_discount_amount > 0 &&
                        current_orders.order_discount_applied === 'Yes' && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  DISCOUNT (
                                  {current_orders.order_promocode_name})
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  - ${current_orders.order_discount_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="calctrcart-row calctrcart-footer-totrow">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">Total</p>
                          </div>
                          <div className="col-4 text-right">
                            <span>${current_orders.order_total_amount}</span>
                          </div>
                        </div>
                      </div>

                      {/* current_orders.order_discount_apply_grand_total ===
                        '1' && */
                      current_orders.order_promocode_name !== '' && (
                        <div className="calctrcart-row ">
                          <div className="row">
                            <div className="col-8">
                              <p className="text-uppercase">
                                DISCOUNT ({current_orders.order_promocode_name})
                              </p>
                            </div>
                            {current_orders.order_discount_applied === 'Yes' &&
                              current_orders.order_discount_amount > 0 && (
                                <div className="col-4 text-right">
                                  <span>
                                    - ${current_orders.order_discount_amount}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="check-order-status-btn-block acc-order-info">
                    <button className="btn btn-secondary btn-block">
                      Order Status: {current_orders.status_name}
                    </button>
                  </div>
                </div>
              </div>

              <div className="modal-footer" />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const DropdownIndicator = () => (
  <div className="react-select-dropdown">
    <div className="react-select-arrow" />
  </div>
)
export default Myorders
