import axios from "axios";
import { API_URL, APP_ID } from "../config/constans";
import { customerId } from "../config/supporters";
export const GETUSERDATA = "GETUSERDATA";
export const GETPROMOCOUNT = "GETPROMOCOUNT";
export const REQUEST_FBLOGIN_DATA = "REQUEST_FBLOGIN_DATA";
export const CUSTOMER_UPDATE_DATA = "CUSTOMER_UPDATE_DATA";
var qs = require("qs");

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null,
  rewardCount: 0,
  promoCount: 0,
  fbloginData: [],
  customerUpdatedData: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETUSERDATA:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false,
        commoData: action.commoData
      };

    case GETPROMOCOUNT:
      return {
        ...state,
        rewardCount: action.rewardCount,
        promoCount: action.promoCount
      };
    case REQUEST_FBLOGIN_DATA:
      return {
        ...state,
        fbloginData: action.fbloginData,
      }
    case CUSTOMER_UPDATE_DATA:
      return {
        ...state,
        customerUpdatedData: action.customerUpdatedData,
      }
    default:
      return state;
  }
}
export function getUserData(pageslug = null) {
  const FI_API_URL = API_URL + "api/customer/customerdetail";

  const customer_Id = customerId();

  return function (dispatch) {
    axios
      .get(
        `${FI_API_URL}/?app_id=` +
        APP_ID +
        "&status=A" +
        "&refrence=" +
        customer_Id
      )
      .then(response => {
        dispatch({
          type: GETUSERDATA,
          returnData: response.data.result_set,
          commoData: response.data.common,
          isStatus: response.data.status,
          isFetching: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function getPromoCount(pageslug = null) {
  //let FI_API_URL = API_URL + "api/reports/activity_counts1";

  let customer_Id = customerId();

  const inputKeys = [
    "notify",
    "reward",
    "promotion",
    "order",
    "order_all",
    "reward_monthly"
  ];
  var postObject = {};
  postObject = {
    app_id: APP_ID,
    customer_id: customer_Id,
    act_arr: JSON.stringify(inputKeys)
  };

  return function (dispatch) {
    axios
      .post(API_URL + "api/reports/activity_counts", qs.stringify(postObject))
      .then(response => {
        dispatch({
          type: GETPROMOCOUNT,
          rewardCount: response.data.count.reward_ponits,
          promoCount: response.data.count.promotion

        });
      })
      .catch(error => {
        console.log(error);
      });

  };
}

/* Fb login page */
export function getFbLoginData(postData) {
  return function (dispatch) {
    axios.post(API_URL + 'api/customer/fbloginapp', postData)
      .then(function (response) {
        dispatch({
          type: REQUEST_FBLOGIN_DATA,
          fbloginData: response.data
        });
      })
      .catch(function (error) {
        return error;
      });
  };
}
/* Fb login page */
export function updateCustomerCurrentData(postData) {
  return function (dispatch) {
    axios.post(API_URL + "api/cart/update_customer_info", postData)
      .then(function (response) {
        dispatch({
          type: CUSTOMER_UPDATE_DATA,
          customerUpdatedData: response.data
        });
      })
      .catch(function (error) {
        return error;
      });
  };
}
