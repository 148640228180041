import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import cookie from 'react-cookies';
 
import {
    customerId,
    showInfo
} from "../../config/supporters";
import { history } from "../../store";
 
 
import {
    APP_ID, API_URL
} from "../../config/constans";

class Changepassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: new Date(),
            fields: {},
            errors: {},
        };

    }
 

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;



        if (!fields["currentpassword"]) {
            formIsValid = false;
            errors["currentpassword"] = "Current Password field is required.";
        }
        if (typeof fields["currentpassword"] !== "undefined") {
            if (fields["currentpassword"].length < 6) {
                formIsValid = false;
                errors["currentpassword"] = "Current Password must be of minimum 6 characters length.";
            }
        }

        if (!fields["newpassword"]) {
            formIsValid = false;
            errors["newpassword"] = "New Password field is required.";
        }
        if (!fields["reenternewpassword"]) {
            formIsValid = false;
            errors["reenternewpassword"] = "Re enter New Password field is required.";
        }

        if (typeof fields["reenternewpassword"] !== "undefined") {
            if (fields["reenternewpassword"].length < 6) {
                formIsValid = false;
                errors["reenternewpassword"] = "Confirm Password must be of minimum 6 characters length";
            } else if (fields["newpassword"] !== fields["reenternewpassword"]) {
                formIsValid = false;
                errors["reenternewpassword"] = "Your password and confirmation password do not match.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields, errors: {} });        
    }

    changePasswordSubmit(e) {
        e.preventDefault();
        
        if (this.handleValidation()) {
            const formPayload = this.state.fields;

            var qs = require('qs');

           $("#c_loading").show();
            var postObject = {
                "app_id": APP_ID,                
                "type": "web",
                "oldpassword": formPayload.currentpassword,
                "password": formPayload.newpassword,
                "confirmpassword": formPayload.reenternewpassword,
                "refrence": cookie.load('UserId')
            };
           
            axios.post(API_URL + "api/customer/changepassword", qs.stringify(postObject))
            .then(response => {
                $("#c_loading").hide();

                if (response.data.status === "ok") {
                    $("#changePasswordModalCenter").modal("hide");
                    showInfo(response.data.message, 'Success');                                       
                      
                    this.setState({ fields: {}, errors: {} });  
                } else {

                    if (response.data.form_error) {
                        $("#form-msg").html('<div class="alert alert_danger" style="display:block"><p>' + response.data.form_error + '</p></div>');
                    } else {
                        
                        $("#form-msg").html('<div class="alert alert_danger" style="display:block"><p>' + response.data.message + '</p></div>');
                    }
                    this.setState({ fields: {}, errors: {} });  
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
    render() {

        if (this.props.userStatus === "error") {
            history.push("/");
        }

        /* { value: 'Unspecified', label: 'Unspecified' } */

        return (
            <React.Fragment>
                {/* Guest Checkout Modal */}
                <div className="modal fade" id="changePasswordModalCenter" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content bg-transparent sign-up-modal">

                            <div className="modal-body">

                                <div className="pop-whole d-flex flex-row-reverse">
                                    <div className="pop-whole-lhs">
                                        <div className="pop-whole-lhs-inner">
                                            <h2 className="font-gothamlight text-uppercase">Set New <br />Password !</h2>
                                            <h2 className="font-azakie">Change your Spring Court account Password.</h2>
                                        </div>
                                    </div>
                                    <div className="inside-popup-rhs">
                                        <Link to="#" data-dismiss="modal" aria-label="Close" className="btn-link go-back-link"><i className="fas fa-chevron-left"></i> Back</Link>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        </button>

                                        <div className="popup-body mt-5">

                                            <h4>Change Password</h4>
                                            <div id="form-msg"></div>
                                            <div className="form-group">
                                                <div className="focus-out">
                                                    <input type="password" name="currentpassword" id="currentpassword" className="form-control input-focus" placeholder="Current Password" onChange={this.handleChange.bind(this, "currentpassword")} value={this.state.fields["currentpassword"] || ''} />
                                                </div>
                                                <span className={this.state.errors["currentpassword"] ? "error show" : "error hide"}>{this.state.errors["currentpassword"]}</span>
                                            </div>

                                            <div className="form-group">
                                                <div className="focus-out">
                                                    <input type="password" name="newpassword" id="newpassword" className="form-control input-focus" placeholder="New Password" onChange={this.handleChange.bind(this, "newpassword")} value={this.state.fields["newpassword"] || ''} />
                                                </div>
                                                <span className={this.state.errors["newpassword"] ? "error show" : "error hide"}>{this.state.errors["newpassword"]}</span>
                                            </div>
                                            <div className="form-group">
                                                <div className="focus-out">

                                                    <input type="password" name="reenternewpassword" id="reenternewpassword" className="form-control input-focus" placeholder="Re-enter New Password" onChange={this.handleChange.bind(this, "reenternewpassword")} value={this.state.fields["reenternewpassword"] || ''} />
                                                </div>
                                                <span className={this.state.errors["reenternewpassword"] ? "error show" : "error hide"}>{this.state.errors["reenternewpassword"]}</span>
                                            </div>


                                            <div className="form-group">
                                                <div className="login_pop_sub">
                                                    <button type="button" className="btn btn-primary btn-block" onClick={this.changePasswordSubmit.bind(this)}>Submit</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

}
const customStyles = {

    control: () => ({
        // none of react-select's styles are passed to <Control />
        height: 50,
        padding: '.375rem .75rem'
    })

}

const DropdownIndicator = () => (
    <div className="react-select-dropdown">
        <div className="react-select-arrow"></div>
    </div>
);

 
export default  Changepassword



