import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getblocks, getOutlets } from '../../stores/cms'
import { getSetings } from '../../stores/settings'
import axios from 'axios'
import Header from '../layout/header'
import Footer from '../layout/footer'
import {
  API_URL,
  APP_ID,
  DINEIN_ID,
  DELIVERY_ID,
  stripeCompany,
  stripeImage,
  stripeCurrency,
  stripeReference
} from '../../config/constans'
import { showLoader, hideLoader } from '../../config/SettingHelper'
import { addressFormat, showInfo } from '../../config/supporters'
import jQuery from 'jquery'
import { history } from '../../store'
import moment from 'moment'
import noImage from '../../assets/img/product-no-image.jpg'
import StripeCheckout from 'react-stripe-checkout'
import loadingImage from '../../assets/img/spinner.gif'
import cookie from 'react-cookies'
import ico_like from '../../assets/img/ico_like.png'
import tickImage from '../../assets/img/tick_popup.png'
import Checkout1 from '../checkout/Checkout'
var _ = require('lodash')
var parse = require('html-react-parser')
var base64 = require('base-64')
const $ = window.$
var qs = require('qs')
class Paymentrequest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cartData: [],
      cartItems: [],
      status: 'Loading',
      availabilityId: '',
      windowHeightNormal: 0,
      windowHeight: 0,
      requestData: [],
      order_id: '',
      payment_id: '',
      payment_ref_id: '',
      omise_payment_enable: 0,
      omise_log_id: '',
      omisecodeerror: '',
      omiseyearerror: '',
      omisemontherror: '',
      omisecardrror: '',
      omisenameerror: '',
      holdername: '',
      cardNumber: '',
      expiration_month: '',
      expiration_year: '',
      security_code: '',
      cardImage: '',
      payment_ref_id: '',
      omise_tokken_response: '',
      omise_tokken_card_id: '',
      omise_tokken_id: '',
      processOmise: false,
      customerId: '',
      client_payments: 'Omise'
    }
    this.onChangePayment = this.onChangePayment.bind(this)
  }

  componentDidMount() {
    this.props.getblocks()
    this.props.getOutlets()
    this.props.getSetings()

    const orderId =
      typeof this.props.match.params.orderId !== 'undefined' &&
      this.props.match.params.orderId !== ''
        ? base64.decode(this.props.match.params.orderId)
        : ''
    var paymentId =
      typeof this.props.match.params.paymentId === 'undefined'
        ? ''
        : base64.decode(this.props.match.params.paymentId)

    if (paymentId == '' || orderId == '') {
      history.push('/')
    }
    axios
      .all([
        axios.get(
          API_URL +
            'api/reports/order_history?app_id=' +
            APP_ID +
            '&order_id=' +
            orderId
        )
      ])
      .then(
        axios.spread(orders => {
          if (orders.data.status === 'ok') {
            //  console.log(paymentId); return false;
            this.setState({
              status: orders.data.status,
              order_id: orderId,
              payment_id: orders.data.result_set[0].order_primary_id,
              customerId: orders.data.result_set[0].order_customer_id,
              orderOutletId: orders.data.result_set[0].outlet_id,
              orderOutletName: orders.data.result_set[0].outlet_name,
              order_local_no: orders.data.result_set[0].order_local_no
            })
            this.setState({ cartItems: orders.data.result_set[0]['items'] })
            this.setState({ cartData: orders.data.result_set[0] })
          } else {
            history.push('/')
          }
        })
      )

    axios
      .all([
        axios.get(
          API_URL +
            'api/advancepayments/order_requestlist/?app_id=' +
            APP_ID +
            '&order_id=' +
            orderId +
            '&primary_id=' +
            paymentId
        ),
        axios.get(
          API_URL +
            'api/callcenterapi/get_payment_reference_id?app_id=' +
            APP_ID
        )
      ])
      .then(
        axios.spread((requestDetails, paymentRef) => {
          if (requestDetails.data.status === 'ok') {
            var payrefId = ''
            if (paymentRef.data.status === 'ok') {
              payrefId = paymentRef.data.payment_ref_id
            }
            this.setState({
              requestData: requestDetails.data.result_set,
              payment_ref_id: payrefId
            })
          } else {
            showInfo(requestDetails.data.message)
            history.push('/')
          }
        })
      )
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.settings !== nextProps.settings) {
      this.setState({ settings: nextProps.settings })
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        html +=
          "<li className='sub-item'>" + modName + ' : ' + modval + '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (_.isEmpty(comboLen) === true && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        //console.log(combos[0]['modifiers']);
        var comboPro = combos[r]['menu_product_name']
        var comboQty = combos[r]['menu_product_qty']
        var comboPrice = combos[r]['menu_product_price']
        var newPrice = ''
        /*  var newPrice = (comboPrice >0)? " (+"+comboPrice+")" : ""; */
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['cart_modifier_price']
        var newModValPrice = ''
        /* var newModValPrice = (modValPrice >0)? " (+"+modValPrice+")" : ""; */

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }

  /* post stripe account */

  onToken = token => {
    /*  load process html */
    const avText =
      this.state.cartData.order_availability_id === DINEIN_ID
        ? 'Dine In'
        : 'Self Collection'
    const orderDate = moment(this.state.cartData.order_date).format(
      'MM/DD/YYYY HH:mm A'
    )
    var payRefID = this.state.payment_ref_id
    var postObject = {}
    postObject = {
      app_id: APP_ID,
      token: token.id,
      stripe_envir:
        parseInt(this.props.settings.client_stripe_mode) === 1 ? 'live' : 'dev',
      stripe_key: this.props.settings.stripe_public_key,
      customer_id: '1',
      paid_amount: this.state.requestData.ap_request_amount,
      outlet_name:
        'springcourt.com.sg|Web|' +
        orderDate +
        '|' +
        avText +
        '|' +
        this.state.cartData.order_local_no +
        '|' +
        payRefID,
      payment_reference: stripeReference
    }

    //console.log(postObject); return false;
    axios
      .post(
        API_URL + 'ninjapro/payment/stripeTokenPay',
        qs.stringify(postObject)
      )
      .then(res => {
        if (res.data.status === 'ok') {
          var captureID = res.data.result_set.payment_reference_1
          var paymentSuccess = {}
          paymentSuccess = {
            app_id: APP_ID,
            paid_amount: this.state.requestData.ap_request_amount,
            order_id: this.state.order_id,
            order_primary_id: this.state.payment_id,
            order_local_payment_reference: payRefID
          }

          //console.log(paymentSuccess); return false;

          axios
            .post(
              API_URL + 'api/advancepayments/recordPayment',
              qs.stringify(paymentSuccess)
            )
            .then(res => {
              showInfo('your payment processed successfully')
              history.push('/')
            })
            .catch(error => {
              showInfo('error')
              return false
            })
        } else if (res.data.status === 'error') {
          showInfo('error')
          return false
        }
      })
      .catch(error => {
        showInfo('error')
        return false
      })
  }

  onChangePayment(event) {
    var client_payments = ''
    var paymentModeID = 3
    if (event.target.value === '1') {
      client_payments = 'COD'
      paymentModeID = 2
    } else if (event.target.value === '4') {
      client_payments = 'Omise'
    } else if (event.target.value === '3') {
      client_payments = 'STRIPE'
    }
    this.setState({
      paymentMode: parseInt(event.target.value),
      client_payments: client_payments
    })
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/
    if (item == 'cardNumber') {
      if (event.target.value === '' || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value)
        var cardnumberLenght = cardnumber.length
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber })
          this.cardValidation(cardnumber)
        }
      }
    } else if (
      item == 'expiration_month' ||
      item == 'expiration_year' ||
      item == 'security_code'
    ) {
      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value })
      }
    } else {
      this.setState({ [item]: event.target.value })
    }

    this.setState({
      omisenameerror: '',
      omisecardrror: '',
      omisemontherror: '',
      omiseyearerror: '',
      omisecodeerror: ''
    })
  }

  space(el) {
    var numbes = el.replace(/ /g, '')
    return numbes.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '')
  }

  cardValidation(carnumber) {
    var imagename = ''
    if (carnumber != '') {
      var single = carnumber.substring(0, 1)
      var double = carnumber.substring(0, 2)

      if (single == 4) {
        imagename = 'visa'
      } else if (double == 34 || double == 37) {
        imagename = 'american'
      } else if (double >= 51 && double <= 55) {
        imagename = 'master'
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = 'discover'
      }
    }

    this.setState({ cardImage: imagename })
  }

  sateValChange = (field, value) => {
    if (field === 'processOmise') {
      this.setState({ processOmise: value })
    } else if (field === 'omiseTokenError' && value !== '') {
      hideLoader('omise-pay-btn', 'class')
      this.showOmiselert(value)
    } else if (field === 'omiseTokensucess' && value !== '') {
      this.setState(
        {
          omise_tokken_response: value,
          omise_tokken_card_id: value.card.id,
          omise_tokken_id: value.id
        },
        () => {
          this.onProcessOmiseToken(value)
        }
      )
    }
  }
  orderPayValidaion(event) {
    event.preventDefault()
    jQuery('#pay-omiseconf-popup').modal('show')
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = '',
      omisecardrror = '',
      omisemontherror = '',
      omiseyearerror = '',
      omisecodeerror = ''
    if (this.state.holdername == '') {
      errorFlg++
      omisenameerror = 'omise-form-error'
    }
    if (this.state.cardNumber == '') {
      errorFlg++
      omisecardrror = 'omise-form-error'
    }
    if (this.state.expiration_month == '') {
      errorFlg++
      omisemontherror = 'omise-form-error'
    }
    if (this.state.expiration_year == '') {
      errorFlg++
      omiseyearerror = 'omise-form-error'
    }
    if (this.state.security_code == '') {
      errorFlg++
      omisecodeerror = 'omise-form-error'
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror
    })
    if (errorFlg === 0) {
      this.setState({ processOmise: true }, function() {
        showLoader('omise-pay-btn', 'class')
      })
    } else {
      return false
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $('.omise-error-info-div').attr('data-timeoutid')
    clearTimeout(dataTimeoutId)
    $('.omise-error-info-div').hide()
    $('.omise-error-msg').html(msgArr['message'])
    $('#omise-error-info-div').fadeIn()
    var timeoutid = setTimeout(function() {
      $('.omise-error-info-div').hide()
    }, 6000)
    $('.omise-error-info-div').attr('data-timeoutid', timeoutid)
  }

  /* post stripe account */
  onProcessOmiseToken = token => {
    if (this.state.requestData.ap_request_amount > 0) {
      var payAmount = parseFloat(
        this.state.requestData.ap_request_amount
      ).toFixed(2)

      var requestType =
        'Springcourt-' + this.state.orderOutletId + '-' + this.state.customerId
      var postObject = {}
      postObject = {
        app_id: APP_ID,
        token: token.id,
        customer_id: this.state.customerId,
        outlet_id: this.state.orderOutletId,
        paid_amount: payAmount,
        request_type: requestType,
        outlet_name:
          typeof this.state.orderOutletName === 'undefined'
            ? this.state.orderOutletName
            : ''
      }
      axios
        .post(API_URL + 'api/paymentv1/authOmise', qs.stringify(postObject))
        .then(res => {
          if (res.data.status === 'ok') {
            var captureID = res.data.result_set.payment_reference_1
            var omiseLogId = res.data.result_set.log_id
            this.setState(
              { omise_log_id: omiseLogId },
              function() {
                this.captureOmiseAmount(
                  captureID,
                  this.state.payment_id,
                  this.state.order_local_no
                )
              }.bind(this)
            )
          } else if (res.data.status === 'error') {
            hideLoader('omise-pay-btn', 'class')
            if (res.data.message == 'token was already used') {
              this.omise_search_history()
            } else {
              var errerTxt =
                res.data.message !== ''
                  ? res.data.message
                  : 'Error code: 1001 Oops! Something went wrong! Please try again.'
              this.paymentFail('Error', errerTxt)
              return false
            }
          } else {
            var currents = this
            setTimeout(
              function() {
                currents.omise_search_history()
              }.bind(this),
              2000
            )
          }
        })
        .catch(error => {
          var currentcatch = this
          setTimeout(
            function() {
              currentcatch.omise_search_history()
            }.bind(this),
            2000
          )
        })
    }
  }
  /* Omise Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {}
    cabtureObjects = {
      payment_reference: '',
      customer_id: this.state.customerId,
      app_id: APP_ID,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: this.state.orderOutletId
    }
    axios
      .post(
        API_URL + 'api/paymentv1/captureAmountOmise',
        qs.stringify(cabtureObjects)
      )
      .then(captureRes => {
        if (captureRes.data.status === 'ok') {
          this.setState({ completingpaymentimage: tickImage })

          this.setState(
            { stop_authentication: 'yes' },
            function() {
              var payRefID = this.state.payment_ref_id
              var paymentSuccess = {}
              paymentSuccess = {
                app_id: APP_ID,
                paid_amount: this.state.requestData.ap_request_amount,
                order_id: this.state.order_id,
                order_primary_id: this.state.payment_id,
                order_local_payment_reference: payRefID,
                client_payments: this.state.client_payments
              }

              //console.log(paymentSuccess); return false;

              axios
                .post(
                  API_URL + 'api/advancepayments/recordPayment',
                  qs.stringify(paymentSuccess)
                )
                .then(res => {
                  hideLoader('omise-pay-btn', 'class')
                  jQuery('#pay-omiseconf-popup').modal('hide')
                  showInfo('your payment processed successfully')
                  history.push('/')
                })
                .catch(error => {
                  showInfo('error')
                  return false
                })
            }.bind(this)
          )
        } else if (captureRes.data.status === 'pending') {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        } else if (captureRes.data.status === 'error') {
          var omsMsgTxt =
            captureRes.data.message != ''
              ? captureRes.data.message
              : 'Your order was not successful.'
          showInfo(omsMsgTxt)
        } else {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        }
      })
      .catch(error => {
        this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo)
      })
  }

  retrieve_charge_details = (captureID, orderPrimaryId, localOrderNo) => {
    var orderOutlet_Id = this.state.customerId
    var postObject = {}
    postObject = {
      charge_id: captureID,
      app_id: APP_ID,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id
    }

    axios
      .post(
        API_URL + 'api/paymentv1/retrieve_charge_details',
        qs.stringify(postObject)
      )
      .then(res => {
        if (
          res.data.status === 'ok' &&
          res.data.payment_status === 'successful'
        ) {
          this.setState(
            { stop_authentication: 'yes' },
            function() {
              //  this.showSuccessPage(localOrderNo)
            }.bind(this)
          )
        } else if (
          res.data.status === 'ok' &&
          res.data.payment_status === 'pending'
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        } else {
          var magnfPopup = $.magnificPopup.instance
          var msgTxt =
            res.data.message != ''
              ? res.data.message
              : 'Your order was not successful.'
          showInfo(msgTxt)
        }
      })
  }

  omise_search_history = () => {
    var orderOutlet_Id = this.state.customerId
    var postObject = {}
    postObject = {
      app_id: APP_ID,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id
    }

    axios
      .post(
        API_URL + 'paymentv1/omise_search_details',
        qs.stringify(postObject)
      )
      .then(res => {
        if (res.data.status === 'ok') {
          var captureID = res.data.captureID
          var omiseLogId = res.data.log_id
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function() {
              //this.postOrder(3, '', captureID, 'Omise')
            }.bind(this)
          )
        } else if (res.data.status === 'error') {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response)
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response)
        }
      })
  }

  paymentFail(header, message) {
    jQuery('.warining_popup').remove()
    jQuery('body').append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    )
  }
  pageReload = () => {
    setTimeout(function() {
      //  window.location.reload()
    }, 15000)
  }

  /* Omise End */

  render() {
    const settings = this.props.setStatus === 'ok' ? this.props.settings : []

    if (this.state.status === 'Loading' || _.isEmpty(settings) === true) {
      return (
        <React.Fragment>
          {' '}
          <div
            id="cartLoading"
            style={{
              display: 'block',
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              margin: 'auto',
              zIndex: 9999,
              background: 'rgba(255, 255, 255, 0.8)'
            }}>
            <img
              src={loadingImage}
              alt="Loading"
              style={{
                bottom: 0,
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                margin: 'auto'
              }}
            />
          </div>{' '}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Header allProps={this.props} />
          <main>
            <div className="main-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="page-header-block bg-transparent">
                      <h1 className="font-gothamlight  text-center text-uppercase">
                        {' '}
                        Make Your Payment
                      </h1>
                      <h6 className=" text-center">
                        Requested Payment Amount - ${' '}
                        {this.state.requestData.ap_request_amount}
                      </h6>
                    </div>
                  </div>
                  <div className="col-12 col-md-8 col-lg-7 m-auto">
                    <div className="order-details-item-block order-info-block">
                      <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                        Your Order Details
                      </h4>
                      <div className="order-info text-center">
                        <h4 className="font-gothamlight  text-center text-uppercase">
                          Order No - {this.state.cartData.order_local_no}
                        </h4>
                        <p>
                          Order placed at :{' '}
                          {moment(this.state.cartData.order_created_on).format(
                            'MM/DD/YYYY HH:mm a'
                          )}
                        </p>
                      </div>
                      <div className="delivery-info">
                        <div className="slfcol-form-block">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="media">
                                <img
                                  className="mr-3"
                                  src="/img/take-away.png"
                                  alt="Generic placeholder "
                                />
                                <div className="media-body">
                                  <h6 className="mt-0">
                                    {this.state.cartData
                                      .order_availability_id === DINEIN_ID
                                      ? 'DIne in'
                                      : 'SELF COLLECTION'}{' '}
                                    LOCATION{' '}
                                  </h6>
                                  <p className="outlet-info">
                                    {this.state.cartData.outlet_name} -{' '}
                                    {addressFormat(
                                      this.state.cartData.outlet_unit_number1,
                                      this.state.cartData.outlet_unit_number2,
                                      this.state.cartData.outlet_address_line1,
                                      this.state.cartData.outlet_address_line2,
                                      this.state.cartData.outlet_postal_code
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="media">
                                <div className="media-body">
                                  <h6 className="mt-0">
                                    {this.state.cartData
                                      .order_availability_id === DINEIN_ID
                                      ? 'DIne in'
                                      : 'SELF COLLECTION'}{' '}
                                    Date & Time
                                  </h6>
                                  <p className="outlet-info">
                                    {moment(
                                      this.state.cartData.order_date
                                    ).format('MM/DD/YYYY HH:mm a')}
                                  </p>
                                </div>
                                <img
                                  className="mr-3"
                                  src="/img/alarm-clock.png"
                                  alt="Generic placeholder image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="cart-items-block">
                        {this.state.cartItems.map((item, index) => (
                          <div className="cart-row" key={index}>
                            <div className="row">
                              <div className="col-7">
                                <div className="cart-left">
                                  <div className="cart-img">
                                    <img
                                      src={
                                        item.item_image !== ''
                                          ? item.item_image
                                          : noImage
                                      }
                                      width={110}
                                      height={67}
                                      alt="image"
                                    />
                                  </div>
                                  <div className="cart-info">
                                    <h6>
                                      {item.item_qty} X {item.item_name}
                                    </h6>

                                    {this.loadModifierItems(
                                      item.cart_item_type,
                                      item.modifiers,
                                      item.set_menu_component
                                    )}
                                    {/*  <div className="cart-addon-info">
                                <span className="d-inline-block">Size:</span><span className="d-inline-block font-italic"> Large</span>

                              </div>
                              <div className="cart-addon-info">
                                <span className="d-inline-block">Add on:</span><span className="d-inline-block font-italic"> Name</span>

                              </div> */}
                                  </div>
                                </div>
                                <p className="cart-special-text">
                                  {item.item_specification}
                                </p>
                              </div>
                              <div className="col-5 text-right">
                                <div className="cart-right ">
                                  <div className="cart-price">
                                    <p>${item.item_total_amount}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="calctrcart-footer">
                          <div className="calctrcart-row">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">SUBTOTAL</p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${this.state.cartData.order_sub_total}
                                </span>
                              </div>
                            </div>
                          </div>

                          {this.state.cartData.order_discount_amount > 0 &&
                            this.state.cartData.order_discount_applied ===
                              'Yes' && (
                              <div className="calctrcart-row ">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">DISCOUNT</p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <span>
                                      $
                                      {
                                        this.state.cartData
                                          .order_discount_amount
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}

                          {this.state.cartData.order_tax_charge > 0 &&
                            this.state.cartData.order_tax_calculate_amount && (
                              <div className="calctrcart-row ">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">
                                      GST (
                                      {this.state.cartData.order_tax_charge}
                                      %)
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <span>
                                      $
                                      {
                                        this.state.cartData
                                          .order_tax_calculate_amount
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}

                          <div className="calctrcart-row calctrcart-footer-totrow">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">Total</p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${this.state.cartData.order_total_amount}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="calctrcart-row calctrcart-footer-totrow">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">Payable amount</p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${this.state.requestData.ap_request_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="checkout-payment-actions">
                        <div className="row">
                          <div className="col-12">
                            <h4 className="text-center text-uppercase">
                              Select Your Payment Method
                            </h4>
                            <div className="payment-types-block">
                              <div className="form-group">
                                {settings.client_omise_enable === '1' && (
                                  <div className="custom-radio custom-control">
                                    <input
                                      type="radio"
                                      id="ptype_omise"
                                      name="customRadio"
                                      className="custom-control-input"
                                      defaultChecked={
                                        this.state.client_payments === 'Omise'
                                          ? true
                                          : false
                                      }
                                      value="4"
                                      onClick={this.onChangePayment}
                                    />
                                    <label
                                      className={
                                        this.state.client_payments === 'Omise'
                                          ? 'custom-control-label active'
                                          : 'custom-control-label'
                                      }
                                      htmlFor="ptype_omise">
                                      <img
                                        src="../../img/omise.png"
                                        alt="..."
                                      />
                                    </label>
                                  </div>
                                )}
                                {settings.client_stripe_enable === '1' && (
                                  <div className="custom-radio custom-control">
                                    <input
                                      type="radio"
                                      id="ptype_cod"
                                      name="customRadio"
                                      className="custom-control-input"
                                      defaultChecked={
                                        this.state.client_payments === 'STRIPE'
                                          ? true
                                          : false
                                      }
                                      value="3"
                                      onClick={this.onChangePayment}
                                    />
                                    <label
                                      className={
                                        this.state.client_payments === 'STRIPE'
                                          ? 'custom-control-label active'
                                          : 'custom-control-label'
                                      }
                                      htmlFor="ptype_cod">
                                      <img
                                        src="/img/payment-card-type.png"
                                        alt="..."
                                      />
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* this.state.client_payments === 'STRIPE' &&
                        settings.client_stripe_enable === '1' && (
                          <div className="check-order-status-btn-block">
                            <StripeCheckout
                              name={stripeCompany}
                              image={stripeImage}
                              description={'Spring Court'}
                              token={this.onToken}
                              stripeKey={settings.stripe_public_key}
                              amount={
                                this.state.requestData.ap_request_amount * 100
                              }
                              email={'admin@springcourt.com'}
                              currency={stripeCurrency}>
                              {' '}
                              <button
                                type="button"
                                className="btn btn-secondary btn-block">
                                Pay Now
                              </button>{' '}
                            </StripeCheckout>
                          </div>
                        ) */}

                      {this.state.client_payments === 'Omise' &&
                        settings.client_omise_enable === '1' && (
                          <div className="check-order-status-btn-block">
                            {settings.omise_public_key &&
                              this.state.customerId != '' && (
                                <button
                                  type="button"
                                  onClick={this.orderPayValidaion.bind(this)}
                                  className="btn btn-secondary btn-block">
                                  Pay Now
                                </button>
                              )}
                            <Checkout1
                              sateValChange={this.sateValChange}
                              checkoutState={this.state}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/*Payment omise confirm popup Start*/}
          <div
            className="modal fade pay-omiseconf-popup"
            id="stripPaymentProcess"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            id="pay-omiseconf-popup">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content bg-Black delivery-datetime-modal">
                <div className="modal-body">
                  <div
                    className="omise-error-info-div"
                    id="omise-error-info-div"
                    style={{ display: 'none' }}>
                    <div className="container">
                      <span className="omise-error-icon">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        />
                      </span>

                      <a
                        href="#"
                        className="omise_alert_close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={evt => {
                          evt.preventDefault()
                        }}>
                        {' '}
                        <span aria-hidden="true">×</span>{' '}
                      </a>
                      <p className="omise-error-msg">Something went wrong</p>
                    </div>
                  </div>

                  {settings.omise_envir === 'dev' && (
                    <div className="omisepay-mode-option">Test Mode</div>
                  )}

                  <div className="custom_alert_new pay-omiseconf-popup">
                    <div className="custom_alertin">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="alert_height">
                        <div className="omise_pay_header">
                          <span className="omise-pay-img">
                            <img
                              className="makisan-logo-img"
                              src={stripeImage}
                            />
                          </span>
                          <span className="omise-pay-title">Spring Court</span>
                        </div>
                        <div className="omise_pay_body">
                          <div className="omisepop_in">
                            <div className="form_sec">
                              <div
                                className={
                                  'uname-box ' + this.state.omisenameerror
                                }>
                                <i className="fa fa-user" data-unicode="f007" />
                                <input
                                  type="text"
                                  id="holdername"
                                  className="form-control"
                                  placeholder="Card Holder Name"
                                  value={this.state.holdername}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    'holdername'
                                  )}
                                />
                              </div>
                              <div
                                className={
                                  'card-numbr-div ' + this.state.omisecardrror
                                }>
                                <i
                                  className="fa fa-credit-card"
                                  data-unicode="f09d"
                                />
                                <input
                                  type="text"
                                  className={
                                    'form-control ' + this.state.cardImage
                                  }
                                  placeholder="Card Number"
                                  value={this.state.cardNumber}
                                  id="omise_card_number"
                                  value={this.state.cardNumber}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    'cardNumber'
                                  )}
                                />
                                <span className="card" />
                              </div>
                              <div className="form_expire_row">
                                <div className="expire_row_inner">
                                  <div
                                    className={
                                      'expire_left ' +
                                      this.state.omisemontherror
                                    }>
                                    <i
                                      className="fa fa-calendar"
                                      data-unicode="f133"
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength="2"
                                      id="expiration_month"
                                      placeholder="MM"
                                      value={this.state.expiration_month}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        'expiration_month'
                                      )}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'expire_mdl ' + this.state.omiseyearerror
                                    }>
                                    <input
                                      type="text"
                                      placeholder="YY"
                                      className="form-control"
                                      maxLength="4"
                                      id="expiration_year"
                                      value={this.state.expiration_year}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        'expiration_year'
                                      )}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'expire_right ' +
                                      this.state.omisecodeerror
                                    }>
                                    <i
                                      className="fa fa-lock"
                                      data-unicode="f023"
                                    />
                                    <input
                                      type="password"
                                      maxLength="3"
                                      className="form-control"
                                      id="security_code"
                                      placeholder="CVV"
                                      value={this.state.security_code}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        'security_code'
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="alt_btns">
                                {settings.omise_public_key &&
                                  this.state.customerId != '' &&
                                  this.state.client_payments == 'Omise' &&
                                  settings.client_omise_enable === '1' && (
                                    <button
                                      onClick={this.getOmiseToken.bind(this)}
                                      className="btn-checkout btn btn-primary omise-pay-btn">
                                      Pay {stripeCurrency}{' '}
                                      {parseFloat(
                                        this.state.requestData.ap_request_amount
                                      ).toFixed(2)}
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*payment omise confirm popup - End*/}
          <Footer allProps={this.props} />
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = ({ cms, settings }) => ({
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  outlets: cms.outlets,
  settings: settings.returnData,
  setStatus: settings.isStatus
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getblocks, getOutlets, getSetings }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Paymentrequest)
