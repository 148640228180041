/* eslint-disable */
import React from 'react'; 
import { history } from '../../store';
import axios from 'axios'; 
 
 import {
  APP_ID, API_URL
} from "../../config/constans";
 
 
 import loadingImage from "../../assets/img/spinner.gif";
import {
 showInfo
} from "../../config/supporters";

/* Load meta tags */
 



class Activation extends React.Component {
	

	componentWillMount () { 
	
	  
	
	}
	 /* Account activation */
	   componentDidMount () { 

	     var qs = require('qs');
	   	var activationId = this.props.match.params.activationId ;  
		
	 /* for account activation - start */	
	 if( typeof activationId !== 'undefined' && activationId !=="")
	  {	
	  // return false;
		var postObject = {
				   "app_id": APP_ID,
				   "key" : activationId,
				   }; 
		axios.post(API_URL+"api/customer/activation", qs.stringify(postObject)).then(response => {
	 
		/* Success response */
		if(response.data.status==="ok"){ 
			 var errorMsg = response.data.message;
			 showInfo( errorMsg);
		     history.push("/");
		}
		
			/* Success response */
		if(response.data.status==="error"){ 
		
		 var errorMsg = response.data.message;
		  showInfo( errorMsg);
		 history.push("/");
		}
		/* error reponse */
		
		}); 
	}
	 /* for account activation - end  */		
		
	   }
	   
	     render() {
              return ( <div  style={{
               width : "320px",
                bottom: 0,
                left: 0,
                position: "fixed",
                right: 0,
                top: 0,
                margin: "auto",
                zIndex: 9999,
                background: "rgba(255, 255, 255, 0.8)"
              }}> 
			  	 
				 
 
			 <br/> <br/> <br/> <br/> <br/>
			  
			 <img src={loadingImage} alt="" />
 
		 
		 
	 
 	  </div>)
		 }
	 
 }
 export default Activation;
