import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import jquery from 'jquery'
import {
  API_URL,
  APP_ID,
  dineInText,
  pickUpText,
  DINEIN_ID,
  PICKUP_ID
} from '../../config/constans'
import axios from 'axios'
import { getblocks, getOutlets } from '../../stores/cms'
import { getCartItems } from '../../stores/cart'
import { getSetings } from '../../stores/settings'
import { getMenu } from '../../stores/products'
import Header from '../layout/header'
import Footer from '../layout/footer'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import cookie from 'react-cookies'
import Select from 'react-select'
import {
  stripslashes,
  getCookies,
  getAliasName,
  getReferenceID,
  customerId,
  showInfo
} from '../../config/supporters'
import { showLoader, hideLoader } from '../../config/SettingHelper'
import noImage from '../../assets/img/product-no-image.jpg'
import loadingImage from '../../assets/img/spinner.gif'
import ico_sorry from '../../assets/img/ico-sorry.png'
import cross_symbol from '../../assets/img/cross-symbol-gray.png'
import pickup_banner from '../../assets/img/pickup_banner.jpg'
import promo_pickup_banner from '../../assets/img/promo_pickup_banner.jpg'
import pickup_banner_new from '../../assets/img/2021CNYtakeaway_promo.jpg'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
var _ = require('lodash')
var parse = require('html-react-parser')
var qs = require('qs')
const $ = window.$
class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: 0,
      selectedProduct:
        this.props.match !== '' &&
        typeof this.props.match !== undefined &&
        this.props.match.params !== '' &&
        this.props.match.params !== undefined &&
        this.props.match.params.proslug !== '' &&
        this.props.match.params.proslug !== undefined
          ? this.props.match.params.proslug
          : '',
      selectedCate:
        this.props.match !== '' &&
        typeof this.props.match !== undefined &&
        this.props.match.params !== '' &&
        this.props.match.params !== undefined &&
        this.props.match.params.proCateSlug !== '' &&
        this.props.match.params.proCateSlug !== undefined
          ? this.props.match.params.proCateSlug
          : '',
      products: [],
      productsLoading: true,
      productsCommon: [],
      currentProduct: null,
      currentProductCategory: null,
      detailstatus: 'loading',
      prDetails: {},
      prCommon: {},
      productPrice: '',
      productUnitPrice: 0,
      defaultQty: 1,
      allowCart: 'Yes',
      MinMaxSel: 0,
      productName: '',
      product_remarks: '',
      reloadCart: false,
      selected_modifier_value: '',
      multi: false,
      productUnitPrice: 0,
      itemType: 'Simple',
      ModifierCount: '',
      extraComboPrice: 0,
      selected_value: '',
      selectedFile: '',
      giftContent: 'Text',
      dropdownTopHeight: 118,
      renderScreen: 0,
      categoryList: [],
      subcateDetails: '',
      multisubcateDetails: [],
      currentDesign:
        cookie.load('currentDesign') !== '' &&
        typeof cookie.load('currentDesign') !== undefined &&
        typeof cookie.load('currentDesign') !== 'undefined'
          ? parseInt(cookie.load('currentDesign'))
          : 1,
      modParentProductId: '',
      selectedModifiers: '',
      grandTotal: 0,
      defaultIndex: '',
      menubanner_image: '',
      firstLoad: 0,
      cartItem: []
    }
    this.toggleTabs = this.toggleTabs.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    //this.showProductDetails = this.showProductDetails.bind(this)
    const outletId = getCookies('orderOutletId')
    const postalcode = getCookies('orderPostalCode')
    const orderAvailability = getCookies('orderAvailability')
    if (outletId === '' || (postalcode === '' && orderAvailability === '')) {
      //showInfo("Please choose outlet first", "");
      //this.props.history.push("/");
      // cookie.save('enable_order_popup', 'Yes')

      //window.location.replace('/')
      return false
    }

    cookie.save('modiferCount', 0)
    cookie.save('itemType', 'Simple')
    cookie.save('subquantity', 0)
    cookie.save('notsubquantity', '')
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillMount() {}
  componentDidMount() {
    $(document)
      .off('click', '.close_details_pop')
      .on('click', '.close_details_pop', function(e) {
        jquery('body').removeClass('mobile-product-details-res')
      })
    $(document)
      .off('click', '.more-menu-parent')
      .on('click', '.more-menu-parent', function(e) {
        e.preventDefault()
        e.stopPropagation()
        if (!$('.more_categor_info').is(':visible')) {
          $('.more_categor_info')
            .show()
            .addClass('active')
          $('.more-menu-parent').addClass('active')
        } else {
          $('.more_categor_info')
            .hide()
            .removeClass('active')
          $('.more-menu-parent').removeClass('active')
        }
      })
    $(document).click(function(e) {
      if (!$(e.target).is('.more-menu, .more-menu * ')) {
        if ($('.more-menu-parent').hasClass('active')) {
          $('.more-menu-parent').removeClass('active')
          $('.more_categor_info').removeClass('active')
          $('.more_categor_info').hide()
        }
      }
    })

    $(window).scroll(function() {
      var scrollPos = $(document).scrollTop()
      $('.product-menu ul li a')
        .each(function() {
          var currLink = $(this)
          var refElement = $(
            '#catepro_' + currLink.attr('href').replace(/\//g, '')
          )

          if (
            refElement.position() !== '' &&
            typeof refElement.position() !== undefined &&
            typeof refElement.position() !== 'undefined'
          ) {
            var toppos = refElement.position().top - 230
            if (
              toppos <= scrollPos &&
              parseFloat(toppos) + parseFloat(refElement.height()) > scrollPos
            ) {
              $('.product-menu ul li').removeClass('active')
              currLink.parents('li').addClass('active')
              $('#dropdownMenuButton').html(
                refElement.find('.subcategory_tt h3').text()
              )
            } else {
              currLink.parents('li').removeClass('active')
            }
          }
        })
        .promise()
        .done(function() {
          if ($('.more-menu .menu-list-intvl.active a span').length > 0) {
            $('.more-menu .more-menu-name').html(
              $('.more-menu .menu-list-intvl.active a span').text()
            )
          } else {
            $('.more-menu .more-menu-name').html('More')
          }
        })
    })

    $(window).scroll(function() {
      var scrollPos = $(document).scrollTop()
      $('.more-menu ul li a')
        .each(function() {
          var currLink = $(this)
          var refElement = $(
            '#catepro_' + currLink.attr('href').replace(/\//g, '')
          )

          if (
            refElement.position() !== '' &&
            typeof refElement.position() !== undefined &&
            typeof refElement.position() !== 'undefined'
          ) {
            var toppos = refElement.position().top - 230
            if (
              toppos <= scrollPos &&
              parseFloat(toppos) + parseFloat(refElement.height()) > scrollPos
            ) {
              $('.more-menu ul li').removeClass('active')
              currLink.parents('li').addClass('active')
            } else {
              currLink.parents('li').removeClass('active')
            }
          }
        })
        .promise()
        .done(function() {
          if ($('.more-menu .menu-list-intvl.active').length > 0) {
            $('.more-menu .more-menu-name').html(
              $('.more-menu .menu-list-intvl.active a').text()
            )
            $('.product-menu ul li').removeClass('active')
            $('.more-menu').addClass('active')
          } else {
            $('.more-menu').removeClass('active')
            $('.more-menu .more-menu-name').html('More')
          }
        })
    })

    this.props.getblocks()
    this.props.getOutlets()
    this.props.getMenu()
    this.loadInitialProducts(this.props.navItems)
    this.menuBannerImage()

    window.addEventListener('scroll', this.handleScroll)
    /*SCROLL FIXED PRODUCT PAGE*/

    /*$(window).scroll(function () {
			var header_height = $('.main-header').offset();

			if (header_height !== undefined) {
				if (parseFloat(header_height.top) > 150) {
					$('.bakery_row_fixed').css('transition', 'all 400ms');
					$('.bakery_row_fixed').css('top', $('.main-header').height());
					$('.bakery_row').addClass('bakery_row_fixed');

				}
				else {
					$('.bakery_row_fixed').css('transition', 'all 400ms');
					$('.bakery_row').removeClass('bakery_row_fixed');

				}
			}
		});*/

    $(document).click(function(e) {
      if (
        !$(e.target).is(
          ".product-details, .pmodifier_close,  .product-details *, div[id^='react-select-'], .custom_alert, .custom_alert * "
        )
      ) {
        if ($('.product-details').is(':visible')) {
          // $('.product-details').hide()
        }
      }
    })

    //toggle the component with class accordion_body
    $('body').on('click', '.accordion_head', function() {
      if (
        $(this)
          .next('.accordion_body')
          .is(':visible')
      ) {
        $(this)
          .next('.accordion_body')
          .slideToggle(300)
        $(this)
          .children('.plusminus')
          .text('+')
      } else {
        $(this)
          .next('.accordion_body')
          .slideDown(300)
        $(this)
          .children('.plusminus')
          .text('-')
      }
    })
    var current = this
    $('body').on('change', '.combo_list', function() {
      var roots = $(this).parents('.product-size-options-block')

      var mIndex = $(this).attr('data-mindex')
      var maniMenu = ''
      var menuProducts = []
      var i = 0

      var mainRoot = $(this).parents('.product_chosen_item_left_full')
      var currentRoot = $(this).parents('.combo_list_section')

      var CurrentcartQty = mainRoot
        .find('.combo_list_section')
        .find('.combo_list:checked').length
      var minSelect = mainRoot.find('.combo-min-' + mIndex).val()
      var comboApplyPrice = mainRoot.find('.combo-apply-price-' + mIndex).val()
      var action = $(this).prop('checked') == true ? 'add' : 'remove'
      var comboPrice = $('.combo_selected_price_' + mIndex).val()
      if (comboPrice === '') {
        comboPrice = 0
      }

      var unitPrice = $(this).attr('data-price')

      var maxReached = mainRoot.find('.max_reached').val()
      if (comboApplyPrice === '1') {
        if (action === 'add') {
          currentRoot.find(' .combo_pro_price').show()
          comboPrice = parseFloat(comboPrice) + parseFloat(unitPrice)
          currentRoot.attr('data-invcomboprice', $(this).attr('data-price'))
        } else {
          if (currentRoot.find('.combo_pro_price').length > 0) {
            currentRoot.find('.combo_pro_price').hide()
          }
          comboPrice = comboPrice - unitPrice
          currentRoot.attr('data-invcomboprice', '0')
        }
      } else {
        if (CurrentcartQty > minSelect) {
          if (action === 'add') {
            if (currentRoot.find(' .combo_pro_price').length > 0) {
              currentRoot.find(' .combo_pro_price').show()
            }
            comboPrice = parseFloat(comboPrice) + parseFloat(unitPrice)

            maxReached = maxReached !== '' ? parseInt(maxReached) + 1 : 1
            mainRoot.find('.max_reached').val(maxReached)
            currentRoot.attr('data-invcomboprice', $(this).attr('data-price'))
          } else {
            if (currentRoot.find('.combo_pro_price').length > 0) {
              currentRoot.find('.combo_pro_price').hide()
            }
            comboPrice = comboPrice - unitPrice
            maxReached = maxReached !== '' ? parseInt(maxReached) - 1 : 0
            if (maxReached > 0) {
              mainRoot.val(maxReached)
              currentRoot.attr('data-invcomboprice', '0')
            }
          }
        } else {
          mainRoot.find('.max_reached').val('0')
          if (mainRoot.find('.combo_pro_price').length > 0) {
            mainRoot.find('.combo_pro_price').hide()
          }
          mainRoot
            .find('label.combo_list_section')
            .attr('data-invcomboprice', '0')
          comboPrice = 0
        }
      }

      $('.combo_selected_price_' + mIndex).val(comboPrice)
      /*  if (comboPrice > 0) {
       
      } else {
        currentRoot.attr('data-invcomboprice', '0')
      } */
      var totalComobPrice = 0
      $('.comboSelectedPrice').each(function() {
        if ($(this).val() !== '') {
          totalComobPrice =
            parseFloat(totalComobPrice) + parseFloat($(this).val())
        }
      })

      var totalPrice =
        parseFloat($('#product_orignal_price').val()) +
        parseFloat(totalComobPrice)
      //product_orignal_price
      current.setState({ productPrice: totalPrice })
      roots.find('.spct-radio').each(function(index) {
        if (
          $(this)
            .find('.combo_list')
            .prop('checked') == true
        ) {
          var combo_item_list = JSON.parse(
            $(this)
              .find('.combo_item_list')
              .val()
          )

          if (i === 0) {
            maniMenu = combo_item_list.combodetails
          }
          combo_item_list.product_details.product_price = $(this).attr(
            'data-invcomboprice'
          )
          menuProducts.push(combo_item_list.product_details)
          i++
        }
      })
      var comboProductList = ''
      if (maniMenu !== '') {
        comboProductList = maniMenu
        comboProductList['product_details'] = menuProducts
        comboProductList = JSON.stringify(comboProductList)
      }
      $('.combinput_' + mIndex).html(i)
      // comboProductList.push(menuProducts)
      $('#comboOptionValue_' + mIndex).val(comboProductList)
    })

    $(document)
      .off('click', '.single_combo_products')
      .on('click', '.single_combo_products', function(e) {
        if (!$(this).hasClass('active')) {
          var index = $(this)
            .find('.select_single_combo')
            .data('index')
          var roots = $(this)
          var mainroots = $(this).parents('.combo_product_details')
          if (mainroots.find('.active').length > 0) {
            showInfo('Sorry!. maximum of selected item is 1 only.')
          } else {
            mainroots.find('.single_combo_products').removeClass('active')
            $(this).addClass('active')
            var productdetails = $(this)
              .find('.select_single_combo')
              .data('product')

            roots.find('.order_now_btn').hide()
            roots.find('.prd_close').show()
            current.updateComboSelection(index, productdetails)
          }
          return false
        }
      })

    $(document)
      .off('click', '.select_single_combo')
      .on('click', '.select_single_combo', function(e) {
        var index = $(this).data('index')
        var roots = $(this).parents('.single_combo_products')
        var mainroots = $(this).parents('.combo_product_details')

        if (mainroots.find('.active').length > 0) {
          showInfo('Sorry!. maximum of selected item is 1 only.')
        } else {
          mainroots.find('.single_combo_products').removeClass('active')
          $(this)
            .parents('.single_combo_products')
            .addClass('active')
          var productdetails = $(this).data('product')

          roots.find('.order_now_btn').hide()
          roots.find('.prd_close').show()
          current.updateComboSelection(index, productdetails)
        }
        return false
      })
    $(document)
      .off('change', '.modifier_options')
      .on('change', '.modifier_options', function(e) {
        var modify = $(this).data('modifier')
        if ($(this).parents('.single_combo_products.active').length > 0) {
          current.updateCombomodifierSelection(modify)
        }
      })
    $(document)
      .off('click', '.prd_close')
      .on('click', '.prd_close', function(e) {
        e.stopPropagation()
        var index = $(this).data('index')
        var roots = $(this).parents('.single_combo_products')
        $('#comboOptionValueModifierRequired_' + index).val('')
        $('#comboOptionValueModifier_' + index).val('')
        $('#comboOptionValue_' + index).val('')
        $('#comboOptionValuePrice_' + index).val('')
        $('.combinput_' + index).html('0')
        $(this).hide()
        roots.find('.order_now_btn').show()
        roots.removeClass('active')
        current.calculatePrice()
      })
  }

  menuBannerImage() {
    axios
      .get(
        API_URL +
          'api/cms/staticblocks?app_id=' +
          APP_ID +
          '&slug=takeaway-banner'
      )
      .then(value => {
        if (value.data.status == 'ok') {
          let image_path = value.data.result_set[0].gallery_image_path
          let image_details = value.data.result_set[0].gallery_images[0]
          if (
            image_details !== undefined &&
            image_details !== '' &&
            Object.keys(image_details).length > 0
          ) {
            this.setState({
              menubanner_image:
                value.data.result_set[0].gallery_image_path +
                value.data.result_set[0].gallery_images[0]
            })
          }
        }
      })
  }

  loadInitialProducts(navItems) {
    if (_.isEmpty(navItems) === false) {
      const firstItems = navItems[0]

      const cateSlug =
        typeof this.props.match.params.proCateSlug !== 'undefined' &&
        this.props.match.params.proCateSlug !== ''
          ? this.props.match.params.proCateSlug
          : ''
      const outputCateSlug =
        cateSlug !== '' ? cateSlug : firstItems.pro_cate_slug
      const firstCate = outputCateSlug
      const firstSubcate = firstItems.pro_subcate_slug
      this.setState({ currentProductCategory: firstItems.category_name })
      this.getProductDetails(firstCate, firstSubcate)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.categoryList !== nextProps.navItems) {
      this.setState({ categoryList: nextProps.navItems }, function() {
        this.laodCategoryProducts()
      })
    }

    if (nextProps.menuStatus !== this.props.menuStatus) {
      if (_.isEmpty(nextProps.navItems) === false) {
        const navItems = nextProps.navItems
        this.loadInitialProducts(navItems)
      }
    } else {
      const thisCateSlug =
        typeof this.props.match.params.proCateSlug !== 'undefined' &&
        this.props.match.params.proCateSlug !== ''
          ? this.props.match.params.proCateSlug
          : ''
      const nextCateSlug =
        typeof nextProps.match.params.proCateSlug !== 'undefined' &&
        nextProps.match.params.proCateSlug !== ''
          ? nextProps.match.params.proCateSlug
          : ''
      const thisProSlug =
        typeof this.props.match.params.proslug !== 'undefined' &&
        this.props.match.params.proslug !== ''
          ? this.props.match.params.proslug
          : ''
      const nextProSlug =
        typeof nextProps.match.params.proslug !== 'undefined' &&
        nextProps.match.params.proslug !== ''
          ? nextProps.match.params.proslug
          : ''
      if (thisProSlug !== nextProSlug) {
        this.getProductDetails(nextCateSlug, '')
        this.setState({ renderScreen: 0 })
      }
    }
  }

  laodCategoryProducts() {
    if (this.state.categoryList.length > 0) {
      var avId = getCookies('orderAvailability')
      var outletID = getCookies('orderOutletId')
      var apiURL = 'products'
      var avilab = '&availability=' + PICKUP_ID
      var multisubcateDetails = []
      this.state.categoryList.map((item, index) => {
        axios
          .get(
            API_URL +
              'apiv2/' +
              apiURL +
              '/getAllProducts?app_id=' +
              APP_ID +
              avilab +
              '&category_slug=' +
              item.pro_cate_slug +
              '&subcate_slug=&outletId=' +
              outletID
          )
          .then(res => {
            if (res.data.status === 'ok') {
              let subcate = res.data.result_set[0]

              multisubcateDetails[item.pro_cate_slug] = subcate.subcategorie

              if (this.state.categoryList.length - 1 == index) {
                this.setState(
                  { multisubcateDetails: multisubcateDetails },
                  function() {
                    this.loadInitialProducts(this.props.navItems)
                  }
                )
              }
            }
          })
      })
    }
  }

  getProductDetails(cateSlug, subCateSlug) {
    cateSlug =
      typeof cateSlug !== 'undefined' && cateSlug !== null ? cateSlug : ''
    subCateSlug =
      typeof subCateSlug !== 'undefined' && subCateSlug !== null
        ? subCateSlug
        : ''

    var avId = getCookies('orderAvailability')
    var outletID = getCookies('orderOutletId')
    $('#pro_loading').show()
    axios
      .get(
        API_URL +
          'apiv2/products/getAllProducts?app_id=' +
          APP_ID +
          '&availability=' +
          PICKUP_ID +
          '&category_slug=' +
          cateSlug +
          '&subcate_slug=' +
          subCateSlug +
          '&outletId=' +
          outletID
        //"&source_type=Callcenter"
      )
      .then(res => {
        $('#pro_loading').hide()
        if (res.data.status === 'ok') {
          window.scrollTo(0, 0)
          let subcate = res.data.result_set[0]
          let subcateDetails = subcate.subcategorie[0]
          if (_.isEmpty(subcateDetails) === false) {
            var pro = _.chunk(subcateDetails.products, 4)
            this.setState({
              products: pro,
              productsCommon: res.data.common,
              productsLoading: false,
              subcateDetails: subcate.subcategorie
            })
            const proSlug =
              typeof this.props.match.params.proslug !== 'undefined' &&
              this.props.match.params.proslug !== ''
                ? this.props.match.params.proslug
                : ''
            if (proSlug !== '' && this.state.renderScreen === 0) {
              //this.showProductDetails(1, proSlug)
              const dataId = $('.' + cateSlug).data('id')
              this.setState({ renderScreen: 1, menuActive: dataId })
            }
            if (
              getCookies('selectedProductIndex') !== '' &&
              typeof getCookies('selectedProductIndex') !== undefined &&
              getCookies('selectedProductSlug') !== '' &&
              typeof getCookies('selectedProductSlug') !== undefined
            ) {
              this.showProductDetails(
                getCookies('selectedProductIndex'),
                getCookies('selectedProductSlug')
              )
            }
          } else {
            this.setState({ products: [], productsLoading: false })
          }
        } else {
          this.setState({ productsLoading: false })
        }
      })
  }

  toggleTabs(tab, cateSlug, subcateSlug, categoryName) {
    this.setState({ menuActive: tab })
    this.setState({ currentProductCategory: categoryName })
    this.getProductDetails(cateSlug, subcateSlug)
  }

  closeModal() {
    $('.product-details').hide()
    this.setState({ defaultQty: 1, product_remarks: '' })
  }

  productReview() {
    return (
      <div className="d-inline-block product-review-line">
        <span className="chef-pr-food" />
        <span className="fas fa-star checked" />
        <span className="fas fa-star checked" />
        <span className="fas fa-star checked" />
        <span className="fas fa-star" />
        <span className="fas fa-star" />
        <Link className="review-link" to="#">
          5 Reviews
        </Link>
      </div>
    )
  }

  showProductDetails(rowIndex, product_slug, id = null) {
    this.setState({ defaultIndex: '' })
    const availabilityId = getCookies('orderAvailability')
    if (availabilityId === '' || typeof availabilityId === undefined) {
      jquery('#SelectAvailabilityModalCenter').modal('show')
      cookie.save('selectedProductIndex', rowIndex)
      cookie.save('selectedProductSlug', product_slug)
      /*  cookie.remove('selectedProductIndex')
      cookie.remove('selectedProductSlug') */
      return false
    } else {
      cookie.remove('selectedProductIndex')
      cookie.remove('selectedProductSlug')
    }
    if (jquery('.cart-add-div:visible').length == 0) {
      jquery('.cart-add-div').show()
    }

    //	 this.setState({currentProduct : rowIndex });

    const productSlug = product_slug
    /*  if(id!=="" && id!==null) {
      showLoader('order_now_btn', 'class')
      $('#'+id' order_now_btn')
    } */
    this.setState({ prDetails: [] })
    var cust = ''
    if (customerId() !== '') {
      cust = '&customer_id=' + customerId()
    }

    axios
      .get(
        API_URL +
          'api/products/products_list?app_id=' +
          APP_ID +
          '&availability=' +
          availabilityId +
          '&product_slug=' +
          productSlug +
          cust
      )
      .then(res => {
        if (res.data.status === 'ok') {
          var result = res.data.result_set[0]
          if (result.modifiers !== null && result.modifiers.length > 0) {
            var selectedModifiers = []
            result.modifiers.map(function(item) {
              var modifiersVal = [
                {
                  modifier_value_id:
                    item.modifiers_values[0].current_alias_modifier_value_id,
                  modifier_value_qty: 1,
                  modifier_value_name:
                    item.modifiers_values[0].pro_modifier_value_name,
                  modifier_value_price:
                    item.modifiers_values[0].pro_modifier_value_price
                }
              ]
              selectedModifiers.push({
                modifier_id: item.pro_modifier_id,
                modifier_name: item.pro_modifier_name,
                modifiers_values: modifiersVal
              })
            })
            var modifier_value_id = ''
            var modProductId = result.product_id

            selectedModifiers.map(function(item) {
              modifier_value_id +=
                item.modifiers_values[0].modifier_value_id + ';'
            })
            modifier_value_id = modifier_value_id.substring(
              0,
              modifier_value_id.length - 1
            )
            this.validateModifiers(modProductId, modifier_value_id, 'modifiers')
          }

          this.setState({
            detailstatus: 'ok',
            prDetails: result,
            prCommon: res.data.common,
            productPrice: result.product_price,
            grandTotal: result.product_price,
            productUnitPrice: result.product_price,
            MinMaxSel: parseInt(result.product_apply_minmax_select),
            productName: result.product_name,
            currentProduct: rowIndex,
            selectedModifiers: selectedModifiers
          })
          $('.product-details').show()
          jquery('body').addClass('mobile-product-details-res')
          jquery('#productModalCenter').modal({
            backdrop: 'static',
            keyboard: false
          })
        } else {
          jquery('#noProductModal').modal({
            backdrop: 'static',
            keyboard: false
          })
        }
      })

    for (var m = 0, length = 20; m < length; m++) {
      this.setState({ [m]: '' })
      this.setState({ ['modifier' + m]: '' })
    }
  }

  decQty() {
    if (this.state.defaultQty !== 1) {
      this.setState({ defaultQty: this.state.defaultQty - 1 })
    }
  }

  incQty(productDetails) {
    var proqtyInput = this.state.defaultQty + 1
    var checkCartQty = this.checkCartQty(productDetails.product_id)
    var totalQtys = parseInt(checkCartQty) + parseInt(proqtyInput)
    var maxQty = parseInt(productDetails.product_maximum_quantity)
    if (maxQty > 0) {
      if (maxQty < totalQtys) {
        return false
      }
    }
    this.setState({ defaultQty: this.state.defaultQty + 1 })
  }

  checkCartQty(productID) {
    var totalQty = 0
    var cartItem = this.state.cartItem
    if (cartItem.length > 0) {
      cartItem.map(function(item) {
        if (
          parseInt(item.cart_item_qty) > 0 &&
          productID === item.cart_item_product_id
        ) {
          totalQty = parseInt(totalQty) + parseInt(item.cart_item_qty)
        }
      })
    }
    return totalQty
  }

  addToCart(productID) {
    this.setState({ defaultQty: 1 })
    $('.cart_btn').hide()
    $('.done_btn, .product-details').show()
  }

  addToCartAction(
    productId,
    proName,
    prosku,
    proPrice,
    proQty,
    proImage,
    allowCart,
    applyMinmaxSelect,
    Validate_mode,
    productType,
    productDetails
  ) {
    var postObject = {}

    var combo = []
    var modifier = []
    var comboSelction = 'Fail'
    var modifierSelction = 'Fail'
    var overAllQty = 0
    var totalMinSelect = 0

    var proqtyInput = parseInt(this.state.defaultQty)
    var minQty = parseInt(productDetails.product_minimum_quantity)
    var maxQty = parseInt(productDetails.product_maximum_quantity)
    var checkCartQty = this.checkCartQty(productDetails.product_id)

    var totalQtys = parseInt(checkCartQty) + parseInt(proqtyInput)

    if (maxQty > 0) {
      if (maxQty < totalQtys) {
        showInfo('Maximum selection limit exceed for ' + maxQty)
        return false
      }
    }
    /* if (minQty > 0) {
      if (minQty < totalQtys) {
        showInfo('Please select the min number of items for ' + minQty)
        return false
      }
    } */

    if (cookie.load('itemType') === 'Combo' && cookie.load('comboCount') > 0) {
      for (var m = 0, length = cookie.load('comboCount'); m < length; m++) {
        var _items = document.getElementsByClassName('hiddenInput_' + m)
        var selCurQty = 0
        var _cartQty = 0
        var selMincount = 0
        var selMaxcount = 0
        var selMinSetName = ''

        if (_items.length > 0) {
          var _itemCount = _items.length
          for (var i = 0; i < _itemCount; i++) {
            selCurQty = parseInt(
              document.getElementsByClassName('combinput_' + m)[i].innerHTML
            )
            _cartQty = _cartQty + selCurQty
          }
        } else {
          selCurQty = parseInt($('.combinput_' + m).html())

          _cartQty = _cartQty + selCurQty
        }

        overAllQty = overAllQty + _cartQty
        /* validate min max setup */
        selMincount = parseInt($('.combo-min-' + m).val())

        selMinSetName = $('.combo-min-name' + m).val()
        selMaxcount = parseInt($('.combo-max-' + m).val())

        totalMinSelect = totalMinSelect + selMincount

        /* if (_cartQty < selMincount) {
          showInfo('Please select the min number of items for ' + selMinSetName)

          return false
        } */

        if (_cartQty > selMaxcount) {
          showInfo('Maximum selection limit exceed for ' + selMinSetName)

          return false
        }

        /* add products  */
        if (overAllQty <= 0 && totalMinSelect > 0) {
          showInfo('Please choose any combo product combination')

          return false
        }

        var modifierrequired = $('#comboOptionValueModifierRequired_' + m).val()
        var modifierselected = $('#comboOptionValueModifier_' + m).val()

        if (modifierrequired === 'YES' && modifierselected === 'NO') {
          showInfo('Please choose any one shape')

          return false
        }

        var combovalues = $('#comboOptionValue_' + m).val()
        if (
          combovalues !== '' &&
          combovalues !== null &&
          typeof combovalues !== undefined &&
          typeof combovalues !== 'undefined'
        ) {
          var combodetails = JSON.parse(combovalues)
          combo.push(combodetails)
        }
      }
    }

    let API_REQUEST = ''
    if (productType == 1) {
      postObject = {
        app_id: APP_ID,
        product_id: productId,
        product_qty: this.state.defaultQty,
        pageToken: '$%%&(ZCWRT#^FG$%^#D$',
        reference_id: getReferenceID(),
        customer_id: customerId(),
        availability_name:
          getCookies('orderAvailability') === DINEIN_ID
            ? dineInText
            : pickUpText,
        availability_id: getCookies('orderAvailability'),
        product_type: 1,
        outletzone_id: getCookies('orderOutletId'),
        outlet_id: getCookies('orderZoneID'),
        product_remarks: this.state.product_remarks
      }
      API_REQUEST = API_URL + 'apiv2/cart/simpleCartInsert'
    } else if (productType == 4) {
      postObject = {
        app_id: APP_ID,
        product_id: productId,
        product_modifier_parent_id: this.state.modParentProductId,
        product_qty: this.state.defaultQty,
        reference_id: getReferenceID(),
        customer_id: customerId(),
        availability_name:
          getCookies('orderAvailability') === DINEIN_ID
            ? dineInText
            : pickUpText,
        availability_id: getCookies('orderAvailability'),
        product_type: 4,
        /*  outletzone_id: getCookies('orderOutletId'),
        outlet_id: getCookies('orderZoneID'), */
        modifiers: JSON.stringify(this.state.selectedModifiers),
        product_remarks: this.state.product_remarks
      }
      // return false
      API_REQUEST = API_URL + 'apiv2/cart/simpleCartInsert'
    } else if (productType == 2) {
      var totalPrice = proQty * this.state.grandTotal

      postObject = {
        app_id: APP_ID,
        product_id: productId,
        subquantity: cookie.load('subquantity'),
        notsubquantity: cookie.load('notsubquantity'),
        product_name: stripslashes(proName),
        product_sku: prosku,
        reference_id: getReferenceID(),
        customer_id: customerId(),
        product_image: proImage,
        availability_id: getCookies('orderAvailability'),
        product_unit_price: proPrice,
        product_qty: proQty,
        product_total_price: totalPrice,
        modifiers: JSON.stringify(modifier),
        menu_set_component: JSON.stringify(combo),
        product_remarks: this.state.product_remarks
      }

      API_REQUEST = API_URL + 'api/cart/insert'
    }
    showLoader('add_to_cart', 'class')
    $('#proLoading').show()
    axios.post(API_REQUEST, qs.stringify(postObject)).then(res => {
      $('#proLoading').hide()
      hideLoader('add_to_cart', 'class')
      this.setState({ cartAction: true })
      setTimeout(
        function() {
          this.setState({ cartAction: false })
        }.bind(this),
        1000
      )

      if (res.data.status === 'ok') {
        this.resetPromoState()
        jquery('body').removeClass('mobile-product-details-res')
        jquery('#productModalCenter').modal('hide')
        cookie.remove('modiferCount')
        cookie.remove('comboCount')
        cookie.remove('itemType')
        $('.comboOptionValueDetails').val('')
        $('.combo_incdec').html('0')
        $('.done_btn').hide()
        $('.cart_btn').show()
        this.closeModal()
        this.setState({
          defaultQty: 1,
          product_remarks: '',
          cartAlert: true,
          cartAlertMsg: res.data.message,
          reloadCart: true
          /* alertType: SUCCESS_ALERT */
        })

        setTimeout(
          function() {
            this.setState({ reloadCart: false })
          }.bind(this),
          1000
        )
        showInfo(res.data.message)
      } else if (res.data.status === 'error') {
        this.setState({
          cartAlert: true,
          cartAlertMsg: res.data.message
          /*  alertType: FAILURE_ALERT */
        })
        showInfo(res.data.message)
      }
    })
  }

  resetPromoState() {
    if (
      getCookies('promotionId') !== '' &&
      typeof getCookies('promotionId') !== undefined &&
      typeof getCookies('promotionId') !== 'undefined'
    ) {
      var postObject = {}
      postObject = {
        app_id: APP_ID,
        promotionId: getCookies('promotionId'),
        customer_id: getCookies('UserId'),
        availability_id: getCookies('orderAvailability')
      }

      // showLoader('citem_' + cartItemId, 'id')
      axios
        .post(API_URL + 'api/cart/removepromo', qs.stringify(postObject))
        .then(res => {
          this.setState({ cartAlert: true, reloadCart: true, cartAction: true })
          setTimeout(
            function() {
              this.setState({ cartAction: false })
            }.bind(this),
            1000
          )
          cookie.remove('promotionApplied')
          cookie.remove('promotionAmount')
          cookie.remove('promocode')
          cookie.remove('promotionCategory')
          cookie.remove('promotionId')
        })
    }
  }

  handleChangeDescription(event) {
    this.setState({ product_remarks: event.target.value })
    //this.setState({ remaining: 30 - event.target.value.length });
  }

  /* ############################# ##############################*/

  /* show combo product option */
  showComboOptions(products, minMaxSelect) {
    cookie.save('comboCount', products.length)
    cookie.save('itemType', 'Combo')
    if (products.length > 0) {
      const html = products.map((item, index) => (
        <div
          className={
            parseInt(item.menu_component_modifier_apply) === 0 &&
            parseInt(minMaxSelect) === 1
              ? 'product_chosen_item_left product_chosen_item_left_full'
              : 'product_chosen_item_left product_chosen_item_left_full'
          }
          key={index}>
          <div className="accordion_head">
            <div
              className={
                parseInt(item.menu_component_modifier_apply) === 0 &&
                parseInt(minMaxSelect) === 1
                  ? 'product_chosen_addons'
                  : ''
              }>
              <div className="product_chosen_hea">
                <h6>{stripslashes(item.menu_set_component_name)}</h6>
              </div>
            </div>
            <span className="plusminus">-</span>
          </div>
          <div className="accordion_body">
            {item.menu_component_min_select >= 1 && (
              <div
                className="max-min-bar"
                style={{
                  display: this.showMinmaxselectionBar(
                    parseInt(item.menu_component_modifier_apply),
                    parseInt(minMaxSelect)
                  )
                }}>
                Select {item.menu_component_min_select} Option
                {item.menu_component_min_select > 1 ? 's' : ''}
              </div>
            )}
            <div
              className="max-min-bar"
              style={{
                display: this.showMinmaxBar(
                  parseInt(item.menu_component_modifier_apply),
                  parseInt(minMaxSelect)
                )
              }}>
              You've chosen <span className={'minSele-' + index}>0</span>{' '}
              {item.menu_component_min_select > 1 &&
                'out of ' + item.menu_component_min_select}{' '}
              (Min. {item.menu_component_min_select} & Max.{' '}
              {item.menu_component_max_select}){' '}
            </div>
            <input
              type="hidden"
              className={'combo-min-' + index}
              value={item.menu_component_min_select}
            />
            <input
              type="hidden"
              className={'combo-min-name' + index}
              value={stripslashes(item.menu_set_component_name)}
            />
            <input
              type="hidden"
              className={'comboSelectedPrice combo_selected_price_' + index}
            />
            <input
              type="hidden"
              className={'combo-max-' + index}
              value={item.menu_component_max_select}
            />
            <input
              type="hidden"
              className={'combo-apply-price-' + index}
              value={item.menu_component_apply_price}
            />
            <input type="hidden" className="max_reached" />
            {parseInt(item.menu_component_modifier_apply) === 1 &&
              this.showComboAsGrid(
                item.product_details,
                index,
                item.menu_set_component_name,
                item.menu_set_component_id,
                item.menu_component_pieces_count,
                item.menu_component_min_select,
                item.menu_component_max_select,
                item.menu_component_apply_price,
                item.menu_component_default_select,
                item.menu_component_single_selection
              )}
            {parseInt(item.menu_component_modifier_apply) === 0 &&
              parseInt(minMaxSelect) === 0 &&
              this.showComboAsGrid(
                item.product_details,
                index,
                item.menu_set_component_name,
                item.menu_set_component_id,
                item.menu_component_pieces_count,
                item.menu_component_min_select,
                item.menu_component_max_select,
                item.menu_component_apply_price,
                item.menu_component_default_select,
                item.menu_component_single_selection
              )}
            {parseInt(item.menu_component_modifier_apply) === 0 &&
              parseInt(minMaxSelect) === 1 &&
              this.showComboAsCount(
                item.product_details,
                index,
                item.menu_set_component_name,
                item.menu_set_component_id,
                item.menu_component_pieces_count,
                item.menu_component_min_select,
                item.menu_component_max_select,
                item.menu_component_apply_price,
                item.menu_component_single_selection
              )}
          </div>
        </div>
      ))
      return html
    }
  }

  showModifierOptions(modifiers, mindex) {
    if (modifiers.length > 0) {
      const html = modifiers.map((item, index) => (
        <div
          className="product_chosen_item_left product_chosen_item_left_full"
          key={index}
          id={'modifylist_' + mindex + '_' + index}>
          <input
            type="hidden"
            className={'combo-min-' + index}
            value={item.pro_modifier_min_select}
          />
          <input
            type="hidden"
            className={'combo-max-' + index}
            value={item.pro_modifier_max_select}
          />
          <input
            type="hidden"
            className={'combo-min-name' + index}
            value={stripslashes(item.pro_modifier_name)}
          />
          <input
            type="hidden"
            className={'combo-apply-price-' + index}
            value={item.menu_component_apply_price}
          />
          <input type="hidden" className="max_reached" />
          <div className="prochoosen_row">
            <div className="product-size-options-block">
              {item.pro_modifier_name}
            </div>
            {this.loadModifierValues(
              item.modifiers_values,
              mindex,
              index,
              item.pro_modifier_name,
              item.pro_modifier_id
            )}
          </div>
        </div>
      ))
      return html
    }
  }
  loadModifierValues(
    modifiers_values,
    mindex,
    sindex,
    pro_modifier_name,
    pro_modifier_id
  ) {
    if (
      modifiers_values !== '' &&
      modifiers_values.length > 0 &&
      typeof modifiers_values !== undefined
    ) {
      var Modifier = []
      var defaultModify = []

      modifiers_values.map(function(item, index) {
        if (index === 0) {
          defaultModify.push({
            value: item.pro_modifier_value_id,
            label: item.pro_modifier_value_name,
            productID: mindex,
            sindex: sindex
          })
        }
        var priceValue = ''
        if (item.pro_modifier_value_price > 0) {
          priceValue = ' (' + item.pro_modifier_value_price + ')'
        }
        Modifier.push({
          value: item.pro_modifier_value_id,
          label: item.pro_modifier_value_name + priceValue,
          productID: mindex,
          sindex: sindex,
          modifierdetails: item,
          pro_modifier_name: pro_modifier_name,
          pro_modifier_id: pro_modifier_id
        })
      })

      var modifierHtml = (
        <Select
          onChange={this.handleChangeSingleModifier}
          options={Modifier}
          styles={customStyles}
          defaultValue={defaultModify}
          className="react-select-control"
        />
      )
      const html = <div className="re_select">{modifierHtml}</div>
      $('#modifierOptionValue').val()
      return html
    }
  }
  /*  hide minmax bar worksapce */
  showMinmaxBar(val1, val2) {
    return val1 === 0 && val2 === 1 ? 'block' : 'none'
  }

  /*  hide minmax bar worksapce */
  showMinmaxselectionBar(val1, val2) {
    if (val1 === 1) {
      return 'block'
    } else if (val1 === 0 && val2 === 1) {
      return 'block'
    } else {
      return 'none'
    }
  }

  /* Combo with Count options */
  showComboAsCount(
    products,
    mIndex,
    comboName,
    comboId,
    combopieces,
    minSelect,
    maxSelect,
    applyPrice,
    single_selection
  ) {
    var comboascountlist = []
    $.each(products, function(index, item) {
      var product_details = {
        product_id: item[0].product_id,
        product_name:
          item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name),
        product_sku: item[0].product_sku,
        product_qty: '1',
        product_price: item[0].product_price,
        modifiers: []
      }
      var combodetails = {
        menu_component_id: comboId,
        menu_component_name: comboName,
        menu_component_pieces: combopieces
      }
      comboascountlist.push({
        combodetails: combodetails,
        product_details: product_details
      })
    })
    const html = products.map((item, index) => (
      <li
        className="chosen_adn individual_combo_pro"
        data-invcomboprice={0}
        key={index}
        id={'combo_' + item[0].product_id + '_' + mIndex + '_' + index}>
        <div className="bb-txt2 margin-15 chosen_adn_left">
          {single_selection === '1' && (
            <img
              src={
                item[0].product_thumbnail !== ''
                  ? this.state.productsCommon.product_image_source +
                    '/' +
                    item[0].product_thumbnail
                  : noImage
              }
              alt=""
            />
          )}
          <h5>
            {item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias)
              : stripslashes(item[0].product_name)}
          </h5>{' '}
          {item[0].product_price > 0 && (
            <span
              className="combo_pro_price"
              style={{ display: applyPrice === '1' ? '' : 'none' }}>
              ( +{item[0].product_price} )
            </span>
          )}
        </div>
        <div className="radio combo-inc-parent chosen_adn_right">
          <div className="qty_bx">
            <span
              className="qty_minus combo_inc"
              onClick={this.incComboQty.bind(
                this,
                minSelect,
                maxSelect,
                mIndex,
                item[0].product_id + '_' + mIndex + '_' + index,
                'remove',
                item[0].product_price,
                item[0].product_id,
                applyPrice,
                comboascountlist[index]
              )}>
              -
            </span>

            <label
              type="text"
              id={item[0].product_id + '_' + mIndex + '_' + index}
              disabled
              value="0"
              className={
                'combo-input-label combo-input combo_incdec combinput_' + mIndex
              }>
              0
            </label>
            <input type="hidden" className={'comboprice_' + mIndex} />
            <input
              type="hidden"
              className={'hiddenInput_' + mIndex}
              defaultValue={item[0].product_price}
            />

            <input
              className={
                item[0].product_id + '_' + mIndex + '_' + index + ' css-radio'
              }
              type="hidden"
              value={
                comboName +
                '~' +
                comboId +
                '~' +
                stripslashes(
                  item[0].product_alias !== ''
                    ? stripslashes(item[0].product_alias)
                    : stripslashes(item[0].product_name)
                ) +
                '~' +
                item[0].product_id +
                '~' +
                item[0].product_sku +
                '~' +
                item[0].product_price
              }
            />

            <span
              className="qty_plus combo_dec"
              onClick={this.incComboQty.bind(
                this,
                minSelect,
                maxSelect,
                mIndex,
                item[0].product_id + '_' + mIndex + '_' + index,
                'add',
                item[0].product_price,
                item[0].product_id,
                applyPrice,
                comboascountlist[index]
              )}>
              +
            </span>
          </div>
        </div>
      </li>
    ))

    const countoption = (
      <div className="count-option-main">
        <ul
          className={
            single_selection === '1'
              ? 'count-option-one'
              : 'count-option-one combo-widthout-image'
          }>
          {html}
        </ul>
        <input
          type="hidden"
          className="comboOptionValueDetails"
          id={'comboOptionValue_' + mIndex}
        />{' '}
        <input type="hidden" id={'comboOptionValuePrice_' + mIndex} />
      </div>
    )
    return countoption
  }

  /* Show only Dropdown options */
  showComboAsGrid(
    products,
    mIndex,
    comboName,
    comboId,
    combopieces,
    minSelect,
    maxSelect,
    applyPrice,
    defaultProduct,
    single_selection
  ) {
    var comboProducts = []
    var defaultSelection = []
    var modLen = 0
    var multi = false
    if (maxSelect > 1) {
      multi = true
    }
    var modifierHtml = ''
    var defaultIndex = ''
    var defaultProductID = ''
    $.each(products, function(index, item) {
      modLen = item[0].modifiers !== null ? item[0].modifiers.length : 0
      var shwPriceHtml = ''

      if (
        parseFloat(item[0].product_price) > 0 &&
        parseInt(applyPrice) === 1 &&
        item[0].modifiers === null
      ) {
        shwPriceHtml = ' (+ $' + item[0].product_price + ')'
      }
      var product_details = {
        product_id: item[0].product_id,
        product_name:
          item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name),
        product_sku: item[0].product_sku,
        product_qty: '1',
        product_price: shwPriceHtml !== '' ? item[0].product_price : 0,
        modifiers: item[0].modifiers
      }
      var combodetails = {
        menu_component_id: comboId,
        menu_component_name: comboName,
        menu_component_pieces: combopieces
      }
      if (defaultProduct === item[0].product_id) {
        defaultIndex = index
        defaultProductID = item[0].product_id
        defaultSelection.push({
          subquantity: item[0].product_sub_modifier_qty,
          product_id: item[0].product_id,
          product_details: product_details,
          combodetails: combodetails,
          value:
            comboName +
            '~' +
            comboId +
            '~' +
            (item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias)
              : stripslashes(item[0].product_name)) +
            '~' +
            item[0].product_id +
            '~' +
            item[0].product_sku +
            '~' +
            applyPrice +
            '~' +
            item[0].product_price,
          label:
            item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias) + shwPriceHtml
              : stripslashes(item[0].product_name) + shwPriceHtml,
          modLen: modLen,
          id: item[0].product_id + '_' + mIndex + '_' + index,
          mIndex: mIndex,
          refVal:
            minSelect +
            '~' +
            maxSelect +
            '~' +
            mIndex +
            '~' +
            item[0].product_id +
            '_' +
            mIndex +
            '_' +
            index +
            '~' +
            item[0].product_price +
            '~' +
            item[0].product_id +
            '~' +
            applyPrice
        })
      }
      comboProducts.push({
        subquantity: item[0].product_sub_modifier_qty,
        product_id: item[0].product_id,
        product_details: product_details,
        combodetails: combodetails,
        value:
          comboName +
          '~' +
          comboId +
          '~' +
          (item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name)) +
          '~' +
          item[0].product_id +
          '~' +
          item[0].product_sku +
          '~' +
          applyPrice +
          '~' +
          item[0].product_price,
        label:
          item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias) + shwPriceHtml
            : stripslashes(item[0].product_name) + shwPriceHtml,
        modLen: modLen,
        id: item[0].product_id + '_' + mIndex + '_' + index,
        mIndex: mIndex,
        refVal:
          minSelect +
          '~' +
          maxSelect +
          '~' +
          mIndex +
          '~' +
          item[0].product_id +
          '_' +
          mIndex +
          '_' +
          index +
          '~' +
          item[0].product_price +
          '~' +
          item[0].product_id +
          '~' +
          applyPrice
      })
    })

    if (defaultSelection !== '' && typeof defaultSelection.id !== undefined) {
      this.setDefaultCombo(
        mIndex,
        defaultSelection,
        comboName,
        comboId,
        combopieces
      )
    }
    if (single_selection === '0') {
      var dropdown = (
        <Select
          styles={customStyles}
          className="react-select-control"
          multi={multi}
          /* value={this.state.selected_value} */
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          onChange={this.handleChange}
          options={comboProducts}
          defaultValue={defaultSelection}
        />
      )
    }
    return (
      <div>
        <div className="form-group">
          <div className="product-size-options-block">
            {/* this.loadModifierList(comboProducts, mIndex) */}
          </div>
          <ul
            className={
              'scourt-combo combo_product_details combo_mofi_' + mIndex
            }>
            {single_selection === '1'
              ? this.loadComboList(
                  mIndex,
                  products,
                  applyPrice,
                  comboId,
                  comboName,
                  combopieces,
                  minSelect,
                  maxSelect,
                  defaultProduct
                )
              : ''}
          </ul>
          <div className={'re_select re_select_' + mIndex}>
            {single_selection === '0' && (
              <ul>
                {this.loadProductList(
                  comboProducts,
                  mIndex,
                  defaultIndex,
                  defaultSelection,
                  defaultProductID
                )}
                {/* dropdown */}
              </ul>
            )}
            <label
              style={{ display: 'none' }}
              type="text"
              className={'combo-input combinput_' + mIndex}>
              0
            </label>
            <input
              type="hidden"
              id={'comboOptionValueModifierRequired_' + mIndex}
            />
            <input type="hidden" id={'comboOptionValueModifier_' + mIndex} />
            <input
              type="hidden"
              className="comboOptionValueDetails"
              id={'comboOptionValue_' + mIndex}
            />
            <input type="hidden" id={'comboOptionValuePrice_' + mIndex} />
          </div>
        </div>
      </div>
    )
  }
  loadProductList(
    comboProducts,
    mindex,
    defaultIndex,
    defaultSelection,
    defaultProductID
  ) {
    var currentthisradio = this
    if (comboProducts !== '') {
      return comboProducts.map(function(item, index) {
        var modifierHtml = currentthisradio.showCombomodifierOptions(
          item,
          mindex,
          defaultSelection,
          defaultProductID
        )
        return (
          <li key={index}>
            <label className="radio-try">
              <input
                type="radio"
                name={'combo_set_product_' + mindex}
                value={JSON.stringify(item)}
                id={'ncombo_set_product_' + mindex}
                onChange={currentthisradio.handleChangeRadio}
                defaultChecked={index === defaultIndex ? true : false}
              />
              <span>{item.label}</span>
            </label>
            <div className="form-group form-group-modifier">
              <div
                className={'re_select_modifier re_select_modifier_' + mindex}>
                <input
                  type="hidden"
                  className="totalComboModifierPrice"
                  value=""
                />
                {modifierHtml}
                {/* single_selection === '0' ? modifierHtml : '' */}
              </div>
            </div>
          </li>
        )
      })
    }
  }

  loadComboList(
    mIndex,
    products,
    applyPrice,
    comboId,
    comboName,
    combopieces,
    minSelect,
    maxSelect,
    defaultProduct
  ) {
    if (products !== '') {
      const pro_Imsg_src = API_URL + 'media/dev_team/products/main-image/'
      let currentthis = this
      let productDetails = products.map(function(item, index) {
        var product = item[0]
        var combodetails = {
          menu_component_id: comboId,
          menu_component_name: comboName,
          menu_component_pieces: combopieces
        }
        let modLen = item[0].modifiers !== null ? item[0].modifiers.length : 0
        let shwPriceHtml = ''
        if (
          parseFloat(item[0].product_price) > 0 &&
          parseInt(applyPrice) === 1 &&
          item[0].modifiers === null
        ) {
          shwPriceHtml = ' (+ $' + item[0].product_price + ')'
        }
        var product_details = {
          product_id: item[0].product_id,
          product_name:
            item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias)
              : stripslashes(item[0].product_name),
          product_sku: item[0].product_sku,
          product_qty: '1',
          product_price: shwPriceHtml !== '' ? item[0].product_price : 0,
          modifiers: item[0].modifiers
        }

        let comboProducts = {
          subquantity: item[0].product_sub_modifier_qty,
          product_id: item[0].product_id,
          product_details: product_details,
          combodetails: combodetails,
          value:
            comboName +
            '~' +
            comboId +
            '~' +
            (item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias)
              : stripslashes(item[0].product_name)) +
            '~' +
            item[0].product_id +
            '~' +
            item[0].product_sku +
            '~' +
            applyPrice +
            '~' +
            item[0].product_price,
          modLen: modLen,
          id: item[0].product_id + '_' + mIndex + '_' + index,
          mIndex: mIndex,
          refVal:
            minSelect +
            '~' +
            maxSelect +
            '~' +
            mIndex +
            '~' +
            item[0].product_id +
            '_' +
            mIndex +
            '_' +
            index +
            '~' +
            item[0].product_price +
            '~' +
            item[0].product_id +
            '~' +
            applyPrice
        }
        return (
          <li className="single_combo_products" key={index}>
            <a
              href="javascript:;"
              className="prd_close"
              style={{
                display:
                  defaultProduct === product.product_id ? 'block' : 'none'
              }}
              data-index={mIndex}>
              <img src={cross_symbol} alt="close" />
            </a>
            <div className="sc-main">
              <div className="sc-main-inner">
                <img
                  src={
                    product.product_thumbnail !== ''
                      ? pro_Imsg_src + product.product_thumbnail
                      : noImage
                  }
                  alt=""
                />
              </div>
              <div className="sc-main-text">
                <h5 className="text_center">
                  {product.product_alias !== ''
                    ? product.product_alias
                    : product.product_name}
                </h5>
                {parseFloat(item[0].product_price) > 0 &&
                  parseInt(applyPrice) === 1 && (
                    <div className="text_center">
                      <b className="">${item[0].product_price}</b>
                    </div>
                  )}
                {product.modifiers !== null &&
                typeof product.modifiers !== undefined &&
                typeof product.modifiers !== 'undefined'
                  ? currentthis.loadModifiers(product.modifiers, mIndex)
                  : ''}
                <div
                  className="order_now_btn"
                  style={{
                    display:
                      defaultProduct === product.product_id ? 'none' : 'block'
                  }}>
                  <button
                    type="button"
                    data-product={JSON.stringify(comboProducts)}
                    data-index={mIndex}
                    className="add-to-btn-cart btn btn-primary btn-block select_single_combo">
                    Select
                  </button>
                </div>
              </div>
            </div>
          </li>
        )
      })
      return productDetails
    }
  }
  loadModifiers(modifiers, mIndex) {
    if (modifiers !== '') {
      let currentthis = this
      let modi = modifiers.map(function(item, index) {
        return (
          <div key={index} className="both-merge combo_modifier_product">
            <h5>{item.pro_modifier_name}</h5>
            {currentthis.loadModifierValue(
              item,
              item.modifiers_values,
              item.pro_modifier_id,
              mIndex
            )}
          </div>
        )
      })
      return modi
    }
  }
  loadModifierValue(modifier, modifierValue, pro_modifier_id, mIndex) {
    if (modifierValue !== '') {
      let modi = modifierValue.map(function(item, index) {
        var combomodifiervalues = []
        combomodifiervalues.push({
          modifier_value_id: item.pro_modifier_value_id,
          modifier_value_qty: '1',
          modifier_value_name: item.pro_modifier_value_name,
          modifier_value_price: item.pro_modifier_value_price
        })
        var combomodifiername = []
        combomodifiername.push({
          modifier_id: modifier.pro_modifier_id,
          modifier_name: modifier.pro_modifier_name
            .replace(' (Square)', '')
            .replace(' (Both Shapes)', '')
            .replace(' (Round)', ''),
          modifiers_values: combomodifiervalues
        })
        var mPrice =
          item.pro_modifier_value_price > 0
            ? ' ( + $' + item.pro_modifier_value_price + ' )'
            : ''
        let comboModifier = {
          mIndex: mIndex,
          single_selection: 0,
          modifier: combomodifiername,
          value:
            modifier.pro_modifier_name +
            '~' +
            modifier.pro_modifier_id +
            '~' +
            item.pro_modifier_value_name +
            '~' +
            item.pro_modifier_value_id +
            '~' +
            item.pro_modifier_value_modifier_id +
            '~' +
            item.pro_modifier_value_price
        }
        return (
          <div className="modify-flex">
            <label className="radio-try">
              <input
                type="radio"
                name={'modify_' + pro_modifier_id}
                data-modifier={JSON.stringify(comboModifier)}
                className="modifier_options"
                defaultChecked={
                  index == 0 || item.pro_modifier_value_is_default == 'Yes'
                    ? true
                    : false
                }
              />
              <span>
                {item.pro_modifier_value_name}
                {mPrice}
              </span>
            </label>
          </div>
        )
      })
      return modi
    }
  }
  showComboAsDropDown(
    products,
    mIndex,
    comboName,
    comboId,
    combopieces,
    minSelect,
    maxSelect,
    applyPrice,
    defaultProduct
  ) {
    var comboProducts = []
    var defaultSelection = []
    var modLen = 0
    var multi = false
    if (maxSelect > 1) {
      multi = true
    }

    var modifierHtml = ''
    $.each(products, function(index, item) {
      modLen = item[0].modifiers !== null ? item[0].modifiers.length : 0
      var shwPriceHtml = ''

      if (
        parseFloat(item[0].product_price) > 0 &&
        parseInt(applyPrice) === 1 &&
        item[0].modifiers === null
      ) {
        shwPriceHtml = ' (+ $' + item[0].product_price + ')'
      }
      var product_details = {
        product_id: item[0].product_id,
        product_name:
          item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name),
        product_sku: item[0].product_sku,
        product_qty: '1',
        product_price: shwPriceHtml !== '' ? item[0].product_price : 0,
        modifiers: item[0].modifiers
      }
      var combodetails = {
        menu_component_id: comboId,
        menu_component_name: comboName,
        menu_component_pieces: combopieces
      }
      if (defaultProduct === item[0].product_id) {
        defaultSelection.push({
          subquantity: item[0].product_sub_modifier_qty,
          product_id: item[0].product_id,
          product_details: product_details,
          combodetails: combodetails,
          value:
            comboName +
            '~' +
            comboId +
            '~' +
            (item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias)
              : stripslashes(item[0].product_name)) +
            '~' +
            item[0].product_id +
            '~' +
            item[0].product_sku +
            '~' +
            applyPrice +
            '~' +
            item[0].product_price,
          label:
            item[0].product_alias !== ''
              ? stripslashes(item[0].product_alias) + shwPriceHtml
              : stripslashes(item[0].product_name) + shwPriceHtml,
          modLen: modLen,
          id: item[0].product_id + '_' + mIndex + '_' + index,
          mIndex: mIndex,
          refVal:
            minSelect +
            '~' +
            maxSelect +
            '~' +
            mIndex +
            '~' +
            item[0].product_id +
            '_' +
            mIndex +
            '_' +
            index +
            '~' +
            item[0].product_price +
            '~' +
            item[0].product_id +
            '~' +
            applyPrice
        })
      }
      comboProducts.push({
        subquantity: item[0].product_sub_modifier_qty,
        product_id: item[0].product_id,
        product_details: product_details,
        combodetails: combodetails,
        value:
          comboName +
          '~' +
          comboId +
          '~' +
          (item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias)
            : stripslashes(item[0].product_name)) +
          '~' +
          item[0].product_id +
          '~' +
          item[0].product_sku +
          '~' +
          applyPrice +
          '~' +
          item[0].product_price,
        label:
          item[0].product_alias !== ''
            ? stripslashes(item[0].product_alias) + shwPriceHtml
            : stripslashes(item[0].product_name) + shwPriceHtml,
        modLen: modLen,
        id: item[0].product_id + '_' + mIndex + '_' + index,
        mIndex: mIndex,
        refVal:
          minSelect +
          '~' +
          maxSelect +
          '~' +
          mIndex +
          '~' +
          item[0].product_id +
          '_' +
          mIndex +
          '_' +
          index +
          '~' +
          item[0].product_price +
          '~' +
          item[0].product_id +
          '~' +
          applyPrice
      })
    })
    var modifierHtml = this.showCombomodifierOptions(
      products,
      mIndex,
      defaultSelection
    )
    if (defaultSelection !== '' && typeof defaultSelection.id !== undefined) {
      this.setDefaultCombo(
        mIndex,
        defaultSelection,
        comboName,
        comboId,
        combopieces
      )
    }

    var dropdown = (
      <Select
        styles={customStyles}
        className="react-select-control"
        multi={multi}
        /*  value={this.state.selected_value} */
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        onChange={this.handleChange}
        options={comboProducts}
        defaultValue={defaultSelection}
      />
    )
    return (
      <div>
        <div className="form-group">
          <div className="product-size-options-block">
            {/* this.loadModifierList(comboProducts, mIndex) */}
          </div>

          <div className={'re_select re_select_' + mIndex}>
            {dropdown}{' '}
            {/* <div className="pmodifier_ddlist">
              <ul>{this.state[mIndex]}</ul>
            </div> */}
            <label
              style={{ display: 'none' }}
              type="text"
              className={'combo-input combinput_' + mIndex}>
              0
            </label>
            <input
              type="hidden"
              id={'comboOptionValueModifierRequired_' + mIndex}
            />
            <input type="hidden" id={'comboOptionValueModifier_' + mIndex} />
            <input
              type="hidden"
              className="comboOptionValueDetails"
              id={'comboOptionValue_' + mIndex}
            />
            <input type="hidden" id={'comboOptionValuePrice_' + mIndex} />
          </div>
        </div>
        <div className="form-group form-group-modifier">
          <div className={'re_select_modifier re_select_modifier_' + mIndex}>
            <input type="hidden" className="totalComboModifierPrice" value="" />
            {modifierHtml}
            {/* <div className="pmodifier_ddlist">
              <ul>{this.state['modifier' + mIndex]}</ul>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  setDefaultCombo(mIndex, defaultSelection, comboName, comboId, combopieces) {
    if (
      defaultSelection !== '' &&
      typeof defaultSelection !== undefined &&
      typeof defaultSelection !== 'undefined' &&
      defaultSelection.length > 0
    ) {
      var defaultPro = defaultSelection[0].product_details
      var modifiers = []
      if (
        defaultPro.modifiers != '' &&
        defaultPro.modifiers !== undefined &&
        defaultPro.modifiers !== null
      ) {
        if (defaultPro.modifiers.length > 0) {
          defaultPro.modifiers.map(function(item) {
            var modifierValue = []
            if (item.modifiers_values.length > 0) {
              modifierValue = [
                {
                  modifier_value_id:
                    item.modifiers_values[0].pro_modifier_value_id,
                  modifier_value_qty: 1,
                  modifier_value_name:
                    item.modifiers_values[0].pro_modifier_value_name,
                  modifier_value_price:
                    item.modifiers_values[0].pro_modifier_value_price
                }
              ]
            }
            modifiers.push({
              modifier_id: item.pro_modifier_id,
              modifier_name: item.pro_modifier_name,
              modifiers_values: modifierValue
            })
          })
        }
      }
      var ProductDetails = {
        menu_component_id: comboId,
        menu_component_name: comboName,
        menu_component_pieces: combopieces,
        product_details: [
          {
            product_id: defaultPro.product_id,
            product_name: defaultPro.product_name,
            product_sku: defaultPro.product_sku,
            product_qty: defaultPro.product_qty,
            product_price: defaultPro.product_price,
            modifiers: modifiers
          }
        ]
      }
      var current = this
      setTimeout(
        function() {
          $('.combinput_' + mIndex).html(1)
          if (current.state.defaultIndex.indexOf(mIndex + ',') < 0) {
            $('#comboOptionValue_' + mIndex).val(JSON.stringify(ProductDetails))
            var defaultIndex = current.state.defaultIndex + mIndex + ','
            current.calculatePrice()
            current.setState({ defaultIndex: defaultIndex })
          }
        }.bind(this),
        3000
      )
    }
  }

  /* show combo product option */
  showCombomodifierOptions(
    item,
    mIndex,
    defaultSelection,
    defaultProductID = null
  ) {
    if (item !== '') {
      /* const html = products.map((item, index) => ( */
      if (
        item.product_details !== '' &&
        item.product_details.modifiers !== '' &&
        typeof item.product_details.modifiers !== undefined
      ) {
        return (
          <div
            style={{
              display:
                defaultProductID !== '' &&
                defaultProductID === item.product_details.product_id
                  ? ''
                  : 'none'
            }}
            className={
              'select_modifier select_modifier_' +
              item.product_details.product_id
            }>
            {this.showCombomodifierName(item.product_details.modifiers, mIndex)}
          </div>
        )
      }

      /* )) */
      //  return html
    }
  }

  /* show combo product option */
  showCombomodifierName(modifiers, mIndex) {
    var current = this
    if (modifiers !== null && modifiers.length > 0) {
      return modifiers.map(function(item, index) {
        if (
          item.modifiers_values !== null &&
          item.modifiers_values.length > 0
        ) {
          var comboModifier = []
          var modifierHtml = ''
          var defaultComboModify = []
          item.modifiers_values.map(function(items, index) {
            var combomodifiervalues = []
            combomodifiervalues.push({
              modifier_value_id: items.pro_modifier_value_id,
              modifier_value_qty: '1',
              modifier_value_name: items.pro_modifier_value_name,
              modifier_value_price: items.pro_modifier_value_price
            })
            var combomodifiername = []
            combomodifiername.push({
              modifier_id: item.pro_modifier_id,
              modifier_name: item.pro_modifier_name
                .replace(' (Square)', '')
                .replace(' (Both Shapes)', '')
                .replace(' (Round)', ''),
              modifiers_values: combomodifiervalues
            })
            var mPrice =
              items.pro_modifier_value_price > 0
                ? ' ( + $' + items.pro_modifier_value_price + ' )'
                : ''

            comboModifier.push({
              mIndex: mIndex,
              single_selection: 1,
              modifier: combomodifiername,
              value:
                item.pro_modifier_name +
                '~' +
                item.pro_modifier_id +
                '~' +
                items.pro_modifier_value_name +
                '~' +
                items.pro_modifier_value_id +
                '~' +
                items.pro_modifier_value_modifier_id +
                '~' +
                items.pro_modifier_value_price,
              label: items.pro_modifier_value_name + mPrice
            })
            if (index === 0) {
              defaultComboModify = [
                {
                  mIndex: mIndex,
                  single_selection: 1,
                  modifier: combomodifiername,
                  value:
                    item.pro_modifier_name +
                    '~' +
                    item.pro_modifier_id +
                    '~' +
                    items.pro_modifier_value_name +
                    '~' +
                    items.pro_modifier_value_id +
                    '~' +
                    items.pro_modifier_value_modifier_id +
                    '~' +
                    items.pro_modifier_value_price,
                  label: items.pro_modifier_value_name + mPrice
                }
              ]
            }
          })
          modifierHtml = (
            <Select
              /*  value={this.state.selected_modifier_value} */
              onChange={current.handleChangeModifier}
              options={comboModifier}
              styles={customStyles}
              className="react-select-control"
              defaultValue={defaultComboModify}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
            />
          )
        }

        return (
          <div key={index} className="prochoosen_row">
            <div className="product_chosen_hea">
              <h6>
                {stripslashes(
                  item.pro_modifier_name
                    .replace(' (Square)', '')
                    .replace(' (Both Shapes)', '')
                    .replace(' (Round)', '')
                )}
              </h6>
              <span />
            </div>
            <div className="re_select"> {modifierHtml}</div>
          </div>
        )
      })
    }
  }

  loadModifierList(comboProducts, mIndex) {
    return comboProducts.map(function(item, index) {
      return (
        <label
          className="spct-radio combo_list_section"
          key={index}
          data-invcomboprice={0}>
          <span>
            {item.product_details.product_sku !== ''
              ? item.product_details.product_sku
              : item.product_details.product_name}
          </span>
          <span className="combo_pro_price" style={{ display: 'none' }}>
            ( +{item.product_details.product_price} )
          </span>
          <input
            type="checkbox"
            name="radio"
            className="combo_list"
            data-mindex={mIndex}
            data-price={item.product_details.product_price}
          />
          <input
            type="hidden"
            value={JSON.stringify(item)}
            className="combo_item_list"
          />
          <span className="checkmark" />
        </label>
      )
    })
  }
  updatePriceComboCount() {
    if (cookie.load('itemType') === 'Combo' && cookie.load('comboCount') > 0) {
      var comboprices = 0
      for (var m = 0, length = cookie.load('comboCount'); m < length; m++) {
        if ($('#comboOptionValuePrice_' + m).val()) {
          comboprices += parseFloat($('#comboOptionValuePrice_' + m).val())
        }
      }
      var currentPrice =
        parseFloat(this.state.productUnitPrice) + parseFloat(comboprices)
      this.setState({ productPrice: currentPrice.toFixed(2) })
    }
  }
  /* check min max setup */
  incComboQty(
    minSelect,
    maxSelect,
    setCount,
    inputId,
    action,
    unitPrice,
    productId,
    applyPrice,
    selectedvalue
  ) {
    var productprice = 0
    var currentPrice = 0
    var newQty = 0
    var getQty = parseInt($('#' + inputId).text())
    /* disable if click values is zero*/
    if (getQty <= 0 && action === 'remove') {
      return false
    }
    /* get selected input qty values */
    var items = document.getElementsByClassName('hiddenInput_' + setCount)
    var itemCount = items.length
    var cartQty = 0
    for (var i = 0; i < itemCount; i++) {
      cartQty =
        cartQty +
        parseInt(
          document.getElementsByClassName('combinput_' + setCount)[i].innerHTML
        )
    }

    var addCartQty = cartQty + 1

    var removeCartQty = cartQty > 0 ? cartQty - 1 : 0

    if (addCartQty > maxSelect && action === 'add') {
      return false
    }

    if (action === 'add') {
      $('.minSele-' + setCount).html(addCartQty)
    } else if (action === 'remove') {
      $('.minSele-' + setCount).html(removeCartQty)
    }
    /*  increase or decrease  product item qty */
    if (action === 'remove') {
      newQty = getQty > 0 ? getQty - 1 : 0
    } else {
      newQty = getQty + 1
    }

    $('#' + inputId).text(parseInt(newQty))

    if (applyPrice) {
      var itemprice = parseFloat(unitPrice) * parseInt(newQty)
      $('#' + inputId)
        .next('.comboprice_' + setCount)
        .val(itemprice)
      var lengthcnt = $('.comboprice_' + setCount).length

      var itemprice = 0
      for (var i = 0; i < lengthcnt; i++) {
        itemprice += parseFloat(
          document.getElementsByClassName('comboprice_' + setCount)[i].value
        )
      }
      $('.comboOptionValuePrice_' + setCount).val(itemprice)

      this.updatePriceComboCount()
    }

    var selected = []
    var combodetails = []
    var productexist = 0

    /* Update Section Start */
    var product_total_price = $('#product_total_price').val()
    var CurrentcartQty = ''
    if (action === 'add') {
      CurrentcartQty = addCartQty
    } else {
      CurrentcartQty = removeCartQty
    }
    var maxReached = $('#combo_' + inputId)
      .parents('.product_chosen_item_left')
      .find('.max_reached')
      .val()

    var comboPrice = $('.combo_selected_price_' + setCount).val()
    if (comboPrice === '') {
      comboPrice = 0
    }
    var comboApplyPrice = $('.combo-apply-price-' + setCount).val()

    if (comboApplyPrice === '1') {
      if (action === 'add') {
        if ($('#combo_' + inputId + ' .combo_pro_price').length > 0) {
          $('#combo_' + inputId + ' .combo_pro_price').show()
        }
        comboPrice = parseFloat(comboPrice) + parseFloat(unitPrice)
        $('#combo_' + inputId).attr('data-invcomboprice', unitPrice)
      } else {
        var combinput_ = $('#' + inputId).html()
        if (
          $('#combo_' + inputId + ' .combo_pro_price').length > 0 &&
          combinput_ === '0'
        ) {
          //$('#combo_' + inputId + ' .combo_pro_price').hide()
        }
        comboPrice = comboPrice - unitPrice
        if (combinput_ === '0') {
          $('#combo_' + inputId).attr('data-invcomboprice', '0')
        }
      }
    } else {
      if (CurrentcartQty > minSelect) {
        if (action === 'add') {
          if ($('#combo_' + inputId + ' .combo_pro_price').length > 0) {
            $('#combo_' + inputId + ' .combo_pro_price').show()
          }

          comboPrice = parseFloat(comboPrice) + parseFloat(unitPrice)

          maxReached = maxReached !== '' ? parseInt(maxReached) + 1 : 1
          $('#combo_' + inputId)
            .parents('.product_chosen_item_left')
            .find('.max_reached')
            .val(maxReached)

          this.setState({ productPrice: product_total_price })
          $('#combo_' + inputId).attr('data-invcomboprice', unitPrice)
        } else {
          if ($('#combo_' + inputId + ' .combo_pro_price').length > 0) {
            $('#combo_' + inputId + ' .combo_pro_price').hide()
          }
          comboPrice = comboPrice - unitPrice
          //product_total_price = product_total_price - unitPrice
          maxReached = maxReached !== '' ? parseInt(maxReached) - 1 : 0
          if (maxReached > 0) {
            this.setState({ productPrice: product_total_price })
            $('#combo_' + inputId)
              .parents('.product_chosen_item_left')
              .find('.max_reached')
              .val(maxReached)
            if (CurrentcartQty == 0) {
              $('#combo_' + inputId).attr('data-invcomboprice', '0')
            }
          }
        }
      } else {
        $('#combo_' + inputId)
          .parents('.product_chosen_item_left')
          .find('.max_reached')
          .val('0')
        if ($('#combo_' + inputId + ' .combo_pro_price').length > 0) {
          $('#combo_' + inputId + ' .combo_pro_price').hide()
        }
        comboPrice = 0
        $('#combo_' + inputId).attr('data-invcomboprice', '0')
      }
    }

    $('.combo_selected_price_' + setCount).val(comboPrice)

    var totalComobPrice = 0
    $('.comboSelectedPrice').each(function() {
      if ($(this).val() !== '') {
        totalComobPrice =
          parseFloat(totalComobPrice) + parseFloat($(this).val())
      }
    })
    var totalPrice =
      parseFloat($('#product_orignal_price').val()) +
      parseFloat(totalComobPrice)

    this.setState({ productPrice: totalPrice })
    /* Update Section End */

    var combovalues = $('#comboOptionValue_' + setCount).val()
    var invcombopriceUpdated = $('#combo_' + inputId).attr('data-invcomboprice')
    if (combovalues) {
      combodetails = JSON.parse(combovalues)

      if (combodetails.product_details.length) {
        for (var i = 0; i < combodetails.product_details.length; i++) {
          if (combodetails.product_details[i].product_id === productId) {
            productexist = i + 1
          }
        }

        if (productexist) {
          productexist = parseInt(productexist) - 1

          if (action === 'add') {
            combodetails.product_details[productexist].product_qty =
              parseInt(combodetails.product_details[productexist].product_qty) +
              1
          } else {
            combodetails.product_details[productexist].product_qty =
              parseInt(combodetails.product_details[productexist].product_qty) -
              1
          }
          combodetails.product_details[
            productexist
          ].product_price = invcombopriceUpdated
        } else {
          selectedvalue.product_details.product_price = invcombopriceUpdated
          combodetails.product_details.push(selectedvalue.product_details)
        }
      } else {
        combodetails = selectedvalue.combodetails
        selectedvalue.product_details.product_price = invcombopriceUpdated
        selected.push(selectedvalue.product_details)
        combodetails.product_details = selected
      }
    } else {
      combodetails = selectedvalue.combodetails
      selectedvalue.product_details.product_price = invcombopriceUpdated
      selected.push(selectedvalue.product_details)
      combodetails.product_details = selected
    }
    var newProduct = []
    if (combodetails.product_details.length > 0) {
      combodetails.product_details.map(item => {
        if (parseInt(item.product_qty) > 0) {
          newProduct.push(item)
        }
      })
    }
    if (newProduct.length > 0) {
      combodetails.product_details = newProduct
      $('#comboOptionValue_' + setCount).val(JSON.stringify(combodetails))
    } else {
      $('#comboOptionValue_' + setCount).val('')
    }
    this.calculatePrice()
  }

  handleChangeModifier = event => {
    this.updateCombomodifierSelection(event)
  }
  handleChangeSingleModifier = event => {
    this.updateSignlemodifierSelection(event)
  }

  handleChange = event => {
    this.setState({ selected_value: null })
    if (event.mIndex !== undefined) {
      this.updateComboSelection(event.mIndex, event)
    } else {
      this.updateComboSelection(event[0].mIndex, event)
    }
  }

  handleChangeRadio = event => {
    var radioValue = JSON.parse(event.currentTarget.value)
    var radioName = event.currentTarget.name.split('_')
    this.updateComboSelection(radioName[1], radioValue)
  }

  removeComboItem(i, applyPrice, indexval) {
    var combodetails = []
    var combovalues = $('#comboOptionValue_' + indexval).val()
    if (combovalues) {
      combodetails = JSON.parse(combovalues)
      if (combodetails.product_details.length) {
        if (applyPrice) {
          var product_price =
            parseInt(combodetails.product_details[i].product_qty) *
            parseFloat(combodetails.product_details[i].product_price)
          var oldPrice = $('#comboOptionValuePrice_' + indexval).val()
          var productprice = parseFloat(oldPrice) - product_price
          $('#comboOptionValuePrice_' + indexval).val(parseFloat(productprice))
        }
        combodetails.product_details.splice(i, 1)
        var productQty = this.updateProductQty(combodetails.product_details)
        $('.combinput_' + indexval).html(parseInt(productQty))
        $('#comboOptionValue_' + indexval).val(JSON.stringify(combodetails))
      }
    }
    var selectedcombo = this.showSelectedValue(
      combodetails.product_details,
      parseInt(applyPrice),
      indexval
    )
    this.setState({ [indexval]: selectedcombo })
    this.updatePriceComboCount()
  }

  removeComboModifierItem(indexval) {
    var combodetails = []
    var combovalues = $('#comboOptionValue_' + indexval).val()
    if (combovalues) {
      combodetails = JSON.parse(combovalues)
      if (combodetails.product_details.length) {
        combodetails.product_details[0].modifier = []
        $('#comboOptionValue_' + indexval).val(JSON.stringify(combodetails))
        $('#comboOptionValueModifierRequired_' + indexval).val('NO')
        $('#comboOptionValueModifier_' + indexval).val('NO')
      }
      this.setState({ ['modifier' + indexval]: '' })
    }
  }

  updateComboSelection(indexvalues, selectedvalues) {
    var selected = []
    var combodetails = []
    var productprice = 0
    var currentPrice = 0
    var productexist = 0

    if (selectedvalues.length || selectedvalues.refVal !== undefined) {
      var indexval =
        selectedvalues[0] === undefined
          ? selectedvalues.mIndex
          : selectedvalues[0].mIndex
      var combodetails =
        selectedvalues[0] === undefined
          ? selectedvalues.combodetails
          : selectedvalues[0].combodetails
      if (selectedvalues[0] === undefined) {
        if (selectedvalues.subquantity != null) {
          cookie.save('subquantity', selectedvalues.subquantity)
          cookie.save('notsubquantity', selectedvalues.product_id)
        }
        var refVal = selectedvalues.refVal.split('~')
        if (selectedvalues.product_details.modifiers === null) {
          if (parseInt(refVal[6])) {
            productprice += parseFloat(refVal[4])
          }
        }

        if (
          selectedvalues.product_details.modifiers !== null &&
          selectedvalues.product_details.modifiers.length > 0
        ) {
          var newModifiers = []
          selectedvalues.product_details.modifiers.map(function(item) {
            var modVal = []
            if (
              item.modifiers_values !== '' &&
              typeof item.modifiers_values !== undefined &&
              typeof item.modifiers_values !== 'undefined'
            ) {
              modVal = [
                {
                  modifier_value_id:
                    item.modifiers_values[0].pro_modifier_value_id,
                  modifier_value_qty: 1,
                  modifier_value_name:
                    item.modifiers_values[0].pro_modifier_value_name,
                  modifier_value_price:
                    item.modifiers_values[0].pro_modifier_value_price
                }
              ]
            }

            newModifiers.push({
              modifier_id: item.pro_modifier_id,
              modifier_name: item.pro_modifier_name,
              modifiers_values: modVal
            })
          })
          selectedvalues.product_details.modifiers = newModifiers
        }

        selected.push(selectedvalues.product_details)
        combodetails.product_details = selected
        var selectedcombo = this.showSelectedValue(
          combodetails.product_details,
          parseInt(refVal[6]),
          indexval
        )
        this.setState({ [indexval]: selectedcombo })

        if (selectedvalues.modLen > 0) {
          $('.re_select_modifier_' + indexval)
            .find('.select_modifier')
            .hide()
          //$(".re_select_modifier_"+indexval).find(".pmodifier_ddlist").hide();
          $('#comboOptionValueModifierRequired_' + indexval).val('YES')
          $('#comboOptionValueModifier_' + indexval).val('YES')
          this.setState({ ['modifier' + indexval]: '' })
          $('.select_modifier_' + selectedvalues.product_id).show()
        } else {
          $('.re_select_modifier_' + indexval)
            .find('.select_modifier')
            .hide()
          $('#comboOptionValueModifierRequired_' + indexval).val('NO')
          $('#comboOptionValueModifier_' + indexval).val('NO')
          this.setState({ ['modifier' + indexval]: '' })
        }
      } else {
        selectedvalues.forEach(selvalue => {
          var refVal = selvalue.refVal.split('~')
          if (parseInt(refVal[6])) {
            productprice += parseFloat(refVal[4])
          }
          var productId = refVal[5]
          var combovalues = $('#comboOptionValue_' + indexval).val()
          if (combovalues) {
            combodetails = JSON.parse(combovalues)
            if (combodetails.product_details.length) {
              for (var i = 0; i < combodetails.product_details.length; i++) {
                if (combodetails.product_details[i].product_id === productId) {
                  productexist = i + 1
                }
              }

              if (productexist) {
                productexist = parseInt(productexist) - 1
                combodetails.product_details[productexist].product_qty =
                  parseInt(
                    combodetails.product_details[productexist].product_qty
                  ) + 1
              } else {
                combodetails.product_details.push(selvalue.product_details)
              }
            } else {
              combodetails = selvalue.combodetails
              selected.push(selvalue.product_details)
              combodetails.product_details = selected
            }
          } else {
            combodetails = selvalue.combodetails
            selected.push(selvalue.product_details)
            combodetails.product_details = selected
          }

          var selectedcombo = this.showSelectedValue(
            combodetails.product_details,
            parseInt(refVal[6]),
            indexval
          )
          this.setState({ [indexval]: selectedcombo })
        })
      }
      $('#comboOptionValuePrice_' + indexval).val(parseFloat(productprice))
      var productQty = this.updateProductQty(combodetails.product_details)
      $('.combinput_' + indexval).html(parseInt(productQty))
      $('#comboOptionValue_' + indexval).val(JSON.stringify(combodetails))
      this.updatePriceComboCount()
    } else {
      currentPrice =
        parseFloat(this.state.productUnitPrice) + parseFloat(productprice)
      $('#comboOptionValuePrice_' + indexvalues).val(0)
      $('#comboOptionValue_' + indexvalues).val('')
      $('.combinput_' + indexvalues).html(0)
      this.updatePriceComboCount()
    }
    this.calculatePrice()
  }

  updateCombomodifierSelection(selectedvalues) {
    var combodetails = []
    var indexval = selectedvalues.mIndex
    var combovalues = $('#comboOptionValue_' + indexval).val()
    if (combovalues) {
      combodetails = JSON.parse(combovalues)
      var modifierSelect = []
      var modifier_value_id = ''
      var currentModifierPrice = ''
      if (selectedvalues === 1) {
        $('.combo_mofi_' + indexval + ' .single_combo_products.active').each(
          function() {
            $(this)
              .find('.combo_modifier_product')
              .map(function() {
                if (
                  $(this)
                    .find('.modifier_options')
                    .is(':checked') === true
                ) {
                  var sMofifier = $(this)
                    .find('.modifier_options:checked')
                    .data('modifier')
                  modifier_value_id +=
                    sMofifier.modifier[0].modifiers_values[0]
                      .modifier_value_id + ';'
                  modifierSelect.push(sMofifier.modifier[0])
                }
              })
          }
        )
      } else {
        combodetails.product_details[0].modifiers.map(function(items) {
          if (items.modifier_id === selectedvalues.modifier[0].modifier_id) {
            var sMofifier = items
            currentModifierPrice =
              selectedvalues.modifier[0].modifiers_values[0]
                .modifier_value_price
            sMofifier.modifiers_values =
              selectedvalues.modifier[0].modifiers_values
            modifier_value_id +=
              sMofifier.modifiers_values[0].modifier_value_id + ';'
            modifierSelect.push(sMofifier)
          } else {
            modifier_value_id +=
              items.modifiers_values[0].modifier_value_id + ';'
            modifierSelect.push(items)
          }
        })
      }
      modifier_value_id = modifier_value_id.substring(
        0,
        modifier_value_id.length - 1
      )

      //selectedvalues.modifier
      combodetails.product_details[0].modifiers = modifierSelect
      $('#comboOptionValue_' + indexval).val(JSON.stringify(combodetails))
      $('#comboOptionValueModifier_' + indexval).val('YES')

      this.validateModifiers(
        combodetails.product_details[0].product_id,
        modifier_value_id,
        indexval
      )

      var selectedcombomodifier = this.showSelectedmodifierValue(
        selectedvalues.modifier[0].modifiers_values,
        indexval
      )
      this.setState({ ['modifier' + indexval]: selectedcombomodifier })
      this.calculatePrice()
    }
  }

  validateModifiers(modProductId, modifier_value_id, indexval = null) {
    $('#productModalCenter .cart-add-div').show()
    showLoader('cart-add-div', 'class')

    axios
      .get(
        API_URL +
          'api/products/validate_product?app_id=' +
          APP_ID +
          '&product_id=' +
          modProductId +
          '&modifier_value_id=' +
          modifier_value_id
      )
      .then(res => {
        hideLoader('cart-add-div', 'class')
        if (res.data.status === 'ok') {
          $('#productModalCenter .cart-add-div').show()

          if (indexval !== '') {
            if (indexval === 'modifiers') {
              this.setState({
                grandTotal: res.data.result_set[0].product_price
              })
            } else {
              var combovalues = $('#comboOptionValue_' + indexval).val()
              if (combovalues) {
                var combodetails = JSON.parse(combovalues)
                var modifierPrice = 0
                combodetails.product_details[0].modifiers.map(function(items) {
                  modifierPrice += parseFloat(
                    items.modifiers_values[0].modifier_value_price
                  )
                })
                $('#comboOptionValuePrice_' + indexval).val(modifierPrice)
                this.updatePriceComboCount()
              }
            }
          }
          this.setState({
            modParentProductId: res.data.result_set[0].alias_product_primary_id
          })
        } else {
          this.setState({ modParentProductId: '' })
          $('#productModalCenter .cart-add-div').hide()
          showInfo(
            'Product combination is wrong. Please check your combination'
          )
        }
      })
  }

  calculatePrice() {
    var selectedProducts = $('.comboOptionValueDetails')

    var totalPrice = 0
    if (selectedProducts.length > 0) {
      selectedProducts.each(function() {
        var productDetails =
          $(this).val() !== '' ? JSON.parse($(this).val()) : ''
        if (productDetails !== '' && productDetails !== null) {
          productDetails.product_details.map(function(item) {
            if (item.modifiers !== '' && item.modifiers !== null) {
              if (item.modifiers.length > 0) {
                item.modifiers.map(function(mitem) {
                  if (
                    mitem.modifiers_value !== '' &&
                    typeof mitem.modifiers_value !==
                      undefined /*  &&
                    typeof mitem.modifiers_value !== 'undefined' */
                  ) {
                    totalPrice += parseFloat(
                      mitem.modifiers_values[0].modifier_value_price *
                        mitem.modifiers_values[0].modifier_value_qty
                    )
                  }
                })
              } else {
                totalPrice += parseFloat(item.product_price * item.product_qty)
              }
            } else {
              totalPrice += parseFloat(item.product_price * item.product_qty)
            }
          })
        }
      })
    }
    totalPrice += parseFloat($('#product_orignal_price').val())
    this.setState({ grandTotal: totalPrice })
  }

  updateSignlemodifierSelection(selectedvalues) {
    var modProductId = $('#modProductId').val()
    var selectedModify = [
      {
        modifier_value_id: selectedvalues.modifierdetails.pro_modifier_value_id,
        modifier_value_qty: 1,
        modifier_value_name:
          selectedvalues.modifierdetails.pro_modifier_value_name,
        modifier_value_price:
          selectedvalues.modifierdetails.pro_modifier_value_price
      }
    ]
    var selectedModifiers = this.state.selectedModifiers
    selectedModifiers.map(function(item, index) {
      if (item.modifier_id == selectedvalues.pro_modifier_id) {
        selectedModifiers[index].modifiers_values = selectedModify
      }
    })
    this.setState({ selectedModifiers: selectedModifiers })

    var modifier_value_id = ''
    selectedModifiers.map(function(item) {
      modifier_value_id += item.modifiers_values[0].modifier_value_id + ';'
    })

    modifier_value_id = modifier_value_id.substring(
      0,
      modifier_value_id.length - 1
    )

    this.validateModifiers(modProductId, modifier_value_id, 'modifiers')
  }

  updateProductQty(products) {
    var productQty = 0
    for (var i = 0; i < products.length; i++) {
      productQty += parseInt(products[i].product_qty)
    }
    return productQty
  }

  showSelectedmodifierValue(item, indexval) {
    return item.map((item, index) => (
      <li key={index}>
        <span>
          <span>{item.modifier_value_name}</span>
          <a
            href="javascript:;"
            onClick={this.removeComboModifierItem.bind(this, indexval)}
            className="pmodifier_close"
          />
        </span>
      </li>
    ))
  }

  showSelectedValue(products, applyPrice, indexval) {
    return products.map((item, index) => (
      <li key={index + 100}>
        <span>
          <span>
            {item.product_name}
            {item.product_qty > 1 && ' (' + item.product_qty + ')'}{' '}
          </span>
          <a
            href="javascript:;"
            onClick={this.removeComboItem.bind(
              this,
              index,
              applyPrice,
              indexval
            )}
            className="pmodifier_close"
          />
        </span>
      </li>
    ))
    /*	<li key={index + 100}><span><span>{item.product_name}{item.product_qty > 1 && " (" + item.product_qty + ")"} {applyPrice && item.product_price > 0 ? " +$" + (item.product_price * item.product_qty) : ''}</span><a href="javascript:;" onClick={this.removeComboItem.bind(this, index, applyPrice, indexval)} className="pmodifier_close"></a></span></li>); */
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>
        <a href={'#/products/tag/' + item.tag_id} target="_blank">
          {stripslashes(item.pro_tag_name)}{' '}
          {index + 1 !== tags.length ? '/' : ''}{' '}
        </a>
      </li>
    ))
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll = event => {
    if ($('.bakery_row').length) {
      var header = document.getElementById('bakery_row')
      if (header !== undefined) {
        var offsetHeight = document.getElementById('mainNav').offsetHeight
        if (window.pageYOffset > 150) {
          header.classList.add('bakery_row_fixed')
          header.style.top = offsetHeight + 'px'
        } else {
          if ($('#bakery_row').hasClass('bakery_row_fixed') === true) {
            header.classList.remove('bakery_row_fixed')
          }

          header.style.top = 0 + 'px'
        }
      }
    }
    /* Fixed Side menu */
    // Get the Element
    var element = document.getElementsByClassName('left-category-list')[0]
    var endElement = document.getElementsByTagName('footer')[0]
    // Get the offset position of the navbar
    var elementTop = element.offsetTop - 125
    var endElementTop = endElement.offsetTop - window.innerHeight
    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    if (window.pageYOffset > elementTop && window.pageYOffset < endElementTop) {
      element.classList.add('fixed')
    } else {
      element.classList.remove('fixed')
    }
  }
  displayRatting(ratting) {
    var ratingHTML = ''
    for (let index = 0; index < ratting; index++) {
      ratingHTML += '<i class="fa fa-star"></i>'
    }
    if (ratingHTML !== '') {
      return parse(ratingHTML)
    }
  }
  scrollProduct(productslug) {
    var start = 0
    var myInterval = setInterval(function() {
      if (jquery('.' + productslug).length > 0 && start == 0) {
        clearInterval(myInterval)
        start++
        jquery('html, body').animate(
          {
            scrollTop:
              jquery('.' + productslug).offset().top - $('header').height()
          },
          400
        )
      }
    }, 1000)
  }

  openCategoryProList(categorySlug, type, cateName, e) {
    e.preventDefault()
    $('.product-menu .menu-list-intvl').removeClass('active')
    $('.more_categor_info .menu-list-intvl').removeClass('active')
    $('.product-mobile-menu-nav .moible-menu-list-intvl').removeClass('active')

    $('#cate_' + categorySlug).addClass('active')
    $('#mcate_' + categorySlug).addClass('active')

    if (type === 'more') {
      $('.more-menu-name').html(cateName)
      $('#dropdownMenuButton').html(cateName)
    } else {
      $('.more-menu-name').html('CHECK MORE')
    }
    $('html, body').animate(
      {
        scrollTop: parseFloat($('#catepro_' + categorySlug).offset().top) - 180
      },
      1000
    )
  }

  changeTheme() {
    var type = 1
    if (this.state.currentDesign === 1) {
      type = 2
    }
    this.setState({ currentDesign: type }, function() {
      cookie.save('currentDesign', type)
      if (jquery('.breadcrumb').length > 0) {
        jquery('html, body').animate(
          {
            scrollTop: jquery('.breadcrumb').offset().top - 100
          },
          400
        )
      }
    })
  }

  sateValChange = (field, value) => {
    if (field === 'cartItem') {
      this.setState({ cartItem: value })
    }
  }

  render() {
    const productMoreSliderSettings = {
      className: 'product-more-slider',
      speed: 500,
      fade: true,
      cssEase: 'linear',
      arrows: true,
      lazyLoad: 'progressive',
      dots: false
    }
    const productMobileNavsetting = {
      className: 'product-more-slider',
      speed: 500,
      cssEase: 'linear',
      arrows: true,
      lazyLoad: 'progressive',
      dots: false,
      initialSlide: 0,
      infinite: false,
      responsive: [
        {
          breakpoint: 1020,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    const menuCategorySliderSettings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    const prDetails = this.state.prDetails
    var proImsgsrc = API_URL + 'media/dev_team/products/main-image/'
    const cartImage =
      prDetails.product_thumbnail !== '' &&
      typeof prDetails.product_thumbnail !== 'undefined'
        ? proImsgsrc + prDetails.product_thumbnail
        : ''

    return (
      <React.Fragment>
        <Header
          allProps={this.props}
          cartAction={this.state.reloadCart}
          sateValChange={this.sateValChange}
        />
        <main
          className={
            this.state.currentDesign === 1
              ? 'delivery-section new-product-listing'
              : 'delivery-section old-product-listing'
          }>
          {this.state.menubanner_image !=='' && this.state.menubanner_image !== undefined && (
          <div className="inner_banner_sec">
            <div className="container">
                 <img src={(this.state.menubanner_image !=='' && this.state.menubanner_image !== undefined ? this.state.menubanner_image : '')} alt="" />
              
            </div>
          </div>)}

          <section className="inner-page-header">
            <div className="container">
              <div className="page-header-block">
                <h1 className="font-gothamlight  text-center">TAKEAWAY MENU</h1>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'./'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Takeaway Menu</li>
                </ol>
                {/*
                <div className="pager-button-block">
                  <ul>
                    <li className="active">
                      <a href={void 0} onClick={this.changeTheme.bind(this)}>
                        Change View
                      </a>
                    </li>
                  </ul>
                </div>*/}
              </div>
            </div>
          </section>
          <div className="menu-top-section-inner">
            <div className="container menu-desktop-section">
              <div className="product-menu">
                {_.isEmpty(this.props.navItems) === false && (
                  <ul>
                    {this.props.navItems.map((cate, cIndex) => {
                      if (cIndex <= 4) {
                        return (
                          <li
                            className="menu-list-intvl"
                            id={'cate_' + cate.pro_cate_slug}
                            key={cIndex}>
                            <a
                              className="menu-title-link"
                              href={void 0}
                              onClick={this.openCategoryProList.bind(
                                this,
                                cate.pro_cate_slug,
                                'normal',
                                ''
                              )}
                              href={'/' + cate.pro_cate_slug}>
                              {stripslashes(
                                typeof cate.subcategory_name !== 'undefined' &&
                                  cate.subcategory_name !== null
                                  ? cate.subcategory_name
                                  : cate.category_name
                              )}
                            </a>
                          </li>
                        )
                      } else {
                        return (
                          <li
                            className="menu-list-intvl"
                            id={'cate_' + cate.pro_cate_slug}
                            key={cIndex}
                            style={{ display: 'none' }}>
                            <a
                              className="menu-title-link"
                              href={void 0}
                              onClick={this.openCategoryProList.bind(
                                this,
                                cate.pro_cate_slug,
                                'normal',
                                ''
                              )}
                              href={'/' + cate.pro_cate_slug}>
                              {stripslashes(
                                typeof cate.subcategory_name !== 'undefined' &&
                                  cate.subcategory_name !== null
                                  ? cate.subcategory_name
                                  : cate.category_name
                              )}
                            </a>
                          </li>
                        )
                      }
                    })}
                  </ul>
                )}
              </div>

              <div className="more-menu open_more_categories">
                <div className="more-menu-parent">
                  <span className="more-menu-name">CHECK MORE</span>
                  <label className="open_more_category"> </label>
                </div>
                {_.isEmpty(this.props.navItems) === false && (
                  <ul className="more_categor_info">
                    {this.props.navItems.map((cate, cIndex) => {
                      if (cIndex > 4) {
                        return (
                          <li
                            className="menu-list-intvl"
                            key={cIndex}
                            id={'cate_' + cate.pro_cate_slug}>
                            <a
                              className="menu-title-link"
                              href={void 0}
                              onClick={this.openCategoryProList.bind(
                                this,
                                cate.pro_cate_slug,
                                'more',
                                stripslashes(
                                  typeof cate.subcategory_name !==
                                    'undefined' &&
                                    cate.subcategory_name !== null
                                    ? cate.subcategory_name
                                    : cate.category_name
                                )
                              )}
                              href={'/' + cate.pro_cate_slug}>
                              {stripslashes(
                                typeof cate.subcategory_name !== 'undefined' &&
                                  cate.subcategory_name !== null
                                  ? cate.subcategory_name
                                  : cate.category_name
                              )}
                            </a>
                          </li>
                        )
                      }
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* <div
              className="product-mobile-menu-nav"
              style={{ display: 'none' }}>
              <Slider {...productMobileNavsetting}>
                {_.isEmpty(this.props.navItems) === false &&
                  this.props.navItems.map((cate, cIndex) => {
                    return (
                      <div
                      className="moible-menu-list-intvl"
                        id={'mcate_' + cate.pro_cate_slug}>
                        <a
                          className="menu-title-link"
                          href={void 0}
                          onClick={this.openCategoryProList.bind(
                            this,
                            cate.pro_cate_slug,
                            'normal',
                            ''
                          )}>
                          {stripslashes(
                            typeof cate.subcategory_name !== 'undefined' &&
                              cate.subcategory_name !== null
                              ? cate.subcategory_name
                              : cate.category_name
                          )}
                        </a>
                      </div>
                    )
                  })}
              </Slider>
            </div> */}
          </div>
          <div className="main-wrapper">
            <section className="spct-menu-list">
              {_.isEmpty(this.props.navItems) === false && (
                <div className="container main-mobile-category-dropdown">
                  <div className="mobile-category-dropdown">
                    <div className="dropdown">
                      <button
                        className="btn btn-cat-filter dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {stripslashes(this.state.currentProductCategory)}
                      </button>
                      <div
                        className="dropdown-menu scrollable-menu"
                        aria-labelledby="dropdownMenuButton">
                        {this.props.navItems.map((cate, cIndex) => (
                          <React.Fragment key={cIndex}>
                            <Link
                              to={void 0}
                              onClick={this.openCategoryProList.bind(
                                this,
                                cate.pro_cate_slug,
                                'more',
                                stripslashes(
                                  typeof cate.subcategory_name !==
                                    'undefined' &&
                                    cate.subcategory_name !== null
                                    ? cate.subcategory_name
                                    : cate.category_name
                                )
                              )}
                              /*  onClick={() => {
                                this.toggleTabs(
                                  cIndex,
                                  cate.pro_cate_slug,
                                  cate.pro_subcate_slug,
                                  cate.category_name
                                )
                              }} */
                              data-id={cIndex}
                              className={
                                this.state.selectedCate === cate.pro_cate_slug
                                  ? 'dropdown-item active'
                                  : this.state.menuActive === cIndex &&
                                    this.state.selectedCate === ''
                                  ? 'dropdown-item active'
                                  : 'dropdown-item'
                              }>
                              {stripslashes(
                                typeof cate.subcategory_name !== 'undefined' &&
                                  cate.subcategory_name !== null
                                  ? cate.subcategory_name
                                  : cate.category_name
                              )}
                            </Link>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="container spct-producs-container">
                <div className="plist_sec">
                  <div className="plist_tab left-category-list">
                    <div className="plist_tabmenu_out">
                      <PerfectScrollbar>
                        <ul className="plist_tabmenu">
                          {this.props.navItems !== null &&
                            this.props.navItems.map((cate, cIndex) => (
                              <li
                                className={
                                  this.state.selectedCate === cate.pro_cate_slug
                                    ? 'category-item active'
                                    : this.state.menuActive === cIndex &&
                                      this.state.selectedCate === ''
                                    ? 'category-item active'
                                    : 'category-item'
                                }
                                id={cate.pro_cate_slug}
                                key={cIndex}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.toggleTabs(
                                      cIndex,
                                      cate.pro_cate_slug,
                                      cate.pro_subcate_slug,
                                      cate.category_name
                                    )
                                  }}
                                  className={
                                    this.state.selectedCate ===
                                    cate.pro_cate_slug
                                      ? 'active'
                                      : this.state.menuActive === cIndex &&
                                        this.state.selectedCate === ''
                                      ? 'active'
                                      : ''
                                  }>
                                  {stripslashes(
                                    typeof cate.subcategory_name !==
                                      'undefined' &&
                                      cate.subcategory_name !== null
                                      ? cate.subcategory_name
                                      : cate.category_name
                                  )}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </PerfectScrollbar>
                    </div>
                  </div>
                  <div className="plist_content">
                    {this.state.currentDesign === 2 &&
                      _.isEmpty(this.state.subcateDetails) === false &&
                      this.state.subcateDetails.map((subcate, mainIndex1) => (
                        <React.Fragment key={mainIndex1}>
                          <div className="subcategory_tt">
                            <h3>{stripslashes(subcate.pro_subcate_name)}</h3>
                          </div>
                          <div className="plist_grid">
                            <div className="row">
                              {_.isEmpty(subcate.products) === false &&
                                _.chunk(subcate.products, 4).map(
                                  (prod, mainIndex) => (
                                    <React.Fragment key={mainIndex}>
                                      {_.isEmpty(prod) === false &&
                                        prod.map((pro, proIndex) => (
                                          <React.Fragment key={proIndex}>
                                            <div
                                              key={proIndex}
                                              className={
                                                pro.product_slug +
                                                ' ' +
                                                this.state.selectedProduct +
                                                ' product-item-colm  col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3'
                                              }>
                                              {this.state.selectedProduct ===
                                              pro.product_slug
                                                ? this.scrollProduct(
                                                    pro.product_slug
                                                  )
                                                : ''}
                                              <div
                                                className="product-item card"
                                                id={
                                                  'product_' +
                                                  mainIndex1 +
                                                  '_' +
                                                  mainIndex +
                                                  '_' +
                                                  proIndex
                                                }>
                                                <img
                                                  src={
                                                    pro.product_thumbnail !== ''
                                                      ? this.state
                                                          .productsCommon
                                                          .product_image_source +
                                                        '/' +
                                                        pro.product_thumbnail
                                                      : noImage
                                                  }
                                                  alt=""
                                                  /* onClick={this.showProductDetails.bind(
                                                  this,
                                                  mainIndex,
                                                  pro.product_slug,
                                                  'product_' +mainIndex1+'_' +mainIndex +'_' +proIndex
                                                )} */
                                                />
                                                <div className="card-body">
                                                  <div
                                                    className="card-title"
                                                    /*  onClick={this.showProductDetails.bind(
                                                    this,
                                                    mainIndex,
                                                    pro.product_slug,
                                                    'product_' +mainIndex1+'_' +mainIndex +'_' +proIndex
                                                  )} */
                                                  >
                                                    {stripslashes(
                                                      getAliasName(
                                                        pro.product_alias,
                                                        pro.product_name
                                                      )
                                                    )}
                                                  </div>
                                                  {_.isEmpty(
                                                    pro.product_tag
                                                  ) === false && (
                                                    <div className="product-tag">
                                                      {pro.product_tag.map(
                                                        (tags, tagIndex) => (
                                                          <span
                                                            key={tagIndex}
                                                            style={{
                                                              display:
                                                                tagIndex === 0
                                                                  ? ''
                                                                  : 'none'
                                                            }}>
                                                            {tags.pro_tag_name}
                                                          </span>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                  {pro.product_short_description !==
                                                    null && (
                                                    <span className="menu-set-tag">
                                                      {
                                                        pro.product_short_description
                                                      }
                                                    </span>
                                                  )}
                                                  <p className="price-tag card-text">
                                                    ${pro.product_price}
                                                  </p>
                                                  <div className="order_now_btn">
                                                    <button
                                                      type="button"
                                                      className="add-to-btn-cart btn btn-primary btn-block"
                                                      onClick={this.showProductDetails.bind(
                                                        this,
                                                        mainIndex,
                                                        pro.product_slug,
                                                        'product_' +
                                                          mainIndex1 +
                                                          '_' +
                                                          mainIndex +
                                                          '_' +
                                                          proIndex
                                                      )}>
                                                      Order Now
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                  )
                                )}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}

                    {this.state.currentDesign === 1 &&
                      _.isEmpty(this.state.subcateDetails) === false &&
                      Object.keys(this.state.multisubcateDetails).length > 0 &&
                      this.props.navItems.map((mainList, indexList) => {
                        var mainList1 = this.state.multisubcateDetails[
                          mainList.pro_cate_slug
                        ]
                        if (
                          this.state.firstLoad == 0 &&
                          indexList === this.props.navItems.length - 1
                        ) {
                          this.setState({ firstLoad: 1 })
                        }
                        if (
                          mainList1 !== '' &&
                          typeof mainList1 !== undefined &&
                          typeof mainList1 !== 'undefined'
                        ) {
                          return (
                            <div id={'catepro_' + mainList.pro_cate_slug}>
                              {mainList1.map((subcate, mainIndex1) => (
                                <React.Fragment key={mainIndex1}>
                                  <div className="subcategory_tt">
                                    <h3>
                                      {stripslashes(subcate.pro_subcate_name)}
                                    </h3>
                                  </div>
                                  <div className="plist_grid">
                                    <div className="row">
                                      {_.isEmpty(subcate.products) === false &&
                                        _.chunk(subcate.products, 4).map(
                                          (prod, mainIndex) => (
                                            <React.Fragment key={mainIndex}>
                                              {_.isEmpty(prod) === false &&
                                                prod.map((pro, proIndex) => (
                                                  <React.Fragment
                                                    key={proIndex}>
                                                    <div
                                                      key={proIndex}
                                                      className={
                                                        pro.product_slug +
                                                        ' ' +
                                                        this.state
                                                          .selectedProduct +
                                                        ' product-item-colm  col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3'
                                                      }>
                                                      {this.state
                                                        .selectedProduct ===
                                                      pro.product_slug
                                                        ? this.scrollProduct(
                                                            pro.product_slug
                                                          )
                                                        : ''}
                                                      <div
                                                        className="product-item card"
                                                        id={
                                                          'product_' +
                                                          mainIndex1 +
                                                          '_' +
                                                          mainIndex +
                                                          '_' +
                                                          proIndex
                                                        }>
                                                        <img
                                                          src={
                                                            pro.product_thumbnail !==
                                                            ''
                                                              ? this.state
                                                                  .productsCommon
                                                                  .product_image_source +
                                                                '/' +
                                                                pro.product_thumbnail
                                                              : noImage
                                                          }
                                                          alt=""
                                                        />
                                                        <div className="card-body">
                                                          <div className="card-title">
                                                            {stripslashes(
                                                              getAliasName(
                                                                pro.product_alias,
                                                                pro.product_name
                                                              )
                                                            )}
                                                          </div>
                                                          {_.isEmpty(
                                                            pro.product_tag
                                                          ) === false && (
                                                            <div className="product-tag">
                                                              {pro.product_tag.map(
                                                                (
                                                                  tags,
                                                                  tagIndex
                                                                ) => (
                                                                  <span
                                                                    key={
                                                                      tagIndex
                                                                    }
                                                                    style={{
                                                                      display:
                                                                        tagIndex ===
                                                                        0
                                                                          ? ''
                                                                          : 'none'
                                                                    }}>
                                                                    {
                                                                      tags.pro_tag_name
                                                                    }
                                                                  </span>
                                                                )
                                                              )}
                                                            </div>
                                                          )}
                                                          {pro.product_short_description !==
                                                            null && (
                                                            <span className="menu-set-tag">
                                                              {
                                                                pro.product_short_description
                                                              }
                                                            </span>
                                                          )}
                                                          <p className="price-tag card-text">
                                                            ${pro.product_price}
                                                          </p>
                                                          <div className="order_now_btn">
                                                            <button
                                                              type="button"
                                                              className="add-to-btn-cart btn btn-primary btn-block"
                                                              onClick={this.showProductDetails.bind(
                                                                this,
                                                                mainIndex,
                                                                pro.product_slug,
                                                                'product_' +
                                                                  mainIndex1 +
                                                                  '_' +
                                                                  mainIndex +
                                                                  '_' +
                                                                  proIndex
                                                              )}>
                                                              Order Now
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          )
                        }
                      })}
                  </div>
                  <div
                    id="pro_loading"
                    style={{
                      display: 'none',
                      bottom: 0,
                      left: 0,
                      position: 'fixed',
                      right: 0,
                      top: 0,
                      margin: 'auto',
                      zIndex: 9999,
                      background: 'rgba(255, 255, 255, 0.8)'
                    }}>
                    <img
                      src={loadingImage}
                      alt="Loading"
                      style={{
                        bottom: 0,
                        left: 0,
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        margin: 'auto'
                      }}
                    />
                  </div>
                  {_.isEmpty(this.state.products) === true &&
                    this.state.productsLoading === false && (
                      <div
                        style={{
                          padding: '20px',
                          textAlign: 'center',
                          color: 'red'
                        }}>
                        {' '}
                        <b>No Products Found</b>{' '}
                      </div>
                    )}
                </div>
              </div>
            </section>
          </div>
        </main>
        {this.state.detailstatus === 'ok' && _.isEmpty(prDetails) === false && (
          <div
            className="modal fade product_detpopup"
            id="productModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close close_details_pop"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="container-fluid form-block">
                    <div className="row">
                      <div className="col-md-12 m-auto">
                        <div className="product-details product_detsec">
                          <div className="row">
                            <div
                              className="col-12 product_slidersec"
                              align="center">
                              {_.isEmpty(prDetails.image_gallery) === false &&
                                (prDetails.image_gallery.length > 1 ? (
                                  <Slider {...productMoreSliderSettings}>
                                    {prDetails.image_gallery.map(
                                      (slider, sIndex) => (
                                        <React.Fragment key={sIndex}>
                                          {slider.pro_gallery_image !== null &&
                                            slider.pro_gallery_image !== '' && (
                                              <div className="product-slide">
                                                <img
                                                  src={
                                                    this.state.prCommon
                                                      .product_gallery_image_source +
                                                    '/' +
                                                    slider.pro_gallery_image
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </Slider>
                                ) : (
                                  <div className="product-slide">
                                    <img
                                      src={
                                        this.state.prCommon
                                          .product_gallery_image_source +
                                        '/' +
                                        prDetails.image_gallery[0]
                                          .pro_gallery_image
                                      }
                                      alt=""
                                    />
                                  </div>
                                ))}
                            </div>
                            <div
                              className={
                                _.isEmpty(prDetails.image_gallery) === true
                                  ? 'col-12 product-info-colm product_info no-gallery'
                                  : 'col-12 product-info-colm product_info'
                              }>
                              <div className="card mb-3">
                                <div className="card-header bg-transparent">
                                  <div className="pdet_ttrow">
                                    <h5 className="pdet_tt">
                                      {stripslashes(
                                        getAliasName(
                                          prDetails.product_alias,
                                          prDetails.product_name
                                        )
                                      )}
                                      {/*<span className="product-review-block">{this.productReview("")}</span> */}{' '}
                                      {prDetails.product_discount !== '' &&
                                        typeof prDetails.product_discount !==
                                          undefined &&
                                        typeof prDetails.product_discount !==
                                          'undefined' && (
                                          <span className="pdet_off">
                                            {prDetails.product_discount}% OFF
                                          </span>
                                        )}
                                    </h5>
                                    <div className="pdet_rating">
                                      {this.displayRatting(
                                        prDetails.product_overall_rating
                                      )}
                                    </div>
                                  </div>
                                  {_.isEmpty(prDetails.product_tag) ===
                                    false && (
                                    <div className="product-tag">
                                      {prDetails.product_tag.map(
                                        (tags, tagIndex) => (
                                          <span key={tagIndex}>
                                            {tags.pro_tag_name}
                                          </span>
                                        )
                                      )}
                                    </div>
                                  )}
                                  {prDetails.product_long_description !==
                                    null && (
                                    <div className="pdet_des">
                                      <p style={{ whiteSpace: 'pre-line' }}>
                                        {prDetails.product_long_description}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="card-body">
                                  <div>
                                    <div className="choosen-product-options-colm left">
                                      <div className="product-choosen-item  choose-product-size-block">
                                        <div className="product-size-options-block">
                                          {prDetails.product_is_combo ===
                                            'Yes' && (
                                            <div className="accordion_container">
                                              {this.showComboOptions(
                                                prDetails.set_menu_component,
                                                parseInt(
                                                  prDetails.product_apply_minmax_select
                                                )
                                              )}
                                            </div>
                                          )}

                                          {prDetails.product_type === '4' && (
                                            <div
                                              className="modifier_products"
                                              id={
                                                'modify_' +
                                                prDetails.product_primary_id
                                              }>
                                              <input
                                                type="hidden"
                                                id="modProductId"
                                                name="modProductId"
                                                value={prDetails.product_id}
                                              />
                                              <input
                                                type="hidden"
                                                id="modProductPrimaryId"
                                                name="modProductPrimaryId"
                                                value={
                                                  prDetails.product_primary_id
                                                }
                                              />
                                              <input
                                                type="hidden"
                                                id={'modifierOptionValue'}
                                              />
                                              {this.showModifierOptions(
                                                prDetails.modifiers,
                                                prDetails.product_primary_id
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="choosen-product-options-colm right">
                                      <textarea
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        onChange={this.handleChangeDescription}
                                        placeholder="Enter your special message here"
                                        rows="3"
                                        maxLength="150"
                                        value={this.state.product_remarks}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer" />
                <div>
                  <h3 className="text-center prd-total-price product-details-noraml-price">
                    <sup>$</sup>
                    {/*prDetails.product_price */}{' '}
                    {(this.state.defaultQty * this.state.grandTotal).toFixed(2)}
                  </h3>
                  <input
                    type="hidden"
                    id="product_orignal_price"
                    value={prDetails.product_price}
                  />
                  <input
                    type="hidden"
                    id="product_total_price"
                    value={this.state.productPrice}
                  />
                  <div className="prd-chosen-sub-col ">
                    <div className="product-details-mobile-price">
                      <h3 className="text-center prd-total-price">
                        <sup>$</sup>
                        {/*prDetails.product_price */}{' '}
                        {(
                          this.state.defaultQty * this.state.grandTotal
                        ).toFixed(2)}
                      </h3>
                    </div>
                    <div className="addtocart-main">
                      <div className="prd-chosen-sub-item-left cart-update-div">
                        <div
                          className="qty-bx done_btn"
                          /* style={{ display: 'none' }} */
                        >
                          <span
                            className="qty-minus"
                            onClick={this.decQty.bind(this, prDetails)}>
                            -
                          </span>
                          <input
                            type="text"
                            readOnly
                            value={this.state.defaultQty}
                          />
                          <span
                            className="qty-plus"
                            onClick={this.incQty.bind(this, prDetails)}>
                            +
                          </span>
                        </div>
                      </div>
                      <div
                        className="prd-chosen-sub-item-right cart-update-div done_btn"
                        /* style={{ display: 'none' }} */
                        onClick={this.addToCartAction.bind(
                          this,
                          prDetails.product_id,
                          prDetails.product_alias !== ''
                            ? stripslashes(prDetails.product_alias)
                            : stripslashes(prDetails.product_name),
                          prDetails.product_sku,
                          this.state.productPrice,
                          this.state.defaultQty,
                          cartImage,
                          this.state.allowCart,
                          parseInt(prDetails.product_apply_minmax_select),
                          'Insert',
                          prDetails.product_type,
                          prDetails
                        )}>
                        <button className="add_to_cart">Done</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prd-chosen-sub-item-right prd-chosen-item-full cart-add-div">
                  <button
                    className="btn cart_btn"
                    style={{ display: 'none' }}
                    onClick={this.addToCart.bind(
                      this,
                      prDetails.product_primary_id,
                      prDetails.product_id,
                      prDetails.product_type
                    )}>
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer allProps={this.props} />
      </React.Fragment>
    )
  }
}

const DropdownIndicator = () => (
  <div className="react-select-dropdown">
    <div className="react-select-arrow" />
  </div>
)
const customStyles = {
  control: () => ({
    // none of react-select's styles are passed to <Control />
    height: 50,
    padding: '.375rem .75rem'
  })
}
const mapStateToProps = ({ cms, products, cart, settings }) => ({
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  outlets: cms.outlets,
  navItems: products.navItems,
  menuStatus: products.menuStatus,
  cartData: cart.returnData,
  cartStatus: cart.isStatus,
  settings: settings.returnData,
  setStatus: settings.isStatus
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getblocks, getOutlets, getMenu, getCartItems, getSetings },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)
