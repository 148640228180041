import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Header from '../layout/header'
import Footer from '../layout/footer'
import { history } from '../../store'
import { getblocks, getOutlets, getPage } from '../../stores/cms'
import super_saver from '../../assets/img/super_saver.jpg'
//import banner_new from '../../assets/img/banner_new.jpg'
import banner_new from '../../assets/img/2021CNYtakeaway_promo.jpg'
var parse = require('html-react-parser')

class Pages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageTitle: 'About Page'
    }
  }
  componentWillMount() {
    this.props.getblocks()
    this.props.getOutlets()
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    var pageSlug = this.props.match.params.pageSlug
    this.setState({ pageSlug: pageSlug })
    this.props.getPage(pageSlug)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var pageSlug = this.props.match.params.pageSlug
    if (prevProps.match.params.pageSlug !== pageSlug) {
      window.scrollTo(0, 0)
      this.setState({ pageSlug: pageSlug })
      this.props.getPage(pageSlug)
    }
  }
  render() {
    const pages = this.props.pages
    if (this.props.pageStatus === 'error') {
      history.push('/')
    }
    return (
      <React.Fragment>
        <Header allProps={this.props} />
        <div className="main-wrapper">
          {/*<div className="inner_banner_sec">
              <div className="container">
                <a href="https://www.springcourt.com.sg/menu">
                  <img src={banner_new} alt="" />
                </a>
              </div>
            </div>*/}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-header-block bg-transparent">
                  <h1 className="font-gothamlight  text-center text-uppercase">
                    {typeof pages.cmspage_title !== 'undefined'
                      ? parse(pages.cmspage_title)
                      : ''}
                  </h1>
                  {/* <h2 className="font-azakie text-center">
                    You can update your account details and check order status
                    here
                  </h2> */}
                  <nav
                    aria-label="breadcrumb"
                    className="d-flex justify-content-center">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page">
                        {typeof pages.cmspage_title !== 'undefined'
                          ? parse(pages.cmspage_title)
                          : ''}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-12 cms-page-container">
                {typeof pages.cmspage_description !== 'undefined'
                  ? parse(pages.cmspage_description)
                  : ''}
              </div>
            </div>
          </div>
        </div>
        <Footer allProps={this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cms }) => ({
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  outlets: cms.outlets,
  pages: cms.pages,
  pageStatus: cms.pageStatus
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getblocks,
      getOutlets,
      getPage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pages)
