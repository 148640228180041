import React from 'react'
import cookie from 'react-cookies'
import $ from 'jquery'

/* show Loader */
export const showLoader = function(divRef, type) {
  if (type === 'class') {
    $('.' + divRef)
      .addClass('loader-main-cls')
      .append('<div class="loader-sub-div"></div>')
  } else {
    $('#' + divRef)
      .addClass('loader-main-cls')
      .append('<div class="loader-sub-div"></div>')
  }
}

/* hide Loader */
export const hideLoader = function(divRef, type) {
  if (type === 'class') {
    $('.' + divRef).removeClass('loader-main-cls')
    $('.' + divRef)
      .find('.loader-sub-div')
      .remove()
  } else {
    $('#' + divRef).removeClass('loader-main-cls')
    $('#' + divRef)
      .find('.loader-sub-div')
      .remove()
  }
}

/* Allow Number Only */
export const numariconly = function(e) {
  const re = /[0-9. ]+/g
  if (!re.test(e.key)) {
    e.preventDefault()
  }
}


export const defaultLang = "en";
export const defaultLangName = "English";
export const availLangFiles = ["en", "zh"];
export const defaultLangList = { en: "English" };

export const languageCd = typeof cookie.load("language") != "undefined" ? cookie.load("language") : defaultLang;

export const languageNm = typeof cookie.load("language_name") != "undefined" ? cookie.load("language_name") : defaultLangName;
