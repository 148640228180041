import axios from 'axios'
import { API_URL, APP_ID } from '../config/constans'
import { getCookies } from '../config/supporters'
export const GET_SETTINGS = 'settings/GET_SETTINGS'

const initialState = {
  returnData: [],
  isFetching: true,
  isError: null,
  isStatus: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        returnData: action.returnData,
        isStatus: action.isStatus,
        isFetching: false
      }

    default:
      return state
  }
}
export function getSetings(pageslug = null) {
  let FI_API_URL = API_URL + 'apiv2/settings/getCommonSettings'
  let availability =
    getCookies('orderAvailability') !== '' &&
    typeof getCookies('orderAvailability') !== undefined
      ? getCookies('orderAvailability')
      : ''
  let postalFilter = 'No'

  return function(dispatch) {
    axios
      .get(
        `${FI_API_URL}/?app_id=` +
          APP_ID +
          '&availability=' +
          availability +
          '&postalFilter=' +
          postalFilter
      )
      .then(response => {
        dispatch({
          type: GET_SETTINGS,
          returnData: response.data.result_set,
          isStatus: response.data.status,
          isFetching: false
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}
