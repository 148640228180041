import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import axios from 'axios'
import cookie from 'react-cookies'
import {
  getblocks,
  getOutlets,
  getBanners,
  getFeatureProducts
} from '../../stores/cms'
import {
  stripslashes,
  getStaticBlock,
  getAliasName
} from '../../config/supporters'
import noImage from '../../assets/img/product-no-image.jpg'
import Header from '../layout/header'
import Footer from '../layout/footer'
import Slider from 'react-slick'
import { MEDIA_PATH, API_URL, APP_ID } from '../../config/constans'
var _ = require('lodash')
var parse = require('html-react-parser')
class Home extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      showNormalPopup: 'No',
      normalPoupContent: '',
      videoPoupContent: '',
      pageName: this.props.location.pathname
    }
  }
  componentDidMount() {
    var displayPopup = 0
    var NormalPopupTime = cookie.load('NormalPopupTime')
    if (
      NormalPopupTime !== '' &&
      typeof NormalPopupTime !== undefined &&
      typeof NormalPopupTime != 'undefined'
    ) {
      var checkTime = new Date()
      var checkingTime = checkTime.getMinutes()
      var timeCheck = checkingTime - NormalPopupTime

      if (timeCheck > 5) {
        displayPopup = 1
      }
    } else {
      displayPopup = 1
    }

    $('.box-content').click(function(e) {
      e.stopPropagation()
    })
    if (this.state.pageName === '/sign-in') {
      $('#signInModalCenter').modal('show')
    }
    if (this.state.pageName === '/online-order') {
      $('#SelectAvailabilityModalCenter').modal('show')
    }
    if (displayPopup === 1) {
      /* if (cookie.load('NormalPopup') !== 'Yes') { */
      axios
        .get(
          API_URL +
            'apiv2/settings/getCommonSettings?app_id=' +
            APP_ID +
            '&postalFilter=No='
        )
        .then(res => {
          var showNormalPopup =
            parseInt(
              res.data.result_set.client_promo_code_normal_popup_enable
            ) === 1
              ? 'Yes'
              : 'No'
          this.setState({ showNormalPopup: showNormalPopup })
          /*  &&  this.state.showNormalPopup === 'Yes' */
          if (displayPopup === 1) {
            /* if (cookie.load('NormalPopup') !== 'Yes') { */
            // console.log(this.props.staticblocks);
            var normalPoupContent = getStaticBlock(
              this.props.staticblocks,
              'home-available-popup'
            )
            if (
              normalPoupContent !== '' &&
              typeof normalPoupContent !== undefined &&
              typeof normalPoupContent !== 'undefined'
            ) {
              this.setState({ normalPoupContent: normalPoupContent })
              if (this.state.pageName !== '/online-order') {
                $('#promo-video').modal('show')
                cookie.save('NormalPopup', 'Yes')
                var currentTime = new Date()
                var poup_time = currentTime.getMinutes()
                cookie.save('NormalPopupTime', poup_time)
              }
            }
          }
        })
    }

    setTimeout(function(){
      ///if (cookie.load('VideoPopup') !== 'Yes') { 
        var videoPoupContent = getStaticBlock(
          this.props.staticblocks,
          'video-popup'
        )
        if (
          videoPoupContent !== '' &&
          typeof videoPoupContent !== undefined &&
          typeof videoPoupContent !== 'undefined'
        ) {
          this.setState({ videoPoupContent: videoPoupContent })
            $('#video-popup').modal('show')
            cookie.save('VideoPopup', 'Yes')
        }
     // }
    }.bind(this),1000);


  }
  componentWillMount() {
    this.props.getblocks()
    this.props.getOutlets()
    this.props.getBanners()
    this.props.getFeatureProducts()
  }

  toggleCheckbox() {
    const expires = new Date()
    expires.setTime(expires.getTime() + 5 * 60 * 1000)
    cookie.save('NormalPopup', 'Yes', { path: '/', expires })
    $('#promo-video').modal('hide')
    //callPlayer('yt-player', 'stopVideo');
    $('#promo-video').html('')
  }

  render() {
    const masterSliderSettings = {
      className: 'home-slider',
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    }
    const aboutSliderSettings = {
      className: 'home-slider',
      speed: 500,
      fade: true,
      cssEase: 'linear',
      arrows: false,
      dots: true
    }
    const dineInSliderSettings = {
      className: 'home-slider',
      speed: 500,
      fade: true,
      cssEase: 'linear',
      arrows: false,
      dots: true,
      adaptiveHeight: false
    }
    const featureDishesSliderSettings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    var proImsgsrc = MEDIA_PATH + 'products/main-image/'

    return (
      <React.Fragment>
        <Header allProps={this.props} />
        <main>
          <div className="main-wrapper">
            {_.isEmpty(this.props.banners) === false && (
              <section className="master-slider">
                <div className="container">
                  <Slider {...masterSliderSettings}>
                    {this.props.banners.map((banner, bIndex) => (
                      <div className="slck-slider" key={bIndex}>
                        <a
                          target={
                            banner.banner_description !== '' ? '_blank' : ''
                          }
                          href={
                            banner.banner_description !== ''
                              ? banner.banner_description
                              : '#'
                          }>
                          {' '}
                          <img
                            src={MEDIA_PATH + 'banners/' + banner.banner_image}
                          />
                          {/* <div className="banner-info">
                				          { parse(stripslashes(banner.banner_description))}
                				  </div> */}{' '}
                        </a>
                      </div>
                    ))}
                  </Slider>
                </div>
              </section>
            )}
            <section
              className="home-about-section section-wrapper"
              id="home_about_section">
              <div className="container">
                <div className="row">
                  {getStaticBlock(this.props.staticblocks, 'about-left-text')}
                  <div className="col-12 col-md-6 mx-auto  d-flex align-items-center">
                    <div className="right-colm about-slider-block">
                      {_.isEmpty(this.props.staticblocks) === false && (
                        <Slider {...aboutSliderSettings}>
                          {getStaticBlock(
                            this.props.staticblocks,
                            'about-right-slider'
                          )}
                        </Slider>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="home-dinein-section section-wrapper"
              id="home_dinein_section">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto  d-flex left-colm ">
                    <div className="banner-block about-slider-block">
                      {_.isEmpty(this.props.staticblocks) === false && (
                        <Slider {...dineInSliderSettings}>
                          {getStaticBlock(
                            this.props.staticblocks,
                            'home-dine-in-slider'
                          )}
                        </Slider>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mx-auto  d-flex align-items-center right-colm">
                    {getStaticBlock(
                      this.props.staticblocks,
                      'home-dine-in-right-text'
                    )}
                  </div>
                </div>
              </div>
            </section>

            {_.isEmpty(this.props.products) === false &&
              _.isEmpty(this.props.prCommon) === false && (
                <section
                  className="home-featured-dishes-section section-wrapper"
                  id="home_featureddishes_section">
                  <div className="container mt-40">
                    <h1 className="text-center font-gothamlight header-line mb-lg-3">
                      {' '}
                      <span>
                        {' '}
                        {getStaticBlock(
                          this.props.staticblocks,
                          'home-featured-tag-line'
                        )}
                      </span>
                    </h1>
                    <div className="row mt-30">
                      <div className="col-12 featured-dishes-slider">
                        <Slider {...featureDishesSliderSettings}>
                          {this.props.products.map((pro, pIndex) => (
                            <div className="slick-slide-item" key={pIndex}>
                              <div className="sprct-feature-item-box">
                                <img
                                  src={
                                    pro.product_thumbnail !== ''
                                      ? proImsgsrc + pro.product_thumbnail
                                      : noImage
                                  }
                                  alt=""
                                />
                                <div className="box-content">
                                  <h3 className="title">
                                    {stripslashes(
                                      getAliasName(
                                        pro.product_alias,
                                        pro.product_name
                                      )
                                    )}
                                  </h3>
                                  <div className="item-info-action">
                                    {pro.product_short_description !== null && (
                                      <p>{pro.product_short_description}</p>
                                    )}

                                    <Link
                                      to={
                                        '/menu/' +
                                        pro.pro_cate_slug +
                                        '/' +
                                        pro.product_slug
                                      }
                                      className="btn btn-primary">
                                      Order Now
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </section>
              )}
          </div>
        </main>
        <Footer allProps={this.props} />

        {/*Video popup Start*/}
        <div
          className="modal fade"
          id="video-popup"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg intro_out"
            role="document">
            <div className="modal-content intro_popup">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body">{this.state.videoPoupContent}</div>
            </div>
          </div>
        </div>

        {/*Video popup - End*/}

        {/* modal footer */}
        <div
          className="modal fade"
          id="promo-video"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg intro_out"
            role="document">
            <div className="modal-content intro_popup">
              <button
                type="button"
                className="close"
                onClick={this.toggleCheckbox.bind(this)}
                data-dismiss="modal"
                aria-label="Close"
              />

              <div className="modal-body">{this.state.normalPoupContent}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cms }) => ({
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  outlets: cms.outlets,
  banners: cms.banners,
  products: cms.products,
  prCommon: cms.prCommon
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getblocks, getOutlets, getBanners, getFeatureProducts },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
