module.exports = {
  API_URL: 'https://ccpl.ninjaos.com/',

  APP_ID: '37480D40-8F3F-46AA-8693-7EF507D1B60E',
  PICKUP_ID: '718B1A92-5EBB-4F25-B24D-3067606F67F0',
  DINEIN_ID: 'EF9FB350-4FD4-4894-9381-3E859AB74019',
  DELIVERY_ID: '634E6FA8-8DAF-4046-A494-FFC1FCF8BD11',
  SUCCESS_ALERT: 'success',
  FAILURE_ALERT: 'danger',
  OUTLET_ID: 242,
  TABLE_ID: 2391,
  TABLE_NAME: 'Online Table',
  MEDIA_PATH: 'https://ccpl.ninjaos.com/media/dev_team/',
  MENU_PDF: '/pdf/spring-court-menu-2019.pdf',
  MEMBER_LIST: 'http://membership.springcourt.com.sg/',
  MEMBER_PDF: '/pdf/membership.pdf',
  dineInText: 'Dine In',
  pickUpText: 'Self Collection',
  deliveryText: 'Delivery',
  SITEURL: 'https://springcourt.com.sg/',
  FB_APPID: '437831426766700',
  stripeCompany: 'Spring Court',
  stripeImage:
    'https://ccpl.ninjaos.com/media/dev_team/company-logo/eb259dc06fddd5028d99490ec5528e1c.png',
  /* stripeKey: 'pk_test_gOgyc4KtNAk48z5WOuqFQWdP', */
  stripeKey: 'pk_live_zs4WsBNeSIS78Xc9POkRiBpp',
  stripeEmail: 'dev@jankosoft.com',
  stripeReference: 'springcourt',
  stripeDesc: 'My Checkout',
  stripeCurrency: 'SGD',
  dineInPercentage: 25,
  ALACARTE_PDF: '',
  LUNCH_MENU_PDF: '/pdf/springcourt-luch.pdf',
  BEVERAGES_PDF: '/pdf/springcourt-beverage.pdf',
  WEDDING_PDF: '/pdf/wedding-menu.pdf',
  SETMENU_PDF: '/pdf/springcourt-setmenu.pdf',
  SERVICE_CHARGE: 10
}
