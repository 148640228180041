import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { getFbLoginData } from '../../stores/user'
import FacebookLogin from 'react-facebook-login'
import loadingImage from '../../assets/img/spinner.gif'
import { APP_ID, API_URL, SITEURL, FB_APPID } from '../../config/constans'
import DatePicker from 'react-datepicker'
import moment from 'moment'
var dateFormat = require('dateformat')

var _ = require('lodash')
const siteURL = SITEURL

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      guest_type: 1,
      dob: ''
    }
    this.handleChangeDate = this.handleChangeDate.bind(this)
  }
  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true
    var userPhoneNumber = fields['phonenumber']

    //First Name
    if (!fields['firstname']) {
      formIsValid = false
      errors['firstname'] = 'This field is required.'
    }

    if (typeof fields['firstname'] !== 'undefined') {
      if (!fields['firstname'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false
        errors['firstname'] = 'First Name must contain only letters.'
      }
    }
    //Last Name
    if (!fields['lastname']) {
      formIsValid = false
      errors['lastname'] = 'This field is required.'
    }

    if (typeof fields['lastname'] !== 'undefined') {
      if (!fields['lastname'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false
        errors['lastname'] = 'Last Name must contain only letters.'
      }
    }
    //Phone Number
    if (!userPhoneNumber) {
      formIsValid = false
      errors['phonenumber'] = 'This field is required.'
    }

    if (typeof userPhoneNumber !== 'undefined') {
      if (!userPhoneNumber.match(/^[0-9]+$/)) {
        formIsValid = false
        errors['phonenumber'] = 'Invalid Mobile number'
      } else if (userPhoneNumber.length < 8) {
        formIsValid = false
        errors['phonenumber'] =
          'Mobile number must be of minimum 8 characters length.'
      }
    }
    //Email
    if (!fields['email']) {
      formIsValid = false
      errors['email'] = 'This field is required.'
    }

    if (typeof fields['email'] !== 'undefined') {
      let lastAtPos = fields['email'].lastIndexOf('@')
      let lastDotPos = fields['email'].lastIndexOf('.')

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false
        errors['email'] = 'Email is not valid.'
      }
    }
    if (parseInt(this.state.guest_type) === 1) {
      if (!fields['password']) {
        formIsValid = false
        errors['password'] = 'Password field is required.'
      }
      if (typeof fields['password'] !== 'undefined') {
        if (fields['password'].length < 6) {
          formIsValid = false
          errors['password'] =
            'Password must be of minimum 6 characters length.'
        }
      }

      if (!fields['confirmpassword']) {
        formIsValid = false
        errors['confirmpassword'] = 'Confirm Password field is required.'
      }

      if (typeof fields['confirmpassword'] !== 'undefined') {
        if (fields['confirmpassword'].length < 6) {
          formIsValid = false
          errors['confirmpassword'] =
            'Confirm Password must be of minimum 6 characters length'
        } else if (fields['password'] !== fields['confirmpassword']) {
          formIsValid = false
          errors['confirmpassword'] =
            'Your password and confirmation password do not match.'
        }
      }

      if ($('#terms').is(':checked') === false) {
        formIsValid = false
        errors['terms'] = 'Terms and conditions field is required.'
      }
    }
    //Last Name
    if (!fields['dob']) {
      formIsValid = false
      errors['dob'] = 'This field is required.'
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleChangeDate(datevalue) {
    let fields = this.state.fields
    fields['dob'] = datevalue
    this.setState({ fields, errors: {} })
  }

  signUpSubmit(e) {
    e.preventDefault()

    if (this.handleValidation()) {
      var qs = require('qs')
      var customer_birthdate = ''
      if (
        this.state.fields['dob'] !== '' &&
        typeof this.state.fields['dob'] !== undefined
      ) {
        customer_birthdate = moment(this.state.fields['dob']).format(
          'DD-MM-YYYY'
        )
      }

      var postObject = {
        app_id: APP_ID,
        type: 'web',
        customer_first_name: this.state.fields['firstname'],
        customer_last_name: this.state.fields['lastname'],
        customer_email: this.state.fields['email'],
        customer_password: this.state.fields['password'],
        customer_phone: this.state.fields['phonenumber'],
        customer_birthdate: customer_birthdate,
        site_url: siteURL
      }
      $('#c_loading').show()
      axios
        .post(API_URL + 'api/customer/registration', qs.stringify(postObject))
        .then(response => {
          $('#c_loading').hide()
          $('.gue-comm-error').hide()
          if (response.data.status === 'ok') {
            $('#terms').prop('checked', false)
            this.setState({
              fields: {
                firstname: '',
                lastname: '',
                email: '',
                phonenumber: '',
                password: '',
                confirmpassword: ''
              }
            })
            $('.guest-success').show()
            $('.success-msg-p').html(response.data.message)
          } else {
            if (typeof response.data.form_error !== 'undefined') {
              $('.guest-form-error').show()
              $('.guest-form-error').html(response.data.form_error)
              $('.guest-form-error p').addClass('error-msg')
            } else {
              $('.guest-form-error').show()
              $('.guest-form-error-p').html(response.data.message)
            }
          }
          return false
        })
        .catch(function(error) {
          console.log(error)
        })
    } else {
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields
    fields[field] = e.target.value

    this.setState({ fields, errors: {} })
    if (field === 'phonenumber') {
      var mobileNo = e.target.value
      if (mobileNo !== '') {
        this.phoneNumberExist(mobileNo)
      }
    }

    if (field === 'email') {
      var email = e.target.value
      if (email !== '' && this.emailIsValid(email) === true) {
        this.emailExist(email)
      }
    }
  }

  phoneNumberExist(mobileNo) {
    let mobileNumber = mobileNo

    var qs = require('qs')
    var postObject = {
      app_id: APP_ID,
      type: 'web',
      customer_phone: mobileNumber
    }
    axios
      .post(API_URL + 'api/customer/phoneno_exist', qs.stringify(postObject))
      .then(response => {
        if (response.data.status === 'ok') {
          return true
        } else {
          let fields = this.state.fields
          fields['phonenumber'] = ''
          this.setState({
            fields,
            errors: { phonenumber: 'Phone no already exists' }
          })
        }
      })
  }

  emailExist(email) {
    var qs = require('qs')
    var postObject = {
      app_id: APP_ID,
      type: 'web',
      customer_email: email
    }
    axios
      .post(API_URL + 'api/customer/email_exist', qs.stringify(postObject))
      .then(response => {
        if (response.data.status === 'ok') {
          return true
        } else {
          let fields = this.state.fields
          fields['email'] = ''
          this.setState({
            fields,
            errors: { email: 'Email address already exists' }
          })
        }
      })
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  responseFacebook = response => {
    if (
      response.name !== '' &&
      response.email !== '' &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(' ')
      var firstname = vSplitName[0]
      var lastname = vSplitName[1]
      var birthday = response.birthday
      var qs = require('qs')
      var photo = response.picture.data.url
      /* update gender field */
      var postGender = ''
      if (
        typeof response.gender !== 'undefined' &&
        response.gender === 'male'
      ) {
        postGender = 'M'
      } else if (
        typeof response.gender !== 'undefined' &&
        response.gender === 'female'
      ) {
        postGender = 'F'
      }
      /* update DOB */
      var dob = ''
      if (typeof birthday !== 'undefined' && birthday !== '') {
        dob = dateFormat(response.birthday, 'yyyy-mm-dd')
      }

      var postObject = {
        app_id: APP_ID,
        type: 'web',
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob
      }

      this.props.getFbLoginData(qs.stringify(postObject))
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="modal fade"
          id="signUpModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document">
            <div className="modal-content bg-transparent sign-up-modal">
              <div className="modal-body">
                <div className="pop-whole d-flex flex-row-reverse">
                  <div className="pop-whole-lhs">
                    <div className="pop-whole-lhs-inner">
                      <h2 className="font-gothamlight text-uppercase">
                        New to <br />
                        Spring Court ?
                      </h2>
                      <h2 className="font-azakie">
                        Create your Spring Court account.
                      </h2>
                    </div>
                  </div>
                  <div className="inside-popup-rhs">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="popup-header text-center">
                      <FacebookLogin
                        appId={FB_APPID}
                        fields="name,email,picture,first_name,last_name,gender"
                        callback={this.responseFacebook}
                        scope="public_profile,email"
                        cssClass="btn fb-button"
                        redirectUri={siteURL}
                        icon="fab fa-facebook-f"
                        textButton="&nbsp; Signup with Facebook "
                      />
                      <div className="or-seperator">
                        {' '}
                        <span>Or</span>
                      </div>
                      <h4 className="font-opnsns font-weight-light">
                        Create account with <br />
                        <img src="../img/spring-court-text-logo.png" />
                      </h4>
                    </div>
                    <div className="popup-body">
                      <div
                        className="alert-success guest-success gue-comm-error"
                        style={{ display: 'none' }}>
                        <p className="success-msg success-msg-p  ">
                          {' '}
                          Guest account created successfully
                        </p>
                      </div>

                      <div
                        className="alert-error guest-form-error gue-comm-error"
                        style={{ display: 'none' }}>
                        <p className="error-msg guest-form-error-p" />
                      </div>
                      <h4>Your Information</h4>
                      <form name="signupform" className="signupform">
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              name="firstname"
                              id="firstname"
                              className="form-control "
                              type="text"
                              placeholder="First Name"
                              onChange={this.handleChange.bind(
                                this,
                                'firstname'
                              )}
                              value={this.state.fields['firstname']}
                            />
                          </div>
                          <span
                            className={
                              this.state.errors['firstname']
                                ? 'error show'
                                : 'error hide'
                            }>
                            {this.state.errors['firstname']}
                          </span>
                        </div>
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              className="form-control input-focus"
                              placeholder="Last Name"
                              onChange={this.handleChange.bind(
                                this,
                                'lastname'
                              )}
                              value={this.state.fields['lastname']}
                            />
                            <span
                              className={
                                this.state.errors['lastname']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['lastname']}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="tel"
                              maxLength={8}
                              name="phonenumber"
                              id="phonenumber"
                              className="form-control input-focus"
                              pattern="\d*"
                              placeholder="Phone Number"
                              onChange={this.handleChange.bind(
                                this,
                                'phonenumber'
                              )}
                              value={this.state.fields['phonenumber']}
                            />
                            <span
                              className={
                                this.state.errors['phonenumber']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['phonenumber']}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="focus-out bithday_picker">
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Birthday"
                              maxDate={new Date()}
                              onChange={this.handleChangeDate}
                              selected={this.state.fields['dob']}
                            />
                            <span
                              className={
                                this.state.errors['dob']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['dob']}
                            </span>
                            <span className="birthday_info">
                              [ * Used to send vouchers and promotions. ]
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <h4 className="your_lgn_spacing">Your Login</h4>
                          <div className="focus-out">
                            <input
                              type="text"
                              ref="email"
                              id="email"
                              name="email"
                              className="form-control input-focus"
                              placeholder="Email Address"
                              onChange={this.handleChange.bind(this, 'email')}
                              value={this.state.fields['email']}
                            />
                            <span
                              className={
                                this.state.errors['email']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['email']}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              className="form-control input-focus"
                              placeholder="Password"
                              onChange={this.handleChange.bind(
                                this,
                                'password'
                              )}
                              value={this.state.fields['password']}
                            />
                            <span
                              className={
                                this.state.errors['password']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['password']}
                            </span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="focus-out">
                            <input
                              type="password"
                              name="confirmpassword"
                              id="confirmpassword"
                              className="form-control input-focus"
                              placeholder="Confirm Password"
                              onChange={this.handleChange.bind(
                                this,
                                'confirmpassword'
                              )}
                              value={this.state.fields['confirmpassword']}
                            />
                            <span
                              className={
                                this.state.errors['confirmpassword']
                                  ? 'error show'
                                  : 'error hide'
                              }>
                              {this.state.errors['confirmpassword']}
                            </span>
                            <input
                              id="guest-type"
                              type="hidden"
                              name="guest-type"
                              value={this.state.guest_type}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="custom_checkbox custom_checkbox_content">
                            <input
                              type="checkbox"
                              name="terms"
                              id="terms"
                              onChange={this.handleChange.bind(this, 'terms')}
                              value="1"
                            />{' '}
                            <span />
                            <div className="sign_reg">
                              <p>
                                I consent to receive coupons and promotions news
                                from Spring Court, its franchisees and
                                affiliates, and understand and agree that my
                                information will be used as described here and
                                in Spring Court Privacy Policy and Terms of
                                Service.
                              </p>
                            </div>
                          </div>
                          <span
                            className={
                              this.state.errors['terms']
                                ? 'error show'
                                : 'error hide'
                            }>
                            {this.state.errors['terms']}
                          </span>
                        </div>
                        <div className="form-group">
                          <div className="login_pop_sub">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={this.signUpSubmit.bind(this)}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="c_loading"
          style={{
            display: 'none',
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            margin: 'auto',
            zIndex: 9999,
            background: 'rgba(255, 255, 255, 0.8)'
          }}>
          <img
            src={loadingImage}
            alt="Loading"
            style={{
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              margin: 'auto'
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = ({ user }) => ({
  fbloginData: user.fbloginData
})
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        getFbLoginData
      },
      dispatch
    )
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup)
