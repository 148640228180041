import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { getPromotions, getPromotionDetails } from '../../stores/rewards'
import { API_URL, APP_ID, DINEIN_ID } from '../../config/constans'
import $ from 'jquery'
import cookie from 'react-cookies'
import { showInfo, showInfoNew } from '../../config/supporters'
import { history } from '../../store'
import moment from 'moment'
var _ = require('lodash')
var Parser = require('html-react-parser')
var qs = require('qs')
const promotionImgSrc = 'https://ccpl.ninjaos.com/media/dev_team/promotion/'

class Promotions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
      viewPromoData: {},
      cartDetails: [],
      cartItems: [],
      promocodevalue: ''
    }
    this.handleChangeFilterRedeem = this.handleChangeFilterRedeem.bind(this)
    this.viewPromotion = this.viewPromotion.bind(this)
    this.applyPromotionCode = this.applyPromotionCode.bind(this)
    this.handleChangePromoFormField = this.handleChangePromoFormField.bind(this)
  }
  componentDidMount() {
    axios
      .get(
        API_URL +
          'api/cartver2/contents?status=A&app_id=' +
          APP_ID +
          '&customer_id=' +
          cookie.load('UserId')
      )
      .then(res => {
        if (
          res.data.status === 'ok' &&
          typeof res.data.result_set !== 'undefined'
        ) {
          this.setState({
            cartDetails: res.data.result_set.cart_details,
            cartItems: res.data.result_set.cart_items
          })
        }
      })
  }
  componentWillMount() {
    this.props.getPromotions(1)
  }
  handleChangeFilterRedeem(e) {
    this.props.getPromotions(e.target.value)
  }
  handleChangePromoFormField(e) {
    this.setState({ promocodevalue: e.target.value })
  }
  viewPromotion(promo, promotion_id) {
    this.setState({ viewPromoData: promo })
    this.props.getPromotionDetails(promotion_id)
  }
  /*apply coupon  function*/
  applyPromotionCode(promocode) {
    $('#promotionsDetailsModalCenter').modal('hide')
    var postObject = {}
    var promocode = promocode !== '' ? promocode : this.state.promocodevalue

    var cartSubToatl = parseFloat(this.state.cartDetails.cart_sub_total)
    var cartItemsCount = parseInt(this.state.cartDetails.cart_total_items)

    postObject = {
      /* cart details */
      app_id: APP_ID,
      reference_id: cookie.load('UserId'),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(),
      availability_id: cookie.load('orderAvailability'),
      outlet_id: cookie.load('orderOutletId')
    }

    axios
      .post(
        API_URL + 'api/promotion_api_v2/apply_promotion',
        qs.stringify(postObject)
      )
      .then(res => {
        if (res.data.status === 'success') {
          cookie.save('myaccount-promocode', promocode)

          cookie.save('promotionApplied', 'Yes')
          cookie.save(
            'promotionAmount',
            parseFloat(res.data.result_set.promotion_amount)
          )
          cookie.save('promocode', res.data.result_set.promotion_code)
          cookie.save(
            'promotionCategory',
            res.data.result_set.promotionCategory
          )
          cookie.save('promotionId', res.data.result_set.promotion_id)

          /*  showInfo(res.data.message,"","reload");
                    cookie.save('promotion_id', res.data.result_set.promotion_id);
                    cookie.save('promotion_applied', "Yes");
                    cookie.save('promotion_code', res.data.result_set.promotion_code);
                    cookie.save('promotion_delivery_charge_applied', res.data.result_set.promotion_delivery_charge_applied);
                    cookie.save('promotion_amount', res.data.result_set.promotion_amount);
                    cookie.save('promotion_category', res.data.result_set.promotion_category);
                    cookie.save('prmo_type', res.data.result_set.prmo_type);                   

                    Remove if voucher applied 
                    cookie.remove('voucher_applied');
                    cookie.remove('voucher_code');
                    cookie.remove('voucher_amount');*/

          showInfoNew(
            'Congratulations !! The Promocode is available. <br>Click Apply to redeem the code',
            'Info',
            'infor'
          )

          history.push('/checkout')
        } else {
          if (
            typeof res.data.show_message !== 'undefined' &&
            res.data.show_message === 'Yes'
          ) {
            showInfo(res.data.message, '', 'redirect', '/')
          } else if (typeof res.data.form_error !== 'undefined') {
            showInfo(res.data.form_error, '', 'reload')
          } else {
            showInfo(res.data.message, '', 'reload')
          }
          if (res.data.clear_offer === 'Yes') {
            cookie.save('myaccount-promocode', '')
            /*   cookie.remove('promotion_id');
                        cookie.remove('promotion_applied');
                        cookie.remove('promotion_code');
                        cookie.remove('promotion_delivery_charge_applied');
                        cookie.remove('promotion_amount');
                        cookie.remove('promotion_category');
                        cookie.remove('prmo_type');
						*/
          }
        }
      })
  }
  getPromoCategory() {
    var product_cartid = ''
    var cartItems = this.state.cartItems
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
          product_cartid += ';'
        }
        product_cartid +=
          cartItems[p].cart_item_product_id +
          '|' +
          cartItems[p].cart_item_total_price +
          '|' +
          cartItems[p].cart_item_qty
      }
    }

    return product_cartid
  }
  render() {
    const promotionsList = this.props.customerPromotions
    const viewPromoData = this.state.viewPromoData
    const promotionDetails = this.props.promotionDetails

    return (
      <React.Fragment>
        <div className="promotions-container myaccount-orders-block">
          <div className="row">
            <div className="p0 col">
              <div className="redeemMain">
                <p>Redeem Your</p>
                <h2>Promotions</h2>
                <div className="redeemSearch">
                  <div role="group" className="btn-group">
                    <input
                      name="text"
                      onChange={this.handleChangePromoFormField.bind(this)}
                      value={this.state.promocodevalue}
                      placeholder="Add Prome/Invite Code Here"
                      type="text"
                      className="form-control"
                    />
                    <button
                      className="btn btn-secondary"
                      onClick={this.applyPromotionCode.bind(this, '')}>
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="address promo">
                <div className="tab-content" id="promotionsInfoTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="promotionsTabContent"
                    role="tabpanel"
                    aria-labelledby="promotionsTabContent">
                    <div className="row">
                      <div className="col-12 order-item-filter-block mb-3">
                        <div className="order-filter-option">
                          <span className="filter-text-lbl">Show</span>
                          <div className="custom-select-control orders-item-filter-dropdown">
                            <select
                              className="form-control"
                              onChange={this.handleChangeFilterRedeem.bind(
                                this
                              )}>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <span className="filter-text-lbl">Per Page</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-9 ml-auto mr-auto">
                        <div className="row">
                          {_.isEmpty(promotionsList) === false &&
                            promotionsList.map((promo, promoIndex) => (
                              <div className="col-12 col-sm-6" key={promoIndex}>
                                <div className="promoWrapper">
                                  <div className="row">
                                    <div className="col-5">
                                      <div className="promotionBrandLogo">
                                        <img
                                          src={
                                            promo.promotion_image
                                              ? promotionImgSrc +
                                                promo.promotion_image
                                              : 'img/spring-court-logo.png'
                                          }
                                          alt=""
                                        />{' '}
                                      </div>
                                    </div>
                                    <div className="col-7">
                                      <div className="promoContent">
                                        <div className="div1">
                                          {typeof promo.promotion_title !==
                                            'undefined' &&
                                            promo.promotion_title !== '' && (
                                              <p className="txt1">
                                                {' '}
                                                {promo.promotion_title
                                                  ? promo.promotion_title
                                                  : ''}
                                              </p>
                                            )}
                                          {promo.promotion_type ===
                                            'percentage' && (
                                            <p className="txt2">
                                              {promo.promotion_percentage} % OFF
                                            </p>
                                          )}
                                          {promo.promotion_type !==
                                            'percentage' && (
                                            <p className="txt2">
                                              <sup>$</sup>
                                              {promo.promotion_max_amt} OFF
                                            </p>
                                          )}

                                          <p className="txt3">
                                            {'VALID TILL'}{' '}
                                            {moment(
                                              promo.promotion_end_date
                                            ).format('MMM Do YY hh:ss a')}
                                          </p>
                                          <p className="txt4 text-uppercase">
                                            {promo.promo_code}
                                          </p>
                                        </div>

                                        <div className="promoBtn">
                                          <button
                                            className="btn btn-default"
                                            onClick={this.viewPromotion.bind(
                                              this,
                                              promo,
                                              promo.promotion_id
                                            )}
                                            data-toggle="modal"
                                            data-target="#promotionsDetailsModalCenter">
                                            VIEW
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            onClick={this.applyPromotionCode.bind(
                                              this,
                                              promo.promo_code
                                            )}>
                                            REDEEM
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <div />
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Promotions Info Modal */}
        <div
          className="modal fade"
          id="promotionsDetailsModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document">
            <div className="modal-content bg-Camelot promotion-info-modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="container-fluid form-block">
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <div className="promoWrapper">
                        <div className="row">
                          <div className="col-5">
                            <div className="promotionBrandLogo">
                              <img
                                src={
                                  viewPromoData.promotion_image
                                    ? promotionImgSrc +
                                      viewPromoData.promotion_image
                                    : 'img/spring-court-logo.png'
                                }
                                alt=""
                                style={{ width: '173px' }}
                              />{' '}
                            </div>
                          </div>
                          <div className="col-7">
                            <div className="promoContent popPromo">
                              <div className="div1">
                                <p className="txt1">
                                  {viewPromoData.promotion_title
                                    ? viewPromoData.promotion_title
                                    : 'Promotion'}
                                </p>
                                {viewPromoData.promotion_type ===
                                  'percentage' && (
                                  <p className="txt2">
                                    {viewPromoData.promotion_percentage} % OFF
                                  </p>
                                )}
                                {viewPromoData.promotion_type !==
                                  'percentage' && (
                                  <p className="txt2">
                                    <sup>$</sup>
                                    {viewPromoData.promotion_max_amt} OFF
                                  </p>
                                )}
                                {/*  <p className="txt3">VALID TILL 25TH OF MAY 2018</p> */}
                                <p className="txt3">
                                  Promotion amount upto <sup>$</sup>
                                  {viewPromoData.promotion_max_amt} OFF{' '}
                                </p>
                                <p className="txt4 text-uppercase">
                                  {viewPromoData.promo_code
                                    ? 'NO-' + viewPromoData.promo_code
                                    : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*   <div className="row">
                                                    <div className="col-12 text-center promo-info-banner">
                                                        <img src="img/product-popup-image.jpg" alt="cedeleLogo" />
                                                    </div>
                                                </div> */}
                        <div className="row">
                          <div className="col-12">
                            <div className="redemPopTxt">
                              {_.isEmpty(viewPromoData.promo_code) ===
                                false && <p>{Parser(viewPromoData.promo_desc)}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="redemPopbtn">
                              <button
                                className="btn btn-secondary"
                                onClick={this.applyPromotionCode.bind(
                                  this,
                                  viewPromoData.promo_code
                                )}>
                                REDEEM
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer" />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ rewards }) => ({
  customerPromotions: rewards.customerPromotions,
  promotionDetails: rewards.promotionDetails
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPromotions,
      getPromotionDetails
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotions)
