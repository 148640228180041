import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { API_URL, APP_ID, DINEIN_ID } from '../../config/constans'
import { connect } from 'react-redux'
import moment from 'moment'
import cookie from 'react-cookies'
import { getEarnedRewards, getRedeemedRewards } from '../../stores/rewards'
import { getPromoCount } from '../../stores/user'
import noImage from '../../assets/img/product-no-image.jpg'
import { getSetings } from '../../stores/settings'
import { addressFormat } from '../../config/supporters'
var _ = require('lodash')
var parse = require('html-react-parser')
const filterCurrentOrder = 'rewardspoints'
const filterPastOrder = 'redeempoints'

class Myrewards extends Component {
  constructor(props) {
    super(props)
    this.handleChangeFilterRewards = this.handleChangeFilterRewards.bind(this)
    this.handleChangeFilterRedeem = this.handleChangeFilterRedeem.bind(this)
    this.state = {
      startDate: new Date(),
      cartData: [],
      cartItems: [],
      status: 'Loading',
      settings: []
    }
  }
  componentWillMount() {
    this.props.getEarnedRewards()
    this.props.getRedeemedRewards()
    this.props.getPromoCount()
    this.props.getSetings()
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.settings !== nextProps.settings) {
      this.setState({ settings: nextProps.settings })
    }
  }

  printReceipt(orderId) {
    axios
      .get(
        API_URL +
          'api/ordersv1/orderPdfgenerate?app_id=' +
          APP_ID +
          '&order_primary_id=' +
          orderId
      )
      .then(res => {
        if (res.data.status === 'ok') {
          window.open(res.data.pdf_url, '_blank')
        } else {
        }
      })
  }
  viewReceipt(orderId) {
    axios
      .get(
        API_URL +
          'api/reports/order_history?app_id=' +
          APP_ID +
          '&local_order_no=' +
          orderId +
          '&customer_id=' +
          cookie.load('UserId')
      )
      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({ status: res.data.status })
          this.setState({ cartItems: res.data.result_set[0]['items'] })
          this.setState({ cartData: res.data.result_set[0] })
        } else {
        }
      })
  }
  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        html +=
          "<li className='sub-item'>" + modName + ' : ' + modval + '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (_.isEmpty(comboLen) === true && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        //console.log(combos[0]['modifiers']);
        var comboPro = combos[r]['menu_product_name']
        var comboQty = combos[r]['menu_product_qty']
        var comboPrice = combos[r]['menu_product_price']
        var newPrice = comboPrice > 0 ? ' (+' + comboPrice + ')' : ''
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['cart_modifier_price']
        var newModValPrice = modValPrice > 0 ? ' (+' + modValPrice + ')' : ''

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }
  handleChangeFilterRewards(e) {
    this.props.getEarnedRewards(e.target.value)
  }
  handleChangeFilterRedeem(e) {
    this.props.getRedeemedRewards(e.target.value)
  }
  render() {
    const getEarnedRewards = this.props.earnedRewards
    const getRedeemedRewards = this.props.redeemedRewards
    const rewardCount = this.props.rewardCount

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="p-0 col">
              <div className="myacunt-rewards-banner">
                <div className="points">
                  <p>Cashback Balance</p>
                  <p>SGD$ {rewardCount}</p>
                  <p>
                    The Cashback earned for every order is valid for{' '}
                    {this.state.settings !== ''
                      ? this.state.settings.client_loyalty_expiry_on
                      : ''}{' '}
                    days from the order fulfilment date.
                  </p>
                </div>
              </div>
              <div className="myaccount-rewards-block  myaccount-orders-block">
                <ul
                  className="nav nav-tabs nav-justified"
                  id="myAccountRewardsTab"
                  role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="dineinCurrentOrdersList-tab"
                      data-toggle="tab"
                      href="#pointsEarnedList"
                      role="tab"
                      aria-controls="pointsEarnedList"
                      aria-selected="true">
                      <span>Cashback $ Earned</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      to="#"
                      className="nav-link"
                      id="dineinPastOrdersList-tab"
                      data-toggle="tab"
                      href="#pointsRedeemedList"
                      role="tab"
                      aria-controls="pointsRedeemedList"
                      aria-selected="false">
                      <span>Cashback $ Redeemed</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myAccountRewardsTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pointsEarnedList"
                    role="tabpanel"
                    aria-labelledby="pointsEarnedList">
                    <div className="orderPanel">
                      <div className="row">
                        <div className="col-12 order-item-filter-block mb-2">
                          <div className="order-filter-option">
                            <span className="filter-text-lbl">Show</span>
                            <div className="custom-select-control orders-item-filter-dropdown">
                              <select
                                className="form-control"
                                onChange={this.handleChangeFilterRewards.bind(
                                  this
                                )}>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <span className="filter-text-lbl">Per Page</span>
                          </div>
                        </div>
                      </div>
                      {_.isEmpty(getEarnedRewards) === false &&
                        getEarnedRewards.map((eRewards, ernIndex) => (
                          <div className="orderList" key={ernIndex}>
                            <div className="orderList-head">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <button className="btn btn-default btn-delivery">
                                      {eRewards.order_availability_name}
                                    </button>
                                    <span className="calender">
                                      <i className="calender" />
                                      {moment(eRewards.order_date).format(
                                        'DD/MM/YYYY'
                                      )}
                                    </span>
                                    <span className="deliveryTime">
                                      <i className="time" />
                                      {moment(eRewards.order_date).format(
                                        'hh:mm A'
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    {/*  <div className="viewReceipt">
                                    <div><Link data-toggle="modal"
                                      data-target="#orderRecieptsModalCenter"
                                      to="#orderRecieptsModalCenter"
                                      onClick={this.viewReceipt.bind(this, eRewards.order_local_no)}>VIEW RECEIPT</Link><span>|</span><Link to="#" onClick={this.printReceipt.bind(this, eRewards.order_id)}>PRINT</Link></div>
                                    <div className="clickTxt">Click View Receipt for order details</div>
								</div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="orderList-body">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12">
                                    <h5 className="m0">
                                      Order No - {eRewards.order_local_no}
                                    </h5>
                                    <p className="deliveryAddress">
                                      {eRewards.lh_reason}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="orderList-footer">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <h5 className="orderAmt">
                                      Order Amount{' '}
                                      <span>
                                        <sup>$</sup>
                                        {eRewards.order_total_amount}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="text-right col-12 col-sm-6">
                                    <span className="earnedTxt">
                                      {eRewards.lh_status}
                                    </span>
                                    <button className="btn btn-default btn-received">
                                      {eRewards.lup_customer_points} Cashback $
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show"
                    id="pointsRedeemedList"
                    role="tabpanel"
                    aria-labelledby="pointsRedeemedList">
                    <div className="orderPanel">
                      <div className="row">
                        <div className="col-12 order-item-filter-block mb-2">
                          <div className="order-filter-option">
                            <span className="filter-text-lbl">Show</span>
                            <div className="custom-select-control orders-item-filter-dropdown">
                              <select
                                className="form-control"
                                onChange={this.handleChangeFilterRedeem.bind(
                                  this
                                )}>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                            <span className="filter-text-lbl">Per Page</span>
                          </div>
                        </div>
                      </div>
                      {_.isEmpty(getRedeemedRewards) === false &&
                        getRedeemedRewards.map((eRedeemedRewards, erdIndex) => (
                          <div className="orderList" key={erdIndex}>
                            <div className="orderList-head">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <button className="btn btn-default btn-delivery">
                                      {eRedeemedRewards.order_availability_name}
                                    </button>
                                    <span className="calender">
                                      <i className="calender" />
                                      {moment(
                                        eRedeemedRewards.order_date
                                      ).format('DD/MM/YYYY')}
                                    </span>
                                    <span className="deliveryTime">
                                      <i className="time" />
                                      {moment(
                                        eRedeemedRewards.order_date
                                      ).format('hh:mm A')}
                                    </span>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    {/* <div className="viewReceipt">
                                    <div><Link to="#" data-toggle="modal"
                                      data-target="#orderRecieptsModalCenter"
                                      to="#orderRecieptsModalCenter"
                                      onClick={this.viewReceipt.bind(this, eRedeemedRewards.order_local_no)}>VIEW RECEIPT</Link><span>|</span><Link to="#" onClick={this.printReceipt.bind(this, eRedeemedRewards.order_id)}>PRINT</Link></div>
                                    <div className="clickTxt">Click View Receipt for order details</div>
								</div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="orderList-body">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12">
                                    <h5 className="m0">
                                      Order No -{' '}
                                      {eRedeemedRewards.order_local_no}
                                    </h5>
                                    <p className="deliveryAddress">
                                      {eRedeemedRewards.lh_reason}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="orderList-footer">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <h5 className="orderAmt">
                                      Order Amount{' '}
                                      <span>
                                        <sup>$</sup>
                                        {eRedeemedRewards.order_total_amount}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="text-right col-12 col-sm-6">
                                    <span className="earnedTxt">
                                      {eRedeemedRewards.lh_status}
                                    </span>
                                    <button className="btn btn-default btn-received">
                                      {eRedeemedRewards.lh_redeem_point}{' '}
                                      Cashback $
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* View Orders Reciept Modal */}
        <div
          className="modal fade"
          id="orderRecieptsModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document">
            <div className="modal-content bg-Camelot">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="order-details-item-block order-info-block">
                  <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                    Your Order Details
                  </h4>
                  <div className="order-info text-center">
                    <h4 className="font-gothamlight  text-center text-uppercase">
                      Order No - {this.state.cartData.order_local_no}
                    </h4>
                    <p>
                      Order placed at :
                      {moment(this.state.cartData.order_date).format(
                        'DD/MM/YYYY'
                      )}
                    </p>
                    <p>Pay by : {this.state.cartData.order_method_name}</p>
                  </div>
                  <div className="delivery-info">
                    <div className="slfcol-form-block">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="media">
                            <img
                              className="mr-3"
                              src="../img/take-away.png"
                              alt="Generic placeholder "
                            />
                            <div className="media-body">
                              <h6 className="mt-0">
                                {this.state.cartData.order_availability_id ===
                                DINEIN_ID
                                  ? 'DIne in'
                                  : 'SELF COLLECTION'}{' '}
                                LOCATION{' '}
                              </h6>
                              <p className="outlet-info">
                                {this.state.cartData.outlet_name} -{' '}
                                {addressFormat(
                                  this.state.cartData.outlet_unit_number1,
                                  this.state.cartData.outlet_unit_number2,
                                  this.state.cartData.outlet_address_line1,
                                  this.state.cartData.outlet_address_line2,
                                  this.state.cartData.outlet_postal_code
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="media">
                            <div className="media-body">
                              <h6 className="mt-0">
                                {this.state.cartData.order_availability_id ===
                                DINEIN_ID
                                  ? 'DIne in'
                                  : 'SELF COLLECTION'}{' '}
                                Date & Time
                              </h6>
                              <p className="outlet-info">
                                {moment(this.state.cartData.order_date).format(
                                  'DD/MM/YYYY'
                                )}
                              </p>
                            </div>
                            <img
                              className="mr-3"
                              src="../img/alarm-clock.png"
                              alt="Generic placeholder image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cart-items-block">
                    {this.state.cartItems.map((item, index) => (
                      <div className="cart-row">
                        <div className="row">
                          <div className="col-7">
                            <div className="cart-left">
                              <div className="cart-img">
                                <img
                                  src={noImage}
                                  width={110}
                                  height={67}
                                  alt
                                />
                              </div>
                              <div className="cart-info">
                                <h6>
                                  {item.item_qty} X {item.item_name}
                                </h6>

                                {this.loadModifierItems(
                                  item.cart_item_type,
                                  item.modifiers,
                                  item.set_menu_component
                                )}
                                {/*  <div className="cart-addon-info">
                                <span className="d-inline-block">Size:</span><span className="d-inline-block font-italic"> Large</span>

                              </div>
                              <div className="cart-addon-info">
                                <span className="d-inline-block">Add on:</span><span className="d-inline-block font-italic"> Name</span>

                              </div> */}
                              </div>
                            </div>
                            <p className="cart-special-text">
                              {item.item_specification}
                            </p>
                          </div>
                          <div className="col-5 text-right">
                            <div className="cart-right ">
                              <div className="cart-price">
                                <p>$172.00</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="calctrcart-footer">
                      <div className="calctrcart-row">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">SUBTOTAL</p>
                          </div>
                          <div className="col-4 text-right">
                            <span>${this.state.cartData.order_sub_total}</span>
                          </div>
                        </div>
                      </div>

                      {this.state.cartData.order_discount_apply_grand_total !==
                        '1' &&
                        this.state.cartData.order_discount_amount > 0 &&
                        this.state.cartData.order_discount_applied ===
                          'Yes' && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  DISCOUNT (
                                  {this.state.cartData.order_promocode_name})
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${this.state.cartData.order_discount_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      {this.state.cartData.order_tax_charge > 0 &&
                        this.state.cartData.order_tax_calculate_amount && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  GST ({this.state.cartData.order_tax_charge}%)
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  $
                                  {
                                    this.state.cartData
                                      .order_tax_calculate_amount
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      <div className="calctrcart-row calctrcart-footer-totrow">
                        <div className="row">
                          <div className="col-8">
                            <p className="text-uppercase">Total</p>
                          </div>
                          <div className="col-4 text-right">
                            <span>
                              ${this.state.cartData.order_total_amount}
                            </span>
                          </div>
                        </div>
                      </div>
                      {this.state.cartData.order_discount_apply_grand_total ===
                        '1' &&
                        this.state.cartData.order_discount_amount > 0 &&
                        this.state.cartData.order_discount_applied ===
                          'Yes' && (
                          <div className="calctrcart-row ">
                            <div className="row">
                              <div className="col-8">
                                <p className="text-uppercase">
                                  DISCOUNT (
                                  {this.state.cartData.order_promocode_name})
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <span>
                                  ${this.state.cartData.order_discount_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="check-order-status-btn-block">
                    <Link
                      to="/myaccount"
                      className="btn btn-secondary btn-block">
                      Check My Order Status
                    </Link>
                  </div>
                </div>
              </div>

              <div className="modal-footer" />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ rewards, user, settings }) => ({
  earnedRewards: rewards.earnedRewards,
  redeemedRewards: rewards.redeemedRewards,
  rewardCount: user.rewardCount,
  promoCount: user.promoCount,
  settings: settings.returnData
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEarnedRewards,
      getRedeemedRewards,
      getPromoCount,
      getSetings
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Myrewards)
