import { combineReducers } from 'redux'
import cms from './cms';
import products from './products';
import cart from './cart';
import settings from './settings';
import current from './orders/current';
import advanced from './orders/advanced';
import user from './user';
import rewards from './rewards';

export default combineReducers({
  cms, products, cart, settings,  current,
  advanced, user, rewards
})
