/* eslint-disable */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getCartItems } from '../../stores/cart'
import { getSetings } from '../../stores/settings'
import { getblocks, getAdonProducts } from '../../stores/cms'
import { getUserData, getPromoCount } from '../../stores/user'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import loadingImage from '../../assets/img/spinner.gif'
import Header from '../layout/header'
import Footer from '../layout/footer'
import Checkout1 from './Checkout'
import cookie from 'react-cookies'
import { addDays, setHours, setMinutes, format } from 'date-fns'
import StripeCheckout from 'react-stripe-checkout'
import noImage from '../../assets/img/product-no-image.jpg'
import ico_like from '../../assets/img/ico_like.png'
import tickImage from '../../assets/img/tick_popup.png'

import jQuery from 'jquery'
import { showLoader, hideLoader, numariconly } from '../../config/SettingHelper'
import {
  getGstValue,
  stripslashes,
  customerId,
  getReferenceID,
  deleteOrderCookie,
  getCookies,
  addressFormat,
  showInfo,
  getAliasName,
  roundednearest
} from '../../config/supporters'
import { history } from '../../store'
import {
  API_URL,
  APP_ID,
  DINEIN_ID,
  DELIVERY_ID,
  deliveryText,
  stripeCompany,
  stripeImage,
  stripeCurrency,
  stripeReference,
  SERVICE_CHARGE,
  OUTLET_ID,
  PICKUP_ID
} from '../../config/constans'
import axios from 'axios'
import moment from 'moment'
import OrderdatetimeSlot from '../layout/OrderdatetimeSlot'
var parse = require('html-react-parser')
var _ = require('lodash')
const $ = window.$
var qs = require('qs')
const tableNo = 'Online Table'
class Checkout extends Component {
  constructor(props) {
    super(props)

    /*  To change the date after 2 PM */
    var CurrentDate = this.calcTime('Singapore', '+8')
    var currentHrs = CurrentDate.getHours()
    // var currentMins = CurrentDate.getMinutes();

    const leadTime = parseInt(getCookies('productLeadTime'))
    if (leadTime > 0 && leadTime > 2) {
      var new_date = new Date(moment().add(leadTime, 'day'))
      var statrDate = new Date(
        moment(getCookies('orderDeliveryDate'), 'DD/MM/YYYY')
      )
      var isAfter = moment(new_date).isAfter(statrDate)
      if (isAfter === true) {
        // const dateval = moment(new_date).format('DD/MM/YYYY')
        // cookie.save('orderDeliveryDate', dateval)
        cookie.save('checkoutMinDay', leadTime, {})
      }
    } else {
      if (currentHrs >= 14) {
        cookie.save('checkoutMinDay', 3)
      } else {
        cookie.save('checkoutMinDay', 2)
      }
    }
    /*new Date(
        moment(getCookies("orderDeliveryDate"), "DD/MM/YYYY")
      ) */
    this.state = {
      startDate:
        typeof cookie.load('orderDeliveryDate') !== 'undefined' &&
        cookie.load('orderDeliveryDate') !== ''
          ? new Date(moment(cookie.load('orderDeliveryDate'), 'DD/MM/YYYY'))
          : '',
      startTime:
        typeof cookie.load('orderDeliveryTime') !== 'undefined' &&
        cookie.load('orderDeliveryTime') !== ''
          ? new Date(moment(cookie.load('orderDeliveryTime'), 'HH:mm'))
          : '',
      paymentMode: '',
      promotionApplied:
        cookie.load('promotionApplied') !== '' &&
        typeof cookie.load('promotionApplied') !== undefined &&
        typeof cookie.load('promotionApplied') !== 'undefined'
          ? cookie.load('promotionApplied')
          : 'No',
      promotionAmount:
        cookie.load('promotionAmount') !== '' &&
        typeof cookie.load('promotionAmount') !== undefined &&
        typeof cookie.load('promotionAmount') !== 'undefined'
          ? cookie.load('promotionAmount')
          : '',
      promoIsDelivery: 'No',
      cartData: '',
      promotionType:
        cookie.load('promocode') !== '' &&
        typeof cookie.load('promocode') !== undefined &&
        typeof cookie.load('promocode') !== 'undefined'
          ? 'PROMOCODE'
          : '',
      promocode:
        cookie.load('promocode') !== '' &&
        typeof cookie.load('promocode') !== undefined &&
        typeof cookie.load('promocode') !== 'undefined'
          ? cookie.load('promocode')
          : '',
      promotionCategory:
        cookie.load('promotionCategory') !== '' &&
        typeof cookie.load('promotionCategory') !== undefined &&
        typeof cookie.load('promotionCategory') !== 'undefined'
          ? cookie.load('promotionCategory')
          : '',
      promotionId:
        cookie.load('promotionId') !== '' &&
        typeof cookie.load('promotionId') !== undefined &&
        typeof cookie.load('promotionId') !== 'undefined'
          ? cookie.load('promotionId')
          : '',

      promoQty: 0,
      promoPoints: 0,
      subTotal: 0,
      finalTotal: 0,
      gstPercentage: 0,
      gstDisplay: 0,
      orderValidFail: 'pending',
      serviceCharge: 0,
      stripeToken: '',
      payment_ref_id: '',
      getDateTimeFlg: 'yes',
      postal_code_information:
        getCookies('postal_code_information') !== '' &&
        typeof getCookies('postal_code_information') !== undefined
          ? getCookies('postal_code_information')
          : '',
      outletZoneInfo:
        getCookies('outletZoneInfo') !== '' &&
        typeof getCookies('outletZoneInfo') !== undefined
          ? getCookies('outletZoneInfo')
          : '',
      settings: '',
      unitnumber1: '',
      unitnumber2: '',
      processStrip: false,
      customerPromoCount: 0,
      cutlery: 'No',
      omise_payment_enable: 0,
      omise_log_id: '',
      omisecodeerror: '',
      omiseyearerror: '',
      omisemontherror: '',
      omisecardrror: '',
      omisenameerror: '',
      holdername: '',
      cardNumber: '',
      expiration_month: '',
      expiration_year: '',
      security_code: '',
      cardImage: '',
      payment_ref_id: '',
      omise_tokken_response: '',
      omise_tokken_card_id: '',
      omise_tokken_id: '',
      processOmise: false,
      omisePay: false
    }

    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeTime = this.handleChangeTime.bind(this)
    this.submitOrder = this.submitOrder.bind(this)
    this.onChangePayment = this.onChangePayment.bind(this)
    this.applyPoints = this.applyPoints.bind(this)
    this.resetPromoState = this.resetPromoState.bind(this)
    this.applyPromocode = this.applyPromocode.bind(this)
    this.UpdateDelveiryDateModal = this.UpdateDelveiryDateModal.bind(this)
    this.changeAvailability = this.changeAvailability.bind(this)

    if (customerId() === '') {
      history.push('/')
    }

    if (
      getCookies('UserFname') === '' ||
      getCookies('UserLname') === '' ||
      getCookies('UserMobile') === '' ||
      getCookies('UserEmail') === ''
    ) {
      showInfo(
        'Some User basic information missing. Please update your profile and try again',
        ''
      )
      cookie.save('isChecoutRedirect', 'Yes')
      history.push('/myaccount')
    }
    this.getUserPromoCount()
  }

  getUserPromoCount() {
    axios
      .get(
        API_URL +
          'api/reports/activity_counts1?app_id=' +
          APP_ID +
          '&act_arr=["promotionwithoutuqc","reward","testtxt"]&status=A&customer_id=' +
          customerId()
      )
      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({
            customerPromoCount: res.data.result_set.promotionwithoutuqc
          })
        } else {
          this.setState({ customerPromoCount: 0 })
        }
      })
  }

  validateAmount() {
    if (getCookies('orderAvailability') === DELIVERY_ID) {
      if (
        this.state.cartData.cart_details !== '' &&
        typeof this.state.cartData.cart_details !== undefined &&
        typeof this.state.cartData.cart_details !== 'undefined'
      ) {
        var orderSubTotal = this.state.cartData.cart_details.cart_sub_total
        var orderMinCart = this.state.outletZoneInfo.zone_min_amount

        if (parseFloat(orderSubTotal) < parseFloat(orderMinCart)) {
          showInfo(
            'The minimum amount to place the order is $' +
              orderMinCart +
              '. Your current order value is $' +
              orderSubTotal +
              '.'
          )
          history.push('/delivery')
          return false
        }
      }
    }
  }
  UpdateDelveiryDateModal() {
    this.setState(
      {
        getDateTimeFlg: 'yes'
      },
      function() {
        jQuery('#switchErrorChangeLocation').modal('show')
      }
    )
  }
  changeAvailability = (e, data, avail = null) => {
    jQuery('#switchErrorChangeLocation').modal('hide')
    this.destroyCart('Yes', 'avail')
    history.push('/')
  }
  setdateTimeFlg = (field, value) => {
    /*if (
       this.state.orderType === deliveryText ||
      getCookies('orderAvailability') === DELIVERY_ID
    ) { */
    if (field == 'tmflg') {
      this.setState({ getDateTimeFlg: value })
    } else if (field == 'ordDate') {
      var ordTime = ''
      $('.ordrdatetime_error').html('')
      var startTime = new Date(this.state.startTime)
      var startDate = new Date(value)
      var orderHrs = startTime.getHours()
      var orderMin = startTime.getMinutes()
      var OrderTime = startDate.setHours(orderHrs)
      OrderTime = startDate.setMinutes(orderMin)
      /* .setMinutes(orderMin) */
      //cookie.save('orderDateTime', new Date(OrderTime))

      var ChangeTime = new Date(OrderTime)

      if (ChangeTime.toString() !== 'Invalid Date') {
        cookie.save('orderDateTime', ChangeTime)
        var dateval = moment(value).format('DD/MM/YYYY')
        cookie.save('orderDeliveryDate', dateval)
      }

      this.setState({ startDate: value, startTime: ordTime })
    } else if (field == 'ordTime') {
      $('.ordrdatetime_error').html('')
      var startTime = new Date(value)
      var startDate = new Date(this.state.startDate)
      var orderHrs = startTime.getHours()
      var orderMin = startTime.getMinutes()
      var OrderTime = startDate.setHours(orderHrs)
      OrderTime = startDate.setMinutes(orderMin)

      //cookie.save('deliveryTime', new Date(value))
      var ChangeTime = new Date(OrderTime)
      if (ChangeTime.toString() !== 'Invalid Date') {
        cookie.save('deliveryTime', ChangeTime.toString())
        cookie.save('orderDeliveryTime', orderHrs + ':' + orderMin)

        //cookie.save('orderDeliveryTime', new Date(value))
      }

      this.setState({ startTime: value })
    } else if (field == 'triggerErrorPopup') {
    }
    /*  } */
  }

  onChangePayment(event) {
    var client_payments = ''
    var paymentModeID = 3
    if (event.target.value === '1') {
      client_payments = 'COD'
      paymentModeID = 2
    } else if (event.target.value === '4') {
      client_payments = 'Omise'
    } else if (event.target.value === '3') {
      client_payments = 'STRIPE'
    }
    this.setState(
      {
        paymentMode: parseInt(event.target.value),
        client_payments: client_payments
      },
      function() {
        this.submitOrder(paymentModeID, 'Yes')
      }
    )
  }
  handleAddrChange(event) {
    if (event.target.name === 'unitnumber1') {
      this.setState({ unitnumber1: event.target.value })
    } else if (event.target.name === 'unitnumber2') {
      this.setState({ unitnumber2: event.target.value })
    }
  }
  applyPoints(subTotal) {
    var pointsObject = {}
    var pointsVal = parseInt($('#point_input').val())

    if (pointsVal > 0) {
      const cartSubToatl = subTotal
      pointsObject = {
        app_id: APP_ID,
        reference_id: customerId(),
        redeem_point: pointsVal,
        cart_amount: parseFloat(cartSubToatl)
      }
      $('#cartLoading').show()
      axios
        .post(
          API_URL + 'api/loyalty/apply_loyalityv1',
          qs.stringify(pointsObject)
        )
        .then(res => {
          $('#cartLoading').hide()
          if (res.data.status === 'success') {
            showInfo(res.data.message)
            this.setState({
              promotionApplied: 'Yes',
              promotionAmount: parseInt(res.data.result_set.points_amount),
              promotionType: 'POINTS',
              promoPoints: parseInt(res.data.result_set.points_used)
            })

            //var retun_data = this.postOrder(2,'Yes');
          } else {
            this.resetPromoState()
            showInfo(res.data.message)
          }
        })
    } else {
      this.resetPromoState()
      showInfo('Please enter a valid point value')
      return false
    }
  }

  /*  reset prmo state values */
  resetPromoState() {
    var postObject = {}
    postObject = {
      app_id: APP_ID,
      promotionId: this.state.promotionId,
      customer_id: getCookies('UserId'),
      availability_id: getCookies('orderAvailability')
    }

    // showLoader('citem_' + cartItemId, 'id')
    axios
      .post(API_URL + 'api/cart/removepromo', qs.stringify(postObject))
      .then(res => {
        this.props.getCartItems()
        console.log(res, 'resresres')
        this.setState({
          promotionApplied: 'No',
          promotionAmount: 0,
          promocode: '',
          promotionCategory: '',
          promotionId: '',
          promoQty: 0,
          promotionType: '',
          promoPoints: 0
        })
        cookie.remove('promotionApplied')
        cookie.remove('promotionAmount')
        cookie.remove('promocode')
        cookie.remove('promotionCategory')
        cookie.remove('promotionId')
      })

    $('#point_input').val('')
    $('#promo_input').val('')
  }

  toFixedRounding(number) {
    var num = number
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    return with2Decimals
  }

  submitOrder(
    paymentType,
    validation = 'No',
    captureID = '',
    payGetWayType = ''
  ) {
    /* subTotal,
    finalTotal,
    deliveryFee,
    additionaldelFee,
    gstPercentage,
    gstDisplay,
    minCart*/
    const availability = getCookies('orderAvailability')
    if (availability === DELIVERY_ID) {
      if (this.state.unitnumber1 === '') {
        showInfo('The Unit No1 field is required.')
        $('html, body').animate(
          {
            scrollTop: $('#unitnumber1').offset().top - 200
          },
          1000
        )
        return false
      }
    }

    const custome_id = customerId()
    const appid = APP_ID

    const hostURL = API_URL
    var orderSubTotal = 0
    var orderFinalTotal = 0
    var orderDeliveryFee = 0
    var orderAdditionaldelFee = 0
    var orderGstPercentage = 0
    var orderGstDisplay = 0
    //var dicountSubtotal = 0
    let promotionAmount = 0
    var promotionApplied = 'No'
    let orderGstDisplay_promo = 0

    if (
      this.state.promotionApplied === 'Yes' &&
      (this.state.promotionAmount > 0 ||
        this.state.promoIsDelivery === 'Yes') &&
      this.state.promotionType === 'PROMOCODE'
    ) {
      promotionAmount =
        this.state.promotionAmount > 0 ? this.state.promotionAmount : 0
      promotionApplied = 'Yes'
    }
    var cartData = this.props.cartData
    var cartDetails = cartData.cart_details
    if (availability === DELIVERY_ID) {
      var settings = this.props.settings
      orderDeliveryFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_delivery_charge
          : 0
      orderAdditionaldelFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_additional_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_additional_delivery_charge
          : 0

      var zone_free_delivery =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_free_delivery > 0
          ? this.state.outletZoneInfo.zone_free_delivery
          : 0

      if (
        parseFloat(cartDetails.cart_sub_total) >
          parseFloat(zone_free_delivery) &&
        zone_free_delivery > 0
      ) {
        orderDeliveryFee = 0
      }
      if (settings.discount_apply_grand_total === '1') {
        var grand_total =
          parseFloat(cartDetails.cart_sub_total) +
          parseFloat(orderDeliveryFee) +
          parseFloat(orderAdditionaldelFee)
      } else {
        var grand_total =
          parseFloat(cartDetails.cart_sub_total) -
          parseFloat(promotionAmount) +
          parseFloat(orderDeliveryFee) +
          parseFloat(orderAdditionaldelFee)
      }

      var grand_totalpromo =
        parseFloat(cartDetails.cart_sub_total) +
        parseFloat(orderDeliveryFee) +
        parseFloat(orderAdditionaldelFee)
      if (
        settings.client_tax_enable === '1' &&
        settings.client_tax_surcharge > 0
      ) {
        orderGstPercentage = parseFloat(settings.client_tax_surcharge)
        orderGstDisplay = (
          (settings.client_tax_surcharge / 100) *
          grand_total
        ).toFixed(2)
        orderGstDisplay_promo = (
          (settings.client_tax_surcharge / 100) *
          grand_totalpromo
        ).toFixed(2)
      }

      orderSubTotal = cartDetails.cart_sub_total

      orderFinalTotal = parseFloat(grand_total) + parseFloat(orderGstDisplay)
      /* dicountSubtotal =
        parseFloat(grand_totalpromo) + parseFloat(orderGstDisplay_promo) */
    } else {
      var settings = this.props.settings
      if (settings.discount_apply_grand_total === '1') {
        var grand_total = parseFloat(cartDetails.cart_sub_total)
      } else {
        var grand_total =
          parseFloat(cartDetails.cart_sub_total) - parseFloat(promotionAmount)
      }

      if (
        settings.client_tax_enable === '1' &&
        settings.client_tax_surcharge > 0
      ) {
        orderGstPercentage = settings.client_tax_surcharge
        orderGstDisplay = (
          (settings.client_tax_surcharge / 100) *
          grand_total
        ).toFixed(2)
      }

      orderSubTotal = cartDetails.cart_sub_total

      orderFinalTotal = parseFloat(grand_total) + parseFloat(orderGstDisplay)
      /*  dicountSubtotal =
        parseFloat(cartDetails.cart_sub_total) + parseFloat(orderGstDisplay) */

      /*  console.log('subTotal', this.state.subTotal)
      orderSubTotal = cartDetails.cart_sub_total
      
      orderFinalTotal = this.state.finalTotal
      orderGstPercentage = this.state.gstPercentage
      orderGstDisplay = this.state.gstDisplay */
    }
    var originalFinalAmt = orderFinalTotal
    if (settings.discount_apply_grand_total === '1') {
      orderFinalTotal = orderFinalTotal - parseFloat(promotionAmount)
    }

    const serviceCharge = this.state.serviceCharge
    const paymentMode = paymentType

    if (custome_id === '' || custome_id === undefined) {
      history.push('/')
      return false
    }

    const cartItems = this.props.cartData

    if (
      _.isEmpty(cartItems) === true ||
      _.isEmpty(cartItems.cart_details) === true ||
      _.isEmpty(cartItems.cart_items) === true
    ) {
      history.push('/')
      return false
    }

    /* if (availability === "DeliveryID") {
      if (orderSubTotal < orderMinCart) {
     
        this.showAlert(FAILURE_ALERT,   "You must have an order with a minimum of $" +
            orderSubTotal +
            " to place your order, your current order total is $" +
            orderMinCart +
            ".");
        return false;
      }  
    } */

    /* if (paymentMode !== 3) {
      showInfo('Please choose payment method.', '')
      return false
    } */

    // return false;
    var modifierdata = []
    var products = []
    var row = cartItems.cart_items.map(
      function(item, i) {
        modifierdata = []
        var comboData = []
        var ComboLen = item.set_menu_component.length
        var modifierLen = item.modifiers.length
        if (item.cart_item_type === 'Modifier' && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = []
            modifierVal.push({
              modifier_value_id:
                item.modifiers[l].modifiers_values[0].cart_modifier_id,
              modifier_value_qty:
                item.modifiers[l].modifiers_values[0].cart_modifier_qty,
              modifier_value_name:
                item.modifiers[l].modifiers_values[0].cart_modifier_name,
              modifier_value_price:
                item.modifiers[l].modifiers_values[0].cart_modifier_price
            })
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal
            })
          }
        } else if (item.cart_item_type === 'Component' && ComboLen > 0) {
          /* combo product setup */

          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = []

            /* build  modifier array */
            var comboModifier =
              item.set_menu_component[m].product_details[0].modifiers

            var combomodifierdata = []
            if (comboModifier.length > 0) {
              /* new version product setup */

              for (var p = 0; p < comboModifier.length; p++) {
                // alert(comboModifier[p].modifiers_values[0].cart_modifier_id); return false;
                var comboModifierVal = []
                comboModifierVal.push({
                  modifier_value_id:
                    comboModifier[p].modifiers_values[0].cart_modifier_id,
                  modifier_value_qty:
                    comboModifier[p].modifiers_values[0].cart_modifier_qty,
                  modifier_value_name:
                    comboModifier[p].modifiers_values[0].cart_modifier_name,
                  modifier_value_price:
                    comboModifier[p].modifiers_values[0].cart_modifier_price
                })
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal
                })
              }
            }

            /*	comborVal.push( { /* commented for new version setup  /
                        product_id:item.set_menu_component[m].product_details[0].cart_menu_component_product_id ,
                        product_name:item.set_menu_component[m].product_details[0].cart_menu_component_product_name,
                        product_sku:item.set_menu_component[m].product_details[0].cart_menu_component_product_sku,
                        modifiers:combomodifierdata});
                        comboData.push( {
                                    menu_component_id:item.set_menu_component[m].menu_component_id,
                                    menu_component_name:item.set_menu_component[m].menu_component_name,
                                    product_details:comborVal}) */

            var comborVal = []
            var comboProductDetailslen =
              item.set_menu_component[m].product_details.length
            if (comboProductDetailslen > 0) {
              for (
                var j = 0, lengthComboPro = comboProductDetailslen;
                j < lengthComboPro;
                j++
              ) {
                comborVal.push({
                  product_id:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_id,
                  product_name:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_name,
                  product_sku:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_sku,
                  product_qty:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_qty,
                  product_price:
                    item.set_menu_component[m].product_details[j]
                      .cart_menu_component_product_price,
                  modifiers: combomodifierdata
                })
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name:
                item.set_menu_component[m].menu_component_name,
              product_details: comborVal
            })
          }
        }

        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          condiments: '',
          modifiers: modifierdata,
          bakers_modifiers: '',
          menu_set_components: comboData,
          baby_pack: '',
          product_special_notes: item.cart_item_special_notes
        })

        return products
      }.bind(this)
    )

    var orderDate = ''
    var cookieDate = getCookies('orderDeliveryDate')
    var splitedDate = cookieDate.split('/')

    var selDate = splitedDate[0]
    var selMonth = splitedDate[1]
    var selYear = splitedDate[2]
    var formatedDate = selYear + '-' + selMonth + '-' + selDate
    var orderTime = getCookies('orderDeliveryTime')
    console.log(orderTime, formatedDate, 'orderTimeorderTime')
    var hourSplit = orderTime.split(':')
    if (formatedDate === '2021-02-11' && parseInt(hourSplit[0]) >= 14) {
      if (parseInt(hourSplit[0]) === 14) {
        if (parseInt(hourSplit[1]) > 30) {
          showInfo(
            'LAST ORDER FOR DELIVERY & PICKUP BY 2:30PM ON 11 FEB 2021. CLOSED ON 12 FEB 2021.',
            'Alert'
          )
          return false
        }
      } else {
        showInfo(
          'LAST ORDER FOR DELIVERY & PICKUP BY 2:30PM ON 11 FEB 2021. CLOSED ON 12 FEB 2021.',
          'Alert'
        )
        return false
      }
    }


    var current_date_checkout = new Date();

    var ordered_date_checkout = this.state.seleted_ord_date;

    var formatedDate_checkout = format(ordered_date_checkout, "yyyy-MM-dd");

    var formatedCurrentdate = format(current_date_checkout, "yyyy-MM-dd");

    if(formatedCurrentdate == formatedDate_checkout){

    var order_Tat_time_checkout   = 
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");

    current_date_checkout.setMinutes( current_date_checkout.getMinutes() + parseInt(order_Tat_time_checkout));

    var currentHrs = current_date_checkout.getHours() + ":" + current_date_checkout.getMinutes() + ":" + current_date_checkout.getSeconds();

    var orderTime_checkout = this.state.seleted_ord_slot_str;

    if(currentHrs >  orderTime_checkout){
      showAlert("Error", "Selected time is not available, Please choose the next slot.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
       return false;
     }
    }




    orderDate = formatedDate + ' ' + orderTime

    var postObject = {}
    postObject = {
      /* cart details */
      app_id: appid,
      availability_id: availability,
      sub_total: orderSubTotal,
      /* dicountSubtotal: dicountSubtotal, */
      grand_total: roundednearest(orderFinalTotal),
      order_original_grand_total: roundednearest(originalFinalAmt),
      order_discount_apply_grand_total: settings.discount_apply_grand_total,
      outlet_id: getCookies('orderOutletId'),
      table_number: availability === DINEIN_ID ? tableNo : 0,
      order_status: 1,
      order_source: 'Web',

      order_date: orderDate,
      order_pickup_time_slot_from: '',
      order_pickup_time_slot_to: '',
      order_is_timeslot: '',
      order_remarks: $('#exampleFormControlTextarea1').val()
        ? $('#exampleFormControlTextarea1').val()
        : '',
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: custome_id,
      customer_address_id: '',
      customer_fname: getCookies('UserFname'),
      customer_lname: getCookies('UserLname'),
      customer_mobile_no: getCookies('UserMobile'),
      customer_email: getCookies('UserEmail'),
      customer_address_line1:
        '#' +
        this.state.postal_code_information.zip_buno +
        ',' +
        this.state.postal_code_information.zip_sname,
      customer_address_line2: '',
      customer_postal_code: this.state.postal_code_information.zip_code,
      customer_unit_no1: this.state.unitnumber1,
      customer_unit_no2: this.state.unitnumber2,
      /*  "customer_tower_number": (typeof cookie.load('towerNo') === "undefined" ? '' : cookie.load('towerNo')),*/

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: stripeReference,
      stripe_envir:
        parseInt(this.props.settings.client_stripe_mode) === 1 ? 'live' : 'dev',
      payment_type: paymentMode === 3 ? 'Stripe' : '',

      order_capture_token: captureID,
      order_payment_getway_type: '',
      order_payment_getway_status: 'Yes',
      order_local_payment_reference: this.state.payment_ref_id,

      /* dine in .advance payment
	  order_is_advanced_payment: (availability === DINEIN_ID? "Yes" : "No"),
	  order_advanced_percentage: (availability === DINEIN_ID? dineInPercentage : 0),
	  order_advanced_amount: (availability === DINEIN_ID? this.state.dineInInitAmount : 0),
	  order_remaining_amount: (availability === DINEIN_ID? (orderFinalTotal - this.state.dineInInitAmount) : 0),
            */
      /* additional paras */
      delivery_charge: orderDeliveryFee,
      additional_delivery: orderAdditionaldelFee,
      order_tat_time: '',
      tax_charge: orderGstPercentage,
      order_tax_calculate_amount: orderGstDisplay,
      order_data_encode: 'Yes',

      /* discount */
      order_discount_applied: '',
      order_discount_amount: '',
      order_discount_type: '',
      discount_applied: promotionApplied,
      discount_amount: promotionAmount,

      /* validation purpose*/
      promotion_applied_text: this.state.promotionApplied,
      promo_applied_delivery_text: 'No',
      reward_point_status: 'Yes',
      cutleryOption: this.state.cutlery
    }
    // console.log(postObject)
    //return false

    /*  promo code object - start */
    if (
      this.state.promotionApplied === 'Yes' &&
      this.state.promotionType === 'PROMOCODE'
    ) {
      postObject['coupon_applied'] = 'Yes'
      postObject['promo_code'] = this.state.promocode
      postObject['cart_quantity'] = cartItems.cart_details.cart_total_items
      postObject['category_id'] = this.getPromoCategory(cartItems.cart_items)
      postObject['coupon_amount'] = this.toFixedRounding(
        this.state.promotionAmount
      )
      postObject['promotion_delivery_charge_applied'] = 'No'
    } else if (
      this.state.promotionApplied === 'Yes' &&
      this.state.promotionType === 'POINTS'
    ) {
      postObject['redeem_point'] = this.state.promoPoints //redeem_applied
      postObject['redeem_applied'] = 'Yes' //redeem_amount
      postObject['redeem_amount'] = this.state.promotionAmount
    }

    if (availability === DINEIN_ID) {
      postObject['order_service_charge'] = SERVICE_CHARGE
      postObject['order_service_charge_amount'] = serviceCharge
    }

    if (validation === 'Yes') {
      postObject['validation_only'] = 'Yes'
    }
    if (cartData.product_lead_time > 0) {
      postObject['product_leadtime'] = cartData.product_lead_time
    }

    // console.log(OrderAPIUrl);
    //return false;

    $('#cartLoading').show()
    var OrderAPIUrl = hostURL + 'api/ordersv1/submit_order'
    // console.log(OrderAPIUrl);
    //return false;
    axios.post(OrderAPIUrl, qs.stringify(postObject)).then(res => {
      if (res.data.status === 'ok') {
        this.setState({ orderValidFail: 'success' })
        $('#cartLoading').hide()
        if (validation !== 'Yes') {
          var localOrderNo = res.data.common.local_order_no
          if (payGetWayType === 'Stripe') {
            this.captureAmount(
              captureID,
              res.data.common.order_primary_id,
              localOrderNo
            )
          }
          if (payGetWayType === 'Omise') {
            this.captureOmiseAmount(
              captureID,
              res.data.common.order_primary_id,
              localOrderNo
            )
          } else {
            this.showSuccessPage(localOrderNo)
          }
        } else {
          if (this.state.processStrip === true) {
            jQuery('#stripPaymentProcess').modal('show')
            this.setState({ processStrip: false })
          }
          if (payGetWayType === 'Omise' && this.state.omisePay === true) {
            jQuery('#pay-omiseconf-popup').modal('show')
            this.setState({ omisePay: false })
          }
        }
      } else if (res.data.status === 'error') {
        jQuery('#pay-omiseconf-popup').modal('hide')
        $('#cartLoading').hide()
        this.setState({ orderValidFail: 'failue', omisePay: false })
        let msgTxt =
          typeof res.data.form_error !== 'undefined' &&
          res.data.form_error != ''
            ? res.data.form_error
            : res.data.message
        if (validation !== 'Yes') {
          showInfo(msgTxt, 'Alert')
        } else {
          showInfo(msgTxt, 'Alert')

          //this.orderValidationFail()
        }
      }
    })
  }

  showSuccessPage(localOrderNo) {
    this.destroyCart('', 'orderSuccess')
    deleteOrderCookie()
    history.push('/orderconfirmation/' + localOrderNo)
  }
  orderValidationFail() {
    showInfo(
      'Something went wrong. Your order details or personal information is wrong. Please check and try again.'
    )
    history.push('/')
  }
  handleChange(field, e) {
    this.setState({ [field]: e.target.value })
  }
  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue)
    dateval = moment(dateval).format('DD/MM/YYYY')
    cookie.save('orderDeliveryDate', dateval)
    this.setState({
      startDate: datevalue
    })
  }
  handleChangeTime(timevalue) {
    var timeval = new Date(timevalue)

    var timeval1 = moment(timeval).format('HH:mm')
    var timeval2 = moment(timeval).format('hh:mm A')
    cookie.save('orderDeliveryTime', timeval1)
    cookie.save('orderDeliveryTimeShow', timeval2)

    this.setState({
      startTime: timevalue
    })
  }

  /* Convert Timezone */
  calcTime(city, offset) {
    var d = new Date()
    var utc = d.getTime() + d.getTimezoneOffset() * 60000
    var nd = new Date(utc + 3600000 * offset)
    return nd
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const myaccountPromo = getCookies('myaccount-promocode')
    if (typeof myaccountPromo !== 'undefined' && myaccountPromo !== '') {
      $('#promo_input').val(myaccountPromo)
      cookie.save('myaccount-promocode', '')
    }
    jQuery(document).ready(function() {
      setTimeout(function() {
        if (jQuery('.promo_pop_close').length > 0) {
          $('.alrt_overlay, .custom_alert').remove()
        }
      }, 3000)
    })

    this.props.getCartItems()
    this.props.getSetings()
    this.props.getblocks()
    this.props.getUserData()
    this.props.getPromoCount()
    this.props.getAdonProducts()
  }
  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    if (this.state.settings !== nextProps.settings) {
      this.setState({ settings: nextProps.settings }, function() {
        this.placeOrderEnable()
      })
    }
    if (this.state.cartData !== nextProps.cartData) {
      if (
        nextProps.cartData !== '' &&
        typeof nextProps.cartData !== undefined &&
        typeof nextProps.cartData !== 'undefined'
      ) {
        if (
          nextProps.cartData.cart_items !== '' &&
          typeof nextProps.cartData.cart_items != undefined &&
          typeof nextProps.cartData.cart_items != 'undefined'
        ) {
          this.setState({ cartData: nextProps.cartData }, function() {
            this.validateAmount()
          })
        }
      }
    }
  }

  /* Cart action */

  incQty(cartItemId, qty, productId, type, itemArr) {
    if (type === 'add') {
      var cartQty = Number(qty) + 1
    } else {
      if (Number(qty) === 1) {
        return false
      } else {
        cartQty = Number(qty) - 1
      }
    }
    /* if (type === 'add') {
      var checkCartQty = this.checkCartQty(productId, cartItemId)
      var maxQty = parseInt(itemArr.product_maximum_quantity)
      var totalQtys = parseInt(checkCartQty) + parseInt(cartQty)
      if (maxQty > 0) {
        if (maxQty < totalQtys) {
          hideLoader('item_' + cartItemId, 'id')
          return false
        }
      }
    } */
    const customId = customerId()
    const refId = getReferenceID()
    const host = API_URL
    const app = APP_ID
    var postObject = {}
    postObject = {
      app_id: app,
      cart_item_id: cartItemId,
      product_id: productId,
      product_qty: cartQty
    }
    if (typeof customId === 'undefined' || customId === '') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }

    showLoader('citem_' + cartItemId, 'id')
    //$('#cartLoading').show()
    axios
      .post(host + '/api/cart/update', qs.stringify(postObject))
      .then(res => {
        hideLoader('citem_' + cartItemId, 'id')
        //$('#cartLoading').hide()
        this.resetPromoState()
        if (res.data.status === 'ok') {
          this.props.getCartItems()
        } else if (res.data.status === 'error') {
          alert(res.data.message)
        }
      })
  }

  checkCartQty(productID, cartItemId) {
    var totalQty = 0
    var cartItem = this.props.cartData.cart_items
    if (cartItem.length > 0) {
      cartItem.map(function(item) {
        if (
          parseInt(item.cart_item_qty) > 0 &&
          productID === item.cart_item_product_id &&
          cartItemId !== item.cart_item_id
        ) {
          totalQty = parseInt(totalQty) + parseInt(item.cart_item_qty)
        }
      })
    }
    return totalQty
  }

  deleteCartItem(cartItemId) {
    const customId = customerId()
    const refId = getReferenceID()
    const host = API_URL
    const app = APP_ID
    var postObject = {}
    postObject = { app_id: app, cart_item_id: cartItemId }

    if (typeof customId === 'undefined' || customId === '') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }
    axios.post(host + 'api/cart/delete', qs.stringify(postObject)).then(res => {
      if (res.data.status === 'ok') {
        if (res.data.contents) {
          this.props.getCartItems()
        } else {
          deleteOrderCookie()
          history.push('/')
        }
      } else if (res.data.status === 'error') {
        alert(res.data.message)
      }
    })
  }

  destroyCart(deletevar, orderSuccess = '') {
    const customId = customerId()
    const refId = getReferenceID()
    const host = API_URL
    const app = APP_ID
    var postObject = {}
    postObject = { app_id: app }

    if (typeof customId === 'undefined' || customId === '') {
      postObject['reference_id'] = refId
    } else {
      postObject['customer_id'] = customId
    }

    axios
      .post(host + 'api/cart/destroy', qs.stringify(postObject))
      .then(res => {
        //cookie.remove('promotion_code');
        if (res.data.status === 'ok') {
          deleteOrderCookie()
          if (orderSuccess === '') {
            this.props.getCartItems()
          }

          this.setState({ modal: false })
          if (deletevar === 'Yes') {
            history.push('/')
          }
        } else if (res.data.status === 'error') {
          //showInfo(res.data.message)
        }
      })
  }

  loadProducts(pro) {
    var link = ''
    if (getCookies('orderAvailability') === DELIVERY_ID) {
      link = 'delivery'
    } else {
      link = 'menu'
    }
    return pro.products.map((loaddata, index) => (
      <React.Fragment key={index}>
        <div className="card">
          <Link
            to={
              '/' + link + '/' + pro.pro_cate_slug + '/' + loaddata.product_slug
            }>
            {' '}
            <img
              src={
                loaddata.product_thumbnail !== ''
                  ? API_URL +
                    '/media/dev_team/products/main-image/' +
                    loaddata.product_thumbnail
                  : noImage
              }
              alt=""
            />
            <div className="card-body">
              <h5 className="card-title">
                {' '}
                {stripslashes(
                  getAliasName(loaddata.product_alias, loaddata.product_name)
                )}
              </h5>
              {/* <p className="card-text">
              Product short description will be available here
									  </p> */}
              <h5 className="prd-price-tag text-center">
                {/* <del>$4.50</del> */} ${loaddata.product_price}
              </h5>
            </div>{' '}
          </Link>
        </div>
      </React.Fragment>
    ))
  }

  /*apply coupon  function*/
  applyPromocode(SubTotal) {
    var promoObject = {}
    var promocode = $('#promo_input').val()

    if (promocode === '') {
      showInfo('The Promo code field is Required.')
      return false
    }

    $('#cartLoading').show()
    var product_cartid = ''
    const cartData = this.props.cartData
    var cartItems = cartData.cart_items

    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
          product_cartid += ';'
        }
        product_cartid +=
          cartItems[p].cart_item_product_id +
          '|' +
          cartItems[p].cart_item_total_price
      }
    }

    let availability = getCookies('orderAvailability')
    let cartDetails = cartData.cart_details
    let orderFinalTotal = 0
    let orderDeliveryFee = 0
    let orderAdditionaldelFee = 0
    let orderGstDisplay = 0

    if (availability === DELIVERY_ID) {
      var settings = this.props.settings
      orderDeliveryFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_delivery_charge
          : 0
      orderAdditionaldelFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_additional_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_additional_delivery_charge
          : 0

      var zone_free_delivery =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_free_delivery > 0
          ? this.state.outletZoneInfo.zone_free_delivery
          : 0

      if (
        parseFloat(cartDetails.cart_sub_total) >
          parseFloat(zone_free_delivery) &&
        zone_free_delivery > 0
      ) {
        orderDeliveryFee = 0
      }

      var grand_total =
        parseFloat(cartDetails.cart_sub_total) +
        parseFloat(orderDeliveryFee) +
        parseFloat(orderAdditionaldelFee)
      if (
        settings.client_tax_enable === '1' &&
        settings.client_tax_surcharge > 0
      ) {
        orderGstDisplay = (
          (settings.client_tax_surcharge / 100) *
          grand_total
        ).toFixed(2)
      }
      orderFinalTotal = parseFloat(grand_total) + parseFloat(orderGstDisplay)
    } else {
      var settings = this.props.settings
      var grand_total = parseFloat(cartDetails.cart_sub_total)
      if (
        settings.client_tax_enable === '1' &&
        settings.client_tax_surcharge > 0
      ) {
        orderGstDisplay = (
          (settings.client_tax_surcharge / 100) *
          grand_total
        ).toFixed(2)
      }
      orderFinalTotal = parseFloat(grand_total) + parseFloat(orderGstDisplay)
    }
    var cartSubToatl = ''
    if (settings.discount_apply_grand_total === '1') {
      cartSubToatl = roundednearest(orderFinalTotal)
    } else {
      cartSubToatl = parseFloat(SubTotal)
    }

    var cartItemsCount = cartData.cart_details.cart_total_items

    //console.log(cartItemsCount); return false;
    promoObject = {
      /* cart details */
      app_id: APP_ID,
      reference_id: customerId(),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(cartItems),
      availability_id: getCookies('orderAvailability'),
      outlet_id: getCookies('orderOutletId')
    }
    //this.setState({status:"Loading"});
    axios
      .post(
        API_URL + 'api/promotion_api_v2/apply_promotion',
        qs.stringify(promoObject)
      )
      .then(res => {
        $('#cartLoading').hide()

        if (res.data.status === 'success') {
          if (res.data.result_set.promotion_delivery_charge_applied === 'Yes') {
            this.resetPromoState()
            showInfo('This promo code only for delivery orders.')
            return false
          }
          console.log(
            res.data.result_set.promotionId,
            'res.data.result_set.promotionId'
          )
          this.setState(
            {
              promotionApplied: 'Yes',
              promotionAmount: parseFloat(res.data.result_set.promotion_amount),
              promocode: res.data.result_set.promotion_code,
              promotionCategory: res.data.result_set.promotionCategory,
              promotionId: res.data.result_set.promotion_id,
              promoQty: cartItemsCount,
              promotionType: 'PROMOCODE',
              promoPoints: 0
            },
            function() {
              cookie.save('promotionApplied', 'Yes')
              cookie.save(
                'promotionAmount',
                parseFloat(res.data.result_set.promotion_amount)
              )
              cookie.save('promocode', res.data.result_set.promotion_code)
              cookie.save(
                'promotionCategory',
                res.data.result_set.promotionCategory
              )
              cookie.save('promotionId', res.data.result_set.promotion_id)

              this.props.getCartItems()
            }
          )
          showInfo(res.data.message)
          //var retun_data = this.postOrder(2, "Yes");
        } else {
          this.resetPromoState()
          showInfo(res.data.message)
        }
      })
  }

  /*  get promo category details  */
  getPromoCategory(cartData) {
    var product_cartid = ''
    var cartItems = cartData
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
          product_cartid += ';'
        }
        product_cartid +=
          cartItems[p].cart_item_product_id +
          '|' +
          cartItems[p].cart_item_total_price +
          '|' +
          cartItems[p].cart_item_qty
      }
    }

    return product_cartid
  }

  stripeDescription() {
    //userName
    if (
      typeof cookie.load('UserLname') !== 'undefined' &&
      cookie.load('UserLname') !== ''
    ) {
      return cookie.load('UserFname') + ' ' + cookie.load('UserLname')
    } else {
      return cookie.load('UserLname')
    }
  }

  /* post stripe account */
  onToken = token => {
    /*  load process html */

    this.submitOrder(3, 'Yes') // To validate the order
    this.setState({ orderValidFail: 'progress', stripeToken: token.id })
    /*    */
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.orderValidFail !== prevState.orderValidFail &&
      (this.state.orderValidFail === 'success' &&
        prevState.orderValidFail === 'progress')
    ) {
      const avText =
        getCookies('orderAvailability') === DINEIN_ID
          ? 'Dine In'
          : 'Self Collection'
      const orderDate =
        getCookies('orderDeliveryDate') +
        ' ' +
        getCookies('orderDeliveryTimeShow')
      var postObject = {}
      postObject = {
        app_id: APP_ID,
        token: this.state.stripeToken,
        stripe_envir:
          parseInt(this.props.settings.client_stripe_mode) === 1
            ? 'live'
            : 'dev',
        stripe_key: this.props.settings.stripe_public_key,
        customer_id: customerId(),
        paid_amount: this.state.finalTotal,
        outlet_name:
          'springcourt.com.sg|Web|' +
          orderDate +
          '|' +
          avText +
          '|ID:' +
          this.state.payment_ref_id,
        payment_reference: stripeReference
      }

      if (this.state.orderValidFail === 'success') {
        axios
          .post(
            API_URL + 'ninjapro/paymentV3/stripeTokenPay',
            qs.stringify(postObject)
          )
          .then(res => {
            if (res.data.status === 'ok') {
              var captureID = res.data.result_set.payment_reference_1
              // this.setState({ validateimage: tickImage });
              this.submitOrder(3, '', captureID, 'Stripe')
            } else if (res.data.status === 'error') {
              return false
            }
          })
          .catch(error => {
            return false
          })
      }
    }
  }

  onOpened(
    subTotal,
    finalTotal,
    deliveryFee,
    additionaldelFee,
    gstPercentage,
    gstDisplay,
    minCart,
    serviceCharge
  ) {
    jQuery('#stripPaymentProcess').modal('hide')
    axios
      .get(
        API_URL + 'api/callcenterapi/get_payment_reference_id?app_id=' + APP_ID
      )
      .then(res => {
        if (res.data.status === 'ok') {
          this.setState({
            subTotal: subTotal,
            finalTotal: finalTotal,
            gstPercentage: gstPercentage,
            gstDisplay: gstDisplay,
            serviceCharge: serviceCharge,
            payment_ref_id: res.data.payment_ref_id
          })
        }
      })
  }

  submitCOD() {
    this.submitOrder(1, 'No')
  }
  submitStrip() {
    this.setState({ processStrip: true }, function() {
      this.submitOrder(3, 'Yes')
    })
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault()
    this.setState({ omisePay: true })
    this.submitOrder(3, 'Yes', '', payGetWayType, 'Yes')
  }

  /* Capture amount */
  captureAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {}
    var captureParams = captureID + '~' + orderPrimaryId + '~' + localOrderNo
    cabtureObjects = {
      payment_reference: stripeReference,
      stripe_envir:
        parseInt(this.props.settings.client_stripe_mode) === 1 ? 'live' : 'dev',
      customer_id: cookie.load('UserId'),
      app_id: APP_ID,
      token: captureID,
      order_id: orderPrimaryId
    }
    axios
      .post(
        API_URL + 'ninjapro/paymentV3/captureAmount',
        qs.stringify(cabtureObjects)
      )
      .then(captureRes => {
        $('#cartLoading').hide()
        if (captureRes.data.status === 'ok') {
          this.showSuccessPage(localOrderNo)
        } else if (captureRes.data.status === 'error') {
          showInfo('Your order was not successful.', 'Error')
        } else {
          showInfo('Your order was not successful.', 'Error')
        }
      })
      .catch(error => {})
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list cart_sub_items mod'>"

    if (itemType === 'Modifier' && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        html +=
          "<li className='sub-item'>" + modName + ' : ' + modval + '</li> '
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    } else if (itemType === 'Component' && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]['menu_component_name']
        //var comboVal =combo[i]['product_details'][0]['cart_menu_component_product_name'];
        var comboVal = this.showComboProducts(combo[i]['product_details'])
        html +=
          " <li className='sub-item'><b>" +
          comboName +
          ' :</b>' +
          comboVal +
          ' ' +
          this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) +
          '</li> '

        //html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+ "</li> ";
      }
      html += '</ul>'
      var reactElement = parse(html)
      return reactElement
    }
  }
  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = ' '
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        //console.log(combos[0]['modifiers']);
        var comboPro = combos[r]['cart_menu_component_product_name']
        var comboQty = combos[r]['cart_menu_component_product_qty']
        var comboPrice = combos[r]['cart_menu_component_product_price']
        var newPrice = comboPrice > 0 ? ' (+' + comboPrice + ')' : ''
        //var newPrice = ''
        html +=
          "<li className='sub-item'>" +
          comboQty +
          ' X ' +
          comboPro +
          newPrice +
          ' </li> '
      }
      return html
    }
    return ''
  }
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list cart_sub_items combo'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]['cart_modifier_name']
        var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name']
        var modValPrice =
          modifiers[i]['modifiers_values'][0]['cart_modifier_price']
        var newModValPrice = ''
        /* var newModValPrice = (modValPrice >0)? " (+"+modValPrice+")" : ""; */

        html +=
          "<li className='sub-item'>" +
          modName +
          ': ' +
          modval +
          newModValPrice +
          '</li> '
      }
      html += '</ul>'
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ''
  }

  placeOrderEnable() {
    var settings = this.state.settings

    if (
      settings !== '' &&
      typeof settings.client_payments !== undefined &&
      typeof settings.client_payments !== 'undefined'
    ) {
      if (settings.client_cod_availability == 1) {
        var client_payments = settings.client_payments.split(',')
        this.setState({ client_payments: 'COD' })
      } else if (settings.client_omise_availability == 1) {
        this.setState({ client_payments: 'Omise' })
      } else if (settings.client_stripe_availability == 1) {
        this.setState({ client_payments: 'STRIPE' })
      }
    }
    // var settings = this.props.settings
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/
    if (item == 'cardNumber') {
      if (event.target.value === '' || re.test(event.target.value)) {
        var cardnumber = this.space(event.target.value)
        var cardnumberLenght = cardnumber.length
        if (cardnumberLenght <= 19) {
          this.setState({ [item]: cardnumber })
          this.cardValidation(cardnumber)
        }
      }
    } else if (
      item == 'expiration_month' ||
      item == 'expiration_year' ||
      item == 'security_code'
    ) {
      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({ [item]: event.target.value })
      }
    } else {
      this.setState({ [item]: event.target.value })
    }

    this.setState({
      omisenameerror: '',
      omisecardrror: '',
      omisemontherror: '',
      omiseyearerror: '',
      omisecodeerror: ''
    })
  }

  space(el) {
    var numbes = el.replace(/ /g, '')
    return numbes.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '')
  }

  cardValidation(carnumber) {
    var imagename = ''
    if (carnumber != '') {
      var single = carnumber.substring(0, 1)
      var double = carnumber.substring(0, 2)

      if (single == 4) {
        imagename = 'visa'
      } else if (double == 34 || double == 37) {
        imagename = 'american'
      } else if (double >= 51 && double <= 55) {
        imagename = 'master'
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = 'discover'
      }
    }

    this.setState({ cardImage: imagename })
  }

  sateValChange = (field, value) => {
    if (field === 'processOmise') {
      this.setState({ processOmise: value })
    } else if (field === 'omiseTokenError' && value !== '') {
      $('#cartLoading').hide()
      this.showOmiselert(value)
    } else if (field === 'omiseTokensucess' && value !== '') {
      this.setState(
        {
          omise_tokken_response: value,
          omise_tokken_card_id: value.card.id,
          omise_tokken_id: value.id,
          omisePay: false
        },
        () => {
          jQuery('#pay-omiseconf-popup').modal('hide')
          this.onProcessOmiseToken(value)
        }
      )
    }
  }

  getOmiseToken() {
    var errorFlg = 0,
      omisenameerror = '',
      omisecardrror = '',
      omisemontherror = '',
      omiseyearerror = '',
      omisecodeerror = ''
    if (this.state.holdername == '') {
      errorFlg++
      omisenameerror = 'omise-form-error'
    }
    if (this.state.cardNumber == '') {
      errorFlg++
      omisecardrror = 'omise-form-error'
    }
    if (this.state.expiration_month == '') {
      errorFlg++
      omisemontherror = 'omise-form-error'
    }
    if (this.state.expiration_year == '') {
      errorFlg++
      omiseyearerror = 'omise-form-error'
    }
    if (this.state.security_code == '') {
      errorFlg++
      omisecodeerror = 'omise-form-error'
    }

    this.setState({
      omisenameerror: omisenameerror,
      omisecardrror: omisecardrror,
      omisemontherror: omisemontherror,
      omiseyearerror: omiseyearerror,
      omisecodeerror: omisecodeerror
    })
    if (errorFlg === 0) {
      this.setState({ processOmise: true }, function() {
        $('#cartLoading').show()
      })
    } else {
      return false
    }
  }

  showOmiselert(msgArr) {
    var dataTimeoutId = $('.omise-error-info-div').attr('data-timeoutid')
    clearTimeout(dataTimeoutId)
    $('.omise-error-info-div').hide()
    $('.omise-error-msg').html(msgArr['message'])
    $('#omise-error-info-div').fadeIn()
    var timeoutid = setTimeout(function() {
      $('.omise-error-info-div').hide()
    }, 6000)
    $('.omise-error-info-div').attr('data-timeoutid', timeoutid)
  }

  /* post stripe account */
  onProcessOmiseToken = token => {
    let availability = getCookies('orderAvailability')
    let orderFinalTotal = 0
    let orderDeliveryFee = 0
    let orderAdditionaldelFee = 0
    let orderGstDisplay = 0

    let promotionAmount = 0
    let cartData = this.props.cartData
    let cartDetails = cartData.cart_details
    if (
      this.state.promotionApplied === 'Yes' &&
      (this.state.promotionAmount > 0 ||
        this.state.promoIsDelivery === 'Yes') &&
      this.state.promotionType === 'PROMOCODE'
    ) {
      promotionAmount =
        this.state.promotionAmount > 0 ? this.state.promotionAmount : 0
    }

    if (availability === DELIVERY_ID) {
      var settings = this.props.settings
      orderDeliveryFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_delivery_charge
          : 0
      orderAdditionaldelFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_additional_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_additional_delivery_charge
          : 0

      var zone_free_delivery =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_free_delivery > 0
          ? this.state.outletZoneInfo.zone_free_delivery
          : 0

      if (
        parseFloat(cartDetails.cart_sub_total) >
          parseFloat(zone_free_delivery) &&
        zone_free_delivery > 0
      ) {
        orderDeliveryFee = 0
      }

      var grand_total =
        parseFloat(cartDetails.cart_sub_total) -
        parseFloat(promotionAmount) +
        parseFloat(orderDeliveryFee) +
        parseFloat(orderAdditionaldelFee)
      if (
        settings.client_tax_enable === '1' &&
        settings.client_tax_surcharge > 0
      ) {
        orderGstDisplay = (
          (settings.client_tax_surcharge / 100) *
          grand_total
        ).toFixed(2)
      }

      orderFinalTotal = parseFloat(grand_total) + parseFloat(orderGstDisplay)
    } else {
      var settings = this.props.settings
      var grand_total =
        parseFloat(cartDetails.cart_sub_total) - parseFloat(promotionAmount)
      if (
        settings.client_tax_enable === '1' &&
        settings.client_tax_surcharge > 0
      ) {
        orderGstDisplay = (
          (settings.client_tax_surcharge / 100) *
          grand_total
        ).toFixed(2)
      }
      orderFinalTotal = parseFloat(grand_total) + parseFloat(orderGstDisplay)
    }

    var payAmount = parseFloat(roundednearest(orderFinalTotal)).toFixed(2)
    console.log(payAmount, 'orderFinalTotal')

    var requestType =
      'Springcourt-' +
      cookie.load('orderOutletId') +
      '-' +
      cookie.load('UserId')

    axios
      .get(API_URL + 'api/paymentv1/getPaymentReferenceId?app_id=' + APP_ID)
      .then(topRes => {
        if (topRes.data.status === 'ok') {
          var paymentRef = topRes.data.payment_ref_id
          this.setState({ payment_ref_id: paymentRef })

          var outletName = ''
          if (
            cookie.load('orderOutletName') !== '' &&
            typeof cookie.load('orderOutletName') !== undefined &&
            typeof cookie.load('orderOutletName') !== 'undefined'
          ) {
            outletName += cookie.load('orderOutletName') + '-'
          }
          outletName = paymentRef
          /*  load process html */
          var postObject = {}
          postObject = {
            app_id: APP_ID,
            token: token.id,
            customer_id: cookie.load('UserId'),
            outlet_id: cookie.load('orderOutletId'),
            paid_amount: payAmount,
            request_type: requestType,
            outlet_name: outletName
          }

          this.submitOrder(2, 'Yes') // To validate the order

          if (this.state.orderValidFail === 'success') {
            axios
              .post(
                API_URL + 'api/paymentv1/authOmise',
                qs.stringify(postObject)
              )
              .then(res => {
                if (res.data.status === 'ok') {
                  var captureID = res.data.result_set.payment_reference_1
                  var omiseLogId = res.data.result_set.log_id
                  this.setState(
                    { validateimage: tickImage, omise_log_id: omiseLogId },
                    function() {
                      this.submitOrder(3, '', captureID, 'Omise')
                    }.bind(this)
                  )
                } else if (res.data.status === 'error') {
                  if (res.data.message == 'token was already used') {
                    this.omise_search_history()
                  } else {
                    var errerTxt =
                      res.data.message !== ''
                        ? res.data.message
                        : 'Error code: 1001 Oops! Something went wrong! Please try again.'
                    this.paymentFail('Error', errerTxt)
                    return false
                  }
                } else {
                  var currents = this
                  setTimeout(
                    function() {
                      currents.omise_search_history()
                    }.bind(this),
                    2000
                  )
                }
              })
              .catch(error => {
                var currentcatch = this
                setTimeout(
                  function() {
                    currentcatch.omise_search_history()
                  }.bind(this),
                  2000
                )
              })
          } else {
            this.paymentFail(
              'Error',
              'Error code:1003 Oops! Something went wrong! Please try again.'
            )
            return false
          }
        } else {
          $.magnificPopup.close()
          this.paymentFail(
            'Error',
            'Error code:1003 Oops! Something went wrong! Please try again.'
          )
          return false
        }
      })
      .catch(error => {
        this.paymentFail(
          'Error',
          'Error code: 1002 Oops! Unable to connect to server. Please try again.'
        )

        return false
      })
  }
  /* Omise Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {}
    cabtureObjects = {
      payment_reference: stripeReference,
      customer_id: cookie.load('UserId'),
      app_id: APP_ID,
      token: captureID,
      order_id: orderPrimaryId,
      log_id: this.state.omise_log_id,
      outlet_id: cookie.load('orderOutletId')
    }
    axios
      .post(
        API_URL + 'api/paymentv1/captureAmountOmise',
        qs.stringify(cabtureObjects)
      )
      .then(captureRes => {
        if (captureRes.data.status === 'ok') {
          this.setState({ completingpaymentimage: tickImage })

          this.setState(
            { stop_authentication: 'yes' },
            function() {
              this.showSuccessPage(localOrderNo)
            }.bind(this)
          )
        } else if (captureRes.data.status === 'pending') {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        } else if (captureRes.data.status === 'error') {
          var magnfPopup = $.magnificPopup.instance
          var omsMsgTxt =
            captureRes.data.message != ''
              ? captureRes.data.message
              : 'Your order was not successful.'
          showAlert('Error', omsMsgTxt, magnfPopup)
          /* $.magnificPopup.open({
            items: {
              src: '.alert_popup'
            },
            type: 'inline'
          }) */
        } else {
          /*var magnfPopup = $.magnificPopup.instance;
				showAlert('Error', 'Your order was not successful.', magnfPopup);
                $.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });*/

          /*Get Status of Charge ID*/
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        }
      })
      .catch(error => {
        this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo)
      })
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load('orderOutletId')
    var postObject = {}
    postObject = {
      charge_id: captureID,
      app_id: APP_ID,
      order_primary_id: orderPrimaryId,
      outlet_id: orderOutlet_Id
    }

    axios
      .post(
        API_URL + 'api/paymentv1/retrieve_charge_details',
        qs.stringify(postObject)
      )
      .then(res => {
        if (
          res.data.status === 'ok' &&
          res.data.payment_status === 'successful'
        ) {
          this.setState(
            { stop_authentication: 'yes' },
            function() {
              this.showSuccessPage(localOrderNo)
            }.bind(this)
          )
        } else if (
          res.data.status === 'ok' &&
          res.data.payment_status === 'pending'
        ) {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        } else {
          var magnfPopup = $.magnificPopup.instance
          var msgTxt =
            res.data.message != ''
              ? res.data.message
              : 'Your order was not successful.'
          /* showAlert('Error', msgTxt, magnfPopup)
          $.magnificPopup.open({
            items: {
              src: '.alert_popup'
            },
            type: 'inline'
          }) */
        }
      })
  }

  omise_search_history = () => {
    var orderOutlet_Id = cookie.load('orderOutletId')
    var postObject = {}
    postObject = {
      app_id: APP_ID,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id
    }

    axios
      .post(
        API_URL + 'paymentv1/omise_search_details',
        qs.stringify(postObject)
      )
      .then(res => {
        if (res.data.status === 'ok') {
          var captureID = res.data.captureID
          var omiseLogId = res.data.log_id
          this.setState(
            { validateimage: tickImage, omise_log_id: omiseLogId },
            function() {
              this.postOrder(3, '', captureID, 'Omise')
            }.bind(this)
          )
        } else if (res.data.status === 'error') {
          /* Reset poup message -  start */
          this.onProcessOmiseToken(this.state.omise_tokken_response)
        } else {
          this.onProcessOmiseToken(this.state.omise_tokken_response)
        }
      })
  }

  paymentFail(header, message) {
    jQuery('.warining_popup').remove()
    jQuery('body').append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    )
  }
  pageReload = () => {
    setTimeout(function() {
      //  window.location.reload()
    }, 15000)
  }

  /* Omise End */

  render() {
    const excludeTime = [
      setHours(setMinutes(new Date(), 0), 11),
      setHours(setMinutes(new Date(), 30), 11),
      setHours(setMinutes(new Date(), 0), 12),
      setHours(setMinutes(new Date(), 30), 12),
      setHours(setMinutes(new Date(), 0), 13),
      setHours(setMinutes(new Date(), 30), 13),
      setHours(setMinutes(new Date(), 0), 14),

      setHours(setMinutes(new Date(), 30), 17),
      setHours(setMinutes(new Date(), 0), 18),
      setHours(setMinutes(new Date(), 30), 18),
      setHours(setMinutes(new Date(), 0), 19),
      setHours(setMinutes(new Date(), 30), 19),
      setHours(setMinutes(new Date(), 0), 20),
      setHours(setMinutes(new Date(), 30), 20),
      setHours(setMinutes(new Date(), 0), 21),
      setHours(setMinutes(new Date(), 30), 21)
    ]

    const moreItemsSliderSettings = {
      className: 'prd-more-item-slider',
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    const cartItems = this.props.cartData
    const cartStatus = this.props.cartStatus
    const settings = this.props.setStatus === 'ok' ? this.props.settings : []

    /* redirect */
    if (cartStatus === 'ok' && _.isEmpty(cartItems) === true) {
      history.push('/')
    }

    var finalTotal = 0
    //	var dineInInitAmount = 0;
    var subTotal = 0
    var gstPercentage = 0
    var gstDisplay = 0
    var subTotalDisplay = 0
    var serviceCharge = 0
    var serviceChargeCalc = 0
    let deliveryFee = 0
    let additionaldelFee = 0
    if (
      _.isEmpty(settings) === false &&
      _.isEmpty(cartItems) === false &&
      _.isEmpty(cartItems.cart_details) === false &&
      _.isEmpty(cartItems.cart_items) === false
    ) {
      /* get All values */

      let tempDelivery = 0

      let freeDelivery = 0
      let minCart = 0
      let balanceAmount = 0
      let percentage = 0
      subTotal = parseFloat(cartItems.cart_details.cart_sub_total)
      subTotalDisplay = parseFloat(cartItems.cart_details.cart_sub_total)

      /* promo concept */
      let promotionAmount = 0

      if (
        this.state.promotionApplied === 'Yes' &&
        this.state.promotionAmount > 0 &&
        (this.state.promotionType === 'PROMOCODE' ||
          this.state.promotionType === 'POINTS')
      ) {
        promotionAmount =
          this.state.promotionAmount > 0 ? this.state.promotionAmount : 0
      }
      if (settings.discount_apply_grand_total === '1') {
        subTotal = subTotal
      } else {
        subTotal = subTotal - promotionAmount
      }

      /* promo concept */

      /* SERVICE_CHARGE */
      if (getCookies('orderAvailability') === DINEIN_ID) {
        serviceChargeCalc = getGstValue(1, SERVICE_CHARGE, subTotal, '')

        serviceCharge = serviceChargeCalc.toFixed(2)
      }

      gstPercentage =
        typeof settings.client_tax_surcharge !== 'undefined' &&
        settings.client_tax_surcharge !== ''
          ? settings.client_tax_surcharge
          : 0
      deliveryFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_delivery_charge
          : 0
      var zone_free_delivery =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_free_delivery > 0
          ? this.state.outletZoneInfo.zone_free_delivery
          : 0

      if (
        parseFloat(subTotal) > parseFloat(zone_free_delivery) &&
        zone_free_delivery > 0
      ) {
        deliveryFee = 0
      }
      additionaldelFee =
        this.state.outletZoneInfo !== '' &&
        this.state.outletZoneInfo.zone_additional_delivery_charge > 0
          ? this.state.outletZoneInfo.zone_additional_delivery_charge
          : 0
      var grandTotal = 0
      if (getCookies('orderAvailability') === DELIVERY_ID) {
        grandTotal =
          parseFloat(subTotal) +
          parseFloat(deliveryFee) +
          parseFloat(additionaldelFee) +
          parseFloat(serviceChargeCalc)
      } else {
        grandTotal = parseFloat(subTotal) + parseFloat(serviceChargeCalc)
      }

      var gst = 0
      if (getCookies('orderAvailability') === DELIVERY_ID) {
        gst = (settings.client_tax_surcharge / 100) * grandTotal
        gstDisplay = gst.toFixed(2)
      } else {
        gst = (settings.client_tax_surcharge / 100) * grandTotal

        /* 
        getGstValue(
          settings.client_tax_enable,
          settings.client_tax_surcharge,
          subTotal + parseFloat(serviceCharge),
          ''
        ) */
        gstDisplay = gst.toFixed(2)
      }
      /*  */
      finalTotal = (parseFloat(gstDisplay) + parseFloat(grandTotal)).toFixed(2)
      if (settings.discount_apply_grand_total === '1') {
        finalTotal = finalTotal - promotionAmount
      }
      // dineInInitAmount = getDineInInitialAmount(finalTotal,dineInPercentage);

      // this.setState({finalTotal : finalTotal});
    }
    var deliveryTAT = 0
    if (
      getCookies('orderAvailability') !== '' &&
      getCookies('orderAvailability') !== undefined
    ) {
      if (getCookies('orderAvailability') === DELIVERY_ID) {
        if (
          getCookies('outletZoneInfo') !== '' &&
          typeof getCookies('outletZoneInfo') !== undefined
        ) {
          deliveryTAT = getCookies('outletZoneInfo').outlet_delivery_tat
        }
      } else if (getCookies('orderAvailability') === DINEIN_ID) {
      } else if (getCookies('orderAvailability') === PICKUP_ID) {
        deliveryTAT = getCookies('pickupTAT')
      }
    }

    return (
      <React.Fragment>
        <Header
          allProps={this.props}
          hideCart={true}
          cartAction={this.state.reloadCart}
        />
        <main>
          <div className="main-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="page-header-block bg-transparent">
                    <h1 className="font-gothamlight  text-center text-uppercase">
                      {' '}
                      Checkout
                    </h1>
                    <h2 className="font-azakie text-center">
                      Check your order details and select your payment option
                    </h2>
                    <nav
                      aria-label="breadcrumb"
                      className="d-flex justify-content-center">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Checkout
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                {_.isEmpty(this.props.adproducts) === false && (
                  <div className="col-12">
                    <div className="prd-more-item-block">
                      <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                        Would You like to add these items ?
                      </h4>
                      <div className="prd-more-item-list">
                        <Slider {...moreItemsSliderSettings}>
                          {_.isEmpty(this.props.adproducts) === false &&
                            this.props.adproducts.map((pro, pindex) =>
                              this.loadProducts(pro)
                            )}
                        </Slider>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12 col-md-6">
                  <div className="self-collection-block">
                    <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                      Your{' '}
                      {getCookies('orderAvailability') === DELIVERY_ID
                        ? deliveryText
                        : getCookies('orderAvailability') === DINEIN_ID
                        ? 'Dine in'
                        : 'Self Collection'}{' '}
                      Details
                    </h4>
                    <div className="slfcol-form-block">
                      <div className="media">
                        <img
                          className="mr-3"
                          src="./img/take-away.png"
                          alt="Generic placeholder image"
                        />
                        {getCookies('orderAvailability') === DELIVERY_ID ? (
                          <div className="media-body">
                            <h6 className="mt-0">{deliveryText} LOCATION</h6>

                            <div className="form-group">
                              <div className="focus-out">
                                <input
                                  name="address"
                                  id="address"
                                  className="form-control "
                                  type="text"
                                  placeholder="Address"
                                  readOnly
                                  value={
                                    '#' +
                                    this.state.postal_code_information
                                      .zip_buno +
                                    ', ' +
                                    this.state.postal_code_information.zip_sname
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group unit-address">
                              <div className="row">
                                <div className="col col-xl-4">
                                  <input
                                    name="postal_code_information"
                                    id="postal_code_information"
                                    className="form-control "
                                    type="text"
                                    placeholder="Postal Code"
                                    readOnly
                                    value={
                                      this.state.postal_code_information
                                        .zip_code
                                    }
                                  />
                                </div>
                                <div className="col col-xl-4">
                                  <input
                                    name="unitnumber1"
                                    id="unitnumber1"
                                    className="form-control "
                                    type="text"
                                    placeholder="First Name"
                                    placeholder="Unit No 01"
                                    value={this.state.unitnumber1}
                                    onChange={this.handleAddrChange.bind(this)}
                                  />
                                </div>
                                <div className="col col-xl-4">
                                  <input
                                    name="unitnumber2"
                                    className="form-control "
                                    type="text"
                                    placeholder="Unit No 02"
                                    value={this.state.unitnumber2}
                                    onChange={this.handleAddrChange.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                            <Link
                              to="#"
                              onClick={e => this.UpdateDelveiryDateModal(e)}>
                              Change Delivery Location
                            </Link>
                          </div>
                        ) : (
                          <div className="media-body">
                            <h6 className="mt-0">
                              {getCookies('orderAvailability') === DINEIN_ID
                                ? 'Dine in'
                                : 'Self Collection '}{' '}
                              LOCATION{' '}
                            </h6>
                            <p className="outlet-info">
                              {stripslashes(getCookies('orderOutletName'))} -{' '}
                              {addressFormat(
                                getCookies('orderUnitOne'),
                                getCookies('orderUnitTwo'),
                                getCookies('orderAddressOne'),
                                getCookies('orderAddressTwo'),
                                getCookies('orderPostalCode')
                              )}
                            </p>
                            {/*  <Link to="#">Change Picakup Location</Link> */}
                          </div>
                        )}
                      </div>
                      <div className="media">
                        <img
                          className="mr-3"
                          src="./img/alarm-clock.png"
                          alt="Generic placeholder image"
                        />
                        <div className="media-body">
                          {getCookies('orderAvailability') === DELIVERY_ID ? (
                            <h6 className="mt-0">{deliveryText} Date & Time</h6>
                          ) : (
                            <h6 className="mt-0">
                              {getCookies('orderAvailability') === DINEIN_ID
                                ? 'Dine in'
                                : 'Self Collection '}{' '}
                              Date & Time
                            </h6>
                          )}
                          {
                            /* getCookies('orderAvailability') === DELIVERY_ID ? ( */
                            <OrderdatetimeSlot
                              {...this.props}
                              hdrState={[
                                {
                                  getDateTimeFlg: this.state.getDateTimeFlg,
                                  seletedAvilablityId:
                                    cookie.load('orderAvailability') !== '' &&
                                    typeof cookie.load('orderAvailability') !=
                                      undefined &&
                                    typeof cookie.load('orderAvailability') !==
                                      'undefined'
                                      ? cookie.load('orderAvailability')
                                      : DELIVERY_ID,
                                  seletedOutletId: OUTLET_ID,
                                  order_tat_time: deliveryTAT
                                }
                              ]}
                              setdateTimeFlg={this.setdateTimeFlg}
                            />
                            /* ) : (
                            <div>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                minDate={addDays(
                                  new Date(),
                                  getCookies('checkoutMinDay')
                                )}
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                              />

                              <DatePicker
                                className="form-control collect-time-picker"
                                selected={this.state.startTime}
                                onChange={this.handleChangeTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                dateFormat="h:mm aa"
                                timeCaption="Time"
                                includeTimes={excludeTime}
                              />
                            </div>
                          ) */
                          }
                        </div>
                      </div>
                      <div className="special-instruction-block">
                        <h6>Special Instruction</h6>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="6"
                        />
                      </div>
                      <br />
                      <br />
                      <div className="popup-body special-instruction-block">
                        <h6>Disposable Cutlery</h6>
                        <input
                          type="radio"
                          name="cutlery"
                          id="cutlery"
                          onChange={this.handleChange.bind(this, 'cutlery')}
                          value="No"
                          defaultChecked={
                            this.state.cutlery === 'No' ? true : false
                          }
                        />{' '}
                        No&nbsp; &nbsp; &nbsp;
                        <input
                          type="radio"
                          name="cutlery"
                          id="cutlery"
                          onChange={this.handleChange.bind(this, 'cutlery')}
                          value="Yes"
                          defaultChecked={
                            this.state.cutlery === 'Yes' ? true : false
                          }
                        />{' '}
                        Yes
                      </div>
                    </div>
                  </div>
                </div>

                {_.isEmpty(settings) === false &&
                  _.isEmpty(cartItems) === false &&
                  _.isEmpty(cartItems.cart_details) === false &&
                  _.isEmpty(cartItems.cart_items) === false && (
                    <div className="col-12 col-md-6">
                      <div className="order-details-item-block chekout-cart-bar">
                        <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                          Your{' '}
                          {getCookies('orderAvailability') === DELIVERY_ID
                            ? deliveryText
                            : getCookies('orderAvailability') === DINEIN_ID
                            ? 'Dine in'
                            : 'Self Collection'}{' '}
                          Details
                        </h4>
                        <div className="cart-items-block">
                          <div className="cart-row-header">
                            <div className="row">
                              <div className="col-6">
                                <h6 className="text-uppercase">Added Items</h6>
                              </div>
                              <div className="col-6 text-right">
                                <h6
                                  className="text-uppercase"
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none'
                                  }}
                                  onClick={this.destroyCart.bind(this, 'Yes')}>
                                  Clear Cart
                                </h6>
                              </div>
                            </div>
                          </div>

                          {_.isEmpty(cartItems.cart_items) === false &&
                            cartItems.cart_items.map((cart, cartIndex) => (
                              <div className="cart-row cart-row-header" key={cartIndex}>
                                <div className="row">
                                  <div className="col-8 col-xl-8">
                                    <div className="cart-left">
                                      {cart.cart_item_product_image !== '' && (
                                        <div className="cart-img">
                                          <img
                                            src={cart.cart_item_product_image}
                                            width={110}
                                            height={67}
                                            alt="image"
                                          />
                                        </div>
                                      )}
                                      <div className="cart-info">
                                        <h6>
                                          {' '}
                                          {stripslashes(
                                            cart.cart_item_product_name
                                          ) + " (" + "$" + cart.cart_item_unit_price +")" }
                                        </h6>
                                        {this.loadModifierItems(
                                          cart.cart_item_type,
                                          cart.modifiers,
                                          cart.set_menu_component
                                        )}
                                    {/*  <div className="cart-addon-info">
                                    <span className="d-inline-block">
                                      Size:
                                    </span>
                                    <span className="d-inline-block font-italic">
                                      {" "}
                                      Large
                                    </span>
                                  </div>
                                  <div className="cart-addon-info">
                                    <span className="d-inline-block">
                                      Add on:
                                    </span>
                                    <span className="d-inline-block font-italic">
                                      {" "}
                                      Name
                                    </span>
						 </div> */}
                                      </div>
                                    </div>
                                    {cart.cart_item_special_notes !== '' && (
                                      <p className="cart-special-text">
                                        {stripslashes(
                                          cart.cart_item_special_notes
                                        )}
                                      </p>
                                    )}
                                  </div>
                                 <div class="col-2 col-xl-2 col-pd-2">
                                    <div className="checkout_quantity_show">
                                      <p className="close-c-out">X</p>
                                      <p>{cart.cart_item_qty}</p>    
                                    </div>
                                </div>                                 
                                  <div className="col-2 col-xl-2 text-right">
                                    <div className="cart-right ">
                                      <div className="cart-price">
                                        <p>${cart.cart_item_total_price}</p>
                                      </div>
                                      {cart.cart_item_promo_free === null && (
                                        <div
                                          className="qty-bx"
                                          id={'citem_' + cart.cart_item_id}>
                                          <span
                                            className="qty-minus"
                                            onClick={this.incQty.bind(
                                              this,
                                              cart.cart_item_id,
                                              cart.cart_item_qty,
                                              cart.cart_item_product_id,
                                              'remove',
                                              cart
                                            )}>
                                            -
                                          </span>
                                          <input
                                            type="text"
                                            value={cart.cart_item_qty}
                                            readOnly
                                          />
                                          <span
                                            className="qty-plus"
                                            onClick={this.incQty.bind(
                                              this,
                                              cart.cart_item_id,
                                              cart.cart_item_qty,
                                              cart.cart_item_product_id,
                                              'add',
                                              cart
                                            )}>
                                            +
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {cart.cart_item_promo_free === null && (
                                  <a
                                    href="javascript:;"
                                    className="cart-remove"
                                    onClick={this.deleteCartItem.bind(
                                      this,
                                      cart.cart_item_id
                                    )}
                                  />
                                )}
                              </div>
                            ))}
                          <div className="calctrcart-footer">
                            <div className="calctrcart-row">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">SUBTOTAL</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${subTotalDisplay.toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                            {getCookies('orderAvailability') ===
                              DELIVERY_ID && (
                              <div>
                                {deliveryFee > 0 && (
                                  <div className="calctrcart-row ">
                                    <div className="row">
                                      <div className="col-8">
                                        <p className="text-uppercase">
                                          Delivery Charges
                                        </p>
                                      </div>
                                      <div className="col-4 text-right">
                                        <span>${deliveryFee}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {additionaldelFee > 0 && (
                                  <div className="calctrcart-row ">
                                    <div className="row">
                                      <div className="col-8">
                                        <p className="text-uppercase">
                                          Additional Delivery Charges
                                        </p>
                                      </div>
                                      <div className="col-4 text-right">
                                        <span>${additionaldelFee}</span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {getCookies('orderAvailability') === DINEIN_ID && (
                              <div className="calctrcart-row  ">
                                <div className="row">
                                  <div className="col-8">
                                    <p className="text-uppercase">
                                      Service Charge ({SERVICE_CHARGE}%)
                                    </p>
                                  </div>
                                  <div className="col-4 text-right">
                                    <span>${serviceCharge}</span>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="calctrcart-row  ">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">
                                    GST ({gstPercentage}%)
                                  </p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${gstDisplay}</span>
                                </div>
                              </div>
                            </div>
                            {settings.discount_apply_grand_total !== '1' &&
                              this.state.promotionApplied === 'Yes' &&
                              (this.state.promotionType === 'PROMOCODE' ||
                                this.state.promotionType === 'POINTS') && (
                                <div className="calctrcart-row  ">
                                  <div className="row">
                                    <div className="col-8">
                                      <p className="text-uppercase">
                                        DISCOUNT({this.state.promocode})
                                      </p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <span>
                                        - $
                                        {this.toFixedRounding(
                                          this.state.promotionAmount
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            <div className="calctrcart-row calctrcart-footer-totrow">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">Total</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${roundednearest(finalTotal)}</span>
                                </div>
                              </div>
                            </div>
                            {settings.discount_apply_grand_total === '1' &&
                              this.state.promotionApplied === 'Yes' &&
                              (this.state.promotionType === 'PROMOCODE' ||
                                this.state.promotionType === 'POINTS') && (
                                <div className="calctrcart-row  ">
                                  <div className="row">
                                    <div className="col-8">
                                      <p className="text-uppercase">
                                        DISCOUNT({this.state.promocode})
                                      </p>
                                    </div>
                                    <div className="col-4 text-right">
                                      <span>
                                        - $
                                        {this.toFixedRounding(
                                          this.state.promotionAmount
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {/* (getCookies("orderAvailability") === DINEIN_ID) &&	
							 <div className="calctrcart-row calctrcart-footer-totrow">
                              <div className="row">
                                <div className="col-8">
                                  <p className="text-uppercase">Payable Amount</p>
                                </div>
                                <div className="col-4 text-right">
                                  <span>${dineInInitAmount}</span>
                                </div>
								
								<p  style={{fontSize: '0.5em', marginTop: '1em', color: 'red'}}>For dine in orders, Please pay {dineInPercentage}% upfront for confirmation. </p>
                              </div>
                            </div>
							 */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="redeem-promocode-row row">
                {/* <div className="col-md-6">
                  <div className="left-col-of-redeem-points">
                    <h4 className="text-uppercase">
                      Get Redeem{' '}
                      <span className="badge badge-danger">
                        You have {this.props.rewardCount} cashback $ available
                      </span>
                    </h4>
                    <span className="d-block help-text">
                      Redeem your cashback $ here
                    </span>
                    <div className="input-group">
                      <input
                        placeholder={
                          'You Can Redeem ' +
                          this.props.rewardCount +
                          ' Cashback $'
                        }
                        type="text"
                        className="form-control"
                        id="point_input"
                      />
                      <div className="input-group-append">
                        {this.state.promotionApplied === 'No' && (
                          <button
                            className="btn btn-secondary"
                            onClick={this.applyPoints.bind(
                              this,
                              subTotalDisplay
                            )}>
                            Apply Now
                          </button>
                        )}

                        {this.state.promotionApplied === 'Yes' &&
                          this.state.promotionType === 'POINTS' && (
                            <button
                              className="btn btn-secondary"
                              onClick={this.resetPromoState.bind(this)}>
                              Remove
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-10 col-lg-8 col-xl-6 m-auto">
                  <div className="right-col-of-promo-code">
                    <h4 className="text-uppercase">
                      Promo code{' '}
                      {this.state.customerPromoCount > 0 ? (
                        <span className="badge badge-danger promotions-link">
                          You have{' '}
                          <Link
                            to={'/promotions'}
                            className="checkout-promo-count">
                            {this.state.customerPromoCount} Promotions
                          </Link>{' '}
                          available
                        </span>
                      ) : (
                        <span className="badge badge-danger">
                          You have {this.state.customerPromoCount} Promotions
                          available
                        </span>
                      )}
                    </h4>
                    <span className="d-block help-text">
                      Apply your promo code here
                    </span>
                    <div className="input-group">
                      <input
                        placeholder="Add Your Promo Code Here"
                        id="promo_input"
                        type="text"
                        defaultValue={this.state.promocode}
                        className="form-control"
                      />
                      <div className="input-group-append">
                        {this.state.promotionApplied === 'No' && (
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.applyPromocode.bind(
                              this,
                              subTotalDisplay
                            )}>
                            Apply Now
                          </button>
                        )}
                        {this.state.promotionApplied === 'Yes' &&
                          this.state.promotionType === 'PROMOCODE' && (
                            <button
                              className="btn btn-secondary"
                              onClick={this.resetPromoState.bind(this)}>
                              Remove
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="checkout-payment-actions">
                <div className="row">
                  <div className="col-12">
                    <h4 className="font-gothamlight checkout-block-header text-center text-uppercase">
                      Select Your Payment Method
                    </h4>
                    <div className="payment-types-block">
                      <div className="form-group">
                        {settings.client_cod_availability === '1' && (
                          <div className="custom-radio custom-control">
                            <input
                              type="radio"
                              id="ptype_paidcounter"
                              name="customRadio"
                              className="custom-control-input"
                              value="1"
                              defaultChecked={
                                this.state.client_payments === 'COD'
                                  ? true
                                  : false
                              }
                              onClick={this.onChangePayment}
                            />
                            <label
                              className={
                                this.state.client_payments === 'COD'
                                  ? 'custom-control-label active'
                                  : 'custom-control-label'
                              }
                              htmlFor="ptype_paidcounter">
                              Cash On Delivery
                            </label>
                          </div>
                        )}
                        {/*settings.client_stripe_availability === '1' && (
                          <div className="custom-radio custom-control">
                            <input
                              type="radio"
                              id="ptype_cod"
                              name="customRadio"
                              className="custom-control-input"
                              defaultChecked={
                                this.state.client_payments === 'STRIPE'
                                  ? true
                                  : false
                              }
                              value="3"
                              onClick={this.onChangePayment}
                            />
                            <label
                              className={
                                this.state.client_payments === 'STRIPE'
                                  ? 'custom-control-label active'
                                  : 'custom-control-label'
                              }
                              htmlFor="ptype_cod">
                              <img
                                src="./img/payment-card-type.png"
                                alt="..."
                              />
                            </label>
                          </div>
                        )*/}
                        {settings.client_omise_enable === '1' && (
                          <div className="custom-radio custom-control">
                            <input
                              type="radio"
                              id="ptype_omise"
                              name="customRadio"
                              className="custom-control-input"
                              defaultChecked={
                                this.state.client_payments === 'Omise'
                                  ? true
                                  : false
                              }
                              value="4"
                              onClick={this.onChangePayment}
                            />
                            <label
                              className={
                                this.state.client_payments === 'Omise'
                                  ? 'custom-control-label active'
                                  : 'custom-control-label'
                              }
                              htmlFor="ptype_omise">
                              <img src="./img/omise.png" alt="..." />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {_.isEmpty(settings) === false &&
                  _.isEmpty(cartItems) === false &&
                  _.isEmpty(cartItems.cart_details) === false &&
                  _.isEmpty(cartItems.cart_items) === false && (
                    <div className="checkout-btn-block row">
                      <div className="col-sm-6 pr-sm-0">
                        <Link to="/" className="btn-back btn btn-primary">
                          Back
                        </Link>
                      </div>
                      <div className="col-sm-6 pl-sm-0">
                        {/* onClick={this.submitOrder.bind(
                            this,
                            subTotalDisplay,
                            finalTotal,
                            0,
                            0,
                            gstPercentage,
                            gstDisplay,
                            0
                          )}*/}

                        {this.state.client_payments === 'STRIPE' &&
                          settings.client_stripe_availability === '1' && (
                            <div>
                              {settings.stripe_public_key &&
                                cookie.load('UserMobile') != '' && (
                                  <button
                                    type="button"
                                    onClick={this.submitStrip.bind(this)}
                                    className="btn-checkout btn btn-primary">
                                    Checkout
                                  </button>
                                )}
                            </div>
                          )}
                        {this.state.client_payments === 'Omise' &&
                          settings.client_omise_enable === '1' && (
                            <div>
                              {settings.omise_public_key &&
                                cookie.load('UserMobile') != '' && (
                                  <button
                                    type="button"
                                    onClick={this.orderPayValidaion.bind(
                                      this,
                                      'Omise'
                                    )}
                                    className="btn-checkout btn btn-primary">
                                    Checkout
                                  </button>
                                )}
                              <Checkout1
                                sateValChange={this.sateValChange}
                                checkoutState={this.state}
                              />
                            </div>
                          )}
                        {this.state.client_payments === 'COD' &&
                          settings.client_cod_availability === '1' && (
                            <button
                              onClick={this.submitCOD.bind(this)}
                              type="button"
                              className="btn-checkout btn btn-primary">
                              Checkout
                            </button>
                          )}
                        {/* subTotalDisplay,
                            finalTotal,
                            0,
                            0,
                            gstPercentage,
                            gstDisplay,
                            0 */}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div
            id="cartLoading"
            style={{
              display: 'none',
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              margin: 'auto',
              zIndex: 9999,
              background: 'rgba(255, 255, 255, 0.8)'
            }}>
            <img
              src={loadingImage}
              alt="Loading"
              style={{
                bottom: 0,
                left: 0,
                position: 'fixed',
                right: 0,
                top: 0,
                margin: 'auto'
              }}
            />
          </div>
          <div
            className="modal fade"
            id="switchErrorChangeLocation"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content bg-Black delivery-datetime-modal">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-5 popup_left">
                        <div className="delivery-address-block">
                          <img src="/img/warning-icon.png" alt="..." />
                          <h1 className="text-uppercase mt-2 mb-0">
                            Warning !
                          </h1>
                          {/*    <h1 className="font-azakie mb-0">We can Deliver !</h1> */}
                        </div>
                      </div>
                      <div className="col-lg-7 popup_right">
                        <div className="innervmid_in delivery-datetime-form-block text-center">
                          <p>
                            By changing delivery location you are about to clear
                            your cart.
                          </p>
                          <p>Do you wish to proceed ?</p>
                          <div className="alt_btns">
                            <a
                              href="javascript:;"
                              data-dismiss="modal"
                              className="btn   btn-sm btn_black popup-modal-dismiss">
                              No
                            </a>
                            <a
                              href="javascript:;"
                              onClick={e =>
                                this.changeAvailability(e, '#switchError')
                              }
                              to="#"
                              className="btn btn-sm btn_yellow popup-modal-dismiss">
                              Yes
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="stripPaymentProcess"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content bg-Black delivery-datetime-modal">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-5 popup_left">
                        <div className="delivery-address-block">
                          <img src={ico_like} alt="..." />
                        </div>
                      </div>
                      <div className="col-lg-7 popup_right">
                        <div className="innervmid_in delivery-datetime-form-block text-center">
                          <p>Awesome ! You are all set</p>

                          <div className="alt_btns">
                            {settings.stripe_public_key &&
                              cookie.load('UserMobile') != '' && (
                                <StripeCheckout
                                  opened={this.onOpened.bind(
                                    this,
                                    subTotalDisplay,
                                    roundednearest(finalTotal),
                                    0,
                                    0,
                                    gstPercentage,
                                    gstDisplay,
                                    0,
                                    serviceCharge
                                  )}
                                  name={stripeCompany}
                                  image={stripeImage}
                                  description={
                                    this.stripeDescription() +
                                    roundednearest(finalTotal) * 100
                                  }
                                  token={this.onToken}
                                  stripeKey={settings.stripe_public_key}
                                  amount={roundednearest(finalTotal) * 100}
                                  email={cookie.load('UserEmail')}
                                  currency={stripeCurrency}>
                                  {' '}
                                  <button
                                    type="button"
                                    className="btn-checkout btn btn-primary">
                                    Proceed To Payment
                                  </button>{' '}
                                </StripeCheckout>
                              )}
                            {roundednearest(finalTotal)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Payment omise confirm popup Start*/}

          <div
            className="modal fade pay-omiseconf-popup"
            id="stripPaymentProcess"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            id="pay-omiseconf-popup">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content bg-Black delivery-datetime-modal">
                <div className="modal-body">
                  <div
                    className="omise-error-info-div"
                    id="omise-error-info-div"
                    style={{ display: 'none' }}>
                    <div className="container">
                      <span className="omise-error-icon">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        />
                      </span>

                      <a
                        href="#"
                        className="omise_alert_close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={evt => {
                          evt.preventDefault()
                        }}>
                        {' '}
                        <span aria-hidden="true">×</span>{' '}
                      </a>
                      <p className="omise-error-msg">Something went wrong</p>
                    </div>
                  </div>

                  {settings.omise_envir === 'dev' && (
                    <div className="omisepay-mode-option">Test Mode</div>
                  )}

                  <div className="custom_alert_new pay-omiseconf-popup">
                    <div className="custom_alertin">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="alert_height">
                        <div className="omise_pay_header">
                          <span className="omise-pay-img">
                            <img
                              className="makisan-logo-img"
                              src={stripeImage}
                            />
                          </span>
                          <span className="omise-pay-title">Spring Court</span>
                        </div>
                        <div className="omise_pay_body">
                          <div className="omisepop_in">
                            <div className="form_sec">
                              <div
                                className={
                                  'uname-box ' + this.state.omisenameerror
                                }>
                                <i className="fa fa-user" data-unicode="f007" />
                                <input
                                  type="text"
                                  id="holdername"
                                  className="form-control"
                                  placeholder="Card Holder Name"
                                  value={this.state.holdername}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    'holdername'
                                  )}
                                />
                              </div>
                              <div
                                className={
                                  'card-numbr-div ' + this.state.omisecardrror
                                }>
                                <i
                                  className="fa fa-credit-card"
                                  data-unicode="f09d"
                                />
                                <input
                                  type="text"
                                  className={
                                    'form-control ' + this.state.cardImage
                                  }
                                  placeholder="Card Number"
                                  value={this.state.cardNumber}
                                  id="omise_card_number"
                                  value={this.state.cardNumber}
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    'cardNumber'
                                  )}
                                />
                                <span className="card" />
                              </div>
                              <div className="form_expire_row">
                                <div className="expire_row_inner">
                                  <div
                                    className={
                                      'expire_left ' +
                                      this.state.omisemontherror
                                    }>
                                    <i
                                      className="fa fa-calendar"
                                      data-unicode="f133"
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength="2"
                                      id="expiration_month"
                                      placeholder="MM"
                                      value={this.state.expiration_month}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        'expiration_month'
                                      )}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'expire_mdl ' + this.state.omiseyearerror
                                    }>
                                    <input
                                      type="text"
                                      placeholder="YY"
                                      className="form-control"
                                      maxLength="4"
                                      id="expiration_year"
                                      value={this.state.expiration_year}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        'expiration_year'
                                      )}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'expire_right ' +
                                      this.state.omisecodeerror
                                    }>
                                    <i
                                      className="fa fa-lock"
                                      data-unicode="f023"
                                    />
                                    <input
                                      type="password"
                                      maxLength="3"
                                      className="form-control"
                                      id="security_code"
                                      placeholder="CVV"
                                      value={this.state.security_code}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        'security_code'
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="alt_btns">
                                {settings.omise_public_key &&
                                  cookie.load('UserMobile') != '' &&
                                  this.state.client_payments == 'Omise' &&
                                  settings.client_omise_enable === '1' && (
                                    <button
                                      onClick={this.getOmiseToken.bind(this)}
                                      className="btn-checkout btn btn-primary omise-pay-btn">
                                      Pay {stripeCurrency}{' '}
                                      {roundednearest(
                                        parseFloat(finalTotal).toFixed(2)
                                      )}
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*payment omise confirm popup - End*/}
        </main>
        <Footer allProps={this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ cart, settings, cms, user }) => ({
  cartData: cart.returnData,
  cartStatus: cart.isStatus,
  settings: settings.returnData,
  setStatus: settings.isStatus,
  staticblocks: cms.staticblocks,
  cmsFetch: cms.cmsFetch,
  adCommon: cms.adCommon,
  adproducts: cms.adproducts,
  promoCount: user.promoCount,
  rewardCount: user.rewardCount
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartItems,
      getSetings,
      getblocks,
      getAdonProducts,
      getUserData,
      getPromoCount
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout)
