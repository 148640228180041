import axios from 'axios'
import { API_URL, APP_ID } from '../config/constans'
import { getCookies } from '../config/supporters'
export const NAV_REQUESTED = 'counter/NAV_REQUESTED'
export const GET_NAV = 'counter/GET_NAV'

const initialState = {
  navItems: [],
  navLoading: false,
  menuStatus: false
}

export default (state = initialState, action) => {
  //console.log(action);
  switch (action.type) {
    case NAV_REQUESTED:
      return {
        ...state,
        navLoading: true
      }
    case GET_NAV:
      return {
        ...state,
        navItems: action.navItems,
        navLoading: !state.navLoading,
        menuStatus: action.menuStatus
      }

    default:
      return state
  }
}

export const getMenu = () => {
  var avId = getCookies('orderAvailability')
  var outletID = getCookies('orderOutletId')
  var avilable = ''
  if (avId !== '' && typeof avId !== undefined) {
    avilable = '&availability=' + avId
  }
  const API =
    API_URL +
    'apiv2/products/getMenuNavigation?app_id=' +
    APP_ID +
    avilable +
    '&outletId=' +
    outletID
  return function(dispatch) {
    dispatch({
      type: NAV_REQUESTED
    })
    axios
      .get(API)
      .then(response => {
        dispatch({
          type: GET_NAV,
          navItems: response.data.result_set,
          menuStatus: response.data.status
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}
